

import { Button, Grid, Paper, TextField } from "@material-ui/core";
import { setBk, setBrd } from "../../utils/colorUtils";
import { useContext, useEffect, useRef, useState } from "react";
import { SetPopupContext } from "../home/component/MainRouter";
import { saveAssessNRank } from "../../utils/skillRankUtils";


const SkillTest = (props)=>{

    const { questions, title } = props;
    const setPopup = useContext(SetPopupContext);
    const [ques, setQues] = useState();

    useEffect (()=>{
        setQues([...questions])

    }, [props.questions])

    const verifyQA = ()=>{
        let data = {};
        let unqId = [];

        if(!ques) {
            setPopup({
                open: true,
                severity: "error",
                message: `No answer`,
              });
            return false;
        }

        for(let i=0; i<ques.length; i++) {
            unqId.push(ques[i].id);
            if (!ques[i].answer || ques[i].answer.split(' ').length <5) {
                setPopup({
                    open: true,
                    severity: "error",
                    message: `Question ${i + 1} answer need more details, atleast 5 words are required`,
                  });
                return false;
            }
        }
        unqId.sort();

        data.assess = ques;
        data.title = title;
        data.id = title + "-" + unqId.join('-');
        return data;
    }

    const submitAnswer = async()=>{
        let data = verifyQA();
        
        if(data !== false) {
            console.log('Save QA into DB', data);
            saveAssessNRank(data, "Skill Test", "skill");
            props.setDone();
            
        } else {
            console.log("Error in data.");
        }
    }

     return  <Grid container direction="row" spacing={2} style={{background:'rgb(255 252 252)', paddingLeft:'1em', marginTop:'40px', marginLeft: '10px', marginBottom:'40px', width:'98%', borderRadius:'10px', border:'0.5px solid lightgray'}}>
        <Grid item xs={11} >
            <Grid >
                { ques && ques.map((que, key)=>{
                    return <div>
                        <div key={key} style = {{display:'flex', alignItems: 'center', marginLeft:'0em', marginBottom:'2em'}}>
                            <div className="num" style = {setBk(key)} >{ key < 9 ? `0${key+1}` : `${key+1}` }</div>
                            <div className="context" style = {setBk(key)}>
                                <p className="note" style = {setBrd(key)}>
                                    <span >{que.title}</span>
                                </p>
                            </div>
                        </div>
                        <div>
                        <TextField
                                label="Write Your Answer (upto 400 words)"
                                multiline
                                rows={3}
                                style={{ width: "100%", marginBottom: "30px" }}
                                variant="outlined"
                                value={que.answer}
                                onChange={(event) => {
                                if (
                                    event.target.value.split(" ").filter(function (n) {
                                    return n != "";
                                    }).length <= 400
                                ) {
                                    let temp = [...ques];
                                    temp[key].answer = event.target.value;
                                    setQues(temp);
                                   // console.log()
                                }
                                }}
                            />
                        </div>
                    </div>
                    }
                )}
            </Grid>
            <Button disabled={!questions} style={{margin:'1.4em', width:'8em', color:'white', background:'#44c6f4'}} onClick={()=>{submitAnswer()}}> Submit </Button>
        </Grid>
   </Grid>

}

export default SkillTest;