// src/components/Circle.js

import React from 'react';
import './Circle.css';

const Circle = ({ number }) => {
  return (
      <div className="circle">
        <span className="number">{number}</span>
      </div>
  );
};

export default Circle;
