import { javaSets } from "./Java_Sets"
import { msSets } from "./MS_Sets";
import { sqlSets } from "./Sql_Sets";

export const qaSets = (interview)=>{

    if(interview == 'java') {
        return javaSets;
    } else if(interview == 'sql') {
        return sqlSets;
    } else if(interview == 'ms') {
        return msSets;
    }

}