import { Modal } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

const TestCompletedModal = ({ completed }) => {
  const history = useHistory();
  const [redirect,setRedirect] = useState(5);

  useEffect(() => {

    let redirectTimeout;

    if (completed) {
      redirectTimeout = setInterval(() => {
        setRedirect((prevCountdown) => prevCountdown - 1);
      }, 1000); // Update countdown every second

      setTimeout(() => {
        // Replace the current entry in the history stack with the new one
        clearInterval(redirectTimeout);
        history.replace('/');
      }, 5000);
    }

    // Cleanup the interval when the component unmounts or when completed changes to false
    return () => clearInterval(redirectTimeout);
  }, [completed]);

  return (
    <div>
      <Modal open={completed} className="flex justify-between items-center">
        <div className='flex flex-col items-center justify-center rounded-md' style={{ width: '1000px', height: '500px', backgroundColor: 'white' }}>
          <h2 className='text-[3498db]'>Test Completed</h2>
          <span>Thank you for taking the test, Rate your experience.</span>
          <hr/>
          <span>Redirecting in {redirect}...</span>
        </div>
      </Modal>
    </div>
  );
};

export default TestCompletedModal;
