import React, { useState, useEffect, useContext, useRef } from "react";
import { useDispatch } from "react-redux";
import {
  Button,
  Grid,
  TextField,
  MenuItem,
  FormControlLabel,
  Typography,
  InputAdornment,
  makeStyles,
} from "@material-ui/core";
import { Radio } from "@mui/material";
import { SetPopupContext } from "../home/component/MainRouter";
import {API, fetchData, saveData} from "../../config/apiService"
import TextEditor from "../editor/editor-text/TextEditor";
import './ChatView.css'
import { AttachFile, Height } from "@material-ui/icons";
import CheckIcon from '@mui/icons-material/Check';
import { isMobile } from "../../utils/mobileUtil";
import AttachFileIcon from '@mui/icons-material/AttachFile';
import { handleUpload } from "../login/lib/UploadFile";
import CloseIcon from '@mui/icons-material/Close';
import DownloadIcon from '@mui/icons-material/Download';
import { showErrorToast } from "../../utils/toastMsg";


const ChatView = (props)=>{
    const {profile, chatType } = props;

    const dispatch = useDispatch();
    const setPopup = useContext(SetPopupContext);
    const type = localStorage.getItem('type').charAt(0);
    //const profile = props.profile;
    const chatHistory = useRef();
    let chatBox = useRef();

    // let fetchURL =  chatType ==='job' ? `${API.jobs}/chat?appId=${profile.appId}` : (type==='r' ? `${API.chat}?userId=${profile.userId}` : `${API.chat}?_id=${profile._id}`);
    // let postURL = chatType ==='job' ? `${API.jobs}/chat/${profile.appId}` : `${API.chat}`

    let fetchURL =  profile._id ? `${API.chat}?_id=${profile._id}`: (  type==='r' && profile.appId ?  `${API.chat}?appId=${profile.appId}` : `${API.chat}?userId=${profile.userId}`);
    let postURL = `${API.chat}`
    const uploadTo = `${API.chat}`

    const [chatId, setChatId] = useState(profile._id);

    //console.log('profile:', profile)

    const [chat, setChat] = useState()
    const [listChats, setListChats] = useState([]);
    const [file, setFile] = useState([]);
    const [attach, setAttach] = useState([]);
    const size = isMobile ? '50px' : '28px';
    const Attsize = isMobile ? '45px' : '24px';


    useEffect(()=>{
        setListChats([]);
        setChat('');
        setFile([]);
        setAttach([]);
        getChatInfo();
       
    },[profile])

    const useStyles = makeStyles((theme) => ({
        noBorder: {
          border: "none",
        },
    }));

    const classes = useStyles();

    const getChatInfo = async()=>{
        console.log('NAME:', profile.name);
        let res = await fetchData(fetchURL); //(`${API.jobs}/chat?appId=${profile.appId}`);
        console.log('ChatInfo:', res)
        if (res) {
            setListChats(res.chats);
            setChatId(res._id);
            scrollToBottom();
        }
    }

    const sendMessage = async()=>{
        if(!chat&&attach.length===0) return;
        console.log('listChats:::', listChats)

        let temp = [...listChats];
        let dt = new Date();
        let chatMsg = {};

        chatMsg = {msg: chat, st:'ur', date: dt, ty:type}

        if(attach.length>0) {
            chatMsg.atts = attach;
           
        } else {
            temp.push(chatMsg);
            setListChats(temp);
        }

        let data = {_id: chatId , userId: profile.userId, appId: profile.appId,  chat: chatMsg};
        console.log('temp:', postURL, data)
        let allChats = await saveData(postURL, data); //(`${API.jobs}/chat/${profile.appId}`, chatMsg, "Chat");
        console.log('allChats:', allChats.data);

         if(attach.length>0){ // if any attachment available upload
            for(let i =0; i<attach.length; i++) {
                let id = `${allChats.data._id}_${attach[i].index}`
                console.log ('id', id, file[i])
                await handleUpload(`${uploadTo}/attach/${id}`, file[i], setPopup, 'Attachment Upload ');
            }
            setAttach([]);
            setFile([]);
            setTimeout(()=>{ setListChats(allChats.data.chats); setChatId(allChats.data._id);}, 600); // first image need to upload then it will download so need some delay
        } else {
            setListChats(allChats.data.chats);
            setChatId(allChats.data._id);
        }

        setChat('');
        focusChatBox();      

    }

    const uploadAttach = (f)=>{
        console.log('file:', f)
        if(f){ 
            let number = f.size/1024;
            let atts = [...attach];
            let att = {name: f.name, size: number.toFixed(2), index:`${listChats.length}_${atts.length}`};
            atts.push(att);
            setAttach(atts);
        }
    }
    const removeAttach = (key)=>{
        let atts = [...attach];
        atts.splice(key,1);
        setAttach(atts);

        let ff = [...file];
        ff.splice(key, 1);
        setFile(ff);
        
    }

    const focusChatBox = () => {
        if (chatBox.current) {
            chatBox.current.focus();
            chatBox.current.children[0].children[0].focus();  //.className = 'passive'
        }
    }

    const scrollToBottom = () => {
        if (chatHistory.current) {
            //console.log('scorll:', chatHistory.current)
            chatHistory.current.scrollIntoView({ behavior: 'smooth' })
        }
    }

    const renderAtts = (atts, color)=><div style={{display:'inline-grid'}}>
        {atts.map((att, key)=>{
            let i = att.name.lastIndexOf(".");
            let ext = att.name.substring(i+1, att.name.length).toUpperCase();

            return <div key={key} style={{display:'flex', background: `${color?color:'lightgray'}`, marginLeft:'0px', marginBottom:'2px'}}>
                    <div className="chat-attach" style={{height:size, padding:'15px 5px 15px 5px', background:'red', margin:'10px'}}> {ext}</div>
                    <div style={{display: 'inline-grid',  margin: '5px 80px 5px 5px'}}> 
                        <div  className="chat-attach" style={{ color:'black'}}> {att.name}</div>
                        <div  className="chat-attach" style={{ color:'black', justifyContent:'flex-start'}}>{att.size} KB</div>
                    </div>
                       <div className="chat-attach" style={{ float:'right', marginLeft:'auto', marginRight:'20px', }}><CloseIcon onClick={()=>removeAttach(key)}/></div>
                </div>
        })}
    </div>

    const renderAttsDownload = (atts, color, down, dir)=><div style={{display:'inline-grid'}}>
        {atts.map((att, key)=>{
            let i = att.name.lastIndexOf(".");
            let ext = att.name.substring(i+1, att.name.length).toUpperCase();
            let link = `${API.chat}/attach/${chatId}_${att.index}_${att.name}`
            let ss = isMobile ? 160 : 50;
            if (att.size>250){
                ss = isMobile ? 400 : 200;
            } else if (att.size>150){
                ss = isMobile ? 240 : 120;
            }

            if (ext==='PNG' || ext==='JPEG' || ext==='JPG') {
                return <div  key={key} style={{background: `${color?color:'lightgray'}`, marginBottom:'30px'}}>
                    <a className="preview_container" style={{display:'grid', padding:'20px'}}  href={link} target="_blank">

                        <img className="img_preview"  src={link} alt="Project" ></img>
                        <a key={key} style={{ float:'right', marginLeft:'auto', display:'flex', background: `${color?color:'lightgray'}`}}  href={link} target="_blank">
                            <DownloadIcon style={{display:'flex', alignSelf:'flex-end', color: '#008BDC'}}/>
                        </a>
                    </a>

                </div>

            } else
            return <a key={key} style={{display:'flex', background: `${color?color:'lightgray'}`, marginLeft:'0px', marginBottom:'2px'}}  href={link} target="_blank">
                <div className="chat-attach" style={{height:size, padding:'15px 5px 15px 5px', background:'red', margin:'10px'}}> {ext}</div>
                <div style={{display: 'inline-grid',  margin: '5px 40px 5px 5px'}}> 
                    <div  className="chat-attach" style={{ color:'black'}}> {att.name}</div>
                    <div  className="chat-attach" style={{ color:'black', justifyContent:'flex-start'}}>{att.size} KB</div>
                </div>
                {
                down ? <div> 
                    <div style={{ display:'flex', height:'100%', float:'right', marginLeft:'auto', marginRight:'20px', }}>
                        <DownloadIcon style={{display:'flex', alignSelf:'flex-end', color: '#008BDC'}}/></div>
                </div> :
                <div className="chat-attach" style={{ float:'right', marginLeft:'auto', marginRight:'20px', }}><CloseIcon onClick={()=>removeAttach(key)}/></div>
                }
            </a>
    })}
    </div>



    let lastDate = (new Date()).toLocaleDateString('en-GB', {day: 'numeric', month: 'long'})

    return  <Grid container direction="row" spacing={0} style={{width:'100%'}}>
        <Grid item xs={12} container direction="column" spacing={2} style={{display:'flex'}}>
            <Grid item>
                <Typography style={{display:'flex', marginLeft:'1em', justifyContent:'center'}} variant='h6'>{profile.name}<hr></hr></Typography>
            </Grid>
            <div  className="parent" style={{ width:'100%', height: `${ isMobile?'calc(100vh - 320px)':'calc(100vh - 260px)'}`, padding:'5px', borderRadius:'10px', margin:'10px', border:'1px solid lightgray' }}>
 
                    <div className="parent" style={{background:'#eeeeee', height: `${'calc(100vh - 300px)'}`, marginBottom:'2px', padding:'0 5px 0 5px', overflow:'auto'}}>
                    {
                        listChats&&listChats.map((info, key)=>{
                            let hasNewDate = false;
                            let dt = new Date(info.date);
                            let recDate = dt.toLocaleDateString('en-GB', {day: 'numeric', month: 'long'})
                            if(lastDate !== recDate) {
                                lastDate = recDate;
                                hasNewDate = true;
                            }
                            //console.log(info.date, lastDate);
                           
                            let time = dt.toLocaleTimeString([], {hour: '2-digit', minute:'2-digit'});
                            let date = dt.toLocaleDateString('en-GB', {day: 'numeric', month: 'long'})


                            return <div key={key} style={{display:'grid'}}>

                                {hasNewDate&&<div className='chat_date'>{date}</div>}

                                {/* <a style={{fontSize:'1em',  display:'inline-flex', textDecoration: 'none'}} href={content} target="_blank">Link</a> */}

                                <div style={{  display:'flex', justifyContent: `${type===info.ty?'flex-end':'flex-start'}`}}> 
                                    {type===info.ty ?
                                    <pre  className='message_sender'>

                                         {(info.atts?.length>0) &&<div style={{display:'flex', justifyContent:'center', cursor:'pointer'}}>
                                             {renderAttsDownload(info.atts, '#d4f8ff', 'down', 'right')}
                                        </div>}

                                        {info.msg}
                                        <div className="time">{time}
                                            <i>
                                                <CheckIcon style={{color:`${info.st==='ur'?'gray':'#008BDC'}`, height:'12px', width:'15px'}}/>
                                                <CheckIcon style={{color:`${info.st==='ur'?'gray':'#008BDC'}`, marginLeft:'-15px', marginTop:'-7px', height:'12px', width:'15px'}}/>
                                            </i>
                                        </div>
                                    </pre> :
                                    <pre key={key} className='message_receiver'>
                                        {(info.atts?.length>0) &&<div style={{display:'grid', cursor:'pointer' }}>{renderAttsDownload(info.atts, '#f2f2f2', 'down', 'left')}</div>}
                                        {info.msg}
                                        <div className="time">{time}</div>
                                    </pre>
                                    }
                                    {key==listChats.length-1&&<div id={key} ref={chatHistory}></div>}
                                    {key==listChats.length-1&&scrollToBottom()}
                                </div>
                            </div>

                        })
                    }
                   
                    </div>
                    <div>{(attach.length>0) && renderAtts(attach)}</div>

                    <div className="chatParent">
                        <div className="chat" style={{maxHeight:`${isMobile?'240px':'220px'}`, display:'flex', flexFlow:'row'}}>
                            <TextField ref={chatBox}  multiline autoFocus disableunderline="false"
                            
                                style={{width:'100%', padding:'0 2px', border:'1px solid #239ce2',  overflow:'auto'}}
                                InputProps={{
                                    // classes:{notchedOutline:classes.noBorder},
                                
                                    endAdornment: (
                                        <InputAdornment position="end" style={{marginTop:'auto', marginBottom:'5px'}}> 
                                          <label htmlFor="file"><i><AttachFile/></i></label>
                                          <input multiple="" type="file" name="file_to_upload" id="file" accept=".zip,.pdf,.doc,.docx,.ppt,.pptx,.xls,.xlsx,.png,.jpg,.jpeg" style={{display: "none"}}
                                              onChange={(event) => {
                                                if (attach.length===5) {
                                                    showErrorToast("Cannot have more than 5 attachments at a time");
                                                } else {
                                                let ff = [...file];
                                                ff.push(event.target.files[0])
                                                setFile(ff);
                                                  //console.log("event.target.files[0]:", event.target.files[0])
                                                uploadAttach(event.target.files[0]);
                                                }
                                              }}>
                                          </input>
                                                               </InputAdornment>
                                      ),

                                }}
                                value={chat} onChange={v=>{
                                setChat(v.target.value);
                                }}
                                
                                />
                        </div>
                        <div style={{marginTop:'auto'}}>
                            <Button disabled={!chat && attach.length === 0}
                                variant="contained"
                                color="primary"
                                style={{display:'flex', position:'relative', background:'#239ce2', padding: "8px 8px", float:'right', marginLeft:'auto', border:'2px'}}
                                onClick={() =>{sendMessage()}}
                            >{"Send"}
                            </Button>
                        </div>
                    </div>
            </div>
        </Grid>

    </Grid>
}

export default ChatView;


