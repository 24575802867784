import { useState } from "react";
import IconButton from '@material-ui/core/IconButton';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { ArrowUpward } from "@material-ui/icons";
import FrontPage from "./FrontPage";
import QASetOnSearch from "../interviewrank/QASetOnSearch";
import SkillTestCatalog from "../interviewrank/SkillTestCatalog";
import { matchSkills } from "../../utils/skillMappping";

const { Grid, Modal, Paper, Button } = require("@material-ui/core")


const RevisionPanel = (props)=>{
    const { job , query, text} = props;
    const [guide, setGuide] = useState(false);
    const [test, setTest] = useState(false);

    return (<Grid container direction="column">
            {/* <Grid item xs={12}>
                <div style={{ display:'flex', cursor:'pointer', float:'right'}}>

                    <div style={{display:'flex',marginLeft:'1em', marginBottom:'1.7em', background:'#44c6f4', padding:'0px 0px 0 20px', borderRadius:'20px' }} onClick={()=>{setTest(!test); setGuide(false)}}>
                        <div style={{marginTop:'0.4em', width:'5em', fontSize:'1.6em', color:'white'}} >Take Test</div> 
                        <IconButton style={{marginTop:'0em', marginLeft:'1.2em', width:'3em', height:'3em', fontSize:'1.2em'}}
                                aria-label="expand row"
                                size="small" > 
                             {test ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                        </IconButton>
                    </div>
                </div>
            </Grid> */}
            <Grid item>
                  <div style={{display:'flex',cursor:'pointer',marginLeft:'2em', float:'right', marginBottom:'1.7em', background:'#44c6f4', padding:'0px 0px 0 20px', borderRadius:'20px'}}  onClick={()=>{setTest(!test); setGuide(false)}}>
                        <div style={{marginTop:'0.4em', color:'white', padding:'3px 3px 8px 3px'} } >Take Test</div> 
                        <IconButton style={{marginTop:'0em', marginLeft:'0em', width:'2.5em'}}
                                aria-label="expand row"
                                size="small" > 
                             {test ?  <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                        </IconButton>
                  </div>
            </Grid>
            
            {/* {guide && <Grid item xs={12} alignItems="flex-start" style={{marginLeft:'-8em'}}>
                    <FrontPage ></FrontPage>
                </Grid>
            } */}
            {test && <Grid item xs={12} alignItems="flex-start" style={{marginLeft:'0em'}}>
                    {/* <QASetOnSearch search={query.replace('q=','')}></QASetOnSearch> */}
                    <SkillTestCatalog mode="search" text={matchSkills(text)}></SkillTestCatalog>
                </Grid>
            }
            
            {/* <Modal open={show} style={{overflowY:'scroll'}} onClose={()=>setShow(false)} >
                <Paper
                    style={{
                        padding: "20px",
                        outline: "none",
                        minWidth: "50%",
                        width:'80%',
                        marginLeft:'10%',
                        marginTop:'5%',
                        //maxHeight:'70%',
                        height:'auto; overflow'
                    }}
                    >
                    <FrontPage />
                </Paper>
            </Modal> */}
    </Grid>

    )
}

export default RevisionPanel;