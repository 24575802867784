import { Grid } from "@material-ui/core";
import CurrencyBitcoinIcon from '@mui/icons-material/CurrencyBitcoin';
import rank from '../../assets/img/userrank.JPG'
import { useHistory } from "react-router-dom";
import IconButton from '@material-ui/core/IconButton';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { useEffect, useState } from "react";

const AssessmentSum = (props)=>{
    const history = useHistory();

    const {lead, showLead, setShowLead, showAssReport, setShowAssReport} = props;
    const [count, setCount] = useState(0);

    useEffect( ()=>{
      assCount();
    }, [lead])

    const assCount = ()=>{
      //console.log('lead.assessments:',lead.assessments)
      let tt = 0;
      lead && lead.assessments && Object.keys(lead.assessments).map((key, i) =>{ 
        tt += lead.assessments[key].length;
        setCount(tt);
      });
    }

    return lead&& <Grid item container direction="row" className="styleProfileSum" alignItems="flex-start" >
    <Grid item xs={12} style={{marginBottom:'1em'}}>
          <div className="pageTitle">Profile Summary</div>
          <hr></hr>
    </Grid>
    
    <Grid item xs={2} style={{marginBottom:'1em'}}>
      <div  style={{display:'flex'}}>
        <CurrencyBitcoinIcon className="bitCoin"></CurrencyBitcoinIcon>
        <div style={{marginTop:'-0.2em'}}>
          <div style={{marginLeft:'1em'}}>Coins:</div>
          <div style={{marginLeft:'1em', fontWeight:'bold'}}>{lead.coins}</div>
        </div>
      </div>
     
    </Grid>
    <Grid item xs={3}>
      <div  style={{display:'flex'}}>
        <img height={'40em'} width={'40em'}  src={rank}></img>
        <div style={{height:'2em'}}>
          <span style={{marginLeft:'1em'}}>Rank:-</span>
          <span style={{marginLeft:'1em', fontWeight:'bold'}}>{lead.rank}</span>
          <div style={{display:'flex', height:'2em'}}>
          <a  onClick={()=>setShowLead(!showLead)} target="_blank" style={{fontSize:'1em',color:'rgb(27, 73, 234)', cursor: 'pointer',background:'none', marginLeft:'1em', display:'inline-flex', textDecoration: 'none'}}>Leaderboard</a>
          <IconButton style={{marginTop:'-0.5em', marginLeft:'0.2em', width:'2em', maxHeight:'2.6em', fontSize:'1.2em'}}
                            aria-label="expand row"
                            size="small" onClick={()=>setShowLead(!showLead)} > 
                        {showLead ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </div>
        </div>

      </div>
    </Grid>
    <Grid item xs = {4}>
      <div  style={{display:'flex'}}>
        <span>
          <svg className="styleAsslogo" width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
              <mask id="path-2-inside-1" fill="white">
              <rect x="3.5" y="2" width="9" height="12" rx="1"></rect>
              </mask>
              <rect x="3.5" y="2" width="9" height="12" rx="1" stroke="#032538" strokeWidth="3" mask="url(#path-2-inside-1)"></rect>
              <rect x="6" y="4.5" width="4" height="1" fill="#032538"></rect>
              <rect x="6" y="6.5" width="3" height="1" fill="#032538"></rect>
              <rect x="6" y="8.5" width="3" height="1" fill="#032538"></rect>
              <rect x="6" y="10.5" width="4" height="1" fill="#032538"></rect>
          </svg>
        </span>
        <div style={{height:'1em', marginTop:'0em'}}>
          <span style={{marginLeft:'1em'}}>Assessments:-</span>
          <span style={{marginLeft:'1em', fontWeight:'bold'}}>{count}</span>
          <div style={{display:'flex', height:'2em'}}>
          <a  onClick={()=>setShowAssReport(!showAssReport)} target="_blank" style={{fontSize:'1em',color:'rgb(27, 73, 234)', cursor: 'pointer',background:'none', marginLeft:'1em', display:'inline-flex', textDecoration: 'none'}}>Assessment Report</a>
          <IconButton style={{marginTop:'-0.5em', marginLeft:'0.2em', width:'2em', maxHeight:'2.6em', fontSize:'1.2em'}}
                        aria-label="expand row"
                        size="small" onClick={()=>setShowAssReport(!showAssReport)}> 
                        {showAssReport ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </div>
        </div>
      </div>
    </Grid>
  </Grid>

}

export default AssessmentSum;