import apiList from "../../config/apiList";
import { showErrorToast, showSuccessToast } from "../../utils/toastMsg";
import axios from "axios";



export const applyJob = (job, sop, file) => {
    let token = localStorage.getItem("token");

    if (token === null && !file) {
      showErrorToast("Login or Upload your resune first", 3000);
      return;
    }
    if (token) {
      console.log(job._id);
      console.log(sop);
      axios
        .post(
          `${apiList.applications}/${job._id}`,
          {
            sop: sop,
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((response) => {


          showSuccessToast("You have successfully applied this job");
          //handleClose();
        })
        .catch((err) => {
          console.log(err.response);

          showErrorToast(err.response.data.message, 3000);
          //handleClose();
        });
    } else {  // apply job without user login, save details in temporary job apply table
        console.log("upload without login success", file)
    }
  };