import { Paper,Grid, Button } from "@material-ui/core";
import { useState } from "react";
import TextEditor from "../editor-text/TextEditor"
import BlogEditorial from "./BlogEditorial";
import BlogEditor from "./BlogEditorial";
// import  blogPosts from require('../../../data/blog-post/blogPosts');

const blogPosts = require('../../../data/blog-post/blogPosts').blogPosts;

const BlogView = (props) => {
    const [selected, setSelected] = useState(0);
    const [newPost, setNewPost] = useState(false);
   // const selectedPost = blogPosts[selected];

   const renderNewPost = ()=> {
        return (
            <div>
                <Grid container justifyContent='flex-end'>
                    <Button style={{background:'#239ce2', marginBottom:'1rem' }}
                        variant="contained"
                        color="primary"
                        onClick={ () => { setNewPost(false)} }
                        >
                            CANCLE
                    </Button>
                </Grid>
                <BlogEditorial heading="Write Your Post"></BlogEditorial>
            </div>)
    }

    const renderSelectedPost = ()=> {
        return (
            <div>
            <Grid container justifyContent='flex-end'>
                <Button style={{background:'#239ce2' }}
                    variant="contained"
                    color="primary"
                    onClick={ () => { setNewPost(true)} }
                    >
                        Write Post
                </Button>

            </Grid>
            <Paper elevation={3} style={{padding:'1rem', color:'rgb(0 197 230)',marginTop:'1rem', marginBottom:'1rem'}}>
                    <h1>{blogPosts[selected].title}</h1>
                </Paper>
                {blogPosts[selected].comments && blogPosts[selected].comments.map((comment, index) => (
                    
                    <div key={index} style={{paddingBottom:'1rem'}}>
                        <Paper  elevation={3} style={{padding:'1rem'}}>
                            <div  style={{background:'rgb(212 212 212 / 67%)', fontsize:50}}>
                                <h2 ><strong>{comment.author}</strong></h2>
                                <h3 >{comment.designation} </h3>
                            </div>
                            <TextEditor index={index} toolbar={false} content={comment.body} />
                        </Paper>
                    </div>
                
                ))}
                <BlogEditorial post = {blogPosts[selected]} heading="Write Your Comment"></BlogEditorial>
            </div>)
    }


    return (
        <Grid xs={12} container direction="row" spacing={4}  style={{marginTop:'2rem'}}>
            <Grid xs={8} item alignItems='center' alignContent ='center' justifyContent='center'>
                { newPost == true ? renderNewPost() :renderSelectedPost() }
            </Grid>

            <Grid xs={4} item>
                <h2 style={{marginBottom:'1.5rem'}}><strong>Related post</strong></h2> 
                <Paper elevation={3} style={{color:'rgb(0 197 230)',padding:'0.5rem'}}>
                {blogPosts.map((post, index) => (
                            <div key={index} style={{fontSize:20, marginBottom:'1rem', cursor:'pointer'}} onClick={()=>
                            {
                                setNewPost(false)
                                setSelected(index)
                            }}
                            >
                                <p key={index}><em style={{ backgroundColor: '#f4f4f4', color: 'rgb(31 37 44)'}}>{post.title}</em></p>
                            </div>
                ))}
                </Paper>
            </Grid>
        </Grid>
    )
}

export default BlogView