import { Grid } from "@material-ui/core";

import BusinessIcon from '@mui/icons-material/Business';
import ProfilePanel from '../profilesearch/ProfilePanel';
import { useState } from "react";
import { isMobile } from "../../utils/mobileUtil";

const ProfileTile = (props)=>{
    const { profile , mode, search} = props;

    //console.log('tile search: ', search);
    //let bgColor = job.posting =='internal'?'#e2e6f6':'';
    const showDetails = ()=>{

    }

  const  getHighlightedText = (text, highlight)=> {

       if (!text || !highlight) return text;
        // Split on highlight term and include term into parts, ignore case
        try {
        const parts = text.split(new RegExp(`(${highlight})`, 'gi'));
        return <span> { parts.map((part, i) => 
            <span key={i} style={part.toLowerCase() === highlight.toLowerCase() ? { fontWeight: 'bold' , color:'red'} : {} }>
                { part }
            </span>)
        } </span>;
        }catch {
            return text;
        }
    }

    return (
        <>
        <Grid container direction="column" className="searchResult" style={{margin: "2px 2px", background: '#e2e6f6', alignItems:'start' }}>
            <Grid container direction="row" alignContent="flex-start" justifyContent="flex-start">
                <Grid container item xs={isMobile?12:7} spacing={0} direction="column">
                    <Grid item > 
                        <a style={{ cursor: 'pointer',  display:'inline-flex', color:'blue', background:'none', textDecoration: 'none'}} target="_blank" onClick={()=>{showDetails()}}><strong>{profile.name}</strong> &nbsp;({profile.desig})</a>
                    </Grid>
                    {profile.company&&<Grid item style = {{display:'flex'}}>
                        <div><BusinessIcon style={{marginTop:'-0.4em' , height:'20px', width:'20px'}}/> <span> <strong>&nbsp;{profile.company}</strong></span></div>
                    </Grid>}
                    
                    <Grid item ><strong> Education:&nbsp;</strong> {profile.edu} </Grid>
                    <Grid item ><strong> Email:&nbsp;</strong> {profile.email} </Grid>
                    <Grid item ><strong> Mobile:&nbsp;</strong> {profile.mobile} </Grid>
                    <Grid item ><strong> Experience:&nbsp;</strong> {profile.exp}&nbsp;&nbsp;&nbsp;&nbsp;<strong> Last Active:&nbsp;</strong> {new Date(profile.active).toLocaleDateString('en-GB', {day: 'numeric', month: 'short', year: 'numeric'})} </Grid>
                    {/* <Grid item ><strong> Skills:&nbsp;</strong> <span style={{color:'blue'}}>{profile.skills}</span></Grid> */}
                    <Grid item ><strong> Skills:&nbsp;</strong> <span style={{color:'blue'}}>{getHighlightedText(profile.skills, search)}</span></Grid>
                </Grid>

                {isMobile &&<Grid item xs = {12} style={{margin:"1em 0"}}>
                    <hr></hr>
                </Grid>}


                {profile.assessments &&<Grid container item xs = {isMobile?12:5} spacing={1} direction="column" style={{paddingLeft:'5px', background:'#e4e7f1', color:'rgb(226 0 255)', lineHeight:'1.2em'}}>
                    <Grid item style={{color:'black', marginBottom:'1em'}}><strong>Recent Assessment Activities</strong></Grid>
                    <Grid item style={{color:'rgb(6 8 241)', lineHeight:'1.2rem',marginTop:'0em'}}><strong>Rank Points : &nbsp;({profile.coins}) </strong></Grid>
                    {profile.assessments.test && <Grid item style={{lineHeight:'1.2rem',marginTop:'0.5em'}}><strong> Assessment Test:&nbsp;</strong> {profile.assessments.test.count} </Grid>}
                    {profile.assessments.test && <Grid item style={{color:'black', lineHeight:'1.2rem',}}><strong> Recent:&nbsp;</strong> {profile.assessments.test.recent} </Grid>}

                    {profile.assessments.code && <Grid item style={{lineHeight:'1.2rem',marginTop:'0.5em'}}><strong> Coding Test:&nbsp;</strong> {profile.assessments.code.count} </Grid>}
                    {profile.assessments.code && <Grid item style={{color:'black', lineHeight:'1.2rem',}}><strong> Recent:&nbsp;</strong> {profile.assessments.code.recent} </Grid>}

                    {profile.assessments.design && <Grid item style={{lineHeight:'1.2rem',marginTop:'0.5em'}}><strong> Design Test:&nbsp;</strong> {profile.assessments.design.count} </Grid>}
                    {profile.assessments.design && <Grid item style={{color:'black', lineHeight:'1.2rem',}}><strong> Recent:&nbsp;</strong> {profile.assessments.design.recent} </Grid>}
                </Grid>}
            </Grid>
         
            <Grid item container direction="row" spacing={0}>
                <Grid item xs={12}>
                    <ProfilePanel userId={profile.userId} mode={mode} email={profile.email}></ProfilePanel>
                </Grid>
            </Grid>
        </Grid>
        </>
    )

}


export default ProfileTile;