import React , { useEffect, useState} from 'react'
import { useSelector, useDispatch } from "react-redux";
import ShapeCustom from '../editor-shape/ShapeCustom'
// import "./MainView.scss";

import PDFViewer from '../viewer-pdf/PDFViewer';
//import { fetchCourses } from "../../state/action-creators/course/index"
import { Grid } from '@material-ui/core';
import Landing from '../editor-code/components/Landing';
import Editor from '@monaco-editor/react';
import { Menu, ProSidebarProvider, SubMenu } from 'react-pro-sidebar';
import TextEditor from '../editor-text/TextEditor';
import InterviewTemplate from '../../interviewer/InterviewTemplate';
import CreateTemplate from '../../interviewer/CreateTemplate';
import ManageInterview from '../../interviewer/ManageInterview';
import DesignBlog from '../../blogs/design/DesignBlog';
import InterviewFeedback from '../../interviewfeedback/InterviewFeedback';
import MarkDownViewer from '../viewer-markdown/MarkDownViewer';
import SkillTestLanding from '../../interviewrank/SkillTestLanding';
import ManageMockInterview from '../../interviewer/ManageMockInterview';
import HtmlViewer from '../editor-html/HtmlViewer';


const group = ["Design","Interview"];

const MainView = (props) => {
    const dispatch = useDispatch();
    const selected = useSelector(state=>state.workspace.selected);
 
    // useEffect(() => {
    //   getData();
    // }, [selected]);


const renderSwitch = (category)=> {
  switch(category) {
    case 'code':
      return <Landing refresh={props.refresh}></Landing>
    case 'design':
      return <ShapeCustom/>;
   case 'content':
        return <div style={{ marginLeft:'2%'}}><TextEditor content= {selected.content} readOnly={true}></TextEditor></div>
    case 'new':
        return <div style={{ marginLeft:'2%', marginRight:'20%'}}><CreateTemplate ws="ws"/></div>
    case 'mock':
        return <div style={{ marginLeft:'2%'}}><ManageMockInterview mode="mock"/></div>
    
    case 'md':
        return <div style={{}}><MarkDownViewer/></div>
    case 'html':
        return <div style={{}}><HtmlViewer path={selected.path}></HtmlViewer></div>
    case 'gitcode':
        return <Landing refresh={props.refresh}></Landing>
    case 'pdf':
        return <div style={{ marginLeft:'4em'}}><PDFViewer/></div>      

    default:
      return <div style={{ marginLeft:'4em'}}>No content</div>
  }
}

const loadGroup = (group)=> {
  console.log('group:', group)
  switch(group) {
   case 'Design':
      return <DesignBlog/>;
  case 'Interview':
        return <InterviewFeedback/>;
    default:
      return <></>
  }
}


const listViewStyle = {
  width: '30%',
  height: '52rem',
  overflow: 'scroll',
  // justifyContent:'center',
  // alignItems:'center'
};
const viewerStyle = {
  width: '77%',
  height: '50rem',
  // justifyContent:'center',
  // alignItems:'center'
};

const windowStyle = {
  width: '100%',
  height: '100%',
  overflow:'noscroll'
};

const hasGroup = ()=> {
  console.log('hasGroup:', selected , selected&&selected.group , group)
  return selected && selected.group && group.includes(selected.group);

}
return (
  <>  
     {/* <div style={windowStyle} class="flex flex-row space-x-2 items-start px py-5">

      <LeftCatalogueView style={listViewStyle}></LeftCatalogueView>
      <div style={viewerStyle} className="h w-full bg-gray-100">
        {renderSwitch(selected ? selected.category: '')}
      </div>
      </div> */}

        {/* <LeftCatalogueView style={listViewStyle}></LeftCatalogueView> */}
        { hasGroup()
          ? loadGroup(selected.group)
          : renderSwitch(selected ? selected.category: '')
        }


</>
)
      
}
export default MainView
