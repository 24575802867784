import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { saveAnswer } from '../../state/action-creators/questions';
import { renderMD } from '../../utils/skillRankUtils';

const MainWindow = ({ selectque, answeredCount, setAnsweredCount }) => {
  const [selectOption, setSelectOption] = useState(null);
  const dispatch = useDispatch();

  // const questionByRedux = useSelector((state) => state.questionReducer.questions);
  const questionData = useSelector((state) => state.questionReducer.questions[selectque]);

  // console.log(questionData)

  const handleClick = (index) => {
    // setSelectOption(-1);
    if (questionData?.check === index) {
      setSelectOption(null);
      setAnsweredCount(answeredCount - 1);
      // Data[selectque].check = null;
      dispatch(saveAnswer({ questionIndex: selectque, check: null }));
      return;
    }
    if (questionData?.check === null)
      setAnsweredCount(answeredCount + 1);

    setSelectOption(index);
    dispatch(saveAnswer({ questionIndex: selectque, check: index }));
    // Data[selectque].check = index;
  }
  useEffect(() => {
    setSelectOption(questionData?.check); // Reset selected option when the question changes
  }, [selectque]);

  return (
    <div key={selectque} className='flex flex-col w-[100%] pl-14 overflow-y-scroll pr-10 mb-20'>
      <div className='py-6 px-1 text-2xl font-bold font-["cursive"]'>
        Question &nbsp; {selectque + 1}
      </div>
      <hr className="h-px bg-gray-200 dark:bg-gray-400" />
      {/* <div className='py-6 px-1'>
        {Data[selectque].question}
      </div> */}
      <div className='py-6 px-1'>
        {renderMD(questionData?.question)}
      </div>
      {questionData?.options.map((option, index) => {
        return (
          <div key={index} className='m-2 py-6 px-2 border-2 border-grey-200 rounded-lg items-center cursor-pointer' style={selectOption === index ? { backgroundColor: '#A2F9A2' } : {}} onClick={() => handleClick(index)}>
            <span>{renderMD(option)}</span>
          </div>
        )
      })
      }
    </div>
  )
}

export default MainWindow