import React, { useState, useContext, useEffect } from "react";
import {
  Grid,
  TextField,
  Button,
  Typography,
  makeStyles,
  Paper,
  MenuItem,
  Input,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Avatar,
  Modal,
  FormControlLabel,
  Checkbox,
} from "@material-ui/core";
import axios from "axios";
import { Link, Redirect } from "react-router-dom";
import ChipInput from "material-ui-chip-input";
import DescriptionIcon from "@material-ui/icons/Description";
import FaceIcon from "@material-ui/icons/Face";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/material.css";
import AccountCircleIcon from '@mui/icons-material/AccountCircle';


import PasswordInput from "./lib/PasswordInput";
import EmailInput from "./lib/EmailInput";
import { SetPopupContext } from "../home/component/MainRouter";

import apiList from "../../config/apiList";
import isAuth from "./lib/isAuth";
import { useDispatch, useSelector } from "react-redux";
import {manageProfile, setLoggedIn} from "../../state/action-creators/profile/index"
import { isMobile } from "../../utils/mobileUtil";


const useStyles = makeStyles((theme) => ({
  inputBox: {
    minWidth:'24em'
  },
  passBox: {
    minWidth:'30.8em'
  },
  phone: {
    minWidth:'10em',
  },
  submitButton: {
    width: "40em",
  },
}));

const Register = (props) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const setPopup = useContext(SetPopupContext);
  const [open, setOpen] = useState(props.open);
  const [resume, setResume] = useState("");
  const [profile, setProfile] = useState("");

  const [loggedin, setLoggedin] = useState(isAuth());

  const profileNew = {
    type: "applicant",
    email: "",
    password: "",
    name: "",
    mobile: "",
    // education:[{
    //   institutionName:"",
    //   startYear:1930,
    //   endYear:""
    // }],
    // skills: [],
    // location:"",
    // organizations: [],
    // designation: "",
    // experience: "",
    // resume: "",
    // profile: "",
    // bio: "",
   
  }

  
  // const [signupDetails, setSignupDetails] = useState(
  const [signupDetails, setSignupDetails] = useState(profileNew);
  const [updateDetails, setUpdateDetails] = useState({});

  const [phone, setPhone] = useState("");
  const [next, setNext] = useState(false);

  const [workExp, setWorkExp] = useState([
    {
      companyName: "",
      designation: "",
      project:"",
      technology: "",
      description:"",
      role: "",
      startMonth: "",
      startYear: "",
      endMonth: "",
      endYear: "",
    },
  ]);

  useEffect(() => {
    console.log('props.profile:', props.profile);
    //console.log('useEffect profileData::::' ,profileData)
    // if(props.profile=='edit') {
    //   getData();
    // } else {
    //   setSignupDetails(profileNew);
    // }
    setSignupDetails(profileNew);
  }, []);

  const getData = () => {
    axios
      .get(apiList.user, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
      .then((response) => {
        console.log('profile123:', response.data);
        //setProfileDetails(response.data);
        const signupData = {...response.data, ["type"]: localStorage.getItem("type")}
        setSignupDetails(signupData);
        dispatch(manageProfile(signupData));
      })
      .catch((err) => {
        console.log('Error:', err);
        setPopup({
          open: true,
          severity: "error",
          message: "Error",
        });
      });
  };

  const [inputErrorHandler, setInputErrorHandler] = useState({
    email: {
      untouched: true,
      required: true,
      error: false,
      message: "",
    },
    password: {
      untouched: true,
      required: true,
      error: false,
      message: "",
    },
    name: {
      untouched: true,
      required: true,
      error: false,
      message: "",
    },
    // mobile: {
    //   untouched: true,
    //   required: true,
    //   error: false,
    //   message: "",
    // },
  });

  const handleInput = (key, value) => {
    setSignupDetails({
      ...signupDetails,
      [key]: value,
    });
    setUpdateDetails({
      ...updateDetails,
      [key]: value,
    });
 
  };

  const handleInputError = (key, status, message) => {
    setInputErrorHandler({
      ...inputErrorHandler,
      [key]: {
        required: true,
        untouched: false,
        error: status,
        message: message,
      },
    });
  };

  const handleLogin = () => {
    const tmpErrorHandler = {};
    Object.keys(inputErrorHandler).forEach((obj) => {
      if (inputErrorHandler[obj].required && inputErrorHandler[obj].untouched) {
        tmpErrorHandler[obj] = {
          required: true,
          untouched: false,
          error: true,
          message: `${obj[0].toUpperCase() + obj.substr(1)} is required`,
        };
      } else {
        tmpErrorHandler[obj] = inputErrorHandler[obj];
      }
    });

    const verified = !Object.keys(tmpErrorHandler).some((obj) => {
      console.log(tmpErrorHandler[obj])
      return tmpErrorHandler[obj].error;
    });
    console.log('verified:', verified);
    let signUp = {...signupDetails};
    let mobile = signupDetails.mobile.replace('+91','');
    if(mobile.length>=10) {
      mobile = mobile.substring(mobile.length-10);
      signUp.mobile = mobile;
      //console.log('verified123:', verified, signUp);
    } else {
      setPopup({
        open: true,
        severity: "error",
        message: 'Invalid mobile number',
      });
    }
    

    if (verified) {
      axios
        .post(apiList.signup, signUp)
        .then((response) => {
          localStorage.setItem("token", response.data.token);
          localStorage.setItem("type", response.data.type);
          localStorage.setItem("name",response.data.name);
          localStorage.setItem("hasProfile", response.data.hasProfile);
          localStorage.setItem("tiny",response.data.tiny);
          dispatch(setLoggedIn(isAuth()))
          //const userId = response.data.userId;
          setPopup({
            open: true,
            severity: "success",
            message: "Logged in successfully",
          });
          console.log(response);
          // console.log(resume, userId );
         // handleUpload(apiList.uploadResume, resume, userId); // upload resume
         // handleUpload(apiList.uploadProfileImage, profile, userId); // upload profile image

          setLoggedin(isAuth());
        })
        .catch((err) => {
          setPopup({
            open: true,
            severity: "error",
            message: err.response.data.message,
          });
          console.log(err.response);
        });
    } else {
      setInputErrorHandler(tmpErrorHandler);
      setPopup({
        open: true,
        severity: "error",
        message: "Incorrect Input",
      });
    }
  };

  const handleClose = ()=>{
    setOpen(false);
  }

  return loggedin ? ( next ? ( <Redirect to="/resumebuilder"/> ) : (<Redirect to="/home" />)) 
    : !open ? (<Redirect to="/home" />) : (
    <Modal open={open} onClose={()=>handleClose()} closeAfterTransition
    >
      <Paper 
        elevation={20} 
        style={{ 
          display:'flex',
          float:'right' , 
          width:'21.7em', 
          height: `${isMobile?'70%':'100%'}`,
          paddingTop:'0em', 
          paddingLeft:'1em', 
          paddingRight:'1em',
          borderTopLeftRadius:'20px',
          borderBottomLeftRadius:'20px',
          fontSize:'1.2em'
          
        }}
      >

        <Grid container direction="column" spacing={2} alignItems="center" style={{maxHeight:'105vh', overflowX:'scroll'}}>

          <Grid item >
          </Grid>
          <Grid item >
          <Avatar sx={{ m: 1, bgcolor: 'primary.main' }}  style={{marginLeft:'15px'}}>
              <AccountCircleIcon></AccountCircleIcon>
            </Avatar>
            <Typography component="h1" style={{fontWeight:'bold'}} variant="h5">
              Signup
            </Typography>
          </Grid>
          <Grid item>
            <TextField className='w-96 md:w-96'
              select
              label="Category"
              variant="outlined"
              value={signupDetails.type}
              onChange={(event) => {
                handleInput("type", event.target.value);
              }}
            >
              <MenuItem value="applicant">Applicant</MenuItem>
              <MenuItem value="recruiter">Recruiter</MenuItem>
            </TextField>
          </Grid>
          <Grid item alignItems="stretch">
            <TextField className='w-96 md:w-96'
              label="Name"
              value={signupDetails.name}
              onChange={(event) => handleInput("name", event.target.value)}
              error={inputErrorHandler.name.error}
              helperText={inputErrorHandler.name.message}
              onBlur={(event) => {
                if (event.target.value === "") {
                  handleInputError("name", true, "Name is required");
                } else {
                  handleInputError("name", false, "");
                }
              }}
              variant="outlined"
            />
          </Grid>
            <Grid item>
              <EmailInput className='w-96 md:w-96'
                label="Email"
                value={signupDetails.email}
                onChange={(event) => handleInput("email", event.target.value)}
                inputErrorHandler={inputErrorHandler}
                handleInputError={handleInputError}
                required={true}
              />
            </Grid>
          <Grid item>
              <PasswordInput className='w-96 md:w-96'
                label="Password"
                value={signupDetails.password}
                onChange={(event) => handleInput("password", event.target.value)}
                error={inputErrorHandler.password.error}
                helperText={inputErrorHandler.password.message}
                onBlur={(event) => {
                  if (event.target.value === "") {
                    handleInputError("password", true, "Password is required");
                  } else {
                    handleInputError("password", false, "");
                  }
                }}
              />
          </Grid>
          <Grid item>
              <PhoneInput className='w-96 md:w-96'
                
                country={"in"}
                value={signupDetails.mobile}
                onChange={(phone) => handleInput("mobile", `+${phone}`)}
                  />
          </Grid>
          <Grid item>
            <FormControlLabel
                  control={<Checkbox value="remember" checked={next} onChange={()=>setNext(!next)} color="primary" />}
                  label="Next ->>"
            />
            <Link  to="/resumebuilder" style={{marginRight:'0em', color:'blue'}}>
                Build Your Resume
            </Link>
          </Grid>
          
          <Grid item >
            <div className="menuBtn" style={{width:'18em', fontSize:'1em'}} onClick={()=> handleLogin()}>
              Register
            </div>
          </Grid>
          {/* <Grid item>
            <Button style={{background:'#1997b5', color:'#e6d0d0',marginTop:'2rem' , height:'3rem' , width:'8rem'}}
              variant="contained"
              color="primary"
              onClick={() => { handleLogin()}}
              className={classes.submitButton}
            >
              Register
            </Button>
          
          </Grid> */}
        </Grid>
      </Paper>
    </Modal>
  );
};

export default Register;

