export const principal = {
    "key": "principal",
    "interviews": [
            {
                "id": 1,
                "difficulty": 5,
                "round" : "Round 2 - Principal Software Engineer",
                "tags": "aws services,java,code,design,database",
                "skills":"aws,design",
                "feedback": "Thumbs Up",
                "title": "Principal Software Engineer",
                "discussions": `Candidate was aware with most of the aws services, he was able to write efficient code to solve the problem given to him. He has good experience with different Relational and NoSQL databases. His solution design skills were average, as he could not come up with alternative approaches or discuss pros and cons of his approach. He has worked on Kubernetes and is familiar with different Kubernetes resources. Does not have much experience in Big data and he has also not worked on Kafka. His technical skills are fairly good, but his attitude seems to be poor. He does not listen to the complete requirement and jumps to conclusion, and also does not listen when corrected. He wrote code in Python, although he knows Java and was asked to write in Java. Overall he is Not a good fit for Senior Software Engineer role.
                `
            },
            {
                "id": 2,
                "difficulty": 4,
                "round" : "Round 1 - Principal Software Engineer",
                "tags": "aws,code,db",
                "skills":"aws,java,db",
                "feedback": "Thumbs Down",
                "title": "Principal Software Engineer",
                "discussions": `Candidate was aware with most of the aws services, he was able to write efficient code to solve the problem given to him. He has good experience with different Relational and NoSQL databases. His solution design skills were average, as he could not come up with alternative approaches or discuss pros and cons of his approach. He has worked on Kubernetes and is familiar with different Kubernetes resources. Does not have much experience in Big data and he has also not worked on Kafka. His technical skills are fairly good, but his attitude seems to be poor. He does not listen to the complete requirement and jumps to conclusion, and also does not listen when corrected. He wrote code in Python, although he knows Java and was asked to write in Java. Overall he is Not a good fit for Senior Software Engineer role.
                `
            },
            {
                "id": 3,
                "difficulty": 3,
                "round" : "Round 3 - Principal Software Engineer",
                "tags": "kafka,docker,kubernetes",
                "skills":"java,ms",
                "feedback": "Thumbs Up",
                "title": "Principal Software Engineer",
                "discussions": `Candidate was aware with most of the aws services, he was able to write efficient code to solve the problem given to him. He has good experience with different Relational and NoSQL databases. His solution design skills were average, as he could not come up with alternative approaches or discuss pros and cons of his approach. He has worked on Kubernetes and is familiar with different Kubernetes resources. Does not have much experience in Big data and he has also not worked on Kafka. His technical skills are fairly good, but his attitude seems to be poor. He does not listen to the complete requirement and jumps to conclusion, and also does not listen when corrected. He wrote code in Python, although he knows Java and was asked to write in Java. Overall he is Not a good fit for Senior Software Engineer role.
                `
            },
        ]
    
}