const buildSysout = (problem, level)=>{
    let out = ""; //
    let inout = problem.type.split('#');
    let params = inout[0].split('-');
    let allCases = "\t";

    for (let k = 0; k < problem[level].length; k++) {
        out = "";
        for(let i = 0; i < params.length; i++) {
            if (params[i].length === 2) {
                out +=  `[${problem[level][k].in[i]}]`;   //.replaceAll("'", "")
            // } else if (params[i] ==='b') {
            //     out +=  problem[level][k].in[i] === true ?'True' :'False';
            } else {
                out +=  `${problem[level][k].in[i]}`
            }


            if (params.length>1  &&  i<params.length-1)  {
                out +=', '
            }
        }
        // out = out.replaceAll('true', 'True').replaceAll('false', 'False');

        let expected = '';
       
        if(inout[1].length === 2) {
            expected = `[${problem[level][k].out}]`; //.replaceAll("'", "");
        // } else if (inout[1] === 'b') {
        //     expected = problem[level][k].out === true ?'True' :'False';
        } else
            expected = `${problem[level][k].out}`;

        // expected = expected.replaceAll('true', 'True').replaceAll('false', 'False');

        allCases += `print(solve(${out}) == ${expected})`

        if (problem[level].length > 1) {
            allCases +='\n\t';
        }
    }
    allCases = allCases.replaceAll('true', 'True').replaceAll('false', 'False').replaceAll('{', '').replaceAll('}', '');
    return allCases;
}

const buildCode = (problem, level)=> {

    //console.log('problem:', problem);

    let funcRet = 'result';
    let funcVar = 'result = 0'
    let inout = problem.type.split('#');
    
    //################################## RETURN TYPE AND FUNC VARIABLES ########################
    // if(inout[1]==='s') {
    //     funcVar = 'String result = "";'
    //     funcRet = "String"
    // } else if(inout[1]==='ia') {
    //     funcVar = 'int[] result = null;'
    //     funcRet = "int[]"
    // } else if(inout[1]==='sa') {
    //     funcVar = 'String[] result = null;'
    //     funcRet = "String[]"
    // } else if(inout[1]==='ba') {
    //     funcVar = 'boolean[] result = null;'
    //     funcRet = "boolean[]"
    // }
    // else if(inout[1]==='b') {
    //     funcVar = 'boolean result = false;'
    //     funcRet = "boolean"
    // }

   //################################## FUNC ARGUMENTS ##########################################
    let args = "";
    
    let params = inout[0].split('-');

    let ar = 0;
    let or = 0;
    for(let i = 0; i < params.length; i++) {
        if (params[i].length ===2) {
            args += `arr${ar>0 ? (ar + 1): ''}`;
            ar++;
        } else if (params[i] === 'i') {
            args += `num${or>0 ? (or + 1): ''}`;
            or++;
        } else if (params[i] === 's') {
            args += `str${or>0 ? (or + 1): ''}`;
            or++;
        } else if (params[i] === 'b') {
            args += `b${or>0 ? (or + 1): ''}`;
            or++;
        }

        if (params.length>1 && i < params.length - 1) {
            args +=', '
        }
    }

    //############################## BUILD OUTPUT MATCH ###################################
    let sysout = buildSysout(problem, level);

return`
def solve(${args}):
\t${funcVar}

\t#WRITE YOUR CODE HERE
\treturn result;


if __name__ == "__main__":
${sysout}
`
}

const replace = (code, problem, level)=>{

    let sysoutLevel = buildSysout(problem, level);
    let arrayOfLines = code.match(/[^\r\n]+/g);

    let i = 0;
    let codeLevel = "";
    let flag = false;
    while(i < arrayOfLines.length) {
        let line = arrayOfLines[i];

        if (flag === false) {
            codeLevel +=  line +"\n";
        } else {

        }
        if (line.indexOf('main') >= 0) {
            //flag = true;
            break;
        }
        i++;
    }

    return `
${codeLevel}
${sysoutLevel}
`

}




export const buildPythonMain = (problem)=>{
    if(problem) {
        return buildCode(problem, 'sample')
    }
}


const selected = {
    "title": "Number Sum Recursive",
    "desc": "<p>Given an integer array, write a program to sum them recursive.</span></p><blockquote>Example.</blockquote><blockquote>input: arr = [4,7,3,6,8,1] </blockquote><blockquote>output: 29</blockquote>",
    "category": "code",
    "level": "easy",
    "mode": "test",
    // "type": "sa-i#s",
    // "sample": [{ "in": ["{3,5}"], "out": 8 }],
    "type": "ia-i-s-ba-b#i",
    "sample": [{ "in": ["{3,5}",6, "7", "{true, false}", true], "out": 8 }],
    "easy": [{ "in": ["{3,5}",6, "7", "{true, false}", true], "out": 8 }],
    // "easy": [
    //   { "in": ["{'3','5','6','7'}", 5], "out": 21 },
    //   { "in": ["{3,7}"], "out": 9 },
    //   { "in": ["{3,5,7}"], "out": 15 }
    //]
  }
  const selected12 = {
    "title": "Number Sum Recursive",
    "desc": "<p>Given an integer array, write a program to sum them recursive.</span></p><blockquote>Example.</blockquote><blockquote>input: arr = [4,7,3,6,8,1] </blockquote><blockquote>output: 29</blockquote>",
    "category": "code",
    "level": "easy",
    "mode": "test",
    "type": "sa-i-i-b-b-ia#ia",
    "sample": [{ "in": ["{'acb','bcd'}",8,8, true, false,"{5, 7}"], "out": "{'acb','bcd'}" }, { "in": ["{'acb','bcd'}",8,8, true, false,"{5, 7}"], "out": "{'acb','bcd'}" }],
    "easy": [{ "in": ["{'acb','bcd', 'zyx'}",123, 677, true, false,"{5, 47}"], "out": "{'rty','fgt'}" }, { "in": ["{'acb','bcd'}",8,8, true, false,"{5, 7}"], "out": "{'acb','bcd'}" }],
  }

let rr = buildCode(selected12, 'sample');
console.log(rr);
console.log("REPLACE MAIN WITH EASY\rn\rn")
let essy = replace(rr, selected12, 'easy');

console.log(essy)
