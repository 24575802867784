import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { useEffect, useState } from "react";
import apiList from "../../config/apiList";
import { Button, Grid, Paper, Typography } from '@material-ui/core';
import ProfileTile from '../profilesearch/ProfileTile';
import { fetchData } from '../../config/apiService';
import { Pagination, PaginationItem } from '@material-ui/lab';
import { useLocation } from 'react-router-dom/cjs/react-router-dom';
import { isMobile } from '../../utils/mobileUtil';
import QuestionAnswerIcon from '@mui/icons-material/QuestionAnswer';
import UserFeedback from '../userfeedback/UserFeedback';
import ChatView from '../chat/ChatView';
import { useDispatch } from 'react-redux';
import { shortlistCandidate } from '../../state/action-creators/test';

const JobApplications = (props) => {
  const dispatch = useDispatch();
  const [profiles, setProfiles] = useState();
  const clsMenu = `menuNormal fontS-${isMobile}`

  const [pagination, setPagination] = useState(1);
  const [curProfile, setCurProfile] = useState();
  const [left, setLeft] = useState(isMobile ? 12 :6);
 
  const location = useLocation();
  const jobId = location.pathname.replace("/job/application/", "");


  useEffect(()=>{
    if (props.mode==='link') {
      setLeft(12);
    }
    getApplicants();
  },[])

  const getApplicants = async()=>{
    

      let url = `${apiList.resume}/profile?jobId=all`

      if (location.pathname.indexOf("/job/application/")>-1 && jobId) {
        url = `${apiList.resume}/profile?jobId=${jobId}`
      }

      console.log('profile url:', url);
      //let result = await fetchData(url);
      let result = [
        {
            "_id": "64ec1748f4fa8f6c2401c2ca",
            "name": "Your Name Test4",
            "desig": "Your Designation5",
            "company": "Company Name8",
            "skills": "Java, Microservices",
            "email": "xyz@gmail.com",
            "mobile": "999997666",
            "exp": "10.5",
            "edu": "C-DAC Bengaluru -- PGDM CSE -- 2014",
            "userId": "64de2d9ebc926423b4bd5a4b",
            "coins": 300,
            "assessments": {},
            "appId": "64ec1748f4fa8f6c2401c2ca",
            "jobId": "64ec142ff4fa8f6c2401c29c",
            "jobTitle": "Principal Engineer - US R&D Firm"
        },
        {
            "_id": "64f1a0176006dd6c8bac9433",
            "name": "Guest User",
            "desig": "Founder & CTO",
            "company": "Joblient Technologies",
            "skills": "Java, Microservices, React.js, Node.js",
            "email": "info@joblient.com",
            "mobile": "9999976531",
            "exp": "21 years",
            "edu": "B. Tech Computer Science in 2003 from Allahabad, UP.",
            "userId": "64d88576b6cf8488547ddd8b",
            "coins": 4320,
            "assessments": {},
            "appId": "64f1a0176006dd6c8bac9433",
            "jobId": "64ee1af53912e4591ca51991",
            "jobTitle": "Senior Software Engineer – Layer 3 Advanced Routing"
        },
        {
            "_id": "64f1a0516006dd6c8bac943b",
            "name": "Guest User",
            "desig": "Founder & CTO",
            "company": "Joblient Technologies",
            "skills": "Java, Microservices, React.js, Node.js",
            "email": "info@joblient.com",
            "mobile": "9999976531",
            "exp": "21 years",
            "edu": "B. Tech Computer Science in 2003 from Allahabad, UP.",
            "userId": "64d88576b6cf8488547ddd8b",
            "coins": 4320,
            "assessments": {},
            "appId": "64f1a0516006dd6c8bac943b",
            "jobId": "64ee9f683912e4591ca519c7",
            "jobTitle": "Joblient - Intern UX Designer"
        },
        {
            "_id": "64f4b111c20710906f3a6bb1",
            "name": "Yashita Singh",
            "email": "yashita@joblient.com",
            "mobile": "9999999911",
            "userId": "64f4b0a6c20710906f3a6b9d",
            "jobId": "64ee9ed03912e4591ca519c1",
            "jobTitle": "Joblient - Intern Software Engineer"
        },
        {
            "_id": "64f4c8f1b9f609275884ac6e",
            "name": "Yashita Singh",
            "email": "yashita@joblient.com",
            "mobile": "9999999911",
            "userId": "64f4b0a6c20710906f3a6b9d",
            "jobId": "64ee9f683912e4591ca519c7",
            "jobTitle": "Joblient - Intern UX Designer"
        },
        {
            "_id": "64f6c214e344b7e54e94482a",
            "name": "Guest User",
            "desig": "Founder & CTO",
            "company": "Joblient Technologies",
            "skills": "Java, Microservices, React.js, Node.js",
            "email": "info@joblient.com",
            "mobile": "9999976531",
            "exp": "21 years",
            "edu": "B. Tech Computer Science in 2003 from Allahabad, UP.",
            "userId": "64d88576b6cf8488547ddd8b",
            "coins": 4320,
            "assessments": {},
            "appId": "64f6c214e344b7e54e94482a",
            "jobId": "64ee9ed03912e4591ca519c1",
            "jobTitle": "Joblient - Intern Software Engineer"
        },
        {
            "_id": "64f6c80be344b7e54e944921",
            "name": "Shakeeb",
            "desig": "Software Engineer",
            "company": "Make-Mytour",
            "skills": "Microservices",
            "email": "itzshakeeb@gmail.com",
            "mobile": "1779901142",
            "exp": "10.5",
            "edu": "B.Sc. in CSE  SHEIKH FAZILATUNNESA MUJIB UNIVERSITY",
            "userId": "64f6be073e1845e31f6ce0a8",
            "coins": 300,
            "assessments": {},
            "appId": "64f6c80be344b7e54e944921",
            "jobId": "64ee9ed03912e4591ca519c1",
            "jobTitle": "Joblient - Intern Software Engineer"
        },
        {
            "_id": "64f6c84ae344b7e54e944945",
            "name": "Shakeeb",
            "desig": "Software Engineer",
            "company": "Make-Mytour",
            "skills": "Microservices",
            "email": "itzshakeeb@gmail.com",
            "mobile": "1779901142",
            "exp": "10.5",
            "edu": "B.Sc. in CSE  SHEIKH FAZILATUNNESA MUJIB UNIVERSITY",
            "userId": "64f6be073e1845e31f6ce0a8",
            "coins": 300,
            "assessments": {},
            "appId": "64f6c84ae344b7e54e944945",
            "jobId": "64ee9efd3912e4591ca519c4",
            "jobTitle": "Joblient - Intern Test Engineer"
        },
        {
            "_id": "64feeaf364a90623dcd59ba2",
            "name": "Yashita Singh",
            "email": "yashita@joblient.com",
            "mobile": "9999999911",
            "userId": "64f4b0a6c20710906f3a6b9d",
            "jobId": "64ec167bf4fa8f6c2401c2af",
            "jobTitle": "Senior Manager - US R&D Firm"
        },
        {
            "_id": "64feeb2064a90623dcd59bad",
            "name": "Yashita Singh",
            "email": "yashita@joblient.com",
            "mobile": "9999999911",
            "userId": "64f4b0a6c20710906f3a6b9d",
            "jobId": "64ee9efd3912e4591ca519c4",
            "jobTitle": "Joblient - Intern Test Engineer"
        },
        {
            "_id": "6509d419c47bb114c6a4d10b",
            "name": "Nilesh Srivastav",
            "desig": "",
            "company": "Oasis data Labs",
            "skills": "Java, DSA, ",
            "email": "nileshsrivastav011@gmail.com",
            "mobile": "8318022010, 7313022010",
            "exp": "Fresher ",
            "edu": "G.I.T.M. Lucknow -- Information Technology -- 2024",
            "userId": "6509bd94c47bb114c6a4d0bb",
            "coins": 300,
            "assessments": {},
            "appId": "6509d419c47bb114c6a4d10b",
            "jobId": "64ee9ed03912e4591ca519c1",
            "jobTitle": "Joblient - Intern Software Engineer"
        },
        {
            "_id": "6509d7eec47bb114c6a4d148",
            "name": "Akhilesh Tripathi",
            "email": "akhileshtripathi170@gmail.com",
            "mobile": "6387148461",
            "userId": "6509d606c47bb114c6a4d122",
            "jobId": "64ee9f683912e4591ca519c7",
            "jobTitle": "Joblient - Intern UX Designer"
        },
        {
            "_id": "650b393ec47bb114c6a4d276",
            "name": "Vikas Pandey",
            "email": "vikaspandey490@gmail.com",
            "mobile": "9450311427",
            "userId": "650b381dc47bb114c6a4d248",
            "jobId": "64ee9ed03912e4591ca519c1",
            "jobTitle": "Joblient - Intern Software Engineer"
        },
        {
            "_id": "650b3956c47bb114c6a4d27e",
            "name": "Vikas Pandey",
            "email": "vikaspandey490@gmail.com",
            "mobile": "9450311427",
            "userId": "650b381dc47bb114c6a4d248",
            "jobId": "64ee9efd3912e4591ca519c4",
            "jobTitle": "Joblient - Intern Test Engineer"
        },
        {
            "_id": "650b3976c47bb114c6a4d286",
            "name": "Vikas Pandey",
            "email": "vikaspandey490@gmail.com",
            "mobile": "9450311427",
            "userId": "650b381dc47bb114c6a4d248",
            "jobId": "64ee9f683912e4591ca519c7",
            "jobTitle": "Joblient - Intern UX Designer"
        },
        {
            "_id": "650d47930d5c0047c09735d6",
            "name": "Test 11",
            "desig": "Staff Software Engineer",
            "company": "Gojek",
            "skills": "Java, Microservices",
            "email": "test11@joblient.com",
            "mobile": "9999999992",
            "exp": "20",
            "edu": "C-DAC Bengaluru -- PGDM CSE -- 2014",
            "userId": "650a5cefc278b75548207e57",
            "appId": "650d47930d5c0047c09735d6",
            "jobId": "64ee9ed03912e4591ca519c1",
            "jobTitle": "Joblient - Intern Software Engineer"
        },
        {
            "_id": "651085b4c47bb114c6a4d49a",
            "name": "Jatin",
            "email": "jatinagrawal3101@gmail.com",
            "mobile": "8505053608",
            "userId": "651084fdc47bb114c6a4d470",
            "jobId": "64ee9ed03912e4591ca519c1",
            "jobTitle": "Joblient - Intern Software Engineer"
        },
        {
            "_id": "65108711c47bb114c6a4d4a2",
            "name": "Jatin",
            "email": "jatinagrawal3101@gmail.com",
            "mobile": "8505053608",
            "userId": "651084fdc47bb114c6a4d470",
            "jobId": "64ee9efd3912e4591ca519c4",
            "jobTitle": "Joblient - Intern Test Engineer"
        },
        {
            "_id": "6511d6fb641ac0887e539f04",
            "name": "Ritesh",
            "desig": "Staff Software Engineer",
            "company": "Gojek",
            "skills": "Java, Microservices",
            "email": "rsprabhas11@gmail.com",
            "mobile": "7570092894",
            "exp": "10.5",
            "edu": "C-DAC Bengaluru -- PGDM CSE -- 2014",
            "userId": "64e06dc499dcf0ef292b5bb8",
            "coins": 400,
            "assessments": {},
            "appId": "6511d6fb641ac0887e539f04",
            "jobId": "64ee9ed03912e4591ca519c1",
            "jobTitle": "Joblient - Intern Software Engineer"
        }
    ]
      console.log('fetch applicants:', result);
      if (props.mode==='link') {
        props.setList(result);
      }

      if(result && result.length>0) {
        setProfiles(result);
      } else {
        setProfiles([]);
      }
  }

  const shortList = (candidateId)=>{
    console.log(candidateId);
    dispatch(shortlistCandidate(candidateId))
  }

  const reject = ()=>{

  }

  const startMessage = ()=>{

  }
  
  
return (<Grid
          item 
          container
          direction="row"
          alignItems="flex-start"
          style={{width:'99.2%', padding:'10px', display:'flex', justifyContent:'center', borderRadius:'10px', border:'1px solid lightgray'}}
        >
          {(!isMobile || isMobile && !curProfile ) ?<Grid  item xs={left} style={{height: 'calc(100vh - 238px)', overflow: 'auto'}} >
            {(profiles && profiles.length > 0) ?<>
              <Grid item>
                  <Typography style={{display:'flex', justifyContent:'center', marginBottom:'0.4em'}} variant={`${isMobile?'h3':'h5'}`}>Job Applicant Profiles</Typography>
              </Grid>
              <Grid item>
                {
                  profiles.map((profile, key) => 
                  <Paper key={key} elevation={5} >
                    <Grid container  style={{background:`${profiles[key]===curProfile?'rgb(174 198 205)':'rgb(255 251 228)'}`, marginBottom:'1.7em', borderRadius:'5px', padding:'0.7em 0.5em 0.2em 0.5em'}}>
                      <Grid item xs={12} style={{marginBottom:'0.4em', marginLeft:'auto', float:'right'}}>
                          <p className={clsMenu} onClick={() =>setCurProfile(profile)} style={{marginLeft:'1em',  marginLeft:'auto', float:'right'}}><span><QuestionAnswerIcon style={{fontSize:`${isMobile?'36px':''}`}}/>Send Message</span></p>
                          <button type ='button' className="btnLow" style={{fontSize:`${isMobile?'34px':''}`, marginRight:'1em', marginLeft:'auto', float:'right', height:`${isMobile?'62px':'30px'}`, background:'#DBEEEC', color:'#473f7e', paddingLeft:'1em', paddingRight:'1em'}} onClick={()=>reject(true)}>Reject</button>
                          <button type ='button' className="btnLow" style={{fontSize:`${isMobile?'34px':''}`, marginRight:'1em', marginLeft:'auto', float:'right', height:`${isMobile?'62px':'30px'}`, background:'#DBEEEC', color:'#473f7e', paddingLeft:'1em', paddingRight:'1em'}} onClick={()=>shortList(profile)} >Shortlist</button>
                      </Grid>
                      <Grid item xs={12} style={{marginBottom:'0.2em'}}>
                        <Typography style={{display:'flex', justifyContent:'left'}} variant={`${isMobile?'h3':'h5'}`}>Position: {profile.jobTitle}</Typography>
                      </Grid>

                        <ProfileTile key={key} profile={profile} mode={"applicant"}/>
                        <br></br>
                    </Grid>
                  </Paper>)
                }
              </Grid>

              {/* <Grid item xs={8} style={{display:'flex', justifyContent:'center'}}>
                <Pagination count={pagination} color="primary" 
                    renderItem={(item) => (
                    <PaginationItem
                      slots={{ previous: ArrowBackIcon, next: ArrowForwardIcon }}
                      {...item}
                    />
                    )}/>
              </Grid>  */}
              
              </>
              :
              <Grid item>
                <Typography style={{display:'flex', justifyContent:'center', marginBottom:'0.4em'}} variant={`${isMobile?'h3':'h5'}`}>No Job Application</Typography>
              </Grid>}
        </Grid>
        :<div style={{width:'100%'}}>
          <button className="back" onClick={()=>setCurProfile(null)} style={{ display:'flex', justifyContent:'flex-start', color:'#0a55ad', fontSize:'40px'}}><ArrowBackIcon style={{height:'40px', width:'40px'}}/><span >Back&nbsp;</span></button>
        </div>
        }
        {curProfile && <Grid item xs={isMobile?12: 6}>
              <ChatView profile={{...curProfile, appId: curProfile._id, _id: null}} chatType={"job"}/>
        </Grid>}
  </Grid>)
}

export default JobApplications;


// import React, { useState, useEffect, useContext } from "react";
// import {
//   Button,
//   Chip,
//   Grid,
//   IconButton,
//   InputAdornment,
//   makeStyles,
//   Paper,
//   TextField,
//   Typography,
//   Modal,
//   Slider,
//   FormControlLabel,
//   FormGroup,
//   MenuItem,
//   Checkbox,
//   Avatar,
//   Collapse,
// } from "@material-ui/core";
// import { useParams } from "react-router-dom";
// import Rating from "@material-ui/lab/Rating";
// import axios from "axios";
// import FilterListIcon from "@material-ui/icons/FilterList";
// import ArrowUpwardIcon from "@material-ui/icons/ArrowUpward";
// import ArrowDownwardIcon from "@material-ui/icons/ArrowDownward";

// import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
// import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';

// import { SetPopupContext } from "../home/component/MainRouter"
// import apiList, { server } from "../../config/apiList";
// import CV from "../resume/CV";
// import { fetchData } from "../../config/apiService";

// const useStyles = makeStyles((theme) => ({
//   body: {
//     height: "inherit",
//   },
//   statusBlock: {
//     width: "100%",
//     height: "100%",
//     display: "flex",
//     alignItems: "center",
//     justifyContent: "center",
//     textTransform: "uppercase",
//   },
//   jobTileOuter: {
//     padding: "30px",
//     margin: "20px 0",
//     boxSizing: "border-box",
//     width: "100%",
//   },
//   popupDialog: {
//     height: "100%",
//     display: "flex",
//     alignItems: "center",
//     justifyContent: "center",
//   },
//   avatar: {
//     width: theme.spacing(13),
//     height: theme.spacing(13),
//   },
// }));

// const FilterPopup = (props) => {
//   const classes = useStyles();
//   const { open, handleClose, searchOptions, setSearchOptions, getData } = props;
//   return (
//     <Modal open={open} onClose={handleClose} className={classes.popupDialog}>
//       <Paper
//         style={{
//           padding: "50px",
//           outline: "none",
//           minWidth: "50%",
//         }}
//       >
//         <Grid container direction="column" alignItems="center" spacing={3}>
//           <Grid container item alignItems="center">
//             <Grid item xs={3}>
//               Application Status
//             </Grid>
//             <Grid
//               container
//               item
//               xs={9}
//               justify="space-around"
//               // alignItems="center"
//             >
//               <Grid item>
//                 <FormControlLabel
//                   control={
//                     <Checkbox
//                       name="rejected"
//                       checked={searchOptions.status.rejected}
//                       onChange={(event) => {
//                         setSearchOptions({
//                           ...searchOptions,
//                           status: {
//                             ...searchOptions.status,
//                             [event.target.name]: event.target.checked,
//                           },
//                         });
//                       }}
//                     />
//                   }
//                   label="Rejected"
//                 />
//               </Grid>
//               <Grid item>
//                 <FormControlLabel
//                   control={
//                     <Checkbox
//                       name="applied"
//                       checked={searchOptions.status.applied}
//                       onChange={(event) => {
//                         setSearchOptions({
//                           ...searchOptions,
//                           status: {
//                             ...searchOptions.status,
//                             [event.target.name]: event.target.checked,
//                           },
//                         });
//                       }}
//                     />
//                   }
//                   label="Applied"
//                 />
//               </Grid>
//               <Grid item>
//                 <FormControlLabel
//                   control={
//                     <Checkbox
//                       name="shortlisted"
//                       checked={searchOptions.status.shortlisted}
//                       onChange={(event) => {
//                         setSearchOptions({
//                           ...searchOptions,
//                           status: {
//                             ...searchOptions.status,
//                             [event.target.name]: event.target.checked,
//                           },
//                         });
//                       }}
//                     />
//                   }
//                   label="Shortlisted"
//                 />
//               </Grid>
//             </Grid>
//           </Grid>
//           <Grid container item alignItems="center">
//             <Grid item xs={3}>
//               Sort
//             </Grid>
//             <Grid item container direction="row" xs={9}>
//               <Grid
//                 item
//                 container
//                 xs={4}
//                 justify="space-around"
//                 alignItems="center"
//                 style={{ border: "1px solid #D1D1D1", borderRadius: "5px" }}
//               >
//                 <Grid item>
//                   <Checkbox
//                     name="name"
//                     checked={searchOptions.sort["jobApplicant.name"].status}
//                     onChange={(event) =>
//                       setSearchOptions({
//                         ...searchOptions,
//                         sort: {
//                           ...searchOptions.sort,
//                           "jobApplicant.name": {
//                             ...searchOptions.sort["jobApplicant.name"],
//                             status: event.target.checked,
//                           },
//                         },
//                       })
//                     }
//                     id="name"
//                   />
//                 </Grid>
//                 <Grid item>
//                   <label for="name">
//                     <Typography>Name</Typography>
//                   </label>
//                 </Grid>
//                 <Grid item>
//                   <IconButton
//                     disabled={!searchOptions.sort["jobApplicant.name"].status}
//                     onClick={() => {
//                       setSearchOptions({
//                         ...searchOptions,
//                         sort: {
//                           ...searchOptions.sort,
//                           "jobApplicant.name": {
//                             ...searchOptions.sort["jobApplicant.name"],
//                             desc: !searchOptions.sort["jobApplicant.name"].desc,
//                           },
//                         },
//                       });
//                     }}
//                   >
//                     {searchOptions.sort["jobApplicant.name"].desc ? (
//                       <ArrowDownwardIcon />
//                     ) : (
//                       <ArrowUpwardIcon />
//                     )}
//                   </IconButton>
//                 </Grid>
//               </Grid>
//               <Grid
//                 item
//                 container
//                 xs={4}
//                 justify="space-around"
//                 alignItems="center"
//                 style={{ border: "1px solid #D1D1D1", borderRadius: "5px" }}
//               >
//                 <Grid item>
//                   <Checkbox
//                     name="dateOfApplication"
//                     checked={searchOptions.sort.dateOfApplication.status}
//                     onChange={(event) =>
//                       setSearchOptions({
//                         ...searchOptions,
//                         sort: {
//                           ...searchOptions.sort,
//                           dateOfApplication: {
//                             ...searchOptions.sort.dateOfApplication,
//                             status: event.target.checked,
//                           },
//                         },
//                       })
//                     }
//                     id="dateOfApplication"
//                   />
//                 </Grid>
//                 <Grid item>
//                   <label for="dateOfApplication">
//                     <Typography>Date of Application</Typography>
//                   </label>
//                 </Grid>
//                 <Grid item>
//                   <IconButton
//                     disabled={!searchOptions.sort.dateOfApplication.status}
//                     onClick={() => {
//                       setSearchOptions({
//                         ...searchOptions,
//                         sort: {
//                           ...searchOptions.sort,
//                           dateOfApplication: {
//                             ...searchOptions.sort.dateOfApplication,
//                             desc: !searchOptions.sort.dateOfApplication.desc,
//                           },
//                         },
//                       });
//                     }}
//                   >
//                     {searchOptions.sort.dateOfApplication.desc ? (
//                       <ArrowDownwardIcon />
//                     ) : (
//                       <ArrowUpwardIcon />
//                     )}
//                   </IconButton>
//                 </Grid>
//               </Grid>
//               <Grid
//                 item
//                 container
//                 xs={4}
//                 justify="space-around"
//                 alignItems="center"
//                 style={{ border: "1px solid #D1D1D1", borderRadius: "5px" }}
//               >
//                 <Grid item>
//                   <Checkbox
//                     name="rating"
//                     checked={searchOptions.sort["jobApplicant.rating"].status}
//                     onChange={(event) =>
//                       setSearchOptions({
//                         ...searchOptions,
//                         sort: {
//                           ...searchOptions.sort,
//                           "jobApplicant.rating": {
//                             ...searchOptions.sort[["jobApplicant.rating"]],
//                             status: event.target.checked,
//                           },
//                         },
//                       })
//                     }
//                     id="rating"
//                   />
//                 </Grid>
//                 <Grid item>
//                   <label for="rating">
//                     <Typography>Rating</Typography>
//                   </label>
//                 </Grid>
//                 <Grid item>
//                   <IconButton
//                     disabled={!searchOptions.sort["jobApplicant.rating"].status}
//                     onClick={() => {
//                       setSearchOptions({
//                         ...searchOptions,
//                         sort: {
//                           ...searchOptions.sort,
//                           "jobApplicant.rating": {
//                             ...searchOptions.sort["jobApplicant.rating"],
//                             desc: !searchOptions.sort["jobApplicant.rating"]
//                               .desc,
//                           },
//                         },
//                       });
//                     }}
//                   >
//                     {searchOptions.sort["jobApplicant.rating"].desc ? (
//                       <ArrowDownwardIcon />
//                     ) : (
//                       <ArrowUpwardIcon />
//                     )}
//                   </IconButton>
//                 </Grid>
//               </Grid>
//             </Grid>
//           </Grid>

//           <Grid item>
//             <Button
//               variant="contained"
//               color="primary"
//               style={{ padding: "10px 50px" }}
//               onClick={() => getData()}
//             >
//               Apply
//             </Button>
//           </Grid>
//         </Grid>
//       </Paper>
//     </Modal>
//   );
// };

// const ApplicationTile = (props) => {
//   const classes = useStyles();
//   const { application, getData } = props;
//   const setPopup = useContext(SetPopupContext);
//   const [open, setOpen] = useState(false);
//   const [file, setFile] = useState();
//   const [pic, setPic] = useState();

//   const appliedOn = new Date(application.dateOfApplication);

//   const handleClose = () => {
//     setOpen(false);
//   };

//   useEffect(()=>{
//       getResume();
//   },[])
//   const colorSet = {
//     applied: "#3454D1",
//     shortlisted: "#DC851F",
//     accepted: "#09BC8A",
//     rejected: "#D1345B",
//     deleted: "#B49A67",
//     cancelled: "#FF8484",
//     finished: "#4EA5D9",
//   };

//   const getResume = ()=>{
//     console.log('application123:', application);
//     axios
//     .get(`${apiList.resume}?userId=${application.userId}`, {
//       headers: {
//         Authorization: `Bearer ${localStorage.getItem("token")}`,
//       },
//     })
//     .then((resume) => {
//       console.log('resume124:', resume);
//       if (resume.data.userId) {
//        let resumeAddrs = apiList.uploadResume+`?_id=${resume.data.userId}_${resume.data.file}`;

//        setFile(resumeAddrs);
//        let picAddrs = apiList.uploadProfileImage+`?_id=${resume.data.userId}_${resume.data.pic}`;
//        setPic(picAddrs);
//        console.log('resumeAddrs:', resumeAddrs, picAddrs);
//       } else {
//         setPopup({
//           open: true,
//           severity: "error",
//           message: "No resume found",
//         });
//       }
//     })
//     .catch((err) => {
//       console.log('Error:', err);
//       setPopup({
//         open: true,
//         severity: "error",
//         message: "Error",
//       });
//     });
// };
//   // const getResume = () => {
//   //   console.log('application:', application)
//   //   if (
//   //     application.jobApplicant.resume &&
//   //     application.jobApplicant.resume !== ""
//   //   ) {
//   //     const address = `${server}${application.jobApplicant.resume}`;
//   //     console.log(address);
//   //     axios(address, {
//   //       method: "GET",
//   //       responseType: "blob",
//   //     })
//   //       .then((response) => {
//   //         const file = new Blob([response.data], { type: "application/pdf" });
//   //         const fileURL = URL.createObjectURL(file);
//   //         window.open(fileURL);
//   //       })
//   //       .catch((error) => {
//   //         console.log(error);
//   //         setPopup({
//   //           open: true,
//   //           severity: "error",
//   //           message: "Error",
//   //         });
//   //       });
//   //   } else {
//   //     setPopup({
//   //       open: true,
//   //       severity: "error",
//   //       message: "No resume found",
//   //     });
//   //   }
//   // };

//   const updateStatus = (status) => {
//     const address = `${apiList.applications}/${application._id}`;
//     const statusData = {
//       status: status,
//       dateOfJoining: new Date().toISOString(),
//     };
//     axios
//       .put(address, statusData, {
//         headers: {
//           Authorization: `Bearer ${localStorage.getItem("token")}`,
//         },
//       })
//       .then((response) => {
//         setPopup({
//           open: true,
//           severity: "success",
//           message: response.data.message,
//         });
//         getData();
//       })
//       .catch((err) => {
//         setPopup({
//           open: true,
//           severity: "error",
//           message: err.response.data.message,
//         });
//         console.log(err.response);
//       });
//   };

//   const buttonSet = {
//     applied: (
//       <Grid container direction="column" spacing={2}>
//         <Grid item xs>
//           <Button
//             className={classes.statusBlock}
//             style={{
//               background: colorSet["shortlisted"],
//               color: "#ffffff",
//             }}
//             onClick={() => updateStatus("shortlisted")}
//           >
//             Shortlist
//           </Button>
//         </Grid>
//         <Grid item xs>
//           <Button
//             className={classes.statusBlock}
//             style={{
//               background: colorSet["rejected"],
//               color: "#ffffff",
//             }}
//             onClick={() => updateStatus("rejected")}
//           >
//             Reject
//           </Button>
//         </Grid>
//       </Grid>
//     ),
//     shortlisted: (
//       <Grid container direction="column" spacing={2}>
//         <Grid item xs>
//           <Button
//             className={classes.statusBlock}
//             style={{
//               background: colorSet["accepted"],
//               color: "#ffffff",
//             }}
//             onClick={() => updateStatus("accepted")}
//           >
//             Accept
//           </Button>
//         </Grid>
//         <Grid item xs>
//           <Button
//             className={classes.statusBlock}
//             style={{
//               background: colorSet["rejected"],
//               color: "#ffffff",
//             }}
//             onClick={() => updateStatus("rejected")}
//           >
//             Reject
//           </Button>
//         </Grid>
//       </Grid>
//     ),
//     rejected: (
//       <Grid container direction="column" spacing={2}>
//         <Grid item xs>
//           <Paper
//             className={classes.statusBlock}
//             style={{
//               background: colorSet["rejected"],
//               color: "#ffffff",
//             }}
//           >
//             Rejected
//           </Paper>
//         </Grid>
//       </Grid>
//     ),
//     accepted: (
//       <Grid container direction="column" spacing={2}>
//         <Grid item xs>
//           <Paper
//             className={classes.statusBlock}
//             style={{
//               background: colorSet["accepted"],
//               color: "#ffffff",
//             }}
//           >
//             Accepted
//           </Paper>
//         </Grid>
//       </Grid>
//     ),
//     cancelled: (
//       <Grid container direction="column" spacing={2}>
//         <Grid item xs>
//           <Paper
//             className={classes.statusBlock}
//             style={{
//               background: colorSet["cancelled"],
//               color: "#ffffff",
//             }}
//           >
//             Cancelled
//           </Paper>
//         </Grid>
//       </Grid>
//     ),
//     finished: (
//       <>
//         <Grid item xs>
//           <Paper
//             className={classes.statusBlock}
//             style={{
//               background: colorSet["finished"],
//               color: "#ffffff",
//             }}
//           >
//             Finished
//           </Paper>
//         </Grid>
//       </>
//     ),
//   };

//   return (
//     <Paper className={classes.jobTileOuter} elevation={3}>
//       <Grid container>
//         <Grid
//           item
//           xs={2}
//           style={{
//             display: "flex",
//             justifyContent: "center",
//             alignItems: "center",
//           }}
//         >
//           <Avatar
//             //src={`${server}${application.jobApplicant.profile}`}
//             src={pic}
//             className={classes.avatar}
//           />
//         </Grid>
//         {/* <Grid container item xs={7} spacing={1} direction="column">
//           <Grid item>
//             <Typography variant="h5">
//               {application.jobApplicant.name}
//             </Typography>
//           </Grid>
//           <Grid item>
//             <Rating
//               value={
//                 application.jobApplicant.rating !== -1
//                   ? application.jobApplicant.rating
//                   : null
//               }
//               readOnly
//             />
//           </Grid>
//           <Grid item>Applied On: {appliedOn.toLocaleDateString()}</Grid>
//           <Grid item>
//             Education:{" "}
//             {application.jobApplicant.education
//               .map((edu) => {
//                 return `${edu.institutionName} (${edu.startYear}-${
//                   edu.endYear ? edu.endYear : "Ongoing"
//                 })`;
//               })
//               .join(", ")}
//           </Grid>
//           <Grid item>
//             SOP: {application.sop !== "" ? application.sop : "Not Submitted"}
//           </Grid>
//           <Grid item>
//             {application.jobApplicant.skills.map((skill) => (
//               <Chip label={skill} style={{ marginRight: "2px" }} />
//             ))}
//           </Grid>
//         </Grid> */}
//         <Grid item container direction="column" xs={3}>
//           <Grid item>
//             <a type="button" onClick={()=>getResume()} href = {file} target = "_blank" 
//             style = {{cursor:'pointer', display:'inline-flex', textDecoration:'none', textAlign:'center', minWidth:'10em',fontSize:'1em', height:'3em', marginLeft:'-1em', marginTop:'0.6em'}}>
//               Download Resume
//             </a> 
//           </Grid>
//           <Grid item container xs>
//             {buttonSet[application.status]}
//           </Grid>
//         </Grid>
//         <Grid>
//           <div style={{ display:'inline-flex', marginLeft:'0em',marginTop:'1.5em'}} onClick={()=>setOpen(!open)}>
//             <p style={{fontWeight:'bold', fontSize:'1.4em'}} >View Profile</p>
//             <IconButton style={{marginLeft:'1em',fontSize:'2em'}}
//                               aria-label="expand row"
//                               size="small"
//                               onClick={()=>setOpen(!open)}
//                           >
//                           {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon/>}

//             </IconButton>
//           </div>

//           <Collapse in={open} timeout="auto" unmountOnExit>
//             <CV readyOnly = {true} query={`userId=${application.userId}`} ></CV>
//           </Collapse>
//         </Grid>
//       </Grid>
//       {/* <Modal open={open} onClose={handleClose} className={classes.popupDialog}>
//         <Paper
//           style={{
//             padding: "20px",
//             outline: "none",
//             display: "flex",
//             flexDirection: "column",
//             justifyContent: "center",
//             minWidth: "30%",
//             alignItems: "center",
//           }}
//         >
//           <Button
//             variant="contained"
//             color="primary"
//             style={{ padding: "10px 50px" }}
//             // onClick={() => changeRating()}
//           >
//             Submit
//           </Button>
//         </Paper>
//       </Modal> */}
//     </Paper>
//   );
// };

// const JobApplications = (props) => {
//   const setPopup = useContext(SetPopupContext);
//   const [applications,  setApplications] = useState([]);
//   const { jobId } = useParams();
//   const [filterOpen, setFilterOpen] = useState(false);
//   const [searchOptions, setSearchOptions] = useState({
//     status: {
//       all: false,
//       applied: false,
//       shortlisted: false,
//     },
//     sort: {
//       "jobApplicant.name": {
//         status: false,
//         desc: false,
//       },
//       dateOfApplication: {
//         status: true,
//         desc: true,
//       },
//       "jobApplicant.rating": {
//         status: false,
//         desc: false,
//       },
//     },
//   });

//   useEffect(() => {
//     getData();
//   }, []);

//   const getData = async() => {
//     let searchParams = [];

//     if (searchOptions.status.rejected) {
//       searchParams = [...searchParams, `status=rejected`];
//     }
//     if (searchOptions.status.applied) {
//       searchParams = [...searchParams, `status=applied`];
//     }
//     if (searchOptions.status.shortlisted) {
//       searchParams = [...searchParams, `status=shortlisted`];
//     }

//     let asc = [],
//       desc = [];

//     Object.keys(searchOptions.sort).forEach((obj) => {
//       const item = searchOptions.sort[obj];
//       if (item.status) {
//         if (item.desc) {
//           desc = [...desc, `desc=${obj}`];
//         } else {
//           asc = [...asc, `asc=${obj}`];
//         }
//       }
//     });
//     searchParams = [...searchParams, ...asc, ...desc];
//     const queryString = searchParams.join("&");
//     console.log("queryString:", queryString, jobId);
//     //let address = `${apiList.jobPosting}/applicant?jobId=${jobId}`;
//     let address = `${apiList.jobPosting}/all/application`;
//     if (jobId) {
//       address = `${apiList.jobPosting}/${jobId}/application`;
//     }

//     // if (queryString !== "") {
//     //   address = `${address}&${queryString}`;
//     // }
//     // if(jobId ==':jobId') {
//     //   address = apiList.applications;
//     // }

//     console.log("address1234:", address);
//     let res = await fetchData(address,"Job Applications");
//     setApplications(res);

//   //   axios
//   //     .get(address, {
//   //       headers: {
//   //         Authorization: `Bearer ${localStorage.getItem("token")}`,
//   //       },
//   //     })
//   //     .then((response) => {
//   //       console.log(response.data);
//   //       setApplications(response.data);
//   //     })
//   //     .catch((err) => {
//   //       console.log(err.response);
//   //       // console.log(err.response.data);
//   //       setApplications([]);
//   //       setPopup({
//   //         open: true,
//   //         severity: "error",
//   //         message: err.response.data.message,
//   //       });
//   //     });
//   };

//   return (
//     <>
//       <Grid
//         container
//         item
//         direction="column"
//         alignItems="center"
//         style={{ padding: "30px", minHeight: "93vh" }}
//       >
//         <Grid item>
//           <Typography variant="h2">Applications</Typography>
//         </Grid>
//         <Grid item>
//           <IconButton onClick={() => setFilterOpen(true)}>
//             <FilterListIcon />
//           </IconButton>
//         </Grid>
//         <Grid
//           container
//           item
//           xs
//           direction="column"
//           style={{ width: "100%" }}
//           alignItems="stretch"
//           justify="center"
//         >
//           {applications && applications.length > 0 ? (
//             applications.map((obj, key) => (
//               <Grid key={key}  item>
//                 {/* {console.log(obj)} */}
//                 <ApplicationTile application={obj} getData={getData} />
//               </Grid>
//             ))
//           ) : (
//             <Typography variant="h5" style={{ textAlign: "center" }}>
//               No Applications Found
//             </Typography>
//           )}
//         </Grid>
//       </Grid>
//       <FilterPopup
//         open={filterOpen}
//         searchOptions={searchOptions}
//         setSearchOptions={setSearchOptions}
//         handleClose={() => setFilterOpen(false)}
//         getData={() => {
//           getData();
//           setFilterOpen(false);
//         }}
//       />
//     </>
//   );
// };

// export default JobApplications;