import * as React from 'react';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import "./style.scss";

import {JOB_POS_CATLOG} from '../../data/feedback/JOB_POS_CATLOG'
import { fetchInterviewDetails, fetchQASets, getInterviewSets } from './helper';
import { Grid } from '@material-ui/core';

import { aws_set} from '../../data/feedback/QA/qa_aws_services'
import { setBk, setBrd, setColor, setPaperTheme } from '../../utils/colorUtils';

const InterviewFeedback = ()=>{
    const [valuePos, setValuePos] = React.useState();
    const [valueIntvw, setValueIntvw] = React.useState();
    const [qaSets, setQaSets] = React.useState();

    const [selIntvw, setSelIntvw] = React.useState();
    const [selTopic, setSelTopic] = React.useState();

    const onPosChanged = (item) => {
        setValuePos(item);
        console.log('onPosChanged: ', item);
        // let temp= getInterviewSets(item.key);
        // setIntvw(temp);
        setValueIntvw();
        setQaSets();
      //  setClassPos()
    }

    const onSetChanged = (key, id)=>{
        console.log('onSetChanged: ', key, id);
        let temp = fetchInterviewDetails(key, id);
        setValueIntvw(temp);
        setSelIntvw(key);
    }

    const fetchQuestionSets = (key, id)=>{
        console.log('fetchQuestionSets:', key, id);
        let temp = fetchQASets(key, id);
        setQaSets(temp);
        setSelTopic(key);
        console.log('fetchQuestionSets:', temp);
    }

    const logoEng = <svg class="SVGInline-svg d-flex-svg" width="28" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 90 90"><g stroke="#999" stroke-width="2" fill="none" fill-rule="evenodd"><path stroke-linejoin="round" d="M64.826 16.104H69v63H21v-63h4.174"></path><path stroke-linecap="round" stroke-linejoin="round" d="M34 57.104h22v22H34z"></path><path d="M45 57.104v22"></path><path d="M28 28.104h8v8h-8zm13 0h8v8h-8zm13 0h8v8h-8zm-26 14h8v8h-8zm13 0h8v8h-8zm13 0h8v8h-8zM26 10.104h38v12H26z" stroke-linecap="round" stroke-linejoin="round"></path></g></svg>
    
    return  <Grid container direction="row" justifyContent="center" spacing={2} style={{userSelect:'none', margin: '20px', width:'95%', borderRadius:'10px', border:'0.5px solid lightgray'}}>
        <Grid item xs={12}>
            <span style={{fontSize:'2em'}}><strong>Candidate Real - Time Interview Feedback</strong></span>
        </Grid>
        <Grid item xs={12}>
            {JOB_POS_CATLOG.map((item, key)=>{
                return <a key={key} type="button" className="no-link" target="_blank" onClick={()=>onPosChanged(item)}>
                            <div className={item==valuePos?"topic-chip-selected":"topic-chip"}>
                                <div className="justify-content-center align-self-center">
                                <img className="topic-logo1"/>
                                {/* <div>{logoEng()}</div> */}
                                <span style={{color:'#37465A'}}><b>&nbsp;&nbsp;{item.title}</b></span><span className="small text-muted">&nbsp;&nbsp;{item.skills.length}&nbsp;&nbsp;</span>
                            </div>
                        </div>
                    </a>
                }
            )}
        </Grid>
        
        <Grid item xs={11} style={{border:'0.5px dotted gray', borderRadius:'10px'}}>
            { valuePos && valuePos.skills.map((item, key)=>{
                return <a key={key} type="button" className="no-link" target="_blank" onClick={()=>onSetChanged(item, key)}>
                            <div className={item==selIntvw?"topic-chip-selected":"topic-chip set"}>
                                <div className="justify-content-center align-self-center">
                                <img className="topic-logo1"/>
                                <span style={{color:'#37465A'}}><b>&nbsp;&nbsp;{`Interview Set ${(key + 1)}`}</b></span><span className="small text-muted">&nbsp;&nbsp;{item}&nbsp;&nbsp;</span>
                            </div>
                        </div>
                    </a>
                }
            )}
        </Grid>

{/* INTERVIEW SECTION */}
        <Grid item xs={11}>
            <span  style={{color: 'red', marginTop:'2em'}}><strong>Interview:</strong></span>
        </Grid>
        <Grid item xs={11} style={{border:'0.5px dotted gray', borderRadius:'10px'}}>
            <div style={{color: 'blue'}}><strong>{valueIntvw&&valueIntvw.round}</strong></div>
            <div style={{marginBottom:'1em'}}><strong>Interview Details:</strong></div>
            <ul style={{fontWeight:'bold', marginLeft:'1em'}}>
                    {valueIntvw && valueIntvw.discussions.split('.').map((item, key)=>{
                return item.trim()!=""&&<li key={key} style = {setColor(key)}>{item}.</li>
            })}</ul>
            <div style={{color: 'Blue'}}><strong>Feedback - {valueIntvw&&valueIntvw.feedback}</strong></div>
        </Grid>

        <Grid item style={{border:'0.5px dotted gray', borderRadius:'10px', marginTop:'3em', marginBottom:'1em', width:'86%'}}>
            <div  style={{color: '#7f0cc1'}}><strong>Interview Area:</strong></div>
            { valueIntvw && valueIntvw.tags.split(',').map((item, key)=>{
                return <a key={key} type="button" className="no-link" target="_blank" onClick={(ev)=>{fetchQuestionSets(item, key)}}>
                            <div className={item==selTopic?"topic-chip-selected":"topic-chip course"}>
                                <div className="justify-content-center align-self-center">
                                <img className="topic-logo1"/>
                                <span style={{color:'#37465A'}}><b>&nbsp;&nbsp;{item}</b></span><span className="small text-muted">&nbsp;&nbsp;{( item.includes("code") || item.includes("design")) ? 1 : 5 } &nbsp;&nbsp;</span>
                            </div>
                        </div>
                    </a>
                }
            )}
        </Grid>

{/* QUESTION ANSWER SECTION */}

        <Grid item container style={{background:'#f9f9f2', border:'0.5px dotted gray', borderRadius:'10px', marginTop:'0em', marginBottom:'1em', width:'86%', justifyContent:'center' }}>
            <Grid item xs={12} style={{ marginTop:'0.2em',marginBottom:'2em'}}>
                    <span  style={{fontSize:'1.5em'}}><strong>Interview Question and Answer</strong></span>
            </Grid>
            <Grid item xs={12}  style={{marginTop:'1em'}} >
                <Grid>
                    { qaSets && qaSets.sets.map((item, key)=>{
                            return <div key={key} style = {{display:'flex', alignItems: 'center', marginLeft:'0em', marginBottom:'2em'}}>
                                <div className="num" style = {setBk(key)} >{ key < 9 ? `0${key+1}` : `${key+1}` }</div>
                                <div className="context" style = {setBk(key)}>
                                    <p className="note" style = {setBrd(key)}>
                                        <span>{item.Q}</span>
                                    </p>
                                    <ul style={{fontWeight:'bold', margin:'0.3em', backgroundColor:'white', padding:'10px', listStyle:'none'}}>
                                            {item.A && item.A.split('.').map((line, key)=>{
                                        return line.trim()!=""&&<li key={key} style = {setColor(key)}>{line} {item.skip?'':'.'}</li>
                                    })}</ul>
                                </div>
                            </div>
                        }
                    )}
                </Grid>
                <Grid>
                { qaSets && qaSets.sets.map((item, key)=>{
                    return <div class="leafygreen-ui-11oupyp">
                        <div className="num" style = {setBk(key)} >{ key < 9 ? `0${key+1}` : `${key+1}` }</div>
                            <div class="leafygreen-ui-1fuktk9">
                                <h3 class="leafygreen-ui-4rvd2s">{item.Q}</h3>
                            </div>
                            <div class="leafygreen-ui-1rlqfkz">
                                {/* <h4 class="leafygreen-ui-1386gk6"> {item.A}</h4> */}
                                {/* <div class="leafygreen-ui-6zh3yw">
                                    <p class="leafygreen-ui-kkpb6g">

                                    
                                    </p>
                                </div> */}
                                <ul style={{fontWeight:'bold', margin:'0.3em', backgroundColor:'white', padding:'10px', listStyle:'none'}}>
                                            {item.A && item.A.split('.').map((line, key)=>{
                                        return line.trim()!=""&&<li key={key} style = {setColor(key)}>{line} {item.skip?'':'.'}</li>
                                })}</ul>
                            </div>
                        </div>
                    }
                    )}
                </Grid>
            </Grid>
        </Grid>
    </Grid>
}


export default InterviewFeedback;