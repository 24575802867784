export const minifyShapes = (curData)=>{
    //let curData = JSON.parse(diagramInstance.saveDiagram());
  console.log('minifyShapes:')
    let idNodes = new Map();
    //console.log('save:' , save.connectors)
    let n = 1;
    let nn = curData.nodes.map( (item, key)=>{ 
        let id = idNodes[item.id];
        if(!id) {
            id= `n${n}`;
            idNodes[item.id] = id;
            n++;
        }
        
        // if(item.shape.shape.indexOf('DirectData')>=0) {
        //     console.log('DB:', item);
        // }
       
        let temp = {
            id: id,
            w: Number(item.width).toFixed(2),
            h: Number(item.height).toFixed(2),
            x: Number(item.offsetX).toFixed(2),
            y: Number(item.offsetY).toFixed(2),
            s: item.shape,
            a: item.annotations[0].content, //[{content: item.annotations[0].content}]
            r: item.rotateAngle,
        }
        // if(item.rotateAngle) {
        //     temp.r = item.rotateAngle;
        // }

        return temp;

    })
    console.log('minifyShapes12:')
    let c = 1;
    let cc = curData.connectors.map( (con, key)=>{ 
        // console.log('conn:', conn);

        {
            console.log('con:', con);
        }

        let temp = {
        id: `c${c++}`,  //con.id,
        s: idNodes[con.sourceID],
        t: idNodes[con.targetID],
        sp: con.sourcePoint,
        tp: con.targetPoint,
        type: con.type,
        si: con.sourcePortID,
        ti: con.targetPortID,
        td: con.targetDecorator.shape,
        };
        if (con.annotations.length > 0){
       // console.log('con.annotations[0]:', con.annotations)
        ///temp['annotations'] = [{content : con.annotations[0].content, style:{color:'red', zIndex:10, backgroundColor:'yellow'}}];
            temp['a'] = con.annotations[0].content;
        }
        return temp;
    
    })
    //let cc = [...save.connectors];
    
    let draw = {nodes:nn, connectors:cc};


    console.log('Shape Minify: ', (draw))

    //restoreShapes(draw);

    return draw;
   // console.log("WSSSSS:", ws);
    //ws && ws.send(JSON.stringify(messageBody));
   // console.log('shape has updated', newMenu);
}


export const trNodes = (nodes)=>{

    let nn = nodes.map( (item, key)=>{ 
        let temp = {
            id: item.id,
            width: Number(item.w),
            height: Number(item.h),
            offsetX: Number(item.x),
            offsetY: Number(item.y),
            shape: item.s,
            rotateAngle: item.r,
            annotations: [{content: item.a}]
        }
        return temp;
    })
   // console.log('nodes123:', nn);
    return nn;
}


export const trCon = (connectors)=>{

    let cc = connectors.map( (con, key)=>{ 
        let temp = {
            id: con.id,
            sourceID: con.s,
            targetID: con.t,
            sourcePoint: con.sp,
            targetPoint: con.tp,
            type: con.type,
            sourcePortID: con.si,
            targetPortID: con.ti,
            targetDecorator: {shape: con.td}
        };
        if  (con.a){
            temp['annotations'] = [{content : con.a}];
        }
        return temp;
    })
    //console.log('connectors:', cc);
    return cc;
}

export const restoreShapes = (curData)=>{

    let nn = curData.nodes.map( (item, key)=>{ 
        let temp = {
            id: item.id,
            width: Number(item.w),
            height: Number(item.h),
            offsetX: Number(item.x),
            offsetY: Number(item.y),
            shape: item.s,
            rotateAngle: item.r,
            annotations: [{content: item.a}]
        }
        return temp;
    })

    let cc = curData.connectors.map( (con, key)=>{ 
        // console.log('conn:', conn);
        let temp = {
            id: con.id,
            sourceID: con.s,
            targetID: con.t,
            sourcePoint: con.sp,
            targetPoint: con.tp,
            type: con.type,
            sourcePortID: con.si,
            targetPortID: con.ti,
        };
        if  (con.a){
            temp['annotations'] = [{content : con.a}];
        }
        return temp;
    
    })
    
    let draw = {nodes:nn, connectors:cc};

    console.log('Shape Restore: ', (draw))

    return draw;

}
