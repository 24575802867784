import { MenuItem } from "@material-ui/core";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import useComponentVisible from "./useComponentVisible";
import { isMobile } from "../../utils/mobileUtil";
import { userType } from "../login/lib/isAuth";
import { uploadBulkCV } from "../../utils/resumeBulkUploadUtils";

export const MenuLogout = (props) => {
    const { ref, isComponentVisible, setIsComponentVisible } = useComponentVisible(true, false, props.setLogin);
    let type = localStorage.getItem('type');
    return (
      <div ref={ref}> {
            isComponentVisible &&
            <div>
               <span style={{position:'absolute', zIndex:100, color:'blue',   }} >
                  <div style={{justifyContent:'center', background:'#f0f0f0', border: '1px solid #f2f2f2', padding:'0.2em', borderRadius:'0.5em',  marginLeft:'-4em', marginTop:'0em'}}>
                        {userType() === "recruiter" ?<>
                        <MenuItem onClick={()=>props.handleRoute("/logout")}><span>Sign Out</span></MenuItem>
                        <hr/>
                        <MenuItem onClick={()=>props.handleRoute("/feedback")}><span>Feedback</span></MenuItem>
                        </>
                     : <>
                        <button button className="menuNormal menu" style={{margin:'0.5em 1.2em', padding:'0.3em 0.8em'}}
                        onClick={()=>props.handleRoute("/profile")}
                        >View Profile </button>
                        <hr></hr>
                        <MenuItem onClick={()=>props.handleRoute("/applications")}><span>Applied Jobs</span></MenuItem>
                        {type==='admin'&&<>
                        <MenuItem onClick={()=>props.handleRoute("/viewfeedback")}><span>View Feedback</span></MenuItem>
                        <MenuItem onClick={()=>props.handleRoute("/viewactivity")}><span>View Activities</span></MenuItem>
                        <hr></hr>
                        </>
                        }
                        <MenuItem onClick={()=>props.handleRoute("/logout")}><span>Sign Out</span></MenuItem>
                        <hr/>
                        <MenuItem onClick={()=>props.handleRoute("/feedback")}><span>Feedback</span></MenuItem>
                        {/* <hr/>
                        <MenuItem onClick={()=>{uploadBulkCV()}}><span>Upload Bulk</span></MenuItem> */}
                        </>
                     }
                  </div>
               </span>
               </div>
         }
      
       </div>
    );
 
}
