import React from 'react'
import Circle from './Circle'
import { useSelector } from 'react-redux'

const Sidebar = ({ selectque, setSelectque }) => {
  const questionData = useSelector((state)=> state.questionReducer.questions);
  const onClick= (index) =>{
    setSelectque(index);
  }
  return (
    <div style={{ overflowY: 'scroll', paddingBottom: '60px', width: 120 }}>
      {questionData?.map((item, index) => {
        return (
          <div key={index} onClick={()=>onClick(index)} style={(item.check !== null && index !== selectque) ? {backgroundColor:'#B2F9B2'} : {}} className={`${index === selectque ? 'bg-blue-200' : ''} p-2 w-full justify-center items-center flex flex-col mb-px`}>
            <Circle number={index+1}  />
          </div>
        )
      })
      }

    </div>
  )
}

export default Sidebar