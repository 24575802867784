import React,{ useContext, useState } from "react";
import {
  Grid,
  TextField,
  Button,
  Typography,
  makeStyles,
  Paper,
  Avatar,
  Modal
} from "@material-ui/core";
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';


import axios from "axios";
import { Redirect } from "react-router-dom";

import PasswordInput from "./lib/PasswordInput";
import EmailInput from "./lib/EmailInput";
import { SetPopupContext } from "../home/component/MainRouter";
import AccountCircle from "@material-ui/icons/AccountCircle";
import FilterListIcon from "@material-ui/icons/FilterList";

import apiList from "../../config/apiList";
import isAuth from "./lib/isAuth";
//import "./LoginForm.css"
import { setLoggedIn } from "../../state/action-creators/profile";
import { useDispatch, useSelector } from "react-redux";
import { BorderLeft } from "@material-ui/icons";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import "react-toastify/dist/ReactToastify.css";
import { showErrorToast, showSuccessToast } from "../../utils/toastMsg";
import { isMobile } from "../../utils/mobileUtil";

const useStyles = makeStyles((theme) => ({
  inputBox: {
    minWidth:'15em'
  },
  passBox: {
    minWidth:'15em',
  },
  submitButton: {
    width: "30em",
  }
}));

const Login = (props) => {
  const [open, setOpen] = useState(props.open);
  const dispatch = useDispatch();
  const classes = useStyles();
  const setPopup = useContext(SetPopupContext);
  let history = useHistory();


  const handleRoute = (location) => {
    console.log(location);
    history.push(location);
  };


  // const handleClose = () => {
   
  //   setOpen(false);
  // };

  //const [loggedin, setLoggedin] = useState(isAuth());
  const token = useSelector(state=>state.profile.profile.token);

  const [loginDetails, setLoginDetails] = useState({
    email: "",
    password: "",
  });

  const [inputErrorHandler, setInputErrorHandler] = useState({
    email: {
      error: true,
      message: "",
    },
    password: {
      error: true,
      message: "",
    },
  });

  const handleInput = (key, value) => {
    setInputErrorHandler({
      ...inputErrorHandler,
      [key]: {
        error: false,
        message: value,
      },
    });

    setLoginDetails({
      ...loginDetails,
      [key]: value,
    });
  };

  const handleInputError = (key, status, message) => {
    setInputErrorHandler({
      ...inputErrorHandler,
      [key]: {
        error: status,
        message: message,
      },
    });
  };

  const handleLogin = () => {
    const verified = !Object.keys(inputErrorHandler).some((obj) => {
      return inputErrorHandler[obj].error;
    });

    if (verified) {
      axios
        .post(apiList.login, loginDetails)
        .then((response) => {
          localStorage.setItem("token", response.data.token);
          localStorage.setItem("type", response.data.type);
          localStorage.setItem("name", response.data.name);
          localStorage.setItem("hasProfile",response.data.hasProfile);
          localStorage.setItem("tiny",response.data.tiny);
          dispatch(setLoggedIn(isAuth()))
          showSuccessToast("Login Success")
          console.log('login:', response);
        })
        .catch((err) => {
          console.log(err.response);
          if (err.response?.data?.message) {
            showErrorToast("Login Failed, "+ err.response?.data?.message, 3000);
          }
       
        }) 
           

    } else {
      setPopup({
        open: true,
        severity: "error",
        message: "Incorrect Input",
      });
    }
  };

  return (token || !open) ? (
    <Redirect to="/" />
  ) : 
 (
    <Modal open={open} onClose={()=>setOpen(false)} closeAfterTransition
    >
     <Paper 
      elevation={20}
      style={{ 
        display:'flex',
        float:'right' , 
        width:'21.7em', 
        height:`${isMobile?'70%':'100%'}`,
        paddingTop:'5em', 
        paddingLeft:'3em', 
        paddingRight:'3em',
        borderTopLeftRadius:'20px',
        borderBottomLeftRadius:'20px',
        fontSize:'1.2em'
      }}
    >
      <Grid container direction="column" spacing={4} alignItems="center"
          onKeyPress={(ev) => {
            if (ev.key === "Enter") {
              handleLogin();
            }
          }}>
        <Grid item>
          <Avatar sx={{ m: 1, bgcolor: 'primary.main' }}  style={{marginLeft:'15px'}}>
            <LockOutlinedIcon/>
          </Avatar>
          <Typography component="h1" style={{fontWeight:'bold'}} variant="h5">
            Log in
          </Typography>
        </Grid>
        <Grid item style={{fontSize:'1em'}}>
           <span>Don't have an account? <a style={{color:'blue'}} href="#"  onClick={()=>handleRoute('register')} > Sign up</a></span>
        </Grid>
        <Grid item>
          <EmailInput className='w-80 md:w-72'
            label="Email"
            value={loginDetails.email}
            onChange={(event) => handleInput("email", event.target.value)}
            inputErrorHandler={inputErrorHandler}
            handleInputError={handleInputError}
          />
        </Grid>
        <Grid item>
          <PasswordInput className='md:w-58'
            label="Password"
            value={loginDetails.password}
            onChange={(event) => handleInput("password", event.target.value)}
          />
        </Grid>
        <Grid item>
          {/* <Button
            variant="contained"
            color="primary"
            onClick={() => handleLogin()}
            className={classes.submitButton}
          >
            Login
          </Button> */}
          {/* <Button endIcon={<AccountCircle/>} className="menuBtn" color='primary' variant="outlined" onClick={() => handleLogin()}>
                Login
          </Button> */}
        </Grid>
        <Grid item >
          <div className="menuBtn" style={{width:'15em', fontSize:'1em'}} onClick={()=> handleLogin()}>
            Log In
          </div>
        </Grid>
        <Grid item>
          <a href="#">Forgot Password?</a>
        </Grid>
      </Grid>
    </Paper>
    </Modal>
  );
};

export default Login;
