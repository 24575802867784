import React, { useEffect, useState, useCallback } from 'react'
import Loader from '../../loader/Loader';
import { Button, Grid } from '@material-ui/core';
import { Download } from 'react-feather';
import { isMobile } from '../../../utils/mobileUtil'
import * as XLSX from 'xlsx';
import { getTestsByRecruiterId } from '../../../config/apiService';
import apiList from '../../../config/apiList';

const DetailsTile = ({ test, testKey }) => {
  const applyStyle = (key) => {
    let style;
    if (key % 2 === 0) {
      style = {
        //fontSize:'0.7em',
        background: '#eceff8',
        width: `${isMobile ? '220%' : '100%'}`,
        padding: '10px 4px',
        minHeight: '40px'
      }
    } else {
      style = {
        //fontSize:'0.7em',

        background: '#f8f8f7',
        width: `${isMobile ? '220%' : '100%'}`,
        padding: '10px 4px',
        minHeight: '40px'
      }
    }
    return style;
  }

  function calculateTimeRemaining(expiresAt) {
    const now = new Date();
    const expirationTime = new Date(expiresAt);

    const timeDifference = expirationTime - now;

    const days = Math.floor(timeDifference / (1000 * 60 * 60 * 24));
    const hours = Math.floor((timeDifference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
    const minutes = Math.floor((timeDifference % (1000 * 60 * 60)) / (1000 * 60));
    let expired = days < 0 || hours < 0 || minutes < 0;
    return expired ? 'completed' : `${days}d, ${hours}hr, ${minutes}min`;
  }
  let expiresIn = calculateTimeRemaining(test.expiresAt);

  function testTakenPercent(candidates) {
    let testTaken = candidates.filter(candidate => candidate?.score !== -1).length;
    let testTakenPercent = testTaken * 100 / candidates?.length;
    return testTakenPercent;
  }
  let testTaken = testTakenPercent(test.candidateData);

  const generateExcelAndDownload = (jsonData, fileName) => {
    // Convert JSON to worksheet
    const ws = XLSX.utils.json_to_sheet(jsonData);
    const wscols = [{ wch: 25 }, { wch: 30 }, { wch: 15 }];
    ws['!cols'] = wscols;
    ws['!style'] = { font: { bold: true }, alignment: { horizontal: 'center' } };
    // Create a workbook with the worksheet
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet 1');

    // Save the workbook to a binary Excel file
    const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });

    // Create a Blob from the Excel buffer
    const blob = new Blob([excelBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });

    // Create a download link
    const url = URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = fileName || 'data.xlsx';

    // Trigger a click on the link to initiate the download
    document.body.appendChild(a);
    a.click();

    // Remove the link from the DOM
    document.body.removeChild(a);
    URL.revokeObjectURL(url);
  };

  return (
    <Grid className="cursor-pointer" item container direction="row" alignItems="flex-start" spacing={1} style={applyStyle(testKey)} key={test._id} >
      <Grid className="w-20 text-center">{testKey+1}.</Grid>
      <Grid className="w-24 text-center">{test.createdBy || 'Recruiter'}</Grid>
      <Grid className="w-36 text-center">{test.name || 'No name'}</Grid>
      <Grid className="w-24 text-center">{test.type || 'CODING'}</Grid>
      <Grid className="w-24 text-center" style={{ color: `${expiresIn === 'completed' ? 'green' : '#FFC000	'}`, fontWeight: 'bold' }}><div>{expiresIn}</div></Grid>
      <Grid className="w-24 text-center">{new Date(test.createdAt).toLocaleDateString('en-GB', {
        day: 'numeric', month: 'short', year: 'numeric'
      })}</Grid>
      <Grid className="w-24 text-center">{new Date(test.expiresAt).toLocaleDateString('en-GB', {
        day: 'numeric', month: 'short', year: 'numeric'
      })}</Grid>
      <Grid className="w-24 text-center">{test.duration + ' min'}</Grid>
      <Grid className="w-24 text-center">{testTaken}%</Grid>
      <Grid className="w-36 text-center">
        <Button style={{ background: '#239ce2', color: 'white', padding: 6, fontWeight: 'bold', fontSize: 9, textAlign:'center' }} onClick={() => generateExcelAndDownload(test.candidateData, test.name)}>Download Excel</Button>
      </Grid>
    </Grid>
  )
}

const TestDetails = () => {
  const [tests, setTests] = useState();

  const getAllTest = useCallback(async () => {
    const response = await getTestsByRecruiterId(apiList.test);
    setTests(response.tests);
  }, []);

  useEffect(() => {
    getAllTest();
  }, []);

  return (
    <div>
      <Loader isLoading={false} />
      <h2 className='text-center'>Test Details</h2>

      <div className="text-[10px] pb-4 md:text-[12px] overflow-x-auto md:w-[80%] h-full pl-4 pr-4 mr-4">
        <Grid className="" item container direction="row" spacing={1} style={{ width: `${isMobile ? '220%' : '100%'}`, padding: '10px 4px', minHeight: '30px', background: '#cfcdfa', fontWeight: 'bold', marginBottom: '2px', border: '5px' }} >
          <Grid className="w-20 text-center">S. No</Grid>
          <Grid className="w-24 text-center">Created By</Grid>
          <Grid className="w-36 text-center">Name</Grid>
          <Grid className="w-24 text-center">Type</Grid>
          <Grid className="w-24 text-center">Status</Grid>
          <Grid className="w-24 text-center">Created on</Grid>
          <Grid className="w-24 text-center">Expires on</Grid>
          <Grid className="w-24 text-center">Duration</Grid>
          <Grid className="w-24 text-center">Test Taken</Grid>
          <Grid className="w-36 flex justify-center gap-1">
            <span>Details</span>
            <Download size={16} />
          </Grid>
        </Grid>

        {tests && tests.map((test, index) => {
          return (
            <DetailsTile test={test} testKey={index} />
          )
        })}
      </div>
    </div>
  )
}

export default TestDetails