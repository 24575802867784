import React, { useState, useEffect, useContext } from "react";
import { useDispatch } from "react-redux";
import {
  Button,
  Grid,
  TextField,
  MenuItem,
  FormControlLabel,
} from "@material-ui/core";
import { Radio } from "@mui/material";
import { SetPopupContext } from "../home/component/MainRouter";
import {API, fetchData, saveData} from "../../config/apiService"
import TextEditor from "../editor/editor-text/TextEditor";
import ViewFeedback from "./ViewFeedback";

const CODE = 1;
const DESIGN = 2;

const UserFeedback = (props)=>{
    const dispatch = useDispatch();
    const setPopup = useContext(SetPopupContext);

    const [feedback, setFeedback] = useState({option: 'general', area: 'all', rating:5, title: '', desc: ''})
    const [select, setSelect] = useState(CODE);
    const [listFB, setListFB] = useState();

    useEffect(()=>{
        getFeedbacks();
    },[])

    const getFeedbacks = async()=>{
        let res = await fetchData(API.feedback);
        console.log('Feedbacks:', res)
        if (res) {
            setListFB(res);
        }
    }

    const validations = ()=>{
        let filt = listFB && listFB.filter(v=> v.title===feedback.title);

        if (filt && filt.length > 0) {
            setPopup({
                open: true,
                severity: "error",
                message: "Title already exist"
                });
            return false;
        }

        if(feedback.title ==="" || feedback.desc === "<p><br></p>") {
            setPopup({
            open: true,
            severity: "error",
            message: "Title and details are mandatory."
            });
            return false;
        }
        return true;
    }

    const postFeedback = ()=>{

        let verify = validations();

        if (verify) {
            console.log('feedback:::', feedback);
            saveData(API.feedback, feedback, "Feedback", setPopup );
            getFeedbacks();
        }
    }

    const contentChanged = (content)=>{
         console.log(content);
        setFeedback({...feedback, desc: content})
    }

    const title = `User Feedback`;

    return  <Grid container direction="row" spacing={0} style={{width:'98%', padding:'1em 1em 3em 1em', borderRadius:'10px', border:'1px solid lightgray'}}>
        <Grid container item xs={10} direction="row" spacing={2} style={{}}>
            <Grid item>
                <h3>{title}
                <h6>(This will help to improve system)</h6>
                <hr></hr>
                </h3>
            </Grid>

            <Grid item container direction="row" spacing={2} alignItems="center">
                <Grid item xs={2}>
                    Feedback
                </Grid>
                <Grid item>
                <FormControlLabel
                    control={
                    <Radio
                        name="general"
                        value={feedback.option}
                        checked={ (select === CODE)}
                        onChange={(event) => {
                            setSelect(CODE)
                            setFeedback({...feedback, option:'general'})
                        }}
                    />
                    }
                    label="&nbsp;General"
                />
                </Grid>
                <Grid item>
                <FormControlLabel
                    control={
                    <Radio
                        name="test"
                        value={feedback.option}
                        checked={ (select === DESIGN)}
                        onChange={(event) => {
                            setSelect(DESIGN)
                            setFeedback({...feedback, option:'test'})
                        }}
                    />
                    }
                    label="&nbsp;Testing"
                />
                </Grid>
            </Grid>
            <Grid item container direction="row" spacing={2} alignItems="center">
                <Grid item xs={2}>
                    Area of Improvement
                </Grid>
                <Grid item>
                    <TextField style={{width:'12em', height:'50px', padding:'0em'}}
                        select
                        label="Area"
                        variant="outlined"
                        value = {feedback.area}
                        onChange={(event) => {
                            // let temp = {...feedback};
                            // temp.rating = event.target.value;
                            setFeedback({...feedback, area:event.target.value})

                        }}
                        >
                        <MenuItem key={1} value="all">All</MenuItem>
                        <MenuItem key={2} value="home">Home Screen</MenuItem>
                        <MenuItem key={3} value="jobsearch">Job Search Screen</MenuItem>
                        <MenuItem key={4} value="profilesearch">Profile Search Screen</MenuItem>
                        <MenuItem key={5} value="topques">Top Questions Screen</MenuItem>
                        <MenuItem key={6} value="quizzes">Quizzes Screen</MenuItem>
                        <MenuItem key={7} value="resume">Resume Builder Screen</MenuItem>
                        <MenuItem key={8} value="interviewer">Interviewer Screen</MenuItem>
                        <MenuItem key={9} value="test">Test Screen</MenuItem>
                        <MenuItem key={10} value="testquiz">Quiz Test Screen</MenuItem>
                        <MenuItem key={11} value="testskill">Skill Test Screen</MenuItem>
                        <MenuItem key={12} value="testcode">Code Test Screen</MenuItem>
                        <MenuItem key={13} value="testdesign">Design Test Screen</MenuItem>
                        <MenuItem key={14} value="learn">Concept Learning Content</MenuItem>
                        <MenuItem key={15} value="practice">Code Practice Screen</MenuItem>
                        <MenuItem key={16} value="design">Design Editor Screen</MenuItem>
                        <MenuItem key={17} value="prep">Preparation Screen</MenuItem>
                        <MenuItem key={18} value="code">Code Editor Screen</MenuItem>
                        <MenuItem key={19} value="leader">Leaderboard Screen</MenuItem>
                        <MenuItem key={20} value="profile">User Profile Screen</MenuItem>
                        <MenuItem key={21} value="joblist">Recruiter Screen</MenuItem>
                        <MenuItem key={22} value="jobpost">Job Post Screen</MenuItem>
                        
                    </TextField>
                </Grid>
                <Grid item x={1}>
                    {select===1 ? 'Rating' : 'Severity'}
                </Grid>
                <Grid item x={1}>
                    <TextField style={{width:'7em', height:'50px', padding:'0em'}}
                        select
                        label={select===1 ? 'Rating' : 'Severity'}
                        variant="outlined"
                        value = {feedback.rating}
                        onChange={(event) => {
                            setFeedback({...feedback, rating:event.target.value})
                            console.log(event);
                        }}
                        >
                        <MenuItem key={1} value="1">1</MenuItem>
                        <MenuItem key={2} value="2">2</MenuItem>
                        <MenuItem key={3} value="3">3</MenuItem>
                        <MenuItem key={4} value="4">4</MenuItem>
                        <MenuItem key={5} value="5">5</MenuItem>
                    </TextField>
                </Grid>


            </Grid>
            <Grid container item direction="row" spacing={2} alignItems="center">
                <Grid item xs={2}>
                    Title
                </Grid>
                <Grid item xs={4}>
                    <TextField
                        label="Add title"
                        variant="outlined"
                        value = {feedback.title}
                        style = {{width:'100%', minWidth:'45.7em'}}
                        onChange={(event) => {
                            setFeedback({...feedback, title:event.target.value})
                        }}
                        fullwidth >
                    </TextField>
                </Grid>
            </Grid>
            <Grid container item direction="row" spacing={2} alignItems="center">
                <Grid item xs={2}>
                    Details (text\picture)
                </Grid>
                <Grid item xs={10}>
                    <TextEditor height toolbar={true} content={feedback.desc} contentChanged={contentChanged} ></TextEditor>
                </Grid>
            </Grid>
            <Grid container item direction="row" spacing={2} alignItems="center">
                <Grid item xs={2}>
                    
                </Grid>
                <Grid item xs={8}>
                        <Button
                            disabled = {feedback.title ==='' && feedback.desc ===''}
                            variant="contained"
                            color="primary"
                            //#239ce2 , 63AAEA
                            style={{background:'#239ce2', padding: "8px 16px"}}
                            onClick={() =>{postFeedback()}}
                        >
                            {"Post Feedback"}
                        </Button>
                        
                </Grid>
            </Grid>
                
        </Grid>
        <Grid item container xs={10}  direction="row" spacing={0} style={{padding:'0.5em', marginTop:'3em'}}>
                <ViewFeedback type="user"></ViewFeedback>
        
            {/* listFB&&listFB.map( (item, key)=>{
                return <Grid item key={key} style={{background: '#e7ecda', marginBottom:'1em'}}>
                    <Grid style={{background:'lightgray', borderRadius:'10px', border:'1px solid lightgray', padding:'0.5em', marginBottom:'0.5em'}}>
                        <span>Title: {item.title}</span>
                    </Grid>
                    <span>Status: {item.status}</span>
                    <Grid item xs={'auto'} style={{marginTop:'0.5em'}}>
                        <TextEditor readOnly={true} toolbar={false} content={item.desc} ></TextEditor>
                    </Grid>
                </Grid>
            }) */}
        
        </Grid>
    </Grid>
}

export default UserFeedback;


