import { Grid } from "@material-ui/core"
import ThumbnailDiagram from "../blogs/design/ThumbnailDiagram"
import { about, designDemo, sectionDetails, whyJoblient } from "./homeData"
import hiringProcess from '../../assets/img/hiring_process.JPG'
import jlFront from '../../assets/img/jlFront.PNG';
import workflow from '../../assets/img/workflow.jpg';
import hiring from '../../assets/img/hiring.jpg';
import plateformAll from '../../assets/img/plateformAll.jpg';


import EastIcon from '@mui/icons-material/East';


import './front.scss';
import JobSearchBar from "../job/JobSearchBar"
import { setBk, setColor } from "../../utils/colorUtils"
import { useHistory } from "react-router-dom/cjs/react-router-dom.min"
import { isMobile } from "../../utils/mobileUtil"
import { designs } from "../interviewGuide/designs";


const HomePage = () => {
    let history = useHistory();
    const textFont = "text-[17px] md:text-20px]";

    const handleRoute = (location) => {
        console.log(location);
        history.push(location);
    };


    return <Grid container direction="column" style={{ display: 'flex', justifyContent: 'center', }}>
        <Grid className="bg-gray-200">


            {/*platform stating */}

            <div className="md:mx-10 mx-2 py-12 md:py-16 md:flex space-x-0 md:space-x-5  ">
                <div className="flex-col w-full mb-12 md:mb-12  space-y-8">
                    {/* <p className=" font-bold">
                        Platform For Candidate, Recruiters, interviewers
                    </p> */}
                    <p className='text-3xl md:text-6xl text-center mb-6 text-blue-700 font-bold'>Platform For Candidate, Recruiters, interviewers</p>
        
                    <p className='text-[17px] md:text-[20px]'>
                        The most reliable platform for recruiter to get technically
                        pre-assessed, immediate joiners, within budget. By upskilling, a
                        candidate becomes eligible to be included on pre-assessed category
                        and increases their chances of being selected for the role..
                    </p>

                    <div>
                        <button className="bg-yellow-500 text-black px-6 py-3 text-2xl font-bold ">
                            Get Started
                        </button>
                    </div>
                </div>
                <div className="">
                    <img className='h-[400px]' src={plateformAll} />
                </div>
            </div>


            {/*About us */}
            <div className="md:mx-10 mx-2 py-12 md:py-16 md:flex space-x-0 md:space-x-5  ">

                <div className="">
                    <div className='w-full'>
                        <p className='text-3xl md:text-6xl text-center mb-6 text-blue-700 font-bold'>A lil bit about us</p>
                    </div>
                    <img className='w-[1920px]' src={jlFront} />
                </div>
                <div className='bg-white  py-4'>

                    <p className='md:mx-10 mx-2 mb-2 text-[17px] md:text-20px] text-justify'>
                        Our platform offers employers a more efficient way to assess candidates. It eliminates the need to manually read through resumes and conduct many interviews to gauge a candidate's capabilities. Instead, employers can use our platform to assess candidates by simulating the tasks associated with the job role and looking at the performance of the candidate in those tasks. This allows employers to make decisions based on a candidate's actual skills rather
                        than relying on traditional methods which may be more subjective.
                    </p>
                    <p className='md:mx-10 mx-2 mb-2 text-[17px]  md:text-20px] text-justify'>Joblient's model of ranking candidates takes into account not only how frequently they update their profiles, but also how actively they have participated in assessments and learning activities. This is beneficial for recruiters as it allows them to easily
                        pick out the most qualified candidates for further interviewing.
                    </p>
                    <p className='md:mx-10 mx-2 mb-2 text-[17px]  md:text-20px] text-justify'>
                        Joblient's assessment and learning based model helps recruiters to identify the most qualified candidates by considering factors such as the candidate's engagement level on the platform, their performance on assessments, and their willingness to participate in learning activities. Thus, recruiters can easily identify higher-ranking
                        candidates and conduct more tailored interviews.
                    </p>
                </div>
            </div>

            {/* smart hiring*/}

            <div className='  md:mx-10 mx-2 py-12 md:py-16 md:flex  space-x-0 md:space-x-5  '>
                <div className='flex-col w-full md:w-[70%] mb-12 text-justify md:mb-0  space-y-8'>
                    <p className='text-3xl md:text-5xl font-bold' > Smart Hiring System</p>
                    <div className='text-[17px] md:text-20px] flex '>
                        <span className="mr-2 pt-0"><EastIcon /></span>A Smart Hiring System uses algorithms to assess job applicants and predict their potential for success in the role .
                    </div>
                    <div className='text-[17px] md:text-20px] flex '> <span className="mr-2 pt-0"><EastIcon /></span>It looks at factors such as previous experience, qualifications, skills, and performance in interviews to
                        make an informed decision on who to hire .</div>
                    <div className='text-[17px] md:text-20px] flex  '> <span className="mr-2 pt-0"><EastIcon /></span>It is designed to save time and money by streamlining the recruitment process, giving employers access to a larger pool of potential applicants, and providing customizable reporting
                        tools to help employers make data-driven decisions .</div>

                    <div><button className='bg-yellow-500 text-black px-6 py-3 text-2xl font-bold '>Get Started</button></div>
                </div>
                <div className=''>
                    <img className='h-400px md:h-300px' src={hiring} />
                </div>
            </div>



            {/*Integrated Sytem */}
            <div className='bg-white  py'>
                <div className="md:mx-10 mx-2 py-12 md:py-16 md:flex  space-x-0 md:space-x-0  ">
                    <div className="w-full md:w-[50%]">
                        <div className='w-full'>
                            <p className='text-3xl md:text-5xl text-center mb-6 text-blue-700 font-bold'> Joblient Integrated System</p>
                        </div>
                        <img className='h-[400px]' src={workflow} />
                    </div>

                    <div className="flex-col mb-12 md:mb-10 space-y-8">
                        {/* <p className='text-3xl md:text-5xl font-bold' > Joblient Integrated System</p> */}
                        <p className='md:mx-40 mx-0 text-[17px] md:text-20px] text-justify '>
                            This system provides users with a one-stop shop for all their job search needs, including resources for preparing for interviews,
                            creating resumes, and applying for jobs .
                        </p>
                        <p className='md:mx-40 mx-0 text-[17px] md:text-20px] text-justify  '>It also provides a platform for recruiters and interviewers to post jobs, review resumes, and conduct interviews with potential candidates, and
                            receive personalized guidance from experts .</p>
                        <p className='md:mx-40 mx-0 text-[17px] md:text-20px] text-justify '>It is designed to help job seekers quickly find the perfect job
                            opportunity and make the most of any interview ..</p>

                        <div><button className='md:mx-40 mx-0 bg-yellow-500 text-black px-6 py-3 text-2xl font-bold '>Get Started</button></div>
                    </div>
                    {/* <div className=''>
            <img className='' src={workflow}/>
        </div> */}
                </div>
            </div>

            {/*Advanced system design*/}
            <div className='md:mx-10 mx-2 py-12 md:py-16 md:flex  space-x-0 md:space-x-5  '>
                <div className='flex-col w-full text-[17px] md:w-[55%] mb-12 md:mb-0  '>
                    <p className='text-3xl md:text-5xl font-bold mb-8' >  Advanced System Design</p>
                    <p className='mb-2 text-[17px md:text-20px]'>
                        This course is designed to help you develop a strong foundation in design principles and practices, allowing you to create designs with clarity and purpose. You will learn to analyze and solve design problems, create effective system and workflows, and be able to
                        communicate your design decisions to stakeholders.
                    </p>

                    <div className="flex items-center">
                        <span className="mr-2 pt-1"><EastIcon /></span >Learn Design Concepts
                    </div>

                    <div className="flex items-center">
                        <span className="mr-2 pt-1"><EastIcon /></span>Inbuilt Design Editor
                    </div>
                    <div className="flex items-center">
                        <span className="mr-2 pt-1"><EastIcon /></span>Create Design
                    </div>
                    <div className="flex items-center">
                        <span className="mr-2 pt-1"><EastIcon /></span>Practice Existing Designs
                    </div>
                    <div className="flex items-center">
                        <span className="mr-2 pt-1"><EastIcon /></span>Takw Design Test
                    </div>
                    <div className="flex items-center mb-6">
                        <span className="mr-2 pt-1"><EastIcon /></span>Solve Open Design Problems
                    </div>


                    {/* <div><button className='bg-yellow-500 text-black px-6 py-3 text-2xl font-bold '>Get Started</button></div> */}
                </div>
                <div className='md:w-[95%]'>
                    {/* <img className='h-[500px]' src={design}/> */}
                    <div style={{ padding: '0.2rem', background: 'white', marginBottom: '0.3em', cursor: 'pointer', borderRadius: '5px' }}>
                        <ThumbnailDiagram item={designDemo} zoom={0.5} height='420px' width='100%' />
                    </div>
                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                        <a href="#" className="button w-button" onClick={() => handleRoute('systemdesign')}>Get Started</a>
                    </div>
                </div>
            </div>
        </Grid>



        <Grid item xs={12} >
            <div className='bg-black text-white mt-1 md:flex'>
                <div className='py-2 px-2 flex md:w-1/4 border-r-2 justify-between'>
                    <div className='ml-4  text-[17px]'>
                        <p className='font-bold mb-2'>1000+</p>
                        <p className='font-semibold text-gray-600'>Learning Content</p>
                    </div>
                    <div className='text-xl font-bold'>...</div>
                </div>
                <div className='py-2 px-2 md:w-1/4 border-r-2 justify-between flex'>
                    <div className='ml-4  text-[17px]'>
                        <p className='font-bold mb-2'>2000+</p>
                        <p className='font-semibold text-gray-600'>Test Contents</p>
                    </div>
                    <div className='text-xl font-bold'>...</div>
                </div>
                <div className='py-2 px-2 md:w-1/4 border-r-2 justify-between flex '>
                    <div className='ml-4  text-[17px]'>
                        <p className='font-bold mb-2'>100000+</p>
                        <p className='font-semibold text-gray-600'>Jobs</p>
                    </div>
                    <div className='text-xl font-bold'>...</div>
                </div>
                <div className='py-2 px-2 md:w-1/4 border-r-2 justify-between flex'>
                    <div className='ml-4  text-[17px]'>
                        <p className='font-bold mb-2'>10000+</p>
                        <p className='font-semibold text-gray-600'>Candidates</p>
                    </div>
                    <div className='text-xl font-bold'>...</div>
                </div>
            </div>
        </Grid>
    </Grid>

    // #1c4980
    //     return  (
    //         <div className="">


    //               <div className='md:mx-20 mx-2 py-12 md:py-16 md:flex  space-x-0 md:space-x-5  '>
    //         <div className='flex-col w-full md:w-[55%] mb-12 md:mb-0  space-y-8'>
    //             <p className='text-3xl md:text-5xl font-bold' > Joblient Integrated System</p>
    //             <p className='text-[17px] text-justify '>
    //             This system provides users with a one-stop shop for all their job search needs, including resources for preparing for interviews,
    //              creating resumes, and applying for jobs .
    //             </p>
    //             <p className='text-[17px] text-justify  '>It also provides a platform for recruiters and interviewers to post jobs, review resumes, and conduct interviews with potential candidates, and 
    //                 receive personalized guidance from experts .</p>
    //             <p className='text-[17px] text-justify '>It is designed to help job seekers quickly find the perfect job 
    //             opportunity and make the most of any interview ..</p>

    //             <div><button className='bg-yellow-500 text-black px-6 py-3 text-2xl font-bold '>Get Started</button></div>
    //         </div>
    //         <div className=''>
    //             <img className='' src={workflow}/>
    //         </div>
    //         </div>







    // </div>) 

    // return <Grid container direction="column" alignItems="center" justifyContent="center" style={{width:'90%', margin:'5%'}}>  


    //     {/*1. WHY JOBLIENT?, A SYSTEM FOR ALL TOGETHER */}
    //      <Grid item  container direction="row" style={{marginTop:'5em'}}>


    //         <Grid item xs={12}>
    //             <h2 className="headfirst">
    //                 <span className="span-firsthead">Platform For</span> Candidate, Recruiters, Interviewer

    //             </h2>

    //         </Grid>
    //         <Grid item xs={isMobile?'auto':3} style={{marginBottom:'2em', marginRight:'2em'}}>
    //             <span className="span-head-details">
    //                 <p>{about[0]}
    //                 </p>
    //                 <p>
    //                 {about[1]}</p>
    //             </span>
    //         </Grid>
    //         <Grid item xs={'auto'} className="bannerpart">
    //             <div style={{marginLeft:'0em', height:'100%', verticalAlign:'center'}}>
    //                 <img width={'880px'} height='auto' src={jlFront}/>
    //             </div>
    //             <div className="x_33256906" style={{textAlign:'center', fontSize:'36px'}}>Joblient is the bridge between candidate and recruiter</div>                  

    //         </Grid>


    //         <Grid item xs={12} style={{marginTop:'10em'}}>
    //             <h1 >
    //                 {/* <span className="x_576e2b6f">Why Joblient?</span> */}
    //                 <div className="heading-why">
    //                     <div style={{height:'1.5em', borderLeft: '2px solid smoked'}}>&nbsp;&nbsp;A lil bit about us</div>
    //                 </div>
    //             </h1>
    //             <div style={{}}>
    //                     <p>{whyJoblient[0]}</p>
    //                     <p>{whyJoblient[1]}</p>
    //                     <p>{whyJoblient[2]}</p>
    //             </div>
    //         </Grid>

    //         <Grid item xs={12} style={{marginTop:'5em'}}>
    //             <h1 className="element-168">
    //                 <span className="x_576e2b6f">Joblient Integrated System</span>
    //                 <hr></hr>
    //                 <div className="x_33256906">For All Together</div>
    //             </h1>
    //             <div className="leafygreen-ui-11oupyp" style={{width:'90%'}}>
    //                 <div className="leafygreen-ui-1fuktk9">
    //                     <h3 className="leafygreen-ui-4rvd2s" >

    //                         <ul style={{display:'flex', padding:'5px', listStyle:'none'}}>
    //                                         {sectionDetails[0].split('.').map((line, key)=>{
    //                                     return line.trim()!=""&&<li key={key} style = {setColor(key)}>{line} {'.'}</li>
    //                             })}
    //                         </ul>
    //                     </h3>
    //                 </div>
    //             </div>
    //         </Grid>
    //         <Grid item xs={'auto'} >
    //             <div style={{display:'flex'}}>
    //                 <h1 className="element-168">
    //                     <ol style={{marginLeft:'1em',minWdth:'6em', marginTop:'1em'}}>
    //                         <li>Intern</li>
    //                         <li>Freshers</li>
    //                         <li>Job Seekers</li>
    //                         <li>Experience</li>
    //                         <li>Recruiter</li>
    //                         <li>Interviewer</li>
    //                         <li>Learn &#x2192; Prepare &#x2192; Challange</li>
    //                     </ol>
    //                 </h1>
    //                 <div style={{marginLeft:'10em', height:'100%', verticalAlign:'center'}}>
    //                  <img width='400px' height='400px' src={systemForAll}/>
    //                </div>
    //             </div>
    //         </Grid>

    //         <Grid item xs={12} container direction="row" style={{paddingTop:'0em', minHeight:'30vh'}}></Grid>
    //     </Grid>




    //     {/*2. SMART HIRING  SYSTEM */}


    //     <Grid item  container direction="row" style={{marginTop:'0em'}}>
    //         <Grid item xs={12} >
    //             <h1 className="element-168">
    //                 <span className="x_576e2b6f">Smart Hiring System</span>
    //                 <hr></hr>
    //                 <div className="x_33256906">High Selection Rate</div>
    //             </h1>
    //             <div className="leafygreen-ui-11oupyp" style={{width:'90%'}}>
    //                 <div className="leafygreen-ui-1fuktk9">
    //                     <h3 className="leafygreen-ui-4rvd2s" >
    //                         <ul style={{display:'flex', padding:'5px', listStyle:'none'}}>
    //                             {sectionDetails[1].split('.').map((line, key)=>{
    //                             return line.trim()!=""&&<li key={key} style = {setColor(key)}>{line} {'.'}</li>
    //                         })}
    //                         </ul>
    //                     </h3>
    //                 </div>
    //             </div>
    //         </Grid>
    //         <Grid item xs={'auto'} >
    //             <div style={{display:'flex'}}>
    //                 <h1 className="element-168">
    //                     <ol style={{marginTop:'1em'}}>
    //                         <li>Find High Ranked Candidate</li>
    //                         <li>Reduce Screening Time</li>
    //                         <li>Search Candidate From Leaderboard...</li>
    //                         <li>Find Already Qualified Candidates From Pool</li>
    //                         <li>Track And Analyze Candidate Assessments, Reports</li>
    //                         <li>Post New Job</li>
    //                         <li>Use ATS Enabled System</li>
    //                     </ol>
    //                 </h1>
    //                 <div style={{height:'100%'}}>
    //                     <img width={'auto'} src={hiringProcess}></img>
    //                 </div>

    //             </div>
    //         </Grid>
    //         {/* <Grid item xs={10} style={{}}>
    //             <img width='100%' src = {profileSearch}></img>
    //         </Grid> */}
    //         <Grid item xs={12} container direction="row" style={{minHeight:'30vh'}}></Grid>
    //     </Grid>




    //     {/*3. Competitive Learning System */}

    //     <Grid item  container direction="row" style={{marginTop:'0em'}}>
    //         <Grid item xs={12} >
    //             <h1 className="element-168">
    //                 <span className="x_576e2b6f">Competitive Learning System</span>
    //                 <hr></hr>
    //                 <div className="x_33256906">Job Seekers</div>
    //             </h1>
    //             <div className="leafygreen-ui-11oupyp" style={{width:'90%'}}>
    //                 <div className="leafygreen-ui-1fuktk9">
    //                     <h3 className="leafygreen-ui-4rvd2s" >

    //                         <ul style={{display:'flex', padding:'5px', listStyle:'none'}}>
    //                                 {sectionDetails[2].split('.').map((line, key)=>{
    //                             return line.trim() != "" && <li key={key} style = {setColor(key)}>{line} {'.'}</li>
    //                         })}
    //                         </ul>
    //                     </h3>
    //                 </div>
    //             </div>
    //         </Grid>
    //         <Grid item xs={12} >
    //             <div style={{display:'flex'}}>
    //                 <h1 className="element-168">
    //                     <ol style={{marginTop:'1em'}}>
    //                         <li>Up&nbsp;&#8593; Position in Leaderboard</li>
    //                         <li>Search Job...</li>
    //                         <li>Get Auto Course Recommendations From Job Posting</li>
    //                         <li>Prepare From Interviewer Feedback</li>
    //                         <li>Take Code Test</li>
    //                         <li>Get Directly Recognize By Recruiters</li>
    //                         <li>Reduce Direct Interviews, Go First Mock Test</li>
    //                         <li>Build ATS Recognize Profile</li>
    //                         <li>Personalize Your Assessments</li>
    //                         <li>Apply Job</li>
    //                         <li>Interview</li>
    //                     </ol>
    //                 </h1>
    //                 <div style={{marginLeft:'25%', marginTop:'15%', height:'100%'}}>
    //                     {/* <JobSearchBar/> */}
    //                 </div>
    //                 {/* <div style={{minHeight:'30vh'}}></div> */}
    //             </div>

    //         </Grid>

    //     </Grid>




    //     {/*4. Competitive Learning System */}

    //     <Grid item  container direction="row" style={{marginTop:'20em'}}>
    //         <Grid item xs={12} >
    //             <h1 className="element-168">
    //                 <span className="x_576e2b6f">Joblient Interview Plateform</span>
    //                 <hr></hr>
    //                 <div className="x_33256906">Candidate Interview Made Easy!</div>
    //             </h1>
    //             <div className="leafygreen-ui-11oupyp" style={{width:'90%'}}>
    //                 <div className="leafygreen-ui-1fuktk9">
    //                     <h3 className="leafygreen-ui-4rvd2s" >

    //                         <ul style={{display:'flex',padding:'5px', listStyle:'none'}}>
    //                                 {sectionDetails[3].split('.').map((line, key)=>{
    //                             return line.trim() != "" && <li key={key} style = {setColor(key)}>{line} {'.'}</li>
    //                         })}
    //                         </ul>
    //                     </h3>
    //                 </div>
    //             </div>
    //         </Grid>
    //         <Grid item xs={12} >
    //             <div style={{display:'flex'}}>
    //                 <h1 className="element-168">
    //                     <ol style={{marginTop:'1em'}}>
    //                         <li>Create Interview Link</li>
    //                         <li>Create Code Template</li>
    //                         <li>Create Design Template</li>
    //                         <li>Share Interview Link</li>
    //                         <li>Start Interview Process</li>
    //                         <li>View Candidate Report</li>
    //                         <li>Share Report</li>
    //                     </ol>
    //                 </h1>
    //                 <div style={{marginLeft:'25%', marginTop:'15%'}}>
    //                     {/* <JobSearchBar/> */}
    //                 </div>
    //                 </div>
    //         </Grid>
    //         <Grid item xs={12} container direction="row" style={{paddingTop:'0em', minHeight:'30vh'}}></Grid>
    //     </Grid>


    //     <Grid item  container direction="row" style={{marginTop:'0em'}}>
    //         <Grid item xs={12} >
    //                 <h1 className="element-168">
    //                     <span className="x_576e2b6f">Joblient Advanced System Design</span>
    //                     <hr></hr>
    //                     <div className="x_33256906">Up! Design Skills</div>
    //                 </h1>

    //                 <div className="leafygreen-ui-11oupyp" style={{width:'90%'}}>
    //                     <div className="leafygreen-ui-1fuktk9">
    //                         <h3 className="leafygreen-ui-4rvd2s">
    //                             {sectionDetails[4]}
    //                         </h3>
    //                     </div>
    //                 </div>
    //         </Grid>

    //         <Grid item xs={3} >
    //             <h1>
    //                 <div style={{display:'flex'}}>
    //                     <ol style={{marginLeft:'1em', fontSize:`${isMobile?'1em':'0.5em'}`}}>
    //                         <li>Learn Design Concepts</li>
    //                         <li>Inbuilt Design Editor</li>
    //                         <li>Create Design </li>
    //                         <li>Practice Existing Designs</li>
    //                         <li>Take Design Test</li>
    //                         <li>Solve Open Design Problems</li>
    //                     </ol>
    //                 </div>
    //             </h1>
    //         </Grid>
    //         <Grid item xs={9}>
    //             <div style={{padding:'0.2rem', marginBottom:'0.3em', cursor:'pointer',borderRadius:'5px'}}>
    //                 <ThumbnailDiagram item = {designDemo} zoom={0.5} height='420px' width='100%'/>
    //             </div>
    //             <div style={{display:'flex', justifyContent:'center'}}>
    //             <a  href="#" className="button w-button" onClick={()=>handleRoute('systemdesign')}>Get Started</a>
    //             </div>
    //         </Grid>

    //     </Grid>
    //     <Grid item xs={12} style={{marginTop:'5em', width:'100%'}}>
    //         <hr></hr>
    //     </Grid>
    //     <Grid item xs={12} style={{marginTop:'3em'}}>

    //             <h2 className="headfirst">
    //                 <span className="span-firsthead">Learning Content</span> 1000+
    //             </h2>
    //             <h2 className="headfirst">
    //                 <span className="span-firsthead">Test Content</span> 2000+
    //             </h2>
    //             <h2 className="headfirst">
    //                 <span className="span-firsthead">Jobs</span> 100000+
    //             </h2>
    //             <h2 className="headfirst">
    //                 <span className="span-firsthead">Candidates</span> 10000+
    //             </h2>
    //     </Grid>
    // </Grid>

}

export default HomePage