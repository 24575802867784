
import { render } from 'react-dom';
import './index.css';
import * as React from "react";
import { updateSampleSection } from './sample-base';
import { DiagramComponent, SymbolPaletteComponent, Node, PrintAndExport } from "@syncfusion/ej2-react-diagrams";
import { useDispatch, useSelector } from 'react-redux';
import { useState } from 'react';
import { activeInterview, fetchWorkspaceSuccess, selectCourseItem, setWorkSpaceItems } from '../../../state/action-creators/workspace';
import TextEditor from '../editor-text/TextEditor';
import { Collapse, ListItem, ListItemText } from '@material-ui/core';
import { ExpandLess, ExpandMore } from '@material-ui/icons';
import { connectToServer, wsConnect } from '../../../config/wssClient';
import { useLocation } from 'react-router-dom';
import { loadWorkspace } from '../../../utils/datautil/dataUtils';
import { API, saveData } from '../../../config/apiService';
import { SetPopupContext } from '../../home/component/MainRouter';
import {nodes, connectors, getNodeDefaults, getConnectorDefaults, dragEnter, updateShapes, shapePaletters} from './ShapeUtil'
import { designNew } from '../../blogs/design/DefaultData';

//import { connectToServer, send, wsConnect, wssInit } from '../../../config/wssClient';

/**
 * Diagram Default sample
 */
//Initializes the nodes for the diagram
// const defaultNodes = [
//     {
//         id: "WebUI",
//         width:60,
//         height: 100,
//         offsetX: 70,
//         offsetY: 200,
//         shape: { type: "Flow", shape: "Process" },
//         annotations: [
//             {
//                 content: "Web UI"
//             }
//         ]
//     },
//     {
//         id: "APIGateway",
//         height: 100,
//         width:100,
//         offsetX: 220,
//         offsetY: 200,
//         shape: { type: "Flow", shape: "Decision" },
//         annotations: [
//             {
//                 content: "API Gateway"
//             }
//         ]
//     },
//     {
//         id: "Service",
//         width:100,
//         height: 60,
//         offsetX: 400,
//         offsetY: 200,
//         shape: { type: "Flow", shape: "Data" },
//         annotations: [
//             {
//                 content: "Service"
//             }
//         ]
//     },
//     {
//         id: "DB",
//         height: 70,
//         width: 60,
//         offsetX: 550,
//         offsetY: 200,
//         shape: { type: "Flow", shape: "DirectData" },
//         annotations: [
//             {
//                 content: "DB"
//             }
//         ]
//     },
// ]

// const defaultConnectors = [
//     {
//         id: "connector1",
//         sourceID: "WebUI",
//         targetID: "APIGateway"
//     },
//     { id: "connector2", sourceID: "APIGateway", targetID: "Service" },
//     { id: "connector3", sourceID: "Service", targetID: "DB" }
// ]

// let nodes = [
//     {
//         id: "NewIdea",
//         height: 60,
//         offsetX: 300,
//         offsetY: 80,
//         shape: { type: "Flow", shape: "Terminator" },
//         annotations: [
//             {
//                 content: "Place Order"
//             }
//         ]
//     },
//     {
//         id: "Meeting",
//         height: 60,
//         offsetX: 300,
//         offsetY: 160,
//         shape: { type: "Flow", shape: "Process" },
//         annotations: [
//             {
//                 content: "Start Transaction"
//             }
//         ]
//     },
//     {
//         id: "BoardDecision",
//         height: 60,
//         offsetX: 300,
//         offsetY: 240,
//         shape: { type: "Flow", shape: "Process" },
//         annotations: [
//             {
//                 content: "Verification"
//             }
//         ]
//     },
//     {
//         id: "Project",
//         height: 60,
//         offsetX: 300,
//         offsetY: 330,
//         shape: { type: "Flow", shape: "Decision" },
//         annotations: [
//             {
//                 content: "Credit card valid?"
//             }
//         ]
//     },
//     {
//         id: "End",
//         height: 60,
//         offsetX: 300,
//         offsetY: 430,
//         shape: { type: "Flow", shape: "Decision" },
//         annotations: [
//             {
//                 content: "Funds available?"
//             }
//         ]
//     },
//     {
//         id: "node11",
//         height: 60,
//         offsetX: 545,
//         offsetY: 330,
//         shape: { type: "Flow", shape: "Process" },
//         annotations: [
//             {
//                 content: "Enter payment method"
//             }
//         ]
//     },
//     {
//         id: "transaction_entered",
//         height: 60,
//         offsetX: 300,
//         offsetY: 630,
//         shape: { type: "Flow", shape: "Terminator" },
//         annotations: [
//             {
//                 content: "Log transaction"
//             }
//         ]
//     },
//     {
//         id: "node12",
//         height: 60,
//         offsetX: 480,
//         offsetY: 630,
//         shape: { type: "Flow", shape: "Process" },
//         annotations: [
//             {
//                 content: "Reconcile the entries"
//             }
//         ]
//     },
//     {
//         id: "transaction_completed",
//         height: 60,
//         offsetX: 300,
//         offsetY: 530,
//         shape: { type: "Flow", shape: "Process" },
//         annotations: [
//             {
//                 content: "Complete Transaction"
//             }
//         ]
//     },
//     {
//         id: "Data",
//         height: 45,
//         offsetX: 110,
//         offsetY: 530,
//         shape: { type: "Flow", shape: "Data" },
//         annotations: [
//             {
//                 content: "Send e-mail",
//                 margin: { left: 25, right: 25 }
//             }
//         ]
//     },
//     {
//         id: "node10",
//         height: 70,
//         offsetX: 475,
//         offsetY: 530,
//         shape: { type: "Flow", shape: "DirectData" },
//         annotations: [
//             { content: "Customer Database", margin: { left: 25, right: 25 } }
//         ]
//     }
// ];
//Initializes the connector for the diagram
// let connectors = [
//     {
//         id: "connector1",
//         sourceID: "NewIdea",
//         targetID: "Meeting"
//     },
//     { id: "connector2", sourceID: "Meeting", targetID: "BoardDecision" },
//     { id: "connector3", sourceID: "BoardDecision", targetID: "Project" },
//     {
//         id: "connector4",
//         sourceID: "Project",
//         annotations: [{ content: "Yes", style: { fill: "white" } }],
//         targetID: "End"
//     },
//     {
//         id: "connector5",
//         sourceID: "End",
//         annotations: [{ content: "Yes", style: { fill: "white" } }],
//         targetID: "transaction_completed"
//     },
//     {
//         id: "connector6",
//         sourceID: "transaction_completed",
//         targetID: "transaction_entered"
//     },
//     { id: "connector7", sourceID: "transaction_completed", targetID: "Data" },
//     { id: "connector8", sourceID: "transaction_completed", targetID: "node10" },
//     {
//         id: "connector9",
//         sourceID: "node11",
//         targetID: "Meeting",
//         segments: [{ direction: "Top", type: 'Orthogonal', length: 120 }]
//     },
//     {
//         id: "connector10",
//         sourceID: "End",
//         annotations: [{ content: "No", style: { fill: "white" } }],
//         targetID: "node11",
//         segments: [{ direction: "Right", type: 'Orthogonal', length: 100 }]
//     },
//     {
//         id: "connector11",
//         sourceID: "Project",
//         annotations: [{ content: "No", style: { fill: "white" } }],
//         targetID: "node11"
//     },
//     {
//         id: "connector12",
//         style: { strokeDashArray: "2,2" },
//         sourceID: "transaction_entered",
//         targetID: "node12"
//     }
// ];
//Initialize the flowshapes for the symbol palatte
let flowshapes = [
    { id: "Terminator", shape: { type: "Flow", shape: "Terminator" } },
    { id: "Text", shape: {
        type: 'Text',
        content: 'Text'
        },
        style: {
            strokeColor: 'none',
            textAlign: 'Center'
        }

    },
    { id: "Process", shape: { type: "Flow", shape: "Process" } },
    { id: "Decision", shape: { type: "Flow", shape: "Decision" } },
    { id: "Document", shape: { type: "Flow", shape: "Document" } },
    {
        id: "PreDefinedProcess",
        shape: { type: "Flow", shape: "PreDefinedProcess" }
    },
    { id: "PaperTap", shape: { type: "Flow", shape: "PaperTap" } },
    { id: "DirectData", shape: { type: "Flow", shape: "DirectData" } ,
    style: {
        strokeColor: 'none',
        fill: 'none',
        color: 'black',
        textAlign: 'Center',
        transform: 'rotate(180deg)'
    }
},
    { id: "SequentialData", shape: { type: "Flow", shape: "SequentialData" } },
    { id: "Sort", shape: { type: "Flow", shape: "Sort" } },
    { id: "MultiDocument", shape: { type: "Flow", shape: "MultiDocument" } },
    { id: "Collate", shape: { type: "Flow", shape: "Collate" } },
    // { id: "SummingJunction", shape: { type: "Flow", shape: "SummingJunction" } },
    // { id: "Or", shape: { type: "Flow", shape: "Or" } },
    // { id: "InternalStorage", shape: { type: "Flow", shape: "InternalStorage" } },
    // { id: "Extract", shape: { type: "Flow", shape: "Extract" } },
    // { id: "ManualOperation", shape: { type: "Flow", shape: "ManualOperation" } },
    // { id: "Merge", shape: { type: "Flow", shape: "Merge" } },
    // {
    //     id: "OffPageReference",
    //     shape: { type: "Flow", shape: "OffPageReference" }
    // },
    // {
    //     id: "SequentialAccessStorage",
    //     shape: { type: "Flow", shape: "SequentialAccessStorage" }
    // },
    // { id: "Annotation", shape: { type: "Flow", shape: "Annotation" } },
    // { id: "Annotation2", shape: { type: "Flow", shape: "Annotation2" } },
    // { id: "Data", shape: { type: "Flow", shape: "Data" } },
    // { id: "Card", shape: { type: "Flow", shape: "Card" } },
    // { id: "Delay", shape: { type: "Flow", shape: "Delay" } }
];
//Initializes connector symbols for the symbol palette
let connectorSymbols = [
    {
        id: "Link1",
        type: "Orthogonal",
        sourcePoint: { x: 0, y: 0 },
        targetPoint: { x: 60, y: 60 },
        targetDecorator: { shape: "Arrow", style: { strokeColor: '#757575', fill: '#757575' } },
        style: { strokeWidth: 1, strokeColor: '#757575' }
    },
    {
        id: "link3",
        type: "Orthogonal",
        sourcePoint: { x: 0, y: 0 },
        targetPoint: { x: 60, y: 60 },
        style: { strokeWidth: 1, strokeColor: '#757575' },
        targetDecorator: { shape: "None" },
    },
    {
        id: "Link21",
        type: "Straight",
        sourcePoint: { x: 0, y: 0 },
        targetPoint: { x: 60, y: 60 },
        targetDecorator: { shape: "Arrow", style: { strokeColor: '#757575', fill: '#757575' } },
        style: { strokeWidth: 1, strokeColor: '#757575' }
    },
    {
        id: "link23",
        type: "Straight",
        sourcePoint: { x: 0, y: 0 },
        targetPoint: { x: 60, y: 60 },
        style: { strokeWidth: 1, strokeColor: '#757575' },
        targetDecorator: { shape: "None" }
    },
    {
        id: "link33",
        type: "Bezier",
        sourcePoint: { x: 0, y: 0 },
        targetPoint: { x: 60, y: 60 },
        style: { strokeWidth: 1, strokeColor: '#757575' },
        targetDecorator: { shape: "None" }
    }
];
let interval;
interval = [
    1,
    9,
    0.25,
    9.75,
    0.25,
    9.75,
    0.25,
    9.75,
    0.25,
    9.75,
    0.25,
    9.75,
    0.25,
    9.75,
    0.25,
    9.75,
    0.25,
    9.75,
    0.25,
    9.75
];
let gridlines = {
    lineColor: "#e0e0e0",
    lineIntervals: interval
};
let diagramInstance;
const ShapeCustom = ()=> {
    const dispatch = useDispatch();
    const setPopup = React.useContext(SetPopupContext);
    let workspace = useSelector(state=>state.workspace);
    const token = useSelector(state=>state.profile.profile.token);
    let current = workspace.selected;
    let selected = workspace.items[current.index];
    let activeIVW = workspace.activeIVW;

    const [open, setOpen] = useState(true);
    const [message, setMessage] = useState();
    const location = useLocation();
    const wsRef = React.useRef();

    // const [draw, setDraw]= useState({nodes: !selected.draw? defaultNodes : selected.draw.nodes, 
    //     connectors: !selected.draw? defaultConnectors : selected.draw.connectors});
    const [draw, setDraw]= useState({nodes: !selected.draw? designNew.draw.nodes : selected.draw.nodes, 
    connectors: !selected.draw? designNew.draw.connectors : selected.draw.connectors});
        

    const [details, setDetails] = useState(selected.details || '');
       // console.log('selected.draw.connectors:',  selected.draw && selected.draw.connectors)

     React.useEffect(() => {
            
            updateSampleSection();
            rendereComplete();

            let urlParams = location.pathname.split(':');
            let _id;

            if(urlParams.length>1) {
            //console.log('urlParams:', urlParams);
                _id= atob(urlParams[1]);
            }

            if (_id) { // have valid session id, enable socket connection
                if (!wsRef.current) { // new WebSocket('ws://localhost:7071/ws');
                  
                    connectToServer().then(ws=>{
                        wsRef.current = ws;
                        let msg = JSON.stringify({init: true, s: activeIVW.session.substring(0,6), u:activeIVW.user.substring(0,1)});
                        wsRef.current.send(msg);
                        if (wsRef.current)  {
                                    // if (!wsRef.current) {
                                    //     wsRef.current = new WebSocket('ws://localhost:7071/ws');
                                    // }
                                    console.log("connected  draw:::", draw);
                                    wsRef.current.onopen = () => {
                                        console.log('connected')
                                    }
                                
                                    wsRef.current.onmessage = async(msg) => {
                                        const payload = JSON.parse(msg.data)
                                        console.log('received:', payload)
                                        setDraw({nodes: [...payload.nodes], connectors: [...payload.connectors]});
                                        //dispatch(setWorkSpaceItems(temp));
                                    }
                                
                                    wsRef.current.onclose = () => {
                                        console.log('closing connection');
                                        if(wsRef.current != null) {
                                            wsRef.current.close()
                                            alert('session closed');
                                        }
                                    }
                                
                            }
                        })
                    } else {
                        console.log('stablished socket connection')
                    }
                
                    return () => {
                        console.log('This will be logged on unmount');
                        if(wsRef.current) {
                            wsRef.current.close();
                            wsRef.current = null;
                        }
                        dispatch(activeInterview(null));
                    }
            } else {
                console.log('no socket required single mode');
                return () => {}
            }



      }, []);
    //console.log('selected123', selected)
    const rendereComplete = ()=> {
        addEvents();
        diagramInstance.fitToPage();
    }

    const saveWorkspace = (status)=>{
       
        updateDraw();
        let data = {};
        data = {
          _id: selected._id,
          category: selected.category,
          group: selected.group,
          title: selected.title,
          desc: selected.desc,
          draw: draw,
          details: details,
          status: status
        }
        console.log("save workspace data: ", data)
        saveData(API.workspace, data, selected.category, setPopup);
        loadWorkspace(dispatch);
      }
    
    const updateDraw = ()=>{
        let curDraw = updateShapes(diagramInstance);
        // let save = JSON.parse(diagramInstance.saveDiagram());
      
        // //console.log('save:' , save.connectors)
        //     let nn = save.nodes.map( (item, key)=>{ 
        //         return {
        //             id: item.id,
        //             width: item.width,
        //             height: item.height,
        //             offsetX: item.offsetX,
        //             offsetY: item.offsetY,
        //             shape: item.shape,
        //             annotations: [{content: item.annotations[0].content}]
        //     }})
        //     let cc = save.connectors.map( (con, key)=>{ 
        //        // console.log('conn:', conn);
        //         let temp = {
        //         id: con.id,
        //         sourceID: con.sourceID,
        //         targetID: con.targetID,
        //         sourcePoint: con.sourcePoint,
        //         targetPoint: con.targetPoint,
        //         type: con.type,
        //         sourcePortID: con.sourcePortID,
        //         targetPortID: con.targetPortID,
        //      };
        //      if (con.annotations.length > 0){
        //        // console.log('con.annotations[0]:', con.annotations)
        //         temp['annotations'] = [{content : con.annotations[0].content, style:{color:'red', zIndex:10, backgroundColor:'yellow'}}];
        //      }
        //      return temp;
            
        //     })
        //let cc = [...save.connectors];
        let temp = [...workspace.items];
        //temp[current.index].draw = {nodes:nn, connectors:cc};
        console.log('Save shape: ', curDraw)
        temp[current.index].draw = curDraw;
        dispatch(setWorkSpaceItems(temp));
        //setDraw({nodes:nn, connectors:cc});
        //setDraw({nodes:save.nodes, connectors:save.connectors});
        
        // FOR TIME BEING SOCKET HOLD, BUT IT WAS WORKING FULLY IN LOCAL SYSTEM
        // if (wsRef.current && activeIVW) {
        //     let msg = JSON.stringify({s:activeIVW.session.substring(0,6), u:activeIVW.user.substring(0,1) , nodes:curDraw.nodes, connectors:curDraw.connectors});
        //     console.log("message send to socket: ");
        //     wsRef.current.send(msg);
        // }

       // console.log("WSSSSS:", ws);
        //ws && ws.send(JSON.stringify(messageBody));
       // console.log('shape has updated', newMenu);
    }

    const mouseUp = ()=>{
        console.log('mouse up')
        updateDraw();
    }
    const exportPDF = ()=>{
       // PrintAndExport(diagramInstance);


    }
        return (<div className="control-pane" style={{width: "100%", backgroundColor: "white"}}>
        {/* <TextEditor  toolbar={false} content={selected.desc} readOnly={true}/> */}
                <ListItem style={{fontSize:'6px'}} button onClick={()=>setOpen(!open)}>
                <ListItemText>
                    <div className="menu" style={{marginLeft:'-1em'}}><span style={{fontWeight:'bold', fontSize:'1.2em'}} >{selected.title}</span></div>
                </ListItemText> 
                    {open ? <ExpandLess/> : <ExpandMore/>}
                </ListItem>
                <Collapse in={open} timeout="auto" unmountOnExit>
                {selected ? <TextEditor  toolbar={false} content={selected.desc} readOnly={true}></TextEditor>:<></>}
                </Collapse>
        <div className="control-section">
          <div style={{ width: "100%" }}>
            <div className="sb-mobile-palette-bar">
              <div id="palette-icon" style={{ float: "right", role: "button" }} className="e-ddb-icons1 e-toggle-palette"></div>
            </div>
            <div id="palette-space" className="sb-mobile-palette">
              {/* <SymbolPaletteComponent id="symbolpalette" expandMode="Multiple" palettes={[
            {
                id: "flow",
                expanded: true,
                symbols: flowshapes,
                iconCss: "e-diagram-icons1 e-diagram-flow",
                title: "Flow Shapes"
            },
            {
                id: "connectors",
                expanded: true,
                symbols: connectorSymbols,
                iconCss: "e-diagram-icons1 e-diagram-connector",
                title: "Connectors"
            }
        ]} width={"100%"} height={"700px"} symbolHeight={60} symbolWidth={60} getNodeDefaults={(symbol) => {
            if (symbol.id === "Terminator" ||
                symbol.id === "Process" ||
                symbol.id === "Delay") {
                symbol.width = 80;
                symbol.height = 40;
            }
            else if (symbol.id === "Decision" ||
                symbol.id === "Document" ||
                symbol.id === "PreDefinedProcess" ||
                symbol.id === "PaperTap" ||
                symbol.id === "DirectData" ||
                symbol.id === "MultiDocument" ||
                symbol.id === "Data") {
                symbol.width = 50;
                symbol.height = 40;
            }
            else {
                symbol.width = 50;
                symbol.height = 50;
            }
            symbol.style.strokeColor = '#757575';
        }} symbolMargin={{ left: 15, right: 15, top: 15, bottom: 15 }} getSymbolInfo={(symbol) => {
            return { fit: true };
                }}/> */}
                {/* <Grid item xs={3} style={{width:"200px" , height:'700px'}}> */}
                    {shapePaletters() }
                {/* </Grid> */}
            </div>
            <div id="diagram-space" className="sb-mobile-diagram" onMouseUp={()=>mouseUp()} onKeyUp={()=>updateDraw()} >
            <div style={{display:'inline-flex'}}>
                {token ?
                <div>
                    <button type='button' className="btnLow" style={{margin:'1em'}} onClick={()=>saveWorkspace('InProgress')}>Save</button>
                    <button type='button' className="btnLow" style={{margin:'1em'}} onClick={()=>saveWorkspace('Completed')}>Submit</button>
                </div>
                :<></>
                }    
            </div>
            {/* <DiagramComponent  ref={diagram => (diagramInstance = diagram)} width={"100%"} height={"700px"} snapSettings={{
                horizontalGridlines: gridlines,
                verticalGridlines: gridlines
            }} 
            nodes={nodes(draw)} 
            connectors = {connectors(draw)}
            getNodeDefaults={(node) => {
                let obj = {};
                if (obj.width === undefined) {
                    obj.width = 145;
                }
                else {
                    let ratio = 100 / obj.width;
                    obj.width = 100;
                    obj.height *= ratio;
                }
                obj.style = { fill: "white", strokeColor: "black" };
                obj.annotations = [
                    { style: { color: "white", fill: "transparent" } }
                ];
                obj.ports = getPorts(node);
                return obj;
            }} 
            getConnectorDefaults={(obj) => {
                if (obj.id.indexOf("connector") !== -1) {
                    obj.type = "Orthogonal";
                    obj.targetDecorator = {
                        shape: "Arrow",
                        width: 10,
                        height: 10
                    };
                }
            }} 
            dragEnter={(args) => {
            let obj = args.element;
            if (obj instanceof Node) {
                let oWidth = obj.width;
                let oHeight = obj.height;
                let ratio = 100 / obj.width;
                obj.width = 100;
                obj.height *= ratio;
                obj.offsetX += (obj.width - oWidth) / 2;
                obj.offsetY += (obj.height - oHeight) / 2;
                obj.style = { fill: "#357BD2", strokeColor: "white" };
            }
            }} /> */}
            <DiagramComponent  ref={diagram => (diagramInstance = diagram)} width={"100%"} height={"700px"} style={{overflow:'hidden'}} snapSettings = {{
                horizontalGridlines: gridlines,
                verticalGridlines: gridlines
                }} 
                nodes = {nodes((draw.nodes))} 
                connectors = {connectors((draw.connectors))}
                getNodeDefaults = {(node) => getNodeDefaults(node)}
                getConnectorDefaults = {(obj) => getConnectorDefaults(obj)}
                dragEnter = {(args) =>dragEnter(args)}
                created={() => {
                    // Sets the zoomFactor
                    //Defines the focusPoint to zoom the Diagram with respect to any point
                    //When you do not set focus point, zooming is performed with reference to the center of current Diagram view.
                    diagramInstance.zoom(1, {
                    x: 10,
                    y: 10,
                    });
                }}
            />
            <div>
                <div className="menu" style={{marginLeft:'0em', marginTop:'2em'}}><span style={{fontWeight:'bold', fontSize:'1.2em'}} >Detailed Design:</span></div>
                {selected ? <TextEditor  toolbar={true} content={details} contentChanged={(value)=>setDetails(value)}></TextEditor>:<></>}
            </div>
            </div>
          </div>
        </div>

      </div>);
    }

function getPorts(obj) {
    let ports = [
        { id: "port1", shape: "Circle", offset: { x: 0, y: 0.5 } },
        { id: "port2", shape: "Circle", offset: { x: 0.5, y: 1 } },
        { id: "port3", shape: "Circle", offset: { x: 1, y: 0.5 } },
        { id: "port4", shape: "Circle", offset: { x: 0.5, y: 0 } }
    ];
    return ports;
}
let isMobile;
function addEvents() {
    isMobile = window.matchMedia('(max-width:550px)').matches;
    if (isMobile) {
        let paletteIcon = document.getElementById('palette-icon');
        if (paletteIcon) {
            paletteIcon.addEventListener('click', openPalette, false);
        }
    }
}
function openPalette() {
    let paletteSpace = document.getElementById('palette-space');
    isMobile = window.matchMedia('(max-width:550px)').matches;
    if (isMobile) {
        if (!paletteSpace.classList.contains('sb-mobile-palette-open')) {
            paletteSpace.classList.add('sb-mobile-palette-open');
        }
        else {
            paletteSpace.classList.remove('sb-mobile-palette-open');
        }
    }
}
 
export default ShapeCustom;
//render(<Default />, document.getElementById('sample'));