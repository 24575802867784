// import React from "react";
// import {
//   AppBar,
//   Toolbar,
//   Typography,
//   Button,
//   makeStyles,
//   TextField,
// } from "@material-ui/core";


import React, { useState, useEffect, useContext } from "react";
import {
  AppBar,
  Toolbar,
  Button,
  Chip,
  Grid,
  IconButton,
  InputAdornment,
  makeStyles,
  Paper,
  TextField,
  Typography,
  Modal,
  Slider,
  FormControlLabel,
  FormGroup,
  MenuItem,
  Checkbox,
  
} from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
import AccountCircle from "@material-ui/icons/AccountCircle";
import EditRounded from "@material-ui/icons/EditRounded";
import AccountBox from "@material-ui/icons/AccountBox";
import FilterListIcon from "@material-ui/icons/FilterList";
import * as Colors from 'material-ui/styles/colors';
// import MuiThemeProvider from 'material-ui/styles/MuiThemeProvider';
import getMuiTheme from 'material-ui/styles/getMuiTheme';

import { useHistory } from "react-router-dom";

import isAuth, { userType } from "../../login/lib/isAuth";
import { AccountBoxRounded } from "@material-ui/icons";
import JobSearchBar from "../../job/JobSearchBar";
import "./nav.css";


// const muiTheme = getMuiTheme({
//   palette: {
//     textColor: Colors.darkBlack,
//     primary1Color: Colors.white,
//     primary2Color: Colors.indigo700,
//     accent1Color: Colors.redA200,
//     pickerHeaderColor: Colors.darkBlack,
//   },
//   appBar: {
//     height: 200,
//   },
// });


const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
  },
}));

const Navbar = (props) => {
  const classes = useStyles();
  let history = useHistory();

  const handleClick = (location) => {
    console.log(location);
    history.push(location);
  };
  const style = {
    container:{
      
    },
    span: {
      backgroundClip: 'text',
      backgroundImage: 'linear-gradient(to right, #239ce2, #1369b9)'
      //color: transparent;
    }
};
const colors = ["#239ce2", "#48bb78", "#0bc5ea", "#a0aec0", "#ed8936"];
  return (

    // <AppBar position="fixed" className="styles.heading" style={{background : 'white', color: '#046ba2', fontSize: 'large', fontWeight:600}}>
    //  <AppBar position="fixed"style={{background : 'transparent',boxShadow: 'none', color:'#239ce2'}}>
    <AppBar position="fixed" style={{background : 'rgb(252, 245, 242)',boxShadow: 'none', color:'#239ce2'}}>
        <Toolbar >

          <Typography variant="h6" className="span">
             
              <div>
          {colors.map((item) => (
            <span
              key={item}
              style={{ backgroundColor: item }}
              className="color"
             
            >&nbsp;&nbsp;</span>
          ))}
        </div>

          {/* Biz Jobs [Find & Hire Experts] */}
          </Typography>
          <Button style={{marginLeft:'3rem'}} color="inherit" onClick={() => handleClick("/blogs")}>
              Blogs
        </Button>
          <Typography variant="h5" className={classes.title}>
          </Typography>
                    <Grid
                          item
                          container
                          direction="column"
                          alignItems="center"
                          justifyContent="center"
                          style={{maxWidth:'800px', margin:'5px'}}
                        >
                        {/* <Grid item xs>
                            <TextField 
                              label="Search Jobs..."
                              value={null}
                              variant="outlined"
                              onKeyPress={(ev) => {
                                if (ev.key === "Enter") {
                                  //getData();
                                }
                              }}
                              InputProps={{
                                startAdornment: (
                                  <InputAdornment position="start">
                                    <IconButton>
                                    <FilterListIcon />
                                    </IconButton>
                                  </InputAdornment>
                                ),
                                endAdornment: (
                                  <InputAdornment>
                                    <IconButton>
                                      <SearchIcon />
                                    </IconButton>
                                  </InputAdornment>
                                ),
                              }}
                              style={{ width: "400px", background:'white' }} 
                           />
                        </Grid> */}
                        <JobSearchBar/>
                    </Grid>
                    <Button color="inherit" onClick={() => handleClick("/home")}>
                          Home
                    </Button>

                     {/*
                    <Button color="inherit" onClick={() => handleClick("/interviewprep")}>
                          Interview Prep
                    </Button> */}

                    {isAuth() ? (
                      userType() === "recruiter" ? (
                                <>
                                  {/* <Button color="inherit" onClick={() => handleClick("/home")}>
                                    Home
                                  </Button> */}
                                  {/* <Button color="inherit" onClick={() => handleClick("/skilltest")}>
                                    Skill Test
                                  </Button>
                                  <Button color="inherit" onClick={() => handleClick("/interviewprep")}>
                                    Interview Prep
                                  </Button> */}
                                  <Button color="inherit" onClick={() => handleClick("/addjob")}>
                                    Add Jobs
                                  </Button>
                                  <Button color="inherit" onClick={() => handleClick("/myjobs")}>
                                    My Jobs
                                  </Button>
                                  <Button color="inherit" onClick={() => handleClick("/employees")}>
                                    Employees
                                  </Button>
                                  <Button color="inherit" onClick={() => handleClick("/profile")}>
                                    Profile
                                  </Button>
                                  <Button color="inherit" onClick={() => handleClick("/logout")}>
                                    Logout
                                  </Button>
                                </>
                    )        : ( //case candaidate
                                    <>
                                      {/* <Button color="inherit" onClick={() => handleClick("/home")}>
                                        Home
                                      </Button> */}

                                      <Button
                                        color="inherit"
                                        onClick={() => handleClick("/applications")}
                                      >
                                        Applications
                                      </Button>
                                      <Button color="inherit" onClick={() => handleClick("/profile")}>
                                        Profile
                                      </Button>
                                      <Button color="inherit" onClick={() => handleClick("/logout")}>
                                        Logout
                                      </Button>
                                    </>
                                  )
                    ) : ( // not loggedin user
                                    <>
{/* 
                                      <Button color="inherit" onClick={() => handleClick("/home")}>
                                        Home
                                      </Button>
                                      <Button color="inherit" onClick={() => handleClick("/interviewprep")}>
                                        Interview Prep
                                      </Button>
                                      <Button color="inherit" onClick={() => handleClick("/resumebuilder")}>
                                        Resume Builder
                                      </Button> */}
                                      <Button color="inherit"  endIcon={<EditRounded/>} onClick={() => handleClick("/resumebuilder")}>
                                        Resume Builder
                                      </Button>
                                      <Button endIcon={<AccountCircle/>} variant="outlined" onClick={() => handleClick("/login")}>
                                        Login
                                      </Button>
                                      <Button  endIcon={<AccountBoxRounded/>}  onClick={() => handleClick("/Register")}>
                                        Register
                                      </Button>
                                      {/* <Button  endIcon={<AccountBoxRounded/>}  onClick={() => handleClick("/signup")}>
                                        Signup
                                      </Button> */}
                                      <hr class="m-divider--vertical"></hr>
                                      <Button color="inherit" variant="outlined" style={{color:'red', fontWeight:'bold', fontSize:'8'}} onClick={() => handleClick("/signup")}>
                                        Employer
                                        {/* <Paper>
                                            <ClickAwayListener onClickAway={handleClose}>
                                              <MenuList
                                                autoFocusItem={open}
                                                id="composition-menu"
                                                aria-labelledby="composition-button"
                                                onKeyDown={handleListKeyDown}
                                              >
                                                <MenuItem onClick={handleClose}>Profile</MenuItem>
                                                <MenuItem onClick={handleClose}>My account</MenuItem>
                                                <MenuItem onClick={handleClose}>Logout</MenuItem>
                                              </MenuList>
                                            </ClickAwayListener>
                                          </Paper> */}
                                      </Button>
                                      <div class="ab q"><p class="bd b rt no ru ns rv rw rx ry rz sa gg"><span><button class="bd b rt no sb ru ns sc rv rw sd rx ry se rz sa sf fq fs ft fu fv fw an fx fy fz ga gb gc gd de bk ge">Sign up</button></span></p><div class="av l"><p class="bd b rt no ru ns rv rw rx ry rz sa gg"><span><a class="ae af ag ah ai aj ak al am an dl dm aq dn do" href="https://medium.com/m/signin?operation=login&amp;redirect=https%3A%2F%2Fchayanit-chaisri.medium.com%2Freact-create-a-show-more-less-button-aa0e9cd0f927&amp;source=post_page---two_column_layout_nav-----------------------global_nav-----------" rel="noopener follow">Sign In</a></span></p></div></div>
                                    </>
                                  )
                    }
        </Toolbar>

    </AppBar>

  );
};

export default Navbar;
