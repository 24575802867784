
// import jlIcon from '../../assets/img/joblient.png';
import logo from '../../assets/img/logo.jpg';

const Joblient = ()=>{

    return <div style={{}}>
            {/* <div  style={{display:'inline-flex',userSelect: 'none' , marginLeft:'-20px',  marginTop:'10px', fontWeight:'bold',fontSize:'1.5em'}} >
                        <img height={'40px'} width={'60px'} style={{marginLeft:'0px', marginTop:'-1px'}} src={jlIcon} alt="Project" />
                            <span style={{color:'#e1412f', marginLeft:'-30px'}}>o</span><span style={{color:'#f5bb00'}}>b</span><span style={{color:'#5086f7'}}>l</span><span style={{color:'#48a84f'}}>i</span><span style={{color:'#e1412f'}}>e</span><span style={{color:'#0bc5ea'}}>n</span><span style={{color:'#ed8936'}}>t</span>
            </div><div style={{marginLeft:'85px', fontSize:'0.7em', marginTop:'-32px', color:'#5086f7'}}>.com</div>
                
                
            <div style={{marginTop:'-5px', marginLeft:'62px', width:'50px' }}>
                <span style={{color:'#e1412f', fontSize:'0.7em'}}>Beta</span>
            </div> */}
             <img className="h-10 w-20" src={logo} />
        </div>

}

export default Joblient;