import Sidebar from "./Sidebar";
import "./Layout.scss";
import Topbar from "./Topbar";
import { Button, Grid, IconButton, Modal, Paper } from "@material-ui/core";
import { useContext, useState } from "react";
import { BrowserRouter } from "react-router-dom";
import MainRouter from "../home/component/MainRouter";
import { RiMenuFill, RiMenuUnfoldFill } from "react-icons/ri";
import JobSearchBar from "../job/JobSearchBar";
import { useDispatch, useSelector } from "react-redux";
import { fetchCourses, setSearch } from "../../state/action-creators/workspace/index"
import { useEffect } from "react";
import { setLoggedIn } from "../../state/action-creators/profile";
import { SetPopupContext } from "../home/component/MainRouter";
// import logo from "./logo.svg";
// import designIcon from '../../common/svg/design.svg';

import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import Joblient from "./Jonlient";
import logo from '../../assets/img/logo.jpg';
import guestLogin from "../login/lib/GuestLogin";
import { ToastContainer } from "react-toastify";
import { isMobile } from "../../utils/mobileUtil";
import { useHistory, useLocation } from "react-router-dom/cjs/react-router-dom.min";
import { ToggleButton, ToggleButtonGroup } from "@material-ui/lab";


const Layout = () => {
   // const isMobile = window.navigator?.userAgent?.toLowerCase().includes("mobi") ? true : (Math.min(window.screen.width, window.screen.height) < 500);
    //console.log('isMobile1:', isMobile , window.screen.width, window.screen.height, window.navigator.userAgent.toLowerCase().includes("mobi") )


    const dispatch = useDispatch();
    const location = useLocation()
    const setPopup = useContext(SetPopupContext);
    const [expand, setExpand] = useState(true);

    const search = useSelector(state=>state.search.query);
    const [showSBar, setShowSBar] = useState(true);
    const [vHeight, setVHeight] = useState('80.5vh');
    const [l, setL] = useState(2);
    const [r, setR] = useState(10);
    const [ratio, setRatio] = useState({left:'150px', right:'auto'})
    const [btn, setBtn] = useState(false);
    const [pos, setPos] = useState(150);

    const [menu, setMenu] = useState(!isMobile);
    let history = useHistory();

 
    useEffect(() => {
        console.log('layout useEffect')
        adjustLayout();
        getData();
        if (isMobile) {
            setRatio({left:'40em', right:'auto'});
        }
       
    },[]);
    // },[window.innerWidth]);

    const resize = ()=>{
        adjustLayout();
    }

    const adjustLayout = ()=>{
        console.log("X location:", pos);
        adjustContentHeight();
        adjustLayoutWidth(pos);
    }

    const adjustContentHeight = ()=>{
        let height = window.innerHeight - 70;
        setVHeight(height);
    }
    const adjustLayoutWidth = (posX)=>{
        if(posX <= 10 || posX >= 500) return;
        let temp =  (posX)/window.innerWidth;
        let ll = temp*100;
        let rr = 100 - ll;

        if (posX>100) {
            setExpand(true);
        } else {
            setExpand(false);
        }
        console.log(posX, "ll%, rr%", ll, rr);
        setRatio({left:`${ll}%`, right: `${rr}%`});

        if(posX>0) {
            let col = (posX*12)/window.innerWidth;
            //console.log('col: ', col);
            if(col<=1){
                setL(1);
                setR(11);

            } else if(col>=2 && col <3){
                setL(2);
                setR(10);
            } else if(col>=3 && col <4){
                setL(3);
                setR(9);
            } else if(col>=4){
                setL(4);
                setR(8);
            }
        }
    }

    const getData = () => {

        let token = localStorage.getItem("token");
        dispatch(setLoggedIn(token));
        console.log('login1', token)
        if(location.pathname.indexOf('systemdesign')>-1 || location.pathname.indexOf('hot-jobs')>-1 || location.pathname.indexOf('intern-jobs')>-1) {
            // no need login message
            console.log('login2', 'systemdesign')
            guestLogin(dispatch, setPopup)
        } else if(token===null) {
            console.log('login3')
            console.log('No user token available, trying to login as guest user');
            guestLogin(dispatch, setPopup)
        } else {
            console.log('login4')
           // console.log('Token available')
        }
    }

    const handleClick = () => {
         if(isMobile){
            setMenu(!menu);
         } else {
            onExpand();
         }
    };

    const expandSidebar = ()=>{
        setShowSBar(true);
        let xWidth = ratio.left.replace('%',"");
        //console.log('ratio:',xWidth, ratio);
        if(xWidth<25) {
            adjustLayoutWidth(320);
        }
    }

    const handleDrag =(e)=> {
        adjustLayoutWidth(e.clientX);
        setPos(e.clientX);
    }

    const onExpand = (clientX)=>{
        // let x = 20;
        // if (clientX> 50) {
        //     x = 50;
        //     setExpand(false);
        // } else {
        //     x = 320;
        //     setExpand(true);
        // }
        
        // adjustLayoutWidth(x);
        let x = 40;
        if (expand) {
            adjustLayoutWidth(x);
            setExpand(false);
        } else {
            x = 150;
            if (pos>x) {
                x = pos;
            }
            
            setExpand(true);
            adjustLayoutWidth(x);
        }
    }
    
   const renderSideBar = ()=>
        <Grid style={{width: `calc(${ratio.left})` , }}>
        <div style={{display:'flex'}} onMouseOver={()=>setBtn(true)} onMouseOut={()=>setBtn(false)}>
            <div className="card" style={{width:'100%', height: 'calc(100vh - 63px)'}}>
                <Sidebar col={l} className="sideBar" expand={expand} expandSidebar={expandSidebar}/>
            </div>
           {!isMobile &&<>
                <div style={{marginLeft:'5px', marginTop:'45px', background:'rgb(250, 252, 252)', cursor:'ew-resize', width: '1.2em', zIndex:100,  display:'flex', alignItems:'center' }} draggable={true} onDragEnd={(e)=>handleDrag(e)}>
                    <DragIndicatorIcon style={{marginLeft:'-5px'}}/>
                </div>
                <div  style={{ marginLeft:'-23px', borderLeft:'1px solid',  height: 'calc(100vh - 63px)'}}>
                    <button style={{opacity:`${btn ? 1 : 0.3}`}} onClick={(e)=>onExpand(e.clientX)}  aria-expanded="false" aria-label="Expand sidebar" data-testid="grid-left-sidebar-resize-button" 
                        className="css-livzzy" aria-describedby="302val-tooltip" >
                        <span className="css-snhnyn">
                        {expand ? <KeyboardArrowLeftIcon color="primary" style={{opacity:`${btn ? 1 : 0.1}`}}/> 
                        : <KeyboardArrowRightIcon color="primary"/>
                        }
                        </span>
                    </button>
                </div>
            </>}

        </div>
        
    </Grid>
   

   return(<BrowserRouter>
        <ToastContainer
        position="top-center"
        autoClose={2000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
        <Grid container direction="column" className="styleLayout">
            <Grid item container direction="row" style={{borderBottom:'0.2px solid'}}>
                <Grid item>
                    <div style={{display:'inline-flex', marginTop:'-5px'}}>
                        <IconButton onClick={()=>handleClick()} style={{ marginLeft:'0px', marginRight:'0', marginTop:'0px'}}
                            edge="start"
                            color="inherit"
                            aria-label="open drawer"
                            sx={{ mr: 2, display: { xs: 'block', sm: 'none',}, }}
                            >   
                            {expand ? <RiMenuFill /> : <RiMenuUnfoldFill />}
                        </IconButton>
                        <img className="py-4 w-[50%]" src={logo} />
                        {/* {isMobile?
                        <div style={{transform:'translate(60px,7px) scale(1.6,1.6)'}}><Joblient/></div>
                        :<Joblient/>} */}
                  </div>
                </Grid>
                <Grid item>
                    <Topbar showSBar = {showSBar} setShowSBar={setShowSBar}/>
                </Grid>
            </Grid>
            <Grid item container direction="row">
                { isMobile ?
                    <Modal open={menu} onClose={()=>setMenu(false)} closeAfterTransition>
                        <Paper 
                        elevation={20}
                        style={{ 
                            display:'flex',
                            float:'left' , 
                            width:'300px',
                            height:'100%',
                            marginTop:'60px', 
                            borderTopRightRadius:'20px',
                            borderBottomrightRadius:'20px',
                        }}
                        >
                        {renderSideBar()}
                        </Paper>
                    </Modal>
                  : renderSideBar()
                }
                <Grid item container direction="column" style={{display:'block', width: `calc(${ratio.right})`, height: 'calc(100vh - 63px)', overflow: 'auto'}}>
                    <Grid><JobSearchBar/></Grid>
                    <Grid><MainRouter/></Grid>
                </Grid>
            </Grid>

        </Grid>
        </BrowserRouter>
    )
}

export default Layout;