import React, { useState, useEffect, useContext } from "react";
import { useDispatch } from "react-redux";
import {
  Button,
  Grid,
  TextField,
  MenuItem,
  FormControlLabel,
} from "@material-ui/core";
import { Radio } from "@mui/material";
import { SetPopupContext } from "../home/component/MainRouter";
import {API, fetchData, saveData} from "../../config/apiService"
import TextEditor from "../editor/editor-text/TextEditor";

const CODE = 1;
const DESIGN = 2;

const ViewFeedback = (props)=>{
    const dispatch = useDispatch();
    const setPopup = useContext(SetPopupContext);
    const { type } = props;
    const [feedback, setFeedback] = useState({})
    const [select, setSelect] = useState(CODE);
    const [listFB, setListFB] = useState();

    useEffect(()=>{
        getFeedbacks();
    },[])

    const getFeedbacks = async()=>{
        let res = await fetchData(API.feedback);
        console.log('Feedbacks:', res)
        if (res) {
            setListFB(res);
        }
    }


    const saveFixes = (item)=>{
        if (item.resolution) {
            item.status = 'Resolved';
            console.log('feedback:::', item);
            saveData(API.feedback, item, "Feedback", setPopup );
        }
    }

    const contentChanged = (_id, content)=>{
        console.log(content);

        let temp = [...listFB]
        let filt = temp && temp.filter(v=> v._id===_id);

        if (filt && filt.length > 0) {
            filt[0].resolution = content;
            setListFB(temp);
        }

       // setFeedback({fix :content})
    }



    return  <Grid container direction="row" spacing={0} style={{width:'98%', padding:'1em 1em 3em 1em', borderRadius:'10px', border:'1px solid lightgray'}}>
            {
                type==='admin'?<h2>Joblient Issue Tracking System<hr></hr></h2>
                :<h2>List of User Feedbacks<hr></hr></h2>
            }
            <Grid item container  direction="row" spacing={0} style={{padding:'0.5em'}}>
                {
                    listFB&&listFB.map( (item, key)=>{
                        return <Grid item key={key} style={{background: 'rgb(218 225 236)', marginBottom:'3em', borderRadius:'10px', border:'1px solid lightgray', padding:'0.5em', marginBottom:'0.5em'}}>
                            
                            <Grid style={{background:'#eaecec', borderRadius:'10px', border:'1px solid lightgray', padding:'0.5em', marginBottom:'0.5em'}}>
                                <h4 style={{fontWeight:'bold', color:'black'}}>Title: {item.title}</h4>
                            </Grid>
                            <Grid item style={{marginLeft:'3em', marginTop:'1em', fontWeight:'bold'}}>
                                <span style={{color:'white', fontWeight:'bold', background:`${item.status === 'Open'? 'red': 'green'}`}} >Status: {item.status}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;CreatedOn: {new Date(item.createdAt).toLocaleDateString('en-GB', {
                                    day: 'numeric', month: 'short', year: 'numeric'})}</span>
                            </Grid>
                            <Grid item style={{marginLeft:'3em', marginTop:'1em', fontWeight:'bold'}}>
                                <span style={{fontWeight:'bold'}}>Severity: {item.rating>3 ? 'High' : 'Medium'}</span>
                            </Grid>                            
                            <Grid item xs={'auto'} style={{marginLeft:'3em', marginTop:'0.5em'}}>
                                <TextEditor readOnly={true} toolbar={false} content={item.desc} ></TextEditor>
                            </Grid>
                            {type==='admin'&&<>
                                <Grid item style={{marginLeft:'3em', marginTop:'1em', fontWeight:'bold'}}>
                                    <span >Resolution:&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Updated On: {new Date(item.updatedAt).toLocaleDateString('en-GB', {
                                        day: 'numeric', month: 'short', year: 'numeric'})}
                                    </span>
                                </Grid>
                                <Grid item xs={'auto'} style={{marginLeft:'3em', marginTop:'0.5em'}}>
                                    <TextEditor toolbar={true} content={item.resolution} contentChanged={ (e)=>contentChanged(item._id, e)} ></TextEditor>
                                </Grid>
                                <Grid item xs={'auto'} style={{marginLeft:'3em', marginTop:'0.5em'}}>
                                    <Button  style={{color:'black', background:'#e5c016', '&:hover':'#fce05d'}} onClick={()=>saveFixes(item)}>Save Fix</Button>
                                </Grid>
                            </>
                            }
                        </Grid>
                    })
                }
            </Grid>
    </Grid>

}
export default ViewFeedback;


