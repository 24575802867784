import React,{ useContext, useEffect, useState } from "react";
import {
  Button,
  Grid,
  Typography,
  Modal,
  Paper,
  makeStyles,
  TextField,
  Collapse,
} from "@material-ui/core";
import axios from "axios";
import ChipInput from "material-ui-chip-input";
// import FileUploadInput from "../lib/FileUploadInput";
import DescriptionIcon from "@material-ui/icons/Description";
import FaceIcon from "@material-ui/icons/Face";

import { SetPopupContext } from "../home/component/MainRouter";

import apiList from "../../config/apiList"
import Register from "./Register";
import { useDispatch, useSelector } from "react-redux";
import {manageProfile} from "../../state/action-creators/profile/index"
import CurrencyBitcoinIcon from '@mui/icons-material/CurrencyBitcoin';
import CV from "../resume/CV";
import rank from '../../assets/img/userrank.JPG'
import AssessmentSum from "../leaderboard/AssessmentSum";
import AssessmentView from "../leaderboard/AssessmentView";
import IconButton from '@material-ui/core/IconButton';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import FileUploadInput from "./lib/FileUploadInput";

const useStyles = makeStyles((theme) => ({
  body: {
    height: "inherit",
  },
  popupDialog: {
    height: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    // padding: "30px",
  },
}));


const Profile = (props) => {
  const {userId, readOnly, mode} = props;
  const classes = useStyles();
  //const setPopup = useContext(SetPopupContext);
  const [userData, setUserData] = useState();
  const [open, setOpen] = useState(false);
  const profileData = useSelector(state=>state.profile);
  const dispatch = useDispatch();
  const [profileDetails, setProfileDetails] = useState(profileData.profile);
  const [file, setFile] = useState('');


  const hasProfile = localStorage.getItem('hasProfile');

  const lead = {coins: 1000, rank:3, assessments: 12};

  const [education, setEducation] = useState([
    {
      institutionName: "",
      startYear: "",
      endYear: "",
    },
  ]);

  const handleInput = (key, value) => {
    console.log('file changed: ', key, value)
    setProfileDetails({
      ...profileDetails,
      [key]: value,
    });
  };

  useEffect(() => {
  }, []);

  const handleClose = () => {
    setOpen(false);
  };

  const editDetails = () => {
    setOpen(true);
  };

  return (
      <Grid container direction="column">
  
        <Grid style={{margin:'0 auto', width:'94.2%'}}>
            {((readOnly && userId) || !readOnly) && <AssessmentView userId={userId}></AssessmentView>}

            { (!readOnly && (hasProfile != 'true')) &&
              <Grid container direction="column" spacing={0}>
                  <Grid item style={{color:'red', margin:'1em 0'}}>
                      <h3>Your profile is incomplete, please complete it before continuing with another task.</h3>
                  </Grid>
                  <Grid item style={{color:'red', lineHeight:'2em'}}>
                    <div style={{ borderRadius:'10px', padding:'10px',fontSize:'1.2em',  border:'0.5px solid lightgray'}}>
                      <span style={{fontWeight:'bold',color:'blue', padding:'0em'}}>Read the instructions carefully before filling the details</span>
                        <ul  style={{paddingLeft:'1em', marginTop:'0.5em',}}>
                          <li>
                            <span>Please spend 5! minutes to get it done</span>
                          </li>
                          <li>
                            <span>Required to update your skills, experience, and other important details</span>  
                          </li>
                          <li>
                          <span>Word can be highlighted between sentences by using prefix and sufix **, sample **data** will display like <span>sample <strong>data</strong></span></span>
                          </li>
                          <li>
                          <span>You can <strong>add/modify/delete</strong> employment section</span>
                          </li>
                        </ul>
                      </div>
                  </Grid>
              </Grid>
            }
            {/* <Grid item style={{padding:'0.2em', marginTop:'1em', background: hasProfile == 'true' ? '' : '#f9dbdb'}}>
              <div className="pageTitle">Candidate Profile</div>
            </Grid> */}
            {/* <Grid item style={{padding:'0.2em', marginTop:'1em'}}>
              <div className="pageTitle">Candidate Profile</div>
            </Grid> */}

        </Grid>
        <CV readOnly={readOnly} userId={userId} mode={mode}></CV>
      </Grid>  )
};

export default Profile;