import { TextField } from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
import AddIcon from "@material-ui/icons/Add";

export const getDefaultWork = ()=>{
    return {
        "post": "Your Designation, ex Senior Software Engineer",
        "start": "Jan 2022",
        "end": "Apr 2023",
        "company": "ABC Company",
        "tech": "Add Your Key Technologies, ex Java,  **Elastic Search, Kafka**, CI-CD, Docker",
        "desc": [
            "**Add your project descption** provides regular reports to millions of merchants on daily basis"
        ]
    }
}

export const initWorkExp = (value, setValue)=> {
    let temp = {...value};
    temp.works.push(getDefaultWork())
    setValue(temp);
    return <></>
}

export const editField = (value,setValue,i,key, font='n')=> {
    //console.log('value...', value,i)
    
    return font ==='s' ? <input type="text"
        style={{ width: "100%", height:'1.8em',border:'none',caretColor:'red', color:'Highlight', background:'', fontWeight:'bold',marginLeft:'-5px',marginTop:'-4px', fontSize:"1.1em", display:'block'}}
        value={value.works[i][key]}
        onChange={(event) => {
            let temp = {...value}
            temp.works[i][key] = event.target.value
            setValue(temp);
        }}
        /> :
        <TextField
        style={{ width:'30em', display:'inline-flex'}}
        value={value.works[i][key]}
        onChange={(event) => {
            let temp = {...value}
            temp.works[i][key] = event.target.value
            setValue(temp);
        }}
        />

}
export const editDescList = (value, setValue, i, j, len)=>{
    
    return <div>
                    <div>
                    { j<=len-1 ? <DeleteIcon style={{display:'inline-block'}} onClick={()=>{
                        let temp = {...value}
                        //temp.works[i].desc.splice(j,1);
                        if( len > 1 ) {
                            temp.works[i].desc.splice(j,1);
                        } else {
                            temp.works[i].desc[j] = "";
                        }
                        setValue(temp);
                    }}></DeleteIcon>:<></>}
                    <TextField
                        multiline
                        style={{ width: "94%"}}
                        value={value.works[i].desc[j]}
                        onChange={(event) => {
                            let temp = {...value}
                            temp.works[i].desc[j] = event.target.value
                            setValue(temp);
                        }}
                    />
                    </div>
                    { j==len-1 && value.works[i].desc[j] !="" ? 
                        <AddIcon onClick={()=> {
                                let temp = {...value}
                                temp.works[i].desc.push("")
                                setValue(temp);
                            }}
                        ></AddIcon>
                        : <></>
                    }
                        
    </div>
}

export const editContent = (profile, setProfile, sec, j, len)=>{
    //console.log(profile, setProfile, j, len);
    return  <div key={j}>
                    <div>
                    { j<len ? <DeleteIcon style={{display:'inline-block'}} onClick={()=>{
                        let temp = {...profile}
                        if( len > 1 ) {
                            temp[sec].content.splice(j,1);
                        } else {
                            temp[sec].content[j] = "";
                        }
                        setProfile(temp);
                    }}></DeleteIcon>:<></>}
                    <TextField
                        multiline
                        style={{ width: "94%"}}
                        value={profile[sec].content[j]}
                        onChange={(event) => {
                            let temp = {...profile}
                            temp[sec].content[j] = event.target.value
                            setProfile(temp);
                        }}
                    />
                    </div>
                    { j==len-1 && profile[sec].content[j] !="" ? 
                        <AddIcon onClick={()=> {
                                let temp = {...profile}
                                temp[sec].content.push("")
                                setProfile(temp);
                            }}
                        ></AddIcon>
                        : <></>
                    }
                        
    </div>
}

export const editContentMulti = (profile, setProfile, sec, j, len)=>{
    //console.log('editContentMulti:',sec, j, len);
    return    <div key={j} style={{display:'inline-flex'}}>
                    { j<len ? <DeleteIcon style={{}} onClick={()=>{
                        let temp = {...profile}
                        if( len > 1 ) {
                            temp[sec].content.splice(j,1);
                        } else {
                            temp[sec].content[j] = "";
                        }
                        setProfile(temp);
                    }}></DeleteIcon>:<></>}
                    <TextField
                        multiline
                        style={{width:'auto'}}
                        value={profile[sec].content[j].skill}
                        onChange={(event) => {
                            let temp = {...profile}
                            temp[sec].content[j].skill = event.target.value
                            setProfile(temp);
                        }}
                    />
                    <TextField
                        multiline
                        style={{width:'10px', marginLeft:'20px'}}
                        value={profile[sec].content[j].rating}
                        onChange={(event) => {
                            let temp = {...profile}
                            temp[sec].content[j].rating = event.target.value
                            setProfile(temp);
                        }}
                    />
                
                { j==len-1 && profile[sec].content[j].skill !="" ? 
                        <div  style={{marginLeft:'10px'}}>
                            <AddIcon onClick={()=> {
                                    let temp = {...profile}
                                    temp[sec].content.push({skill:"", rating:"4"})
                                    setProfile(temp);
                                }}
                            ></AddIcon>
                        </div>
                        : <></>
                }       
    </div>
}