import React, { useCallback, useEffect, useRef, useState } from "react";
import Editor, { useMonaco, BeforeMount, OnMount, OnValidate} from "@monaco-editor/react";
// import  { MonacoLSPProxy } from "monaco-editor";
import { useSelector } from "react-redux";
import { getMainCode } from "../../../../data/practice-code";
import { buildMainBlock } from "../../../../utils/codeloader/codeUtil";
import { isMobile } from "../../../../utils/mobileUtil";
// import {
//     MonacoLanguageClient,
//     // MonacoServices,
//     // createConnection,
//     // MessageConnection,
// } from "monaco-languageclient";


const CodeEditorWindow = ({onChange, language, code, theme})=>{
    const editorRef = useRef(null); //useRef<RefObject | null>(null);
    let workspace = useSelector(state=>state.workspace);
    let current = workspace.selected;
    let selected = workspace.items[current.index];
    console.log('selected::::', language===selected.lang, selected, language);
    let code_main = (language===selected.lang && selected.code) ? selected.code : buildMainBlock(selected, language);

    useEffect(() => {
    if(!editorRef.current)
        onChange("code", code_main);
        handleEditorPrettify();
    }, [selected]);

    const createCompleters = (monaco)=> {
        return [
            {
                label: 'customFunction1',
                kind: monaco.languages.CompletionItemKind.Function,
                documentation: "My first Custom Function",
                insertText: 'customFunction1()'
            },
            {
                label: 'customFunction2',
                kind: monaco.languages.CompletionItemKind.Function,
                documentation: "My second Custom Function",
                insertText: 'customFunction2()'
            }
        ];
    }
    const handleEditorDidMount = (editor) => {
     // console.log('handleEditorDidMount')
        editorRef.current = editor;
        editor.getModel()?.updateOptions({ tabSize: 4, insertSpaces: false });
        handleEditorPrettify();
        // MonacoLanguageClient.install(editor);

        //Register the custom completion function into Monaco Editor    
        // editor.languages.registerCompletionItemProvider('java', {
        //     provideCompletionItems: (model, position)=> {
        //         return createCompleters(editor);
        //     }
        // });

        // "Java": {
        //     "main": true,
        //     "monaco_mode": "java",
        //     "file_mapping": "Main.java",
        //     "icon": "https://s3.amazonaws.com/coderbytestaticimages/language_logos/skill_logo_java_notext.png",
        //     "version": "Java version: 18-ea+1-7",
        //     "lsp_url": "https://monaco-lsp-java.herokuapp.com"
        // },
        // console.log('editor:', editor)
        // let lsp_url = "https://monaco-lsp-java.herokuapp.com"
        // let SocketOptions = {
        //     "reconnectionDelay": 1000,
        //     "reconnectionDelayMax": 60000,
        //     "path": "/socket.io",
        //     "hostname": "monaco-lsp-java.herokuapp.com",
        //     "secure": true,
        //     "port": "443"
        // }

        // //const socket = io(lsp_url, MonacoLSPProxy.SocketOptions);
        // const socket = io(lsp_url, SocketOptions);

        // let provider = new MonacoLSPProxy.SocketCompletionItemProvider(socket,editor,monaco.editor)

        // provider.onInitialized(()=>{
        //     console.log('>> editor intellisense setup');
        //     monaco.languages.registerCompletionItemProvider(monaco_mode, provider);
        //     monaco.languages.registerSignatureHelpProvider(monaco_mode, provider);
        //     monaco.languages.registerHoverProvider(monaco_mode, provider);
        //     that.languageIntelliSenseStarting = !1
        // }
        // )
    }
   



    const handleEditorPrettify =(() => {
        //console.log('editor.action.formatDocument1');
        if(editorRef.current)
            setTimeout(function() {
            editorRef.current?.getAction("editor.action.formatDocument").run()}, 50);



    });
    const handleEditorChange = (val)=> {
        onChange("code", val);
        //handleEditorPrettify();
        //console.log('setprof..', val)
       // if(section=='doc')
    //    console.log('profileSection changed', sel, editData);
    //    console.log('changed data', JSON.parse(data));
       //setProfileChange(JSON.parse(data));
    }

    
    //console.log('sec', section);
    //const data = section == 'doc' ? JSON.stringify(resdata) : JSON.stringify(resdata[section]);

    return<Editor 
        language= {language}
        height={`${isMobile?'50vh':'80vh'}`}
        value = {code_main}
        theme={theme}
        options={{
        automaticLayout: true,
        autoClosingBrackets: "always",
        autoClosingQuotes: "always",
        formatOnPaste: true,
        formatOnType: true,
        scrollBeyondLastLine: false,
        }}
        onMount={handleEditorDidMount}
        onChange={handleEditorChange}

    // beforeMount={handleEditorWillMount}
    // onValidate={handleEditorValidation}
/>

}

export default CodeEditorWindow;



// import React, { useState } from "react";

// import Editor from "@monaco-editor/react";
// import { useSelector } from "react-redux";

// const CodeEditorWindow = ({ onChange, language, code, theme }) => {
//   const selected = useSelector(state=>state.workspace.selected);
//   //console.log('selected.desc:', selected.desc);
//   const [value, setValue] = useState(selected.desc)  //useState(code || "");
//   //setTimeout(()=> {setValue(selected.desc)},10);
//   const handleEditorChange = (value) => {
//     setValue(value);
//     onChange("code", value);
//   };
//   console.log(language,theme);
//   return (
//     <div className="overlay rounded-md overflow-hidden w-full h-full shadow-4xl">
//       <Editor
//         height="85vh"
//         width={`100%`}
//         language={language || "java"}
//         value={selected.code_main}
//         theme={theme}
//         defaultValue="// some comment"
//         onChange={handleEditorChange}
//       />
//     </div>
//   );
// };
//export default CodeEditorWindow;
