
import '../../editor/editor-shape/index.css';
import './Diagram.scss';
import * as React from "react";
import { DiagramComponent, DiagramConstraints,} from "@syncfusion/ej2-react-diagrams";
import { useDispatch} from 'react-redux';
import { useState } from 'react';
import TextEditor from '../../editor/editor-text/TextEditor';
import { Button, Collapse, Grid, IconButton } from '@material-ui/core';
import { API, fetchData, saveData } from '../../../config/apiService';
import { SetPopupContext } from '../../home/component/MainRouter';
import {nodes, connectors, dragEnter,  getNodeDefaults, getConnectorDefaults, gridlines, updateShapes} from '../../editor/editor-shape/ShapeUtil'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { useEffect } from 'react';



const ViewDiagram = (props)=>{
    const { api, zoom, item, height, width , paletters, index} = props;
    const [show, setShow] = useState(true);
    const [like, setLike] = useState({like:false, dislike: false});
    const [likeDislike, setLikeDislike] = useState({like: 0, dislike: 0});
   // const [title, setTitle] = useState(index+item.title);
    const [comments, setComments] = useState();
   // const dispatch = useDispatch();
    const setPopup = React.useContext(SetPopupContext);
    const actualHeight = height || '700px';
    const actualWidth = width || '100%';
    const zm = zoom || 0.7;
    const full = paletters ? 9 : 12;
    const toolbar = paletters ? true : false;
    let diagramInstance;
    //console.log('props:', index)

    useEffect(() => {
        //preventeditDiagram();
       // console.log('useEffect invoked', index + item.title)
        // console.log('useEffect', 'comments:', item.comments);
        // if(!title || title != (index+item.title))
        //     setComments([...item.comments]);

        // setTitle(index + item.title);
        //console.log('useefect in', item.comments)
        //getData();
        init();
        // return () => {
        //      //console.log('new post created')
        // } 
        if(diagramInstance) {
            diagramInstance.updateViewPort();
        }

    }, [index + item.title]);

    const init = ()=>{
        setComments(item.comments);
        setLikeDislike({like: item.like, dislike: item.dislike});
    }

    const getData = async(field)=>{
        let api = API.blogDesign;
        let query;
       
        if (index>=0) {
            query = `index=${index}&&_id=${item._id}`
        } else {
            query = `_id=${item._id}`
        }

        if(field=='comments') {
            api = `${API.blogDesign}/comments`
            let desData = await fetchData(`${api}?${query}`, "Comments", null, null, setPopup);
            console.log('get comments by _id:', desData);
            let temp = [...desData];
            temp.push({comment:''});
            setComments(temp);
        } else if(field=='like') {
            api = `${API.blogDesign}/like`
            let desData = await fetchData(`${api}?${query}`, "Like", null, null, setPopup);
            console.log('get like by _id:', desData);
            setLikeDislike({like: desData.like, dislike: desData.dislike});
        }
        
    }

    const preventeditDiagram =()=> {
        //diagramInstance.clearSelection();
        let zoomOptions = {
            type: "ZoomOut",
            //Sets the factor by which we can zoom in or zoom out
            zoomFactor: 1
          }
          //zoomout the diagram
          //diagramInstance.zoomTo(zoomOptions)
          //diagramInstance.updateViewPort();
         // diagramInstance.constraints = DiagramConstraints.None;
    }

    const commentChange = (value)=>{
        let temp = [...comments];
        temp[comments.length-1].comment = value;
        setComments(temp);
    }

    const saveComment = ()=>{
       
        let hasChanged = {};
        let lastComment =  comments[comments.length-1];
        //console.log('lastComment:', lastComment);
        if(( lastComment.comment && lastComment.comment == '<p><br></p>') || lastComment.comment =='') {
            // no need to save un related values
            return;
        } else {
            hasChanged.comment = lastComment.comment
        }


        hasChanged['_id'] = item._id;
        if(index>=0) {
            hasChanged.index = index;
        }

        saveData(api, hasChanged, "comments", setPopup);
        setTimeout(() => {
            getData('comments');
        }, 10);
     }
     const updateLike = (val)=>{
        let temp = {...like};
        if(val) {
            temp.like = false;
            //temp.dislike = false;
            likeDislike.like = likeDislike.like - 1;
        } else {
            likeDislike.like = likeDislike.like + 1;
            if(temp.dislike){
                likeDislike.dislike = likeDislike.dislike - 1;
            }
            temp.like = true;
            temp.dislike = false;
        }
        setLike(temp);
        setTimeout(() => {
            saveLike();
        }, 10);
     }

     const updateUnLike = (val)=>{
        let temp = {...like};
        if(val) {
            temp.dislike = false;
            //temp.dislike = false;
            likeDislike.dislike = likeDislike.dislike - 1;
        } else {
            likeDislike.dislike = likeDislike.dislike + 1;
            if(temp.like) {
                likeDislike.like = likeDislike.like - 1;
            }
            temp.like = false;
            temp.dislike = true;
        }
        setLikeDislike(likeDislike);
        setLike(temp);
        setTimeout(() => {
            saveLike();
        }, 10);
     }

     const saveLike = ()=>{
  
        let hasChanged = {};
        //console.log('lastComment:', lastComment);
        hasChanged.like = likeDislike.like;
        hasChanged.dislike = likeDislike.dislike;
        hasChanged['_id'] = item._id;
        if(index>=0) {
            hasChanged.index = index;
        }

        saveData(api, hasChanged, "like", setPopup);
        setTimeout(() => {
            getData('like');
        }, 10);
     }


    const updatedAt = new Date(item.updatedAt).toLocaleDateString('en-GB', {
    day: 'numeric', month: 'short', year: 'numeric'});

    return (<Grid  container direction="column" spacing={1} style={{margin:'0.5em', padding:'0.2em', borderRadius:'15px', border:'2px solid #e0e0e0', borderColor:'#e0e0e0'}}>
            <Grid item container direction="row" alignItems='center' justifyContent='center'>
                <Grid item xs={12}>
                    <div style={{display:'flex',  verticalAlign:'middle', alignItems:'center', background:'#f2f2f2', padding:'0.5em', borderRadius:'8px', border:'2px solid #e0e0e0', borderColor:'#e0e0e0'}}>
                    <div style={{display: 'block', fontSize:'1em'}}>
                        <span>&nbsp;{updatedAt}</span>
                        <p className="avatar" style={{marginTop:'0.5em', marginBottom:'0.5em'}}>
                            <span className="title--refresh">{item.title}</span>
                        </p>
                        <p className="avatar" style={{marginBottom:'0.5em'}}>
                            {item.postType === 1 ? 'Origional':'Improved'}&nbsp;Post By -&nbsp;
                            <span >&nbsp;{item.createdBy}</span>
                        </p>
                    </div>
                    <div style={{marginLeft:'auto', marginRight:'0', textAlign:'center', fontSize:'1em', fontWeight:'bold'}}>
                        <span >
                             <svg width="24" height="24" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" onClick={()=>updateLike(like.like)}>
                                <path fill-rule="evenodd" clipRule="evenodd" d="M8.5 0.625C8.00272 0.625 7.52581 0.822544 7.17417 1.17417C6.82254 1.52581 6.625 2.00272 6.625 2.5V3.277C6.625 4.07265 6.30893 4.83571 5.74632 5.39832C5.36416 5.78048 4.88952 6.04888 4.375 6.18175V5.875C4.375 5.46079 4.03921 5.125 3.625 5.125H1.375C0.960786 5.125 0.625 5.46079 0.625 5.875V12.625C0.625 13.0392 0.960786 13.375 1.375 13.375H3.625C3.99354 13.375 4.3 13.1092 4.3631 12.7588L6.0149 13.2306L6.01501 13.2307C6.34973 13.3263 6.69615 13.3749 7.04427 13.375H7.0445H9.48699C10.4166 13.375 11.3131 13.0297 12.0026 12.4061C12.692 11.7825 13.1253 10.925 13.2182 10C13.2193 9.9897 13.2201 9.97938 13.2207 9.96904L13.3707 7.41904L13.3707 7.41904L13.371 7.41317C13.4064 6.71915 13.1997 6.03438 12.7863 5.4758C12.3729 4.91722 11.7784 4.51948 11.1044 4.35051C11.097 4.34866 11.0896 4.34692 11.0821 4.3453L10.375 4.19075V2.5C10.375 2.00272 10.1775 1.52581 9.82582 1.17417C9.47419 0.822544 8.99728 0.625 8.5 0.625ZM2.875 11.875V7.027V6.625H2.125V11.875H2.875ZM4.375 11.2104C4.45387 11.2275 4.53223 11.2471 4.60996 11.2693L4.60999 11.2693L6.42699 11.7883L6.4271 11.7884C6.62789 11.8458 6.83568 11.8749 7.0445 11.875H7.04473H9.48701C10.0448 11.875 10.5827 11.6678 10.9964 11.2937C11.4065 10.9227 11.6655 10.4139 11.7243 9.86434L11.8731 7.33422C11.8901 6.9881 11.7868 6.64673 11.5806 6.36814C11.376 6.0916 11.0825 5.8939 10.7496 5.80802L10.0344 5.6517L10.014 5.64696C9.68882 5.56632 9.39998 5.37915 9.19355 5.11527C8.98713 4.8514 8.87499 4.52602 8.875 4.191V4.19096V2.5C8.875 2.40054 8.83549 2.30516 8.76517 2.23483C8.69484 2.16451 8.59946 2.125 8.5 2.125C8.40054 2.125 8.30516 2.16451 8.23484 2.23483C8.16451 2.30516 8.125 2.40054 8.125 2.5V3.277C8.125 4.47047 7.65089 5.61507 6.80698 6.45898C6.14196 7.124 5.29023 7.55938 4.375 7.71408V11.2104Z" fill= {like.like ? "#5ec500" : "#1B313D"} fillOpacity={like.like ? "1" : "0.3"}></path>
                            </svg> 
                            <div style={{color:'#5ec500'}}>{likeDislike.like} &nbsp;&nbsp;</div>
                             <svg width="24" height="24" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" onClick={()=>updateUnLike(like.dislike)}>
                                <path fill-rule="evenodd" clipRule="evenodd" d="M6.5 14.375C6.99728 14.375 7.47419 14.1775 7.82583 13.8258C8.17746 13.4742 8.375 12.9973 8.375 12.5V11.723C8.375 10.9274 8.69107 10.1643 9.25368 9.60168C9.63584 9.21952 10.1105 8.95112 10.625 8.81825V9.125C10.625 9.53921 10.9608 9.875 11.375 9.875H13.625C14.0392 9.875 14.375 9.53921 14.375 9.125V2.375C14.375 1.96079 14.0392 1.625 13.625 1.625H11.375C11.0065 1.625 10.7 1.89082 10.6369 2.24119L8.9851 1.76938L8.98499 1.76934C8.65027 1.67369 8.30385 1.62511 7.95573 1.625H7.9555H5.51301C4.58336 1.62499 3.68685 1.97029 2.9974 2.59391C2.30795 3.21753 1.87472 4.07501 1.78176 5C1.78072 5.0103 1.7799 5.02062 1.77929 5.03096L1.62929 7.58096L1.62927 7.58096L1.62897 7.58683C1.5936 8.28085 1.80026 8.96562 2.21367 9.5242C2.62707 10.0828 3.22157 10.4805 3.89564 10.6495C3.90302 10.6513 3.91043 10.6531 3.91786 10.6547L4.625 10.8093V12.5C4.625 12.9973 4.82254 13.4742 5.17418 13.8258C5.52581 14.1775 6.00272 14.375 6.5 14.375ZM12.125 3.125V7.973V8.375H12.875V3.125H12.125ZM10.625 3.78963C10.5461 3.77253 10.4678 3.75286 10.39 3.73067L10.39 3.73066L8.57301 3.21166L8.5729 3.21163C8.37211 3.15424 8.16432 3.12508 7.9555 3.125H7.95527H5.51299C4.9552 3.12499 4.4173 3.33217 4.00363 3.70635C3.59352 4.07729 3.33451 4.58615 3.27573 5.13566L3.1269 7.66578C3.10986 8.0119 3.21319 8.35327 3.41938 8.63186C3.62404 8.9084 3.91748 9.1061 4.25041 9.19198L4.96564 9.3483L4.986 9.35304C5.31118 9.43368 5.60002 9.62085 5.80645 9.88473C6.01287 10.1486 6.12501 10.474 6.125 10.809V10.809V12.5C6.125 12.5995 6.16451 12.6948 6.23483 12.7652C6.30516 12.8355 6.40054 12.875 6.5 12.875C6.59946 12.875 6.69484 12.8355 6.76516 12.7652C6.83549 12.6948 6.875 12.5995 6.875 12.5V11.723C6.875 10.5295 7.34911 9.38493 8.19302 8.54102C8.85804 7.876 9.70977 7.44062 10.625 7.28592V3.78963Z" fill= {like.dislike ? "#d02060" : "#1B313D"} fillOpacity={like.dislike ? "1":"0.3"}></path>
                            </svg>
                            <div style={{color:'#d02060'}}>{likeDislike.dislike} &nbsp;&nbsp;</div>
                        </span> 
                    </div>
                    </div>
                </Grid>
            </Grid>
 
             <Grid item container direction="row" spacing={1}>
                <Grid item xs={6}>
                {/* <p className="heading" style={{fontSize:'0.6em'}}>
                    {item.postType==1 ? 'Origional':'Improved'}&nbsp;Post By -&nbsp;&nbsp;
                    <span>&nbsp;{item.createdBy}</span>
                </p> */}
                    
                </Grid>
                {/* <Grid item xs={1}></Grid> */}


                <Grid item xs={6} container justifyContent='flex-end'>
                    <div style = {{display:'inline-flex'}}>
                        {item.canEdit && <button type='button' className="btnLow" onClick={()=>props.improvePost({item, index, mode: 'edit'})}>Edit</button>}
                        <button type='button' className="btnLow" style={{marginLeft:'1em'}} onClick={()=>props.improvePost({item, index, mode: 'improve'})}>Improve</button>
                        {/* {item.canEdit && <Button style={{background:'#44c6f4',color:'white', marginLeft:'2em', height:'2em', borderRadius:'20px' }} variant="contained" color="seconary"  onClick={()=>props.improvePost({item, index, mode: 'edit'})}>
                                Edit
                        </Button>}
                        <Button style={{background:'#44c6f4',color:'white', marginLeft:'2em', height:'2em', borderRadius:'20px' }} variant="contained" color="seconary"  onClick={()=>props.improvePost({item, index, mode: 'improve'})}>
                                Improve
                        </Button> */}
                    </div>
                </Grid>
                {/* <Grid item xs={1}>
                    <div className="btnLow" style={{marginLeft:'1em'}} onClick={()=>props.improvePost({item, index})}>Improve</div>
                </Grid> */}

            </Grid>
            <Grid className="svg" item container direction="row" style={{}}>
                <Grid item xs={full} style={{padding:'1px'}}> 
                <DiagramComponent ref={diagram => (diagramInstance = diagram)}  width={'100%'} height ={'700px'} rowAutoHeight={true} style={{overflow:'hidden', borderRadius:'15px', border:'2px solid #e0e0e0'}} snapSettings = {{
                    horizontalGridlines: gridlines,
                    verticalGridlines: gridlines
                    }} 
                    nodes = {nodes(item.draw.nodes)} 
                    connectors = {connectors(item.draw.connectors)}
                    getNodeDefaults = {(node) => getNodeDefaults(node)}
                    getConnectorDefaults = {(obj) => getConnectorDefaults(obj)}
                    dragEnter = {(args) =>dragEnter(args)}
                    created={() => {
                        // Sets the zoomFactor
                        // Defines the focusPoint to zoom the Diagram with respect to any point
                        // When you do not set focus point, zooming is performed with reference to the center of current Diagram view.
                        diagramInstance.zoom(zm, {
                        x: 0,
                        y: 0,
                        });
                        diagramInstance.constraints = DiagramConstraints.None;
                    }}
                    scrollSettings={{
                        horizontalOffset: 0,
                        verticalOffset: 0,
                    }}
                    // pageSettings={{
                    //     // Sets the PageOrientation for the diagram to page
                    //     boundaryConstraints: 'Page',
                    //     orientation: 'Landscape',
                    //     // Sets the Page Break for diagram
                    //     showPageBreaks: true,
                    //     // Defines the background color and image  of diagram
                    //     background: {
                    //         color: 'grey',
                    //     },
                    //     // Sets the width for the Page
                    //     width: 600,
                    //     // Sets the height for the Page
                    //     height: 600,
                    //     // Sets the space to be left between an annotation and its parent node/connector
                    //     margin: {
                    //         left: 10,
                    //         top: 10,
                    //         bottom: 10,
                    //     },
                    // }}
                />
            </Grid>
      </Grid>
        <Grid item container direction="row">
            {/* <Grid item xs={3}></Grid> */}


            <Grid item xs={12}>
                <div style={{ display:'inline-flex', marginLeft:'0em',marginTop:'1.5em'}}>
                    <p style={{fontWeight:'bold', fontSize:'1.4em'}} >Design Overview</p>
                    <IconButton style={{marginLeft:'1em',fontSize:'2em'}}
                            aria-label="expand row"
                            size="small"
                            onClick={()=>setShow(!show)}
                        >
                            {show ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                    </IconButton>
                </div>
            </Grid>
        </Grid>
        

        <Grid item container direction="row">
            {/* <Grid item xs={3}></Grid> */}

            <Grid item xs={12}>
                <Collapse in={show} timeout="auto" unmountOnExit>
                    <TextEditor  toolbar={toolbar} readOnly={!toolbar} content={item.content}></TextEditor>
                </Collapse>
            </Grid>
        </Grid>

        {comments && comments.map((comment, key)=> {
            let tlbr = false;
            let ronly = true;
            let col = 12;
            let name = comment.name ? comment.name : 'Anonymous';
            let viewOnly = key < comments.length - 1;
            if(comments.length == key + 1) {
                tlbr = true;
                ronly = false;
                col = 11;
            }
            //console.log('comment:', comment)
            return <div key={key} style={{marginTop:'0.4em', marginBottom:'0em'}}>
                 { viewOnly ? 
                   <div>
                        <Grid item container direction="row"> 
                            <Grid xs={6} item>
                                <div style={{display:'inline-flex','alignItems':'center', marginBottom:'0.4em'}}>
                                    <div className="avatar">
                                        <span className="user user--refresh">
                                            <div>
                                                {name && name.substring(0,1) }
                                            </div>
                                        </span>
                                    </div>
                                    <div style={{ height:'100%', color:'#219bcb', fontSize:'1em',marginLeft:'0.8em', fontWeight:'bold'}}>{name}</div>
                                </div>
                            </Grid>
                        </Grid>
                        <Grid item container direction="row">
                            <Grid xs = {12}>
                                <TextEditor  toolbar={tlbr} readOnly={ronly} content={comment.comment}></TextEditor>
                           </Grid>
                        </Grid>
                    </div>
                 :
                 <Grid item container direction="row">
                    <Grid item xs={1}>
                        <div className="avatar" style={{ display:'inline-flex', alignItems:'center', marginBottom:'0.4em'}}>
                            <span className="user user--refresh">
                                <div>A</div>
                            </span>
                        </div>
                    </Grid>
                    <Grid item xs={11}>
                        <TextEditor  toolbar={tlbr} readOnly={ronly} mode='comment' content={comment.comment} contentChanged={(value)=>commentChange(value)}></TextEditor>
                    </Grid>
                 </Grid>
                 }
             </div>
            })
        }

        <Grid item container direction="row" spacing={2}>
                    <Grid item xs={1}>
                    </Grid>
                    <Grid item xs={2} >
                        <div className="btnSquare" onClick={()=>saveComment()}>Save</div>
                    </Grid>
        </Grid>
    </Grid>)

}

export default ViewDiagram