
// const style = {
//     textBold: {
//         fontWeight:'bold',
//         fontSize: 20,
//         display: 'inline-block',
//     },
//     text:{
//         fontSize: 20,
//         display: 'inline-block',
//     }
// }

export const wordFormatter = (work)=>{
    //if(sentense==null) return <></>

    //console.log('sentense',work)
    let words = work.split(' ');
    let b = false;
    return words.map((_, key)=>{

        let word = words[key];
        //console.log("word::", word)
        if (b && word.indexOf('**')<0) {
            return <div key={key} className="textBold">{word}&nbsp;</div>
        } else if(word && word.indexOf('**')>=0){

            if ( word.startsWith("**") && (word.endsWith("**") || word.endsWith("**,")) ) {
                word = word.replace('**','');
            } else if(b){
                b = false;
            } else {
                b = true;
            }

            word = word.replace('**','');

            return <div key={key} className="textBold">{word}&nbsp;</div>
        }
        return <div key={key} className="text">{word}&nbsp;</div>

        // if(word && word.indexOf('<b>') >=0 && word.indexOf('</b>') >=0) {
        //     word = word.replace('<b>','');
        //     word = word.replace('</b>','');
        //     return <div className="textBold">{word}&nbsp;</div>
        // } else if(word && word.indexOf('<b>') >=0) {
        //     b = true;
        //     word = word.replace('<b>','');
        //     return <div className="textBold">{word}&nbsp;</div>
            
        // } else if(word && word.indexOf('</b>') >=0) {
        //     b = false;
        //     word = word.replace('</b>','');
        //     return <div className="textBold">{word}&nbsp;</div>
        // } else if(b && word){
        //     return <div className="textBold">{word}&nbsp;</div>
        // } else if(word){
        //     return <div className="text">{word}&nbsp;</div>
        // }
    } )
}