import { fetchDataWoToken } from "../../config/apiService"
import apiList from "../../config/apiList";

export const loadBlobData = async(file)=>{

    try {
        const signedurl = apiList.signedurl;
        const api = `${signedurl}?filename=${file}`;
        //const api = `${signedurl}`;

        const {url} = await fetchDataWoToken(api);
        console.log('signed url', url)

        return url;
    
        } catch(err) {
                console.log('Some error:', err)
        }

        return null;
    }
 
//  export const loadBlobData = async(file)=>{
//      return generateCloudinaryUrl(file); 
//  }
 
//  const cloudinaryBasePath = 'https://res.cloudinary.com/dbg5xqub7/raw/upload/v1730139034'
 
//  function generateCloudinaryUrl(fileName) {
//      return `${cloudinaryBasePath}/${fileName}`;
//  }
    
