import React, { useContext, useEffect, useRef, useState } from "react";
import CodeEditorWindow from "./CodeEditorWindow";
import axios from "axios";
import { classnames } from "../utils/general";
import { languageOptions } from "../constants/languageOptions";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { defineTheme } from "../lib/defineTheme";
import useKeyPress from "../hooks/useKeyPress";
//import Footer from "./Footer";
import OutputWindow from "./OutputWindow";
import CustomInput from "./CustomInput";
import OutputDetails from "./OutputDetails";
import ThemeDropdown from "./ThemeDropdown";
import LanguagesDropdown from "./LanguagesDropdown";
import TextEditor from "../../editor-text/TextEditor";
import { useDispatch, useSelector } from "react-redux";
import apiList from "../../../../config/apiList";
import SessionClock from "../../../utils/clock/SessionClock";
import { Collapse, Grid, ListItem, ListItemText } from "@material-ui/core";
import { setWorkSpaceItems } from "../../../../state/action-creators/workspace";
import { SetPopupContext } from "../../../home/component/MainRouter";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { API, saveData } from "../../../../config/apiService";
import { loadMyWorkspace, loadWorkspace } from "../../../../utils/datautil/dataUtils";
import { saveAssessNRank } from "../../../../utils/skillRankUtils";
import { showErrorToast, showSuccessToast } from "../../../../utils/toastMsg";
import { isMobile } from "../../../../utils/mobileUtil";

const Landing = (props) => {
  const [open, setOpen] = useState(true);
  const setPopup = useContext(SetPopupContext);
  let workspace = useSelector(state=>state.workspace);
  const token = useSelector(state=>state.profile.profile.token);
  let current = workspace.selected;
  let selected = workspace.items[current.index];
  const dispatch = useDispatch();
  const childRef = useRef();
  const [code, setCode] = useState('');//useState(javascriptDefault);
  const [outputDetails, setOutputDetails] = useState(null);
  const [processing, setProcessing] = useState(0);
  const [theme, setTheme] = useState("cobalt");
  const [language, setLanguage] = useState(languageOptions[0]);
  const [count, setCount] = useState(0);
  const [error, setError] = useState(true);

  
  const MAX_ATTEMPT = 10;

  const enterPress = useKeyPress("Enter");
  const ctrlPress = useKeyPress("Control");

  //childRef.current?.getAlert(selected.time);
  //setTimeout(()=> {setCode(props.problem.desc)},100);
  const onSelectChange = (sl) => {
    //console.log("selected Option...", sl);
    setLanguage(sl);
  };

  useEffect(() => {
    if (enterPress && ctrlPress) {
      // console.log("enterPress", enterPress);
      // console.log("ctrlPress", ctrlPress);

      handleCompile();

    }
    

    let temp = (selected.lang && languageOptions.filter(v=>v.value===selected.lang).length>0) ?  languageOptions.filter(v=>v.value===selected.lang)[0] : languageOptions[0];
    console.log('language::::::::::', selected.lang, language, temp)
    
    setLanguage(temp);

  }, [selected, ctrlPress, enterPress]);
  const onChange = (action, data) => {
    //console.log('code123:::', data)
    setError(true);
    switch (action) {
      case "code": {
        setCode(data);
        //saveCode(data);
        break;
      }
      default: {
        console.warn("case not handled!", action, data);
      }
    }
  };

  const updateRank = (output)=>{
    let data = {};
    data.assess = {intId: selected.intId, status: output.status};
    data.title = selected.title;
    data.coins = output.coins;
    saveAssessNRank(data, "Code Test", "code", setPopup);
  }

  const submitCode = () => {
    console.log('submitCode:');
    handleCompile('submit');
    setCount(count + 1);
  }

  const handleCompile = (hasSubmit) => {
    if (isMobile) {
      setOpen(false);
    }
    console.log('compile:', hasSubmit);
    saveCode();
    let formData = {}

    try{
        setProcessing(1);
        if (hasSubmit === 'submit') {
          setProcessing(2);
          formData = {
            id: language.id,
            lang: language.value,
            _id: selected._id,   // if submit execute server test cases
            intId: selected.intId, 
            coins : selected.coins ? selected.coins: null,
            attempt : selected.attempt ? selected.attempt: null, 
            level: 'easy',
            code: code, //btoa(JSON.stringify(code)),
            submit: true,
          };
        } else {
          formData = {
            id: language.id,
            lang: language.value,
            code:  code, //btoa(JSON.stringify(code)),
          };
        }

        console.log('formData:',formData)
        axios
          .post(
            `${apiList.execute}`,
            {
              data: formData,
            },
            {
              headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`,
              },
            }
          )
          .then((response) => {
            setProcessing(0);
            console.log('output:', response.data);
            console.log('error:', response.data.stderr, response.data.stderr);
            setError(response.data.stderr !== null);

            setOutputDetails(response.data);
            props.refresh && props.refresh();
            
            if(response.data?.submit === true && response.data?.status ==='Passed') {
                console.log('Update code part')
                updateRank(response.data);
                showSuccessToast("Submit Success")
            } else if (hasSubmit === 'submit') {
                showErrorToast("Submit Error, Test Cases Failed", 3000);
            }
          })
          .catch((err) => {
            console.log('error:', err.response);
            showErrorToast(err.response, 3000);
            setProcessing(0);
          });
      }catch(ex){
        console.log('Save or Submit Error', ex);
      }finally {
      }
    
   }

   const saveCode = ()=>{
    let temp = [...workspace.items];
    temp[current.index].code = code;
    temp[current.index].lang = language.value;
    dispatch(setWorkSpaceItems(temp));
   }

   function handleThemeChange(th) {
    const theme = th;
    console.log("theme...", theme);

    if (["light", "vs-dark"].includes(theme.value)) {
      setTheme(theme);
    } else {
      defineTheme(theme.value).then((_) => setTheme(theme));
    }
  }
  useEffect(() => {
    defineTheme("oceanic-next").then((_) =>
      setTheme({ value: "oceanic-next", label: "Oceanic Next" })
    );
  }, []);


  const savePractice = async(hasSubmit)=>{
    //console.log("save workspace: ", selected)
    setProcessing(1);
    try{
    let data = {};
    data = {
      _id: selected._id,
      category: selected.category,
      group: selected.group,
      title: selected.title,
      //desc: selected.desc,
      lang: language.value,
      code: code, //btoa(JSON.stringify(code)),
    }
    if(hasSubmit==='submit') {
      setProcessing(2);
      data.mode = selected.mode
      data.level = selected.level;
      data.attempt = count + 1;
      data.submit = true;
      setCount(count+1);
    } 

    let response = await saveData(API.workspace, data, selected.category, setPopup);
    console.log('output:', response, response.data);
    if (hasSubmit === 'submit') {

      if (response.data?.submit === true) {
       
        console.log('error:', response.data, response.data.stderr);
        setError(response.data.stderr !== null);

        setOutputDetails(response.data);
        if(response.data?.submit === true && response.data?.status ==='Passed') {
          console.log('Update code part')
          //updateRank(response.data);
          showSuccessToast("Submit Success")
        } else if (hasSubmit === 'submit') {
          showErrorToast("Submit Error, Test Cases Failed", 3000);
        }
      } else {
        showErrorToast("Submit Error From Server, Some Error", 3000);
      }
    } 
  }catch(ex){
    console.log('Save or Submit Error', ex);
  }finally {
    setProcessing(0);
  }

  //loadWorkspace(dispatch);
  }

  const submitPractice = ()=>{
    savePractice('submit');
  }


   const renderTestIntBlock = ()=><>
        <Grid item>
          <button type='button' disabled={processing===1} className="btnLow" style={{marginLeft:'1em', minHeight:'2.6em', minWidth:'8em', cursor:`${processing===1?'wait':''}`, backgroundColor:`${processing===1?'#9c9c9c':''}`}} onClick={()=>handleCompile()}>
            {processing===1 ? "Processing..." : "▶ Run Code"}
          </button>
        </Grid>
        {selected.sample &&
        <Grid item>
          <button type='button' disabled={processing===2} className="btnLow" style={{marginLeft:'1em', minHeight:'2.6em', cursor:`${processing===2?'wait':''}`, backgroundColor:`${processing===2?'#9c9c9c':''}` }} onClick={()=>submitCode()}>
            {processing===2 ? "Submitting..." : " Submit"}
          </button>
          <span style={{color:`${count > 10 ? 'red':''}`}}>&nbsp;&nbsp;&nbsp;{count}/{MAX_ATTEMPT}</span>
        </Grid>}
   </>

  
   const renderPracticeBlock = ()=><>
        <Grid item>
          <button type='button' disabled={processing===1} className="btnLow" style={{marginLeft:'1em', minHeight:'2.6em', minWidth:'8em', cursor:`${processing===1?'wait':''}`, backgroundColor:`${processing===1?'#9c9c9c':''}`}} onClick={()=>handleCompile()}>
            {processing===1 ? "Processing..." : "▶ Run Code"}
          </button>
        </Grid>
        {token && <>
          <Grid item>
            <button type='button' disabled={error} className="btnLow" style={{marginLeft:'1em', minHeight:'2.6em', backgroundColor:`${(processing===1 || error)?'#9c9c9c':''}`}} onClick={()=>savePractice()}>Save</button>
          </Grid>
      
          {selected.sample &&
          <Grid item>
            <button type='button' disabled={processing===2 || error} className="btnLow" style={{marginLeft:'1em', minHeight:'2.6em', cursor:`${processing===2?'wait':''}`, backgroundColor:`${ (processing===2 || error)?'#9c9c9c':''}` }} onClick={()=>submitPractice()}>
              {processing===2 ? "Submitting..." : " Submit"}
            </button>
            <span style={{color:`${count > 10 ? 'red':''}`}}>&nbsp;&nbsp;&nbsp;{count}/{MAX_ATTEMPT}</span>
          </Grid>}
          </>
        }
  </>



  return (
    <>
      <ToastContainer
        position="top-right"
        autoClose={2000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <Grid container direction="column" style={{width:'98.8%',  cursor:`${(processing===1 || processing===2)?'wait':''}`}}         
      onKeyDown={(ev) => {
        console.log(ev);
                if (ev.ctrlKey === true &&  ev.key === "V") {
                
                }
        }}>
        <Grid item container direction="row" spacing={2} alignItems="center">
            <Grid item xs={3}  style={{marginLeft:'0.2vw'}}>
              <LanguagesDropdown language={language} onSelectChange={onSelectChange} />
            </Grid>
            <Grid item xs={3}>
              <ThemeDropdown handleThemeChange={handleThemeChange} theme={theme} />
            </Grid>
            {
              selected.mode === 'practice' ? renderPracticeBlock() : renderTestIntBlock()
            }

        </Grid>
        <Grid item container direction="row" spacing={1} style={{marginLeft:'0.3vw', marginTop:'2vh'}}>
          <Grid item xs={isMobile?11:8} style={{background:'black', borderRadius:'5px', margin:'2px'}} >
            <CodeEditorWindow
              code={code}
              onChange={onChange}
              language={language?.value}
              theme={theme.value}
            />
          </Grid>

           <Grid item xs={isMobile?'auto':4} container direction="column" spacing={1} >
                <Grid item>
                    <ListItem style={{fontSize:'6px'}} button onClick={()=>setOpen(!open)}>
                      <ListItemText>
                        <div className="menu" style={{marginLeft:'-1em'}}><span style={{fontWeight:'bold', fontSize:'1.2em'}} >{selected.title}</span></div>
                      </ListItemText> 
                      {open ? <ExpandLessIcon/> : <ExpandMoreIcon/>}
                    </ListItem>
                    <Collapse in={open} timeout="auto" unmountOnExit>
                      {selected ? <TextEditor  toolbar={false} content={selected.desc} readOnly={true}></TextEditor>:<></>}
                    </Collapse>
                </Grid>
                <Grid item style={{height:'50vh'}}>
                    <OutputWindow  outputDetails={outputDetails} />
                </Grid>
            </Grid>
        </Grid>
      </Grid>
    </>
  );
};
export default Landing;
