import React, { useState } from "react";
import { render } from "react-dom";
import ReactQuill from "react-quill";
import 'quill/dist/quill.core.css';
import 'quill/dist/quill.snow.css';
import "./style.scss";
import { classnames } from "../editor-code/utils/general";
//import CustomCode from './QuillHighlight'

const Quill = ReactQuill.Quill
var Font = Quill.import('formats/font');
Font.whitelist = ['Ubuntu', 'Raleway', 'Roboto'];
Quill.register(Font, true);

// CustomCode.blotName = "code-custom";
// CustomCode.tagName = "pre";
// CustomCode.className = "ql-syntax";
// Quill.register(CustomCode);

function TextEditor({toolbar, content, contentChanged, readOnly=false, mode}) {

  let clsName = "text-black dark:text-white " + mode;
  clsName = toolbar ? clsName : ( clsName +  ' noToolbar')
  let modules = !toolbar ?
                {
                  toolbar: false
                }
                :
                {
                  toolbar: [
                      [{ font: [] }],
                      [{ header: [1, 2, 3, 4, 5, 6, false] }],
                      ["bold", "italic", "underline", "strike"],
                      [{ color: [] }, { background: [] }],
                      [{ script:  "sub" }, { script:  "super" }],
                      ["blockquote", "code-block"],
                      [{ list:  "ordered" }, { list:  "bullet" },],
                      [{ indent:  "-1" }, { indent:  "+1" }, { align: [] }],
                      ["link", "image", "video"],
                      ["clean"],
                  ],
                  clipboard: {
                    matchVisual: false
                }
                };

  if(toolbar == false) modules =  {
    toolbar: false
  }
  const formats = [
    'code-block',
    "header",
    "bold",
    "italic",
    "underline",
    "strike",
    "blockquote",
    "list",
    "bullet",
    "indent",
    "link",
    "color",
    "image",
    "background",
    "align",
    "size",
    "font"
  ];

  const [code, setCode] = useState(content);
  const handleProcedureContentChange = (content, delta, source, editor) => {
    //setCode(content);
    //let has_attribues = delta.ops[1].attributes || "";
    //console.log(has_attribues);
    //const cursorPosition = e.quill.getSelection().index;
    // this.quill.insertText(cursorPosition, "★");
    //this.quill.setSelection(cursorPosition + 1);
    //console.log(content,delta, source);
  };

  const style ={
    color:'#585858!important',
    background:'rgb(129 139 144 / 5%)!important',
    letterSpacing: '.2px',
    fontSize: '13px',
  }



  return (
    <div className='editor-wrapper'>
      <ReactQuill
      theme="snow"
      modules={modules}
      formats={formats}
      value={content}
      onChange={contentChanged}
      readOnly = {readOnly}
      className= {clsName}
      style={style}
      
    />
  
    </div>
  );
}

export default TextEditor;


