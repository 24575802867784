import React, { useState, useEffect, useContext } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  Button,
  Grid,
  Paper,
  TextField,
  Modal,
  FormControlLabel,
  MenuItem,
  ListItem,
  ListItemText,
  Collapse,
  TableRow,
  IconButton,
  TableCell,
} from "@material-ui/core";
import { Radio } from "@mui/material";
import { SetPopupContext } from "../home/component/MainRouter";
import {API, fetchData, saveData} from "../../config/apiService"
import {manageInterview} from "../../state/action-creators/workspace"
import TextEditor from "../editor/editor-text/TextEditor";
import { ExpandLess, ExpandMore } from "@material-ui/icons";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import CreateTemplate from "./CreateTemplate";

const InterviewTemplate = (props)=>{
    const dispatch = useDispatch();
    const setPopup = useContext(SetPopupContext);
    const [show, setShow] = useState();
    const [templateData, setTemplateData] = useState();


    useEffect(() => {
        refreshData();
    }, []);

    const refreshData = async()=>{
        let tempData = await fetchData(API.template, "Template", null, null, setPopup);
        console.log('tempData:', tempData)
        setTemplateData(tempData);
        //console.log('refreshData invoked:', sessions)
    }


    const renderTemplates = (type)=>{
        return <div>
            <ul>
            { 
            templateData && templateData.filter(item=>item.category === type).map((item, key)=> {
                return <React.Fragment key={key}>
                        <TableRow>
                            <TableCell >
                                <IconButton style = {{marginLeft:'-1em'}}
                                    aria-label = "expand row"
                                    size = "small"
                                    onClick = {()=>setShow((show === item.title) ? "": item.title)}
                                >
                                {show === item.title ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                                </IconButton>
                            </TableCell>
                            <TableCell component="th" scope="row">
                                <span style = {{marginLeft:'-2em', textAlign:'left'}}>{item.title}</span>
                            </TableCell>
                        </TableRow>
                    <Collapse style = {{marginLeft:'2em'}} in={show===item.title} timeout="auto" unmountOnExit>
                        <TextEditor  toolbar={false} content={item.desc} readOnly={true}></TextEditor>
                    </Collapse>
                </React.Fragment>
                }
                )
            }
            </ul>
        </div>
    }

     return  <Grid className="md:flex mt-4" container direction="row">
                <Grid className='w-full md:w-[70%]'>
                    <CreateTemplate refreshData={refreshData}></CreateTemplate>
                </Grid>
                <Grid className='w-full md:w-[30%]'>
                    <Grid className='md:ml-2'>
                        <h4>Your Templates</h4>
                    </Grid>
                    <div className="p-2 mt-2  md:ml-2 bg-gray-200 border-r-8">
                        <Grid item >
                                <div style={{marginTop:'0em', marginBottom:'1.1rem'}}><strong>Code Templates:</strong> 
                                    {renderTemplates('code')}
                                </div>
                        </Grid>
                        <Grid item>
                                <div style={{marginTop:'0em', marginBottom:'1.1rem'}}><strong>Design Templates:</strong> 
                                    {renderTemplates('design')}
                                </div>
                        </Grid>
                    </div>              
                </Grid>
        </Grid>
    //   </Paper>
  //</Modal>
}

export default InterviewTemplate;


