import { Grid, Switch, Typography } from "@material-ui/core";
import { useState } from "react";
import TabLinkGroup from "./TabLinkGroup";
import ProfileSearch from "../../profilesearch/ProfileSearch";
import JobApplications from "../JobApplications";
import AddCandidate from "./AddCandidate";
import ImportCandidate from "./ImportCandidate";
import { isMobile } from "../../../utils/mobileUtil";
import AssessTestLink from "./AssessTestLink";


const TestLinkLanding = ()=> {

    const [tab, setTab] = useState(0);
    const [list, setList] = useState();
    
    //const getStyle = (v)=> (view == v) ? { color:'blue' } : {color:'black'};
    const getStyle = (v)=> {
        return {
            fontWeight:'bold',
            cursor:'pointer',
            fontSize:'1.1em',
        }
    }
    
    const setTabIndex = (v) =>{
       setTab(v);
    }
    
    const renderTabView = ()=>{
        switch(tab) {
            case 0:
                return <ProfileSearch/>
            case 1: 
                return <JobApplications mode={"link"} setList={setList}/>
            case 2:
                return <ImportCandidate/>
            case 3: 
                return <AddCandidate/>
            default:
                return <AddCandidate/>
        }

    }


    return <Grid container direction="column" style={{marginLeft:'20px', width:'99%', padding:'2em', borderRadius:'15px',border:'2px solid #e0e0e0', borderColor:'#e0e0e0'}}>
            <Grid item>
                <Typography style={{display:'flex', justifyContent:'center', marginBottom:'0.4em'}} variant={`${isMobile?'h3':'h5'}`}>Recruiter Assessment Test Link Wizard</Typography>
            </Grid>
            <Grid item container direction="row" spacing={2}>
                <TabLinkGroup setTabIndex={setTabIndex}></TabLinkGroup>
            </Grid>

            <Grid item container direction="row" spacing={2}>
                <Grid item xs ={6} style={{marginTop:'1em'}}>
                {   
                    renderTabView()
                }
                </Grid>
                <Grid item xs ={6} style={{marginTop:'1em'}}>
                    <AssessTestLink profiles = {list} />
                </Grid>
            </Grid>
        </Grid> 
   
}

export default TestLinkLanding;