const buildSysout = (problem, level)=>{
    let out = ""; //
    let inout = problem.type.split('#');
    let params = inout[0].split('-');
    let allCases = "";

    for (let k = 0; k < problem[level].length; k++) {
        out = "";
        for(let i = 0; i < params.length; i++) {
            if (params[i] === 'ia') {
                out +=  `new int []${problem[level][k].in[i]}`
            } else if (params[i] === 'sa') {
                out +=  `new String []${problem[level][k].in[i].replaceAll("'",'"')}`
            } else if (params[i] === 'ba') {
                out +=  `new boolean []${problem[level][k].in[i]}`
            } else if (params[i] === 'i') {
                out +=  `${problem[level][k].in[i]}`
            }else if (params[i] === 's') {
                out +=  `"${problem[level][k].in[i]}"`
            } else if (params[i] === 'b') {
                out +=  `${problem[level][k].in[i]}`
            }
            if (params.length>1  &&  i<params.length-1)  {
                out +=', '
            }
        }


       
        let expected = '';
        if(inout[1] === 'i' || inout[1] === 'b') {
            expected = `==${problem[level][k].out}`
            allCases += `System.out.println(solve(${out})${expected});`
        } else if(inout[1] === 's') {
            expected = `.equals("${problem[level][k].out}")`
            allCases += `System.out.println(solve(${out})${expected});`
        } else if(inout[1] === 'ia' || inout[1] === 'ba' || inout[1] === 'sa') {

            if(inout[1] === 'ia') expected = `new int[]${problem[level][k].out}`
            if(inout[1] === 'sa') {
                expected = `new String[]${problem[level][k].out.replaceAll("'",'"')}`
            }
            if(inout[1] === 'ba') expected = `new boolean[]${problem[level][k].out}`
            allCases += `System.out.println(Arrays.equals(solve(${out}),${expected}));`
        }

        if (problem[level].length > 1) {
            allCases +='\n\t    ';
        }
    }
    return allCases;
}

const buildCode = (problem, level)=> {

    //console.log('problem:', problem);

    let funcRet = 'result';
    let funcRetType = 'int';
    let funcVar = 'int result = 0;'
    let inout = problem.type.split('#');
    
    //################################## RETURN TYPE AND FUNC VARIABLES ########################
    if(inout[1]==='s') {
        funcVar = 'String result = "";'
        funcRetType = "String"
    } else if(inout[1]==='ia') {
        funcVar = 'int[] result = {};'
        funcRetType = "int[]"
    } else if(inout[1]==='sa') {
        funcVar = 'String[] result = {};'
        funcRetType = "String[]"
    } else if(inout[1]==='ba') {
        funcVar = 'boolean[] result = {};'
        funcRetType = "boolean[]"
    }
    else if(inout[1]==='b') {
        funcVar = 'boolean result = false;'
        funcRetType = "boolean"
    }

   //################################## FUNC ARGUMENTS ##########################################
    let args = "";
    
    let params = inout[0].split('-');

    let ar= 0;
    let num = 0;
    for(let i = 0; i < params.length; i++) {
        if (params[i] === 'ia') {
            args += `int[] arr${ar===0 ? '' : (ar+1)}`;
            ar++;
        } else if (params[i] === 'sa') {
            args += `String[] arr${ar===0 ? '' : (ar+1)}`;
            ar++;
        }else if (params[i] === 'ba') {
            args += `boolean[] arr${ar===0 ? '' : (ar+1)}`;
            ar++;
        } else if (params[i] === 'i') {
            args += `int num${num===0 ? '' : (num+1)}`;
            num++;
        } else if (params[i] === 's') {
            args += `String str${num===0 ? '' : (num+1)}`;
            num++;
        } else if (params[i] === 'b') {
            args += `boolean b${num===0 ? '' : (num+1)}`;
            num++;
        }


        if (params.length>1 && i < params.length - 1) {
            args +=', '
        }
    }

    //############################## BUILD OUTPUT MATCH ###################################
    let sysout = buildSysout(problem, level);

    return`
    import java.util.*;

    public class Solution {
    
        public static ${funcRetType} solve(${args}) {
            ${funcVar}

            // WRITE YOUR CODE HERE




            return ${funcRet};
        }

        // DON'T CHANGE OR REMOVE ANY LINE FROM MAIN FUNCTION
        public static void main(String[] args) {

            ${sysout}

        }
    }
    `
}

const replace = (code, problem, level)=>{

    let sysoutLevel = buildSysout(problem, level);
    let arrayOfLines = code.match(/[^\r\n]+/g);

    let i = 0;
    let codeLevel = "";
    let flag = false;
    while(i < arrayOfLines.length) {
        let line = arrayOfLines[i];

        if (flag === false) {
            codeLevel += line +"\r\n";
        } else {

        }
        if (line.indexOf('main') >= 0) {
            flag = true;
        }
        
        if (flag === true && line.trim().length===1 && line.indexOf('}') >= 0) {
            codeLevel +="\r\n";
            codeLevel += sysoutLevel + "\r\n";
            codeLevel +=line +"\r\n";
            flag = false;
        }
        i++;
    }
    return codeLevel;
}


const codeBlock = [
    `class Solution {`,
    ``,
    `\tpublic static int verify(int[] arr) {`,
    `\t\tint result = 0;`,
    `\t\treturn result;`,
    `\t}`
]


export const buildJavaMain = (problem)=>{
    if(problem) {
        return buildCode(problem, 'sample')
    }
}


const selected = {
    "title": "Number Sum Recursive",
    "desc": "<p>Given an integer array, write a program to sum them recursive.</span></p><blockquote>Example.</blockquote><blockquote>input: arr = [4,7,3,6,8,1] </blockquote><blockquote>output: 29</blockquote>",
    "category": "code",
    "level": "easy",
    "mode": "test",
    // "type": "sa-i#s",
    // "sample": [{ "in": ["{3,5}"], "out": 8 }],
    "type": "ia-i-s-ba-b#i",
    "sample": [{ "in": ["{3,5}",6, "7", "{true, false}", true], "out": 8 }],
    "easy": [{ "in": ["{3,5}",6, "7", "{true, false}", true], "out": 8 }],
    // "easy": [
    //   { "in": ["{'3','5','6','7'}", 5], "out": 21 },
    //   { "in": ["{3,7}"], "out": 9 },
    //   { "in": ["{3,5,7}"], "out": 15 }
    //]
  }
  const selected12 = {
    "title": "Number Sum Recursive",
    "desc": "<p>Given an integer array, write a program to sum them recursive.</span></p><blockquote>Example.</blockquote><blockquote>input: arr = [4,7,3,6,8,1] </blockquote><blockquote>output: 29</blockquote>",
    "category": "code",
    "level": "easy",
    "mode": "test",
    "type": "sa#i",
    "sample": [{ "in": ["{'acb','bcd'}"], "out": 21 }],
    "easy": [{ "in": ["{'acb','bcd'}"], "out": 21 }]
  }

  const selected3 = {
    "level": "easy",
    "mode": "test",
    "type": "sa#s",
    "sample": [{ "in": ["{'acb','bcd'}"], "out": '23' }],
    "easy": [{ "in": ["{'acb','bcd'}"], "out": '29' }]
  }

  const selected4 = {
    "level": "easy",
    "mode": "test",
    "type": "sa-i#ia",
    "sample": [{ "in": ["{'acb','bcd'}", 4], "out": "{ 1,2}"}],
    "easy": [{ "in": ["{'acb','bcd'}", 8], "out": "{ 11,33}" }]
  }

// let rr = buildCode(selected4, 'sample');
// console.log(rr);
// console.log('REPLACE MAIN WITH EASY')
// let essy = replace(rr, selected4, 'easy');

//console.log(essy)
