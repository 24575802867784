import { buildJSMain } from "./JSUtil";
import { buildCplusMain } from "./cplusUtil";
import { buildCsharpMain } from "./csharpUtil";
import { buildJavaMain } from "./javaUtil";
import { buildPythonMain } from "./pythonUtil";

export const mainProgJava =`class Solution {
  
    public static void main(String[] args) {

        System.out.printf("Write Code here!");
    }
}`

export const mainProgPy =`
    
    print(""Write Code here!")

`
export const mainProgJS =`
    
    console.log(""Write Code here!");

`

export const mainProgCsharp=`
    
using System;
using System.Collections.Generic;
using System.Linq;
using System.Text.RegularExpressions;
namespace Submit
{
    public class Solution {
        // DON'T CHANGE OR REMOVE ANY LINE FROM MAIN FUNCTION
        public static void Main(string[] args) {

            Console.WriteLine(""Write Code here!);
        }
    }
}

`
export const mainProgCplus=`
    
#include <iostream>
#include <string>
#include <vector>
using namespace std;

int main() {
	// DON'T CHANGE OR REMOVE ANY LINE FROM MAIN FUNCTION


  cout << ""Write Code here!" << endl;
  

  return 0;
}

`


export const buildMainBlock = (problem, lang)=>{
    console.log('lang:', lang)
    if (lang ==='java') {
        if (problem.mode) {
            return buildJavaMain(problem);
        } else {
            return mainProgJava;
        }
    } else if (lang ==='python') {
        if (problem.mode) {
            return buildPythonMain(problem);
        } else {
            return mainProgPy;
        }
    } else if (lang ==='javascript') {
        if (problem.mode) {
            return buildJSMain(problem);
        } else {
            return mainProgJS;
        }
    } else if (lang ==='csharp') {
        if (problem.mode) {
            return buildCsharpMain(problem);
        } else {
            return mainProgCsharp;
        }
    } else if (lang ==='cpp') {
    if (problem.mode) {
        return buildCplusMain(problem);
        } else {
            return mainProgCplus;
        }
    } 
    else
        return `print("hello")`
}