import { Grid } from "@material-ui/core";
import React from "react";
import DoneIcon from '@mui/icons-material/Done';
import ClearIcon from '@mui/icons-material/Clear';

const OutputWindow = ({ outputDetails }) => {

  console.log('outputDetails:', outputDetails)
  const getOutput = () => {
    //let statusId = outputDetails?.status?.id;
    let statusId = outputDetails?.exception?7:3;

    if (statusId === 6) {
      // compilation error
      return (
        <pre className="px-2 py-1 font-normal text-xs text-red-500">
          {(outputDetails?.compile_output)}
        </pre>
      );
    } else if (statusId === 3) {
      return (
          <pre style={{padding:'1em', color:'green', fontWeight:'bold', fontSize:'1.2em'}}>
            {(outputDetails.stdout) !== null
              ? `${(outputDetails.stdout)}`
              : null}
          </pre>
      );
    } else if (statusId === 5) {
      return (
        <pre className="px-2 py-1 font-normal text-xs text-red-500">
          {`Time Limit Exceeded`}
        </pre>
      );
    } else {
      return (
        <pre style={{padding:'1em', color:'red', fontSize:'1.2em'}}>
          {(outputDetails?.stderr)}
        </pre>
      );
    }
  };
  
  const renderSubmitMessage = (title, cases)=>
    cases&&<div style={{display:'grid', width:'16em', padding:'0.5em', borderRadius:'10px', border:'1px solid white', marginLeft:'1em'}}>
          <h5 style={{color:'rgb(239 177 0)'}}> {title} - TEST CASES :</h5>
          <hr></hr>
          {cases.split('\n').map((item, key)=>{
            if (item === '') return <div key={key}></div>
            { return item.toLowerCase() === 'true' 
            ?<span key={key} className="pass">Test Case #{(key+1)} :&nbsp;&nbsp;&nbsp;&nbsp;Passed&nbsp;<DoneIcon style={{color:'green'}}/></span>
            :<span key={key} className="fail">Test Case #{(key+1)} :&nbsp;&nbsp;&nbsp;&nbsp;Failed&nbsp;&nbsp;<ClearIcon style={{color:'#ff0008'}}/></span>
            }
          })
          }
    </div> 
  

  return (
    <div>
      <h4 className="bg-clip-text text-transparent bg-gradient-to-r from-slate-900 to-slate-700 mb-2">
        Output
      </h4>
      {outputDetails?.submit === true ?
      <Grid style={{width:'100%', padding:'1em 1em 1em 0', background:'black', color:'green'}}>
          {renderSubmitMessage('SAMPLE',outputDetails.samplecases)}
          <br/>
          <hr></hr>
          {renderSubmitMessage('SERVER', outputDetails.servercases)}
      </Grid>
      :
      <Grid style={{minHeight:'42vh', maxHeight:'48vh', overflow:'auto', marginLeft:'-1em'}}>
        <div style={{minHeight:'42vh', maxHeight:'48vh', background:'black', color:'green',  borderRadius:'10px', border:'1px solid white', marginLeft:'1em'}}>
          {outputDetails ? <>{getOutput()}</> : null}
        </div>
      </Grid>
      
        
      }
    </div>
  );
};

export default OutputWindow;
