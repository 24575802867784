import Axios from "axios";
import { showErrorToast, showSuccessToast } from "../../../utils/toastMsg";

export const handleUpload = (uploadTo, file, setPopup, message) => {
    // if(!userId) {
    // //   setPopup({
    // //     open: true,
    // //     severity: "error",
    // //     message: 'User name, email are required field'
    // //     //     ? err.response.data.message
    // //     //     : err.response.statusText,
    // //   });
    //   return;
    // }
    console.log('Pic Uplload Start:', uploadTo, 'file:', file);
    //console.log('user:', userId);
    if(!file || !file.name) {
      showErrorToast("To upload please select a valid file, ", 2000);
      return;
    }



    const data = new FormData();
    data.append("file", file);

    Axios.post(`${uploadTo}`, data, {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    //   onUploadProgress: (progressEvent) => {
    //     setUploadPercentage(
    //       parseInt(
    //         Math.round((progressEvent.loaded * 100) / progressEvent.total)
    //       )
    //     );
    //   },
    })
      .then((response) => {
        console.log(response.data);
        showSuccessToast(`${message} saved successfully`)
        // setPopup&&setPopup({
        //   open: true,
        //   severity: "success",
        //   message:`${message} saved successfully`,
        //   //   message: err.response.data
        //   //     ? err.response.data.message
        //   //     : err.response.statusText,
        // });
        //handleInput(identifier, response.data.url);
      })
      .catch((err) => {
        console.log(err.response);
        setPopup({
          open: true,
          severity: "error",
          message: err.response.statusText,
          //   message: err.response.data
          //     ? err.response.data.message
          //     : err.response.statusText,
        });
      });
  };