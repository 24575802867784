
import ReactMarkdown from "react-markdown"
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter"
import { oneDark } from "react-syntax-highlighter/dist/cjs/styles/prism"
import rehypeRaw from "rehype-raw"

import Markdown from 'markdown-to-jsx';
import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import { render } from 'react-dom';
import apiList from '../../../config/apiList';
import { fetchData, fetchDataWoToken } from '../../../config/apiService';
// import './styles/css.css'
import './styles/custom.scss'
import './styles/theme.css'
//import './styles/index.css'
import { useSelector } from "react-redux";
import TopicSelector from "../../interviewPrep/TopicSelector";

import { loadBlobData } from "../../../utils/datautil/blobloader";

// import * as fs from 'fs';
// import { readFile } from 'fs'

//import DatePicker from './date-picker';


const MarkDownViewer = ()=>{
const [md, setMD] = useState();
const ws = useSelector(state=>state.workspace);
const selected = ws.selected;

// const md = `
// # DatePicker

// The DatePicker works by supplying a date to bias towards,
// as well as a default timezone.

// <DatePicker biasTowardDateTime="2017-12-05T07:39:36.091Z" timezone="UTC+5" />
// `;
useEffect( ()=>{
    //getData();
    //console.log('ReactDOM.findDOMNode(this):', ReactDOM.findDOMNode(this))
    //ReactDOM.findDOMNode(this).scrollLeft = 0;
    getDataBlob();

    //const root = ReactDOM .createRoot(document.getElementById('root'));

}, [selected])

const getDataBlob = async ()=>{
    let found = false;

    let data =  null;
    // USER TO CATCH MD FILES
    // if (localStorage.getItem(selected.source)) {
    //     try{
    //         console.log('CACHE DATA124:', selected.source);
    //         data = JSON.parse(localStorage.getItem(selected.source));
    //         setMD(data);
    //         found = true;
    //     }catch(ex) {
    //         console.log('fetchGITData parse error:', ex);
    //         found = false;
    //     }
    // } 
    if (found === false) {
        let url = await loadBlobData(selected.source);
        let res = await fetchDataWoToken(url);
        let tokens = url.split(selected.source);

        console.log('tokens::::', tokens);

        if(res){
            let data = res;
           
            let host = `https://joblient.com/api/v1/assets?key=`;
            data = data.replaceAll('assets/', host);
            // data = data.replaceAll('.png', `.png${tokens[1]}`);
            // data = data.replaceAll('.jpg', `.jpg${tokens[1]}`);
            // data = data.replaceAll('.gif', `.gif${tokens[1]}`);
            setMD(data);
        }
        //working for Azure blob
    //     if (res) { 
    //         let data = res;
    //         data = data.replaceAll('assets/', tokens[0]);
    //         data = data.replaceAll('.png', `.png${tokens[1]}`);
    //         data = data.replaceAll('.jpg', `.jpg${tokens[1]}`);
    //         data = data.replaceAll('.gif', `.gif${tokens[1]}`);
    //         setMD(data);
    //         localStorage.setItem(selected.source, JSON.stringify(data));
    //    }
    }
   
}

// const getData = async()=>{
//     // // url = apiList.course + selected.source + '?' + q + '#toolbar=0&navpanes=0&zoom=100'
//     // let url = `${apiList.course}markdown/1_complexity_analysis.md?${q}`;
//     let data =  null;
//     if (localStorage.getItem(selected.source)) {
//         console.log('data available in local cache', selected.source);
//         data = JSON.parse(localStorage.getItem(selected.source));
//     } else {
//         const q = `folder=${selected.path}`;
//         let url = `${apiList.course}md/${selected.source}?${q}`;

//         console.log('url: ', url);
//         let res = await fetchData(url);

//         data = res.data.replaceAll('assets/', `${apiList.course}md/assets`);
//         data = data.replaceAll('.png', `.png?${q}`);
//         data = data.replaceAll('.jpg', `.jpg?${q}`);
//         localStorage.setItem(selected.source, JSON.stringify(data));
//     }
//     setMD(data);
// }

// return (
//     md && <Markdown className="markdown-body"
//         children={md}
//     />
   
// )

return (
    <div className="markdown-body md-section">
        {/* <LLD/> */}
        <ReactMarkdown
            children={md}
            rehypePlugins={[rehypeRaw]}
            components={{
                code({ node, inline, className, children, ...props }) {
                    const match = /language-(\w+)/.exec(className || "")
                    return !inline && match ? (
                        <SyntaxHighlighter
                            {...props}
                            children={String(children).replace(/\n$/, "")}
                            style={oneDark}
                            language={match[1]}
                            PreTag="div"
                        />
                    ) : (
                        <code {...props} className={className}>
                            {children}
                        </code>
                    )
                },
            }}
        />
    </div>
)
}


export default MarkDownViewer;