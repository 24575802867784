Array.prototype.diff = function(arr2) {
    var ret = [];
    this.sort();
    arr2.sort();
    for(var i = 0; i < this.length; i += 1) {
        if(arr2.indexOf(this[i]) > -1){
            ret.push(this[i]);
        }
    }
    return ret;
};

export const getMatched = (array1=[], array2=[])=> {

    let skills = array1.diff(array2);

    console.log('skills: ', skills);

    return skills;

    // if (text.indexOf ('java') >= 0) 
    //     skills.push('java');

    // if (text.indexOf('node') >= 0) 
    //     skills.push('node');
    
    //     if (text.indexOf('node') >= 0) 
    //     skills.push('node');

}

//getSkills('java');