import React, { useEffect, useState } from 'react';
import './SkillTest.scss'
import { Button, Grid } from '@material-ui/core';
import { a2b } from '../../utils/decodeUtil';
import { showSuccessToast } from '../../utils/toastMsg';
import { isMobile } from '../../utils/mobileUtil';
import { renderMD } from '../../utils/skillRankUtils';


export default function QuizTest(props) {

    const [questions, setQuestions] = useState(props.questions);
	const [answers, setAnswers] =  useState({});
	const [currentQuestion, setCurrentQuestion] = useState(0);
	const [showScore, setShowScore] = useState(false);
	const [score, setScore] = useState(0);
	const [correct, setCorrect] = useState({});
	const [decode, setDecode] = useState({options:[]});
	const count = 5;
	//console.log('questions123:',  questions)
	useEffect(()=>{
		//setQuestions(props.questions);
		setDecode(decOptions(questions[currentQuestion]));

		//console.log('decOptions(questions[currentQuestion])', decOptions(questions[currentQuestion]))
	}, [props.questions])


	const handleAnswerOptionClick = (isCorrect, key, id) => {

		if (isCorrect) {
			setScore(score + 1);
			//console.log("ans score:", id, isCorrect);
		}
		let choice = {...correct};
		choice[id] = {};
		choice[id].correct = isCorrect;
		setCorrect(choice);
		//console.log('choice', choice, key, id, isCorrect);

		let temp = {...answers};
		temp[currentQuestion] =  { isCorrect: isCorrect, index: key};
		setAnswers(temp);
		const nextQuestion = currentQuestion + 1;
		setDecode(decOptions(questions[nextQuestion]));

		if (nextQuestion < questions.length) {
			setCurrentQuestion(nextQuestion);
		} else {
			setCurrentQuestion(nextQuestion);
			setShowScore(true);
			//props.setDone(score, correct);
			
		}
		//console.log('check:', showScore, nextQuestion, questions.length)
	}

	const submit = ()=>{
		props.setDone(score, correct);
		//showSuccessToast(`Do you want to take again?`)
	}

	const highlight = (key, i, answerOption)=>{
		let clsName ={};
		if(answers[key]) {
			if(answers[key].index === i) {
				if (answers[key].isCorrect === true) {
					clsName ={
						backgroundColor: '#2f922f'
					  }
				} else {
					clsName = {
						backgroundColor: '#ff3333'
					  }
				}
			} else if(answerOption === i){
				clsName = {
					backgroundColor: '#9e8fd5'
				}
				//console.log('class:::', clsName, questions[key].options[i].correct)
			}
		} 
		return clsName; 
	}
	const decOptions = (data)=>{

		try {
		let temp = a2b(data);
		let res = []
		res = JSON.parse(temp);
		//console.log('options:', res);
		return res;
		} catch{
			return null;
		}
	}
	

	return (
		
		questions&&<Grid className='app' container direction='row'>
			<Grid item xs={12}>
				{showScore ? (<>
					<div className='score-section'>
						<p className="chakra-text css-1x6efgi">😊</p>
						You scored: {score}/{questions.length}
					</div>
					{ score >= count-1 ?
						<div style={{display:'block'}}> <span style={{color:'yellow'}}>Pass !: You have cleared the test&nbsp;&nbsp;&nbsp;&nbsp;</span> <button className='button-submit' onClick={() => submit()}>Finish</button></div>
						:<div style={{display:'inline-block'}}><span style={{color:'red'}}>Fail !: Score less than 4, Finish and Try Again! &nbsp;&nbsp;&nbsp;&nbsp;</span>
						<button style={{width:'120px'}} className='button-submit' onClick={() => submit()}>Finish</button>
						</div>
					}
					</>
				) : (
				<>
					<div className="quiz-section">
						<div className='question-section'>
							<div className='question-count'>
								<span>Question {currentQuestion + 1}</span>/{questions.length}
							</div>
							{renderMD(decode?.question?.substring(4,decode.question.length))}
						</div>
						<div className='answer-section element-168'>
							<ol style={{fontWeight:'bold', margin:'5px 0em 0 5px'}}>
							{decode?.options.map((answerOption, i) =>(
							 answerOption=== null) ? <></>
							 :<li onClick={() => handleAnswerOptionClick(decode._ps === i, i, decode.id)}>{renderMD(answerOption)}</li>
							)}
							</ol>
						</div>
					</div>
				</>
				)}
			</Grid>
			<Grid item xs={12}>
				<div className='review-section'>
					<h1>
						<span className="x_576e2b6f">Quiz Result Review</span>
						<hr></hr>
					</h1>
				    { [...Array(currentQuestion)].map((item, key)=> {
						let que = decOptions(questions[key]);
						return <div key={key} style={{display:'flex', marginTop:'1em', flexFlow:'column'}}>
							<div  className="element-168" style={{display:'flex', color: 'white', flexFlow:'column'}}>
								<div className='question-count'>
									<span>Question { key + 1 }:</span>
								</div>
								<div className='question-count'>
									<span>{answers[key].index===que._ps?'Correct':`Wrong (Option ${(que._ps+1)} is correct)`}</span>
								</div>
								<div className='question-text'>{renderMD(que?.question?.substring(4,que.question.length))}</div>
								<ol style={{fontWeight:'bold', margin:'1em 0em 0 1em'}}>
									{que?.options.map((answerOption, i) => (
										<li key={key*10+i} style={highlight(key, i, que._ps)}>{renderMD(answerOption)}</li>
									))}
								</ol>
							</div>
						</div>

					}
					)
							
					}
				</div>
			</Grid>
		</Grid>
	);
}
