import { Checkbox, MenuItem, TextField } from "@material-ui/core";
import { useState } from "react";

const ProfileSortOption = ({setOptions})=>{
const sortOptions = [ { key:'profile', name:'Recent Updated'}, { key:'test', name:'Recent Assessment'}, { key:'rank', name:'Top Rank'}, { key:'active', name:'Recent Active'}, { key:'search', name:'Recent Search'}]
const [select, setSelect] = useState(sortOptions[0])

return (<>
    <div style={{color:'lightgray'}}>|</div>
    <TextField select
        key={"filter"} 
        //label='filter option'
        value =  {select.name}

        
          
        variant="standard" disableunderline="false" sx= {{"& .MuiOutlinedInput-root.Mui-disabled":{"& > fieldset": {border: '2px solid red'}}}}
        // onChange={(event) =>
        //     setOptions('sort', select.key)
        // }
        >


        {sortOptions.map( (sort, key) =>
            <MenuItem key={key} value={sort.name} onClick={
                        (event)=>{ 
                        setOptions('sort', sort.key)
                        setSelect(sort);   
                }}>
                <div style={{display:'inline-block'}}>
                    <Checkbox 
                        checked={sort.key === select.key} 
                       
                        onChange={
                        (event)=>{ 
                        setOptions('sort', sort.key)
                        setSelect(sort);   
                    }}>
                    </Checkbox> <span>{sort.name}</span>
                </div>
            </MenuItem>
        )}
    </TextField>
</>)
}

export default ProfileSortOption;