export const server = "https://joblient.com";
//export const server = "http://localhost:4444";
const apiList = {
  login: `${server}/api/v1/auth/login`,
  guest: `${server}/api/v1/auth/guest`,
  signup: `${server}/api/v1/auth/signup`,
  uploadResume: `${server}/api/v1/profile/resume`,
  bulkRes: `${server}/api/v1/profile/bulk`,
  uploadProfileImage: `${server}/api/v1/profile/pic`,
  recruiterJobs: `${server}/api/v1/job/recruiter`,
  jobs: `${server}/api/v1/job`,
  jobPosting:`${server}/api/v1/job/posting`,
  extJobs: `${server}/api/v1/job/ext`,
  applications: `${server}/api/v1/job/application`,
  course: `${server}/api/v1/host/course/`,
  git: `${server}/api/v1/host/git/`,
  course1: `${server}/api/v1/course/`,
  workspace: `${server}/api/v1/workspace`,
  ws: `${server}/api/v1/course/ws`,
  rating: `${server}/api/v1/rating`,
  user: `${server}/api/v1/user`,
  chat: `${server}/api/v1/chat`,
  activity: `${server}/api/v1/user/activity`,
  feedback: `${server}/api/v1/feedback`,
  resume: `${server}/api/v1/resume`,
  applicants: `${server}/api/v1/applicant`,
  execute: `${server}/api/v1/compiler`,
  interview: `${server}/api/v1/interview`,
  template: `${server}/api/v1/interview/template`,
  question: `${server}/api/v1/interview/ques`,
  report: `${server}/api/v1/interview/report`,
  assessReport: `${server}/api/v1/assessment/report`,
  assess: `${server}/api/v1/assessment/`,
  blogDesign: `${server}/api/v1/blog/design`,
  blogCode: `${server}/api/v1/blog/code`,
  leader: `${server}/api/v1/leader`,
  util: `${server}/api/v1/util`,
  skill: `${server}/api/v1/skill`,
  test: `${server}/api/v1/test`,
  signedurl:`${server}/api/v1/signedurl`
};

export default apiList;
