import { Grid } from "@material-ui/core";
import { useState } from "react";
import rank from '../../assets/img/userrank.JPG'
import { setBk, setBrd } from "../../utils/colorUtils";

const Leader = (props)=>{
    const {leads} = props;
    //console.log('leads:', leads)

    const highlight = (lead)=>{
        //console.log('highlight123:', lead);
        if (lead.highlight) {
            return { background:'rgb(222 222 222)', fontWeight:'bold',color:'rgb(36 197 44)', border: `1px dotted red`, marginBottom:'1px', fontSize:'0.8em', marginLeft:'0.9em'};
        } else { 
            return { background:'rgb(246 241 243)', marginBottom:'2px', fontSize:'0.8em', marginLeft:'0.9em'} }
    }

    return <Grid container direction="column" spacing={2} style={{paddingTop:'1em', paddingBottom:'1em'}} >
            <Grid item container direction="row"  >
                <Grid item xs={9}>
                    <img height={'40em'} width={'40em'} src={rank} style={{marginLeft:'5em', marginBottom:'1em'}}></img>
                    <div className="pageTitle">Leaderboard</div>
                    <hr></hr>
                </Grid>
                <Grid item xs={9}>
                    <span>Climbing the Leaderboard</span>
                </Grid>

               
            </Grid>

            <Grid item container direction="row" alignItems="flex-start" spacing={0} style={{background:'rgb(96 98 94)', color:'white', marginLeft:'0.9em', marginBottom:'0em' ,fontWeight:'bold', fontSize:'0.8em', border:'5px'}} >
                <Grid item xs={1}>Rank</Grid>
                <Grid item xs={3}>Candidate Name</Grid>
                <Grid item xs={2}>College</Grid>
                <Grid item xs={2}>City</Grid>
                <Grid item xs={2}>Coins</Grid>
            </Grid>
            {leads && leads.map((lead, key)=>
            //<Grid key={key} item container direction="row"  style={"background:'red'"} alignItems="flex-start" spacing={1} style={{marginBottom:'6px', fontSize:'0.8em', marginLeft:'0.9em'}} >
            <Grid key={key} item container direction="row"  style={highlight(lead)} alignItems="flex-start" spacing={0}>
                <Grid item xs={1}><strong>{lead.rank}</strong></Grid>
                <Grid item xs={3}><a href='/leader' style={{fontSize:'1em',background: 'none', color:'rgb(27, 73, 234)', display:'inline-flex', textDecoration: 'none'}} target="_blank">{lead.name}</a></Grid>
                <Grid item xs={2}>{lead.college}</Grid>
                <Grid item xs={2}>{lead.location}</Grid>
                <Grid item xs={2}>{lead.coins}</Grid>
            </Grid>
            )}
        </Grid>
}

export default Leader;