import React, { useState, useEffect, useContext } from "react";
import {
  Button,
  Chip,
  Grid,
  makeStyles,
  Paper,
  TextField,
  Typography,
  Modal,
  Collapse,
} from "@material-ui/core";
// import { LocationCity,ArrowBackIcon } from '@material-ui/icons';
import Pagination from '@mui/material/Pagination';
import PaginationItem from '@mui/material/PaginationItem';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import AddIcon from '@mui/icons-material/AddBox';
import RemoveIcon from '@mui/icons-material/RemoveCircle';

import Rating from "@material-ui/lab/Rating";
// import Pagination from "@material-ui/lab/Pagination";
import axios from "axios";

import { SetPopupContext } from "../home/component/MainRouter";

import apiList from "../../config/apiList";
import { userType } from "../login/lib/isAuth";
import { useSelector} from "react-redux";
import CourseSuggestion from "./CourseSuggestion";
import BusinessIcon from '@mui/icons-material/Business';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import { lightBlue } from "@material-ui/core/colors";
import { API, fetchData, fetchDataWoToken } from "../../config/apiService";
import IconButton from '@material-ui/core/IconButton';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import TextEditor from "../editor/editor-text/TextEditor";
import RevisionPanel from "../interviewGuide/RevisionPanel";
import { showErrorToast, showSuccessToast } from "../../utils/toastMsg";
//import { isMobile } from "../../utils/mobileUtil";
import FileUploadInput from "../login/lib/FileUploadInput";
import DescriptionIcon from "@material-ui/icons/Description";
import { useHistory } from "react-router-dom/cjs/react-router-dom";
import Login from "../login/Login";
import { applyJob } from "./JobApplyHelper";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import { fetchWSData } from "../../utils/datautil/dataloader";
import { matchSkills } from "../../utils/skillMappping";
import SkillTestCatalog from "../interviewrank/SkillTestCatalog";
import CurrencyRupeeIcon from '@mui/icons-material/CurrencyRupee';
import InfiniteScroll from "react-infinite-scroll-component";

const useStyles = makeStyles((theme) => ({
  body: {
    height: "inherit",
  },
  button: {
    width: "100%",
    height: "100%",
  },
  jobTileOuter: {
    padding: "20px",
    margin: "20px 0",
    boxSizing: "border-box",
    width: "100%",
    //background:'rgb(242,255,255)'
  },
  popupDialog: {
    height: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
}));

const JobTile = (props) => {
  const classes = useStyles();
  const { job, query } = props;
  const setPopup = useContext(SetPopupContext);

  const [open, setOpen] = useState(false);
  const [sop, setSop] = useState("");
  const [showMore, setShowMore] = useState({});
  const [details, setDetails] = useState();
  const [enable, setEnable] = useState(false)
  const [file, setFile] = useState('');
  const [loading, setLoading] = useState(false);
  const height = '20px'; //isMobile? '40px' : '20px';
  const [test, setTest] = useState(false);


  let history = useHistory();

  const handleRoute = (location) => {
    console.log(location);
    history.push(location);
  };
  
  useEffect(()=>{
    let token = localStorage.getItem('token');
    let type = localStorage.getItem('type');
    //console.log('usertype', type)
    setDetails({description: job.jobdetails});
    if (token !== null && type !== 'recruiter') {
      setEnable(true);
    }
  }, [job.jobdetails])

  const handleClose = () => {
    setOpen(false);
    setSop("");
  };

  const convertUnicode = (input)=> {
    if(!input) return "";
    
    return input.replace(/\\+u([0-9a-fA-F]{4})/g, (a,b) =>
      String.fromCharCode(parseInt(b, 16)));
  }

  const fetchDetails = async()=>{
    let address = `${API.extJobs}/details?id=${job.href}`;
    console.log('fetchDetails:', address);
    setLoading(true);
    let details = await fetchData(address,'Fetch job details');
    let temp = {...details}
    temp.description = convertUnicode(details.description);
    temp.apply = details.apply;
    setDetails(temp);
    console.log('details123: ', temp);
    setLoading(false);
  }

  const showDetails = ()=> {
    if(showMore == job.title) {
      console.log('showDetails1:', showMore);
      setShowMore({});
    } else {
      //console.log('showDetails2:', showMore, details.description, job.posting);
      setShowMore(job.title); 
      //fetchDetails();
      if(!details.description) {
        fetchDetails();
      }
    }
  }

  let bgColor = job.posting =='internal'?'#e2e6f6':'';

  const getPostDate = ()=> {
   
    if (!job.jobDate) {
      let dd = new Date();
      if (dd.getMonth() > 1) {
        dd.setMonth(dd.getMonth() - 1);
      }
      dd.setDate(1);
      const jobDate = dd.toLocaleDateString('en-GB', {
      day: 'numeric', month: 'short', year: 'numeric'});
      return jobDate;
    }
    
    //return job.jobDate;
    let jobDate = job.jobDate;

    if (job.jobDate.length>=10) {
      jobDate = new Date(job.jobDate).toLocaleDateString('en-GB', {
      day: 'numeric', month: 'short', year: 'numeric'});
    }
    return jobDate;
  }

  const getText = ()=>{
    let text = `${query.replace("q=", "")} ${job.title} ${job.skills} ${job.summary} `;
    return text;
  }

  const getInfo = (sal)=>{
    if (sal>10000000) {
      let temp = `Upto ${sal/10000000} cr.`
     // console.log('temp:', temp);
      return temp;
    } else if (sal>=1000000) {
      let temp = `Upto ${sal/100000} lakh`
     // console.log('temp:', temp);
      return temp;
    }

    return sal;
  }

  const renderInfo = ()=>{
   
    let uploadTo = apiList.uploadResume;
    
    if (!enable) {
      // let fileId = new Date().toLocaleDateString('en-GB', {
      //   day: 'numeric', month: 'short', year: 'numeric'})+ "_" +  new Date().getTime();
      uploadTo = `${apiList.uploadResume}/anony/${job._id}/sop:${sop}`
    }

    return <Grid container direction="row" spacing={4}>
            {!enable && <>
              <Grid item xs={'auto'} style={{fontSize:'1.2em'}}>
                <span>Login required to apply job<a style={{color:'blue'}} href="#"  onClick={()=>handleRoute('/logout')} > Sign in</a></span>
              </Grid>
              <Grid item xs={'auto'}>Or</Grid>
            </>}
            <Grid item xs={12}>
                <FileUploadInput
                    className={classes.inputBox}
                    label="Resume (.pdf)"
                    icon={<DescriptionIcon color="primary" />}
                    value={file}
                    onChange={(event) => setFile(event.target.files[0])
                        // setFiles({
                        //   ...files,
                        //   resume: event.target.files[0],
                        // })
                    }
                    uploadTo={uploadTo}
                    //handleInput={handleInput}
                    identifier={"resume"}
                    //userId = {signupDetails.email}
                    file = {file}
                    setFile = {setFile}
                />
               
            </Grid>
            <Grid item xs={'auto'} style={{marginBottom:'0.5em', marginTop:'-2em', marginLeft:'0.3em'}}>
              <span>(Uploading resume is optional, please update profile if has not been updated)</span>
            </Grid>
    </Grid>


  }



  return ( <Grid container direction="row" className="searchResult" style={{margin: "5px 0", background: bgColor, boxSizing: "border-box", display:'grid', }}>
          <Grid item xs={12} container spacing={1} direction="column"  style={{width:'100%'}} >
            {job.salary&& 
            <Grid item style={{}}><strong>Salary:&nbsp;</strong> <CurrencyRupeeIcon/> {getInfo(job.salary)} &nbsp;&nbsp;&nbsp;&nbsp;<span style={{color:'#008fff'}}>{job.category&&'(Urgent! referral)'}</span></Grid>}
            <Grid item>
              <a style={{cursor: 'pointer',  display:'inline-flex',color:'blue', background:'none', textDecoration: 'none'}} target="_blank" onClick={()=>{showDetails(); setTest(false)}}><strong>{job.title}</strong></a>
            </Grid>
            <Grid item style = {{display:'flex'}}>
              <div style={{marginTop:'0.2em'}}><BusinessIcon style={{marginTop:'-0.4em' , height:height, width:height}}/><strong>&nbsp;<span>{job.company}</span></strong></div>
            </Grid>
            <Grid>

              <div style={{marginTop:'0.2em'}}><LocationOnIcon style={{height:height, width:height}}/> <strong>{job.location}</strong></div>
            </Grid>
            <hr/>
            <Grid item style={{color:'crimson'}}>Posted On:&nbsp;{getPostDate()} </Grid>

            {job.skills&& <Grid item><strong> Skills:&nbsp;</strong> {job.skills} </Grid>}
            <Grid item><strong> Job Description:&nbsp;</strong>{job.summary}</Grid>

          </Grid>



          <Grid item xs={12} container direction="row" style={{marginTop:'1em'}}>
              <Grid item>
                    <div style={{display:'flex',marginBottom:'1.7em', background:'#a670ff', padding:'0px 0px 0 20px', borderRadius:'20px'}} onClick={()=>{showDetails()}}>
                          <div style={{marginTop:'0.4em', color:'white', padding:'3px 3px 8px 3px'} } >View and Apply</div> 
                          <IconButton style={{marginTop:'0em', marginLeft:'0em', width:'2.5em', cursor:`${loading===false}`?'':'wait'}}
                                  aria-label="expand row"
                                  size="small" > 
                              {showMore == job.title ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                          </IconButton>
                    </div>
              </Grid>
              <Grid item>
                  {/* <RevisionPanel job={job} query={query} text={getText()} ></RevisionPanel> */}

                    <div style={{display:'flex',cursor:'pointer', marginLeft:'10px', float:'right', marginBottom:'1.7em', background:'#44c6f4', padding:'0px 0px 0 20px', borderRadius:'20px'}}  onClick={()=>{setTest(!test); setShowMore({})}}>
                          <div style={{marginTop:'0.4em', color:'white', padding:'3px 3px 8px 3px'} } >Take Test</div> 
                          <IconButton style={{marginTop:'0em', width:'2.5em'}}
                                  aria-label="expand row"
                                  size="small" > 
                              {test ?  <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                          </IconButton>
                    </div>
    
              </Grid>

          </Grid>

          <Grid item container direction="row">
  
              <Grid item xs={12}>
                  <Collapse in={showMore == job.title} timeout="auto" unmountOnExit>
                      <TextEditor readOnly={true} toolbar={false} mode='view' content={details?.description}></TextEditor>
                      { !job.posting ?
                        <a className="btnApply" button href= {details?.apply} target="_blank"><div><strong>{"Apply Job"}</strong></div></a>
                      :
                        <button className="btnApply" onClick={() => {

                          let type = localStorage.getItem("type");

                          if (type==='guest') {
                            showErrorToast("Guest User: Cannot apply job, please logout and signup first", 3000);
                            return;
                          } else {
                                setOpen(true);
                            }}
                          }
                            >
                            Apply Now
                        </button>
                      }
                  </Collapse>
              </Grid>
          </Grid>

          {test && <Grid item xs={12} alignItems="flex-start" style={{marginLeft:'0em'}}>
                      {/* <QASetOnSearch search={query.replace('q=','')}></QASetOnSearch> */}
                      <SkillTestCatalog mode="search" text={matchSkills(getText())}></SkillTestCatalog>
                  </Grid>
          }

        <Modal open={open} onClose={handleClose}>
          <Paper
            style={{
              marginTop:'10%',
              padding: "20px",
              outline: "none",
              //display: "inlinex",
              flexDirection: "column",
              alignSelf:'self',
              justifyContent: "center",
              minWidth: "40%",
            // marginLeft: `${isMobile?'':'25%'}`,
              //maxWidth: `${isMobile?'100%':'60%'}`,
              alignItems: "center",
              //fontSize: `${isMobile?'21px':''}`,
            }}
          >
            <TextField
              label="Write Cover Later (upto 250 words)"
              multiline
              rows={5}
              style={{ width: "100%", marginBottom: "30px"}}
              variant="outlined"
              // InputProps={{style: {fontSize:`${isMobile?'36px':''}`}}}
              value={sop}
              onChange={(event) => {
                if (
                  event.target.value.split(" ").filter(function (n) {
                    return n != "";
                  }).length <= 250
                ) {
                  setSop(event.target.value);
                }
              }}
            />
            {renderInfo()}
            {
            enable&&<Button
              variant="contained"
              background='#1997b5'
              color='primary'
              style={{padding: "10px 50px", marginTop:'3em'}}
              onClick={() => {applyJob(job, sop, file); handleClose(); setShowMore({});}}
            >
              Submit
            </Button>}
            
          </Paper>
        </Modal>
      </Grid>
    );
};

const SearchExtResults = (props) => {
  const [page, setPage] = useState(1);
  const [search, setSearch] = useState();
  let query = useSelector(state=>state.search.query);
  const [pagination, setPagination] = useState();
  const [height, setHeight] = useState('75.8vh');
  const [jobs, setJobs] = useState([]);
  const [type, setType] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const [initialScrollY, setInitialScrollY] = useState(0);
  const location = useLocation()

  useEffect( ()=>{
  
    if(query) {
      console.log('query1234:', page);

      setType(2);
      if (query.indexOf('hotjobs') >= 0 || query.indexOf('internjobs') >= 0 ) {
          getHotJobs();
      } else {
        console.log('pagi123', page);
        setPage(1)
        setInitialScrollY(0);
        setType(1);
        setHeight('75.8vh');
        if (jobs.length > 0) {
          setJobs([]); // need to rest
        }
        setTimeout( ()=>{fetchNext(1, 1)}, 10);
        
      }
    } else if(location.pathname.indexOf('hot-jobs')>-1) {
      console.log('hot jobs', location.pathname);
      query = `q=hotjobs`
      getHotJobs();

    } else if(location.pathname.indexOf('intern-jobs')>-1) {
      console.log('intern jobs', location.pathname);
      query = `q=internjobs`
      getHotJobs();

    }
  }, [query])

  const getHotJobs = async()=>{
    console.log('fetch hots job')
    let category = query.replace('q=','').replace('jobs','').toUpperCase();
    let res = await fetchInternalJobs();
    console.log('res:::', res)
    setJobs(res);
    setPagination(1);
    setSearch({summary: `${res.length} ${category} Jobs Found`});
  }
  const updatePaginator = (search)=>{
      const total = search.jobs.length;
      const pages = Math.ceil(search.count/total);
      //console.log('check: ', search.count, search.jobs.length, pages)

      setPagination(pages);
  }
  const fetchInternalJobs = async()=>{
    let address = apiList.jobs;
    if (query) {
      address = `${address}?${query}`;
      console.log('address123:', address);
      let ijpost = await fetchDataWoToken(address,'Fetch internal job postings');
      return ijpost;
 
    } else return [];
  }
  const fetchNext = async (value, typ)=>{
    
    let mode = typ || type;
    if (mode === 2) return;  // means internal job search

    let newPage = value || page;
    let queryString = query;
    console.log('newPage: ', newPage);
    
    if(newPage > 1) {
      queryString = `${queryString}&page=${newPage}`;
    }

    console.log('query:', queryString);
    let address = apiList.extJobs;
    if (queryString !== "") {
      address = `${address}?${queryString}`;
    }
    console.log('address ext:', address);
    //dispatch(fetchJobs(address));
    let nextResult = await fetchDataWoToken(address,'Fetch job ext');


    if (nextResult === null || nextResult.end || nextResult.count === null || nextResult.jobs.length === 0) {
      //setSearch(nextResult)
      return;
    }
     setSearch(nextResult);
    

    if (newPage === 1) {
      setJobs(nextResult.jobs);
     // setPage(newPage);
    } else {
      setJobs([...jobs, ...nextResult.jobs]);
     
    }
    setPage(newPage + 1);

  }
  const refresh = (setItems) => {};

  return (
    <>
      <Grid
        container
        item
        direction="column"
        alignItems="center"
        style={{margin:'0 auto', width:'94%', minHeight: 'calc(100vh -185px)' }}
      >

        {search &&<Grid item>
             <Typography style={{display:'flex', justifyContent:'center', marginBottom:'0.4em'}} variant={'h5'}>{search && search.summary}</Typography>
          </Grid>
        }

        <InfiniteScroll
          dataLength={jobs.length} //This is important field to render the next data
          next={() => {
            fetchNext();
          }}
          hasMore = {hasMore && jobs.length<500}
          initialScrollY = {initialScrollY}
          // hasMore = {hasMore && !loading}
          height = 'calc(100vh - 225px)'
          //loader={<h4>Loading...</h4>}
          endMessage = {
            <p style = {{ textAlign: "center" }}>
              <b>Yay! You have seen it all</b>
            </p>
          }
          // below props only if you need pull down functionality
          // refreshFunction = {refresh}
          // pullDownToRefresh
          // pullDownToRefreshThreshold = {50}
          // pullDownToRefreshContent = {
          //   <h3 style={{ textAlign: "center" }}># 8595; Pull down to refresh</h3>
          // }
          // releaseToRefreshContent={
          //   <h3 style={{ textAlign: "center" }}># 8593; Release to refresh</h3>
          // }

          // scrollThreshold="200px"
        >
        <Grid item xs = {12} style = {{width:'100%'}}>
          {jobs && jobs.length > 0 && (
            jobs.map((job, key) => {
              return <JobTile key = {key} job = {job} query = {query} />;
            })
          ) 
          }
        </Grid>
        </InfiniteScroll>

        {/* <Grid item xs = {12} style = {{width:'100%'}}>
          {jobs && jobs.length > 0 && (
            jobs.map((job, key) => {
              return <JobTile key = {key} job = {job} query = {query} />;
            })
          ) 
          }
        </Grid>
        <Grid item>
        { (page > 1 || jobs.length > 0) && <Pagination key = {pagination} count = {pagination}   color = "primary" 
              onChange = {(ev, value)=> {fetchNext(value)}}
              renderItem = {(item) => (
              <PaginationItem
                slots={{ previous: ArrowBackIcon, next: ArrowForwardIcon }}
                {...item}
              
              />
              )}/>}
        </Grid> */}
      </Grid>
    </>
  );
};

export default SearchExtResults;
