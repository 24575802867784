// import React from "react";
// import ReactDOM from "react-dom";
// import "./index.css";
// import App from "./App";
// <script type="text/javascript" src="https://viewer.diagrams.net/js/viewer-static.min.js"></script>


import React from 'react';
import ReactDOM from 'react-dom/client';
import "./index.css";
import App from './App';
import { Provider } from 'react-redux';
import { store } from './state/store';
import { BrowserRouter } from 'react-router-dom';
import DefaultShape from './shape';


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <BrowserRouter>
      <Provider store={store}>
        <App />
      </Provider>
    </BrowserRouter>
  //   <React.StrictMode>
  //   <BrowserRouter>
  //     <Provider store={store}>
  //       <App />
  //     </Provider>
  //   </BrowserRouter>
  // </React.StrictMode>
);
