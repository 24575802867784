export const JOB_POS_CATLOG = [{
        "key":"pricipal",
        "title" : "Principal Engineer",
        "skills":["aws,design","aws,java,db","java,ms"],
    },
    {
        "key":"sse",
        "title" : "Staff Software Engineer",
        "skills":["code","design","ms","aws","db"],
    },
    {
        "key":"sse",
        "title" : "Senior Software Engineer",
        "skills":["java","node","react", "react","mongo", "rdbms"],
    },
    {
        "key":"sste",
        "title" : "Staff Software Test Engineer",
        "skills":["plan","testcase","performance"],
    }
]