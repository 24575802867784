import React, { useState, useEffect, useContext } from "react";
import { useDispatch } from "react-redux";
import {
  Grid,
} from "@material-ui/core";

import { SetPopupContext } from "../home/component/MainRouter";
import {API, fetchData, saveData} from "../../config/apiService"

const ViewActivityLogger = (props)=>{
    const dispatch = useDispatch();
    const setPopup = useContext(SetPopupContext);
    const [ profiles, setProfiles] = useState();

    useEffect(()=>{
        getActivities();
    },[])

    const getActivities = async()=>{
        let res = await fetchData(API.activity);
        console.log('get user activity:', res)
        if (res) {
            setProfiles(res);
        }
    }

    return  <Grid container direction="row" spacing={0} style={{width:'98%', padding:'1em 1em 3em 1em', borderRadius:'10px', border:'1px solid lightgray'}}>
        <h2>User Activity Logger System<hr></hr></h2>
            <Grid item container  direction="row" spacing={0} style={{padding:'0.5em'}}>
                <h4>Total Active Profiles: { profiles?.count }</h4>
            </Grid>
        
            <Grid item container  direction="row" spacing={0} style={{padding:'0.5em'}}>
                {
                    profiles&& profiles.activities.map( (item, key)=>{
                        return item.meta&&<Grid item key={key} style={{background: 'rgb(218 225 236)', margin:'1em', borderRadius:'10px', border:'1px solid lightgray', padding:'0.5em', marginBottom:'0.5em'}}>
                            
                            <Grid style={{background:'#eaecec', borderRadius:'10px', border:'1px solid lightgray', padding:'0.5em', marginBottom:'0.5em'}}>
                                <h5 style={{fontWeight:'bold', color:'black'}}>{item.resumeData?.heading?.name} <span style={{fontWeight:'300', color:'black'}}>({item.resumeData?.heading?.designation})</span> </h5>
                            </Grid>
                            <Grid item style={{marginLeft:'1em', marginTop:'1em'}}>
                                <span style={{}}>Active On: {item.status}&nbsp;&nbsp;
                                {item.meta&&item.meta.activeOn&&<span>{new Date(item.meta?.activeOn).toLocaleDateString('en-GB', {
                                    day: 'numeric', month: 'short', year: 'numeric'})}</span>}</span>
                            </Grid>
                            <Grid item style={{marginLeft:'1em', marginTop:'1em'}}>
                                <span>Total Hits: &nbsp;&nbsp;{item.meta.hits}</span>
                            </Grid>                            
                            <Grid item style={{marginLeft:'1em', marginTop:'1em'}}>
                                <span>Recent Search:&nbsp;&nbsp;{item.meta.recentSearch.join(" , ")}</span>
                            </Grid> 
                            <Grid item style={{marginLeft:'1em', marginTop:'1em'}}>
                                <span>Hit Matrix:</span>
                                {
                                    item.meta&& item.meta?.hitMatrix && Object.entries(item.meta.hitMatrix).map( (v, key)=>{
                                        return  <div style={{display:'grid', marginLeft:'2em'}} >{v[0]}&nbsp;&nbsp;-&nbsp;&nbsp;{v[1]}</div>
                                    })
                                }
                            </Grid>
                        </Grid>
                    })
                }
            </Grid>
    </Grid>

}
export default ViewActivityLogger;


