import React, {useEffect} from 'react'
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { submitTestResult } from '../../config/apiService';
import TestCompletedModal from './TestCompletedModal';

const Submit = ({code, testId}) => {
  const questionData = useSelector((state)=> state.questionReducer.questions, shallowEqual);
  console.log("submitting...");

  const calculateScore=()=>{
    let score=0;
    questionData?.forEach(que =>{
      if(que.check !== null && que._ps === que.check) score++;
    })
    return score;
  }
  const submitTest = async () => {
    const requestBody = {
      uniqueCode: code,
      score: calculateScore(),
    };
    console.log({requestBody});
    await submitTestResult({requestBody,testId});
  };

  submitTest();

  return (
    <TestCompletedModal completed={true} />
  )
}

export default Submit