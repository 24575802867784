import React, {useRef, useState} from 'react'
import Joblient from '../appbar/Jonlient'
import SessionClock from '../utils/clock/SessionClock'
import { Grid } from '@material-ui/core'
import TestCompletedModal from './TestCompletedModal'
import { useParams } from 'react-router-dom'
import Submit from './Submit'

const Navbar = React.memo(({start, company, time}) => {
  const [completed, setCompleted] = useState(false);
  const { testId, code } = useParams();
  // const score = useSelector((state)=> state.questionReducer.score, shallowEqual);
  
  const clockNotification = () => {
    //when given time finished
    setDone(0);
  }
  // console.log(score);
  const childRef = useRef();
  // const time = 1; //10 minutes
  const setDone = async () => {
    console.log('Done');
    setCompleted(true);
    return;
  };  
  start && childRef.current?.startClock();
  if(childRef.current?.timer === 0) {
    console.log('Test Over');
  }
  
  return (
    <div className='nav flex h-14'>
      <div className='w-[20%] flex justify-center items-center' style={{ alignItems: 'center' }}>
        <Joblient />
      </div>
      <div className='w-[50%] flex justify-center items-center' style={{ alignItems: 'center' }}>
        {'VINAY KUMAR SINGH'}
      </div>
      <div className='w-[10%] flex justify-center items-center' style={{ alignItems: 'center' }}>
        Company: &nbsp; <b>{company}</b>
      </div>
      <Grid className="flex items-center justify-center w-[20%]">
        <SessionClock clockNotification={clockNotification} ref={childRef} time={time} reset={"true"}></SessionClock>
      </Grid>

      {completed && <Submit code={code} testId={testId} /> }
      {/* <TestCompletedModal completed={completed} /> */}
    </div>
  )
});

export default Navbar