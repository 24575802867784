import * as React from 'react';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { algo, fullStack } from '../../assets/data/images';
import { Avatar } from '@material-ui/core';
import { isMobile } from '../../utils/mobileUtil';


function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

export default function TabIntGroup(props) {
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
    props.setTabIndex(newValue);
  };
 
  return (
    <Box sx={{ width: '100%' }}>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' , fontWeight:'bold', color:'#37465A'}}>
        <Tabs value = {value} onChange = {handleChange} aria-label = "basic tabs example">
           
          <Tab className='MuiTab-wrapper' style={{fontWeight:'bold', color:'#37465A'}} label="Interviews" {...a11yProps(0)}
            icon={ <Avatar style={{height:'24px', width:'24px', marginTop:'5px'}} src={fullStack}></Avatar>}>
          </Tab>

          <Tab className='MuiTab-wrapper' style={{fontWeight:'bold', color:'#37465A'}} label="Templates" {...a11yProps(0)}
            icon={ <Avatar style={{height:'24px', width:'24px', marginTop:'5px'}} src={algo}></Avatar>}>
          </Tab>
        
        </Tabs>
      </Box>
    </Box>
  );
}