import { useContext, useEffect, useRef, useState } from "react";
import './SkillTest.scss';
import QuizTest from "./QuizTest";
import { Button, Grid } from "@material-ui/core";
import SessionClock from "../utils/clock/SessionClock";
import { API, fetchData } from "../../config/apiService";
import { saveAssessNRank } from "../../utils/skillRankUtils";
import { SetPopupContext } from "../home/component/MainRouter";
import BasicTabs from "../interviewPrep/TabQGroup";
import SkillTest from "./SkillTest";
import { showErrorToast } from "../../utils/toastMsg";

const SkillTestCatalog = (props)=>{
    const setPopup = useContext(SetPopupContext);
    const {mode , text} = props;
    const [topics, setTopics] = useState({});
    const [questions, setQuestions] = useState();
    const [selected, setSelected] = useState();
    const [start, setStart] = useState();

    const [tabIndex, setTabIndex] = useState(1);


    const childRef = useRef();
    const time = 10; //10 minutes
   
    const clockNotification = ()=>{
        setDone(0);
    }

    useEffect(()=>{
        loadData('fullstack', 1);
    }, [])

    const loadData = async(skill, index)=>{
        
        if(mode==='search') {
            let tt = {};
            tt[index] = text.map(item=>{ let temp = {title:item.toUpperCase(), qaCount:5}; return temp})
            setTopics(tt);

            console.log('tt::', tt)

        } else if(!topics[index]) {
            let address = `${API.skill}/skill?topic=${skill}`
            let temp = await fetchData(address,"Skill Catalog" );
            console.log('skill data:', temp.questions);
            let tt = {...topics};
            tt[index] = temp.questions;
            setTopics(tt);
        }
    }
    
    function removeWhiteSpaces(str) {
        return str
          ?.toString()
       
          ?.replace(/[\r\n|.|' ']/gm, "");
    }

    const startNow = async()=>{
        console.log("Test Started: ", selected);
        let type = localStorage.getItem("type");
        if (type === 'guest') {
            showErrorToast("Please logout and signup your account to start your test", 5000);
        }

        let topic = removeWhiteSpaces(selected).toLowerCase();
        let address = `${API.skill}/skill?topic=${topic}&count=5`
        let ques = await fetchData(address,"Quiz score" );
        setQuestions(ques);
        setStart('start');
        if(childRef.current) {
            childRef.current.startClock();
        }
    }

    const stop = ()=>{
        console.log("Test Stop: ", selected);
        setStart();
        if(childRef.current) {
            childRef.current.stopClock();
        }
    }

    const onItemSelect = (skill)=>{
        console.log('topic:', skill);
        setStart();
        setSelected(skill);
        if(childRef.current) {
            childRef.current.stopClock();
        }
    }

    const setDone = ()=>{
        setStart();
        if(childRef.current) {
            childRef.current.stopClock();
        }
    }

    const loadTabData = (index)=>{
        console.log("tab:", index)
        if(index === 1) {
            loadData('fullstack', index);
        } else if(index === 3) {
            loadData('algods', index);
        } else {
            loadData('design', index);
        }

    }

    const onTabChanged = (index)=>{
        setTabIndex(index);
        loadTabData(index);
    }

    return <div  className="topic-chip-container" style={{padding:'1em', background:'white', marginTop:'40px', marginLeft: '10px', marginBottom:'40px', width:'98%', borderRadius:'10px', border:'0.5px solid lightgray'}}>
        <h1 style={{marginTop:'0.5em'}}>
            <span className="x_576e2b6f">Skill Assessment Test</span>
            <hr></hr>
        </h1>
        {!start&&<h3>Select a topic to start test</h3>}

        {!mode && <Grid style={{background: '#cadce9', borderRadius: '12px 12px 0 0', width:'100%' }}>
            <BasicTabs setTabIndex= {onTabChanged}></BasicTabs>
        </Grid>}
        
        <Grid style={{background:`${mode?'': 'rgb(244 251 252)'}` , borderRadius: '0 0 12px 12px' }}>
                <div style={{marginTop:'0vh', padding:'10px'}} className="topic-chip-container">

            {topics[tabIndex] && topics[tabIndex].map((item, key)=>{
                let linkTitle = `${item.title} Interview Questions`;
                let flatLink = item.title.replace('.','').split(' ').join('-');
                let href = '#'; //`/interview-questions/${flatLink.toLowerCase()}`
                let altTitle = `Top ${item.qaCount} ${item.title} Interview Questions`;
                let imgTitle = `Top ${item.qaCount} ${item.title}`;

                return <div className="topic-chip hovered" onClick={()=>onItemSelect(item.title)}>
                            <div className="justify-content-center align-self-center">
                            {mode?<img className="topic-logo1"/>:
                            <img className="topic-logo1" src={item.img} alt={altTitle} title={imgTitle}/>}
                            <span style={{color:'#37465A'}}><b>&nbsp;&nbsp;{item.title}</b></span><span className="small text-muted">&nbsp;&nbsp;{item.qaCount}&nbsp;&nbsp;</span>
                        </div>
                    </div>
                })
            }
            </div>
        </Grid>

        {   selected ? <>
            <h1 style={{marginTop:'1em', marginBottom:'0.5em'}}>
                <span className="x_576e2b6f">{selected} : Assessment Test</span>
                <hr></hr>
            </h1>

            <Grid item xs = {11} >
                <div style={{marginBottom:'0.5em', borderRadius:'10px', padding:'10px',fontSize:'1.2em',  border:'0.5px solid lightgray'}}>
                <span style={{fontWeight:'bold',color:'blue', padding:'1em', color:'#5eac1a'}}>Test Instructions:</span>
                    <ul  style={{paddingLeft:'2em', marginTop:'1em',  color:'#5eac1a'}}>
                        <li>{`No. of questions: 05`}</li>
                        <li>{`Test duration: 10 Minutes`}</li>
                        <li>{`Immediately after starting the test, questions will appear`}</li>
                        <li>{`Several unique methods have already been implemented into the system to detect cheating and inappropriate behavior.`}</li>
                        <li>{`Copy/paste of AI contents is prohibited.`}</li>
                        <li>{`A unique and accurate answer will earn you more points.`}</li>
                        <li>{`Up to 100 points will be assigned to each successful submission.`}</li>
                    </ul>
                </div>
            </Grid>

            <Grid item  container>
                <Grid item xs={6}> 
                    <Button disabled={start} style={{margin:'1.4em',width:'10em', color:'white', background: `${start ? 'rgb(173 162 172)' : 'rgb(155 76 145)'}`}} onClick={()=>{startNow()}}> Start Test </Button>
                    <Button disabled={!start} style={{margin:'1.4em',width:'10em', color:'white', background: `${start ? 'rgb(255, 101, 157)' : 'rgb(173 162 172)'}`}} onClick={()=>{stop()}}> End Test </Button>
                </Grid>
                <Grid item xs={4}>
                    <div className={`${start ? 'mv-rail-sticky-400' : ''}`}>
                        <div id="content_7_btf_wrapper" className="adunitwrapper content_btf_wrapper mv-size-320x100">
                        </div>
                    </div>
                </Grid>
                <Grid item xs={1} style={{float:'right', marginTop:'0.5em'}}>
                    <SessionClock  clockNotification={clockNotification} ref={childRef} time={time} reset={"true"}></SessionClock>
                </Grid>
            </Grid> 

            {start&&questions&&<SkillTest questions = {questions} title={selected} setDone= {setDone}></SkillTest>}
            </> : <></>
        }
        <div style={{height:'5em'}}></div>
        </div>

}

export default SkillTestCatalog;