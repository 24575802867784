import React, { useEffect, useContext } from "react";
import { Redirect } from "react-router-dom";

import { SetPopupContext } from "../home/component/MainRouter";
import { editSection, setLoggedIn } from "../../state/action-creators/profile";
import { useDispatch } from "react-redux";
import { fetchWorkspaceSuccess, setWorkSpaceItems } from "../../state/action-creators/workspace";

const Logout = (props) => {
  const setPopup = useContext(SetPopupContext);
  const dispatch = useDispatch();

  useEffect(() => {
    // localStorage.removeItem("token");
    // localStorage.removeItem("type");
    // localStorage.removeItem("name");
    // localStorage.removeItem("hasProfile");
    // localStorage.removeItem("tiny");
    localStorage.clear();
    dispatch(editSection({}));
    dispatch(setLoggedIn(null))
    dispatch(setWorkSpaceItems([]));
    dispatch(fetchWorkspaceSuccess([]));
    setPopup({
      open: true,
      severity: "success",
      message: "Logged out successfully",
    });
    //console.log("token:", localStorage.getItem("token"));
   
  }, []);
  return <Redirect to="/login" />;
};

export default Logout;
