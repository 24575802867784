import React, { useState, useEffect } from 'react';
import Navbar from './Navbar';
import Footer from './Footer';
import Sidebar from './Sidebar';
import MainWindow from './MainWindow';
import { Modal } from '@material-ui/core';
import Instructions from './Instructions';
import { API, getQuestionsWithoutAuth, getTestById } from "../../config/apiService";
import { useParams } from 'react-router-dom';
import { useHistory } from 'react-router-dom';
import { a2b } from '../../utils/decodeUtil';
import { useDispatch, useSelector } from 'react-redux';
import { addQuestions } from '../../state/action-creators/questions';
import Loader from '../loader/Loader';

const TestLayout = () => {
  const [selectque, setSelectque] = useState(0);
  const [modal, setModal] = useState(true);
  const [start, setStart] = useState();
  const [company, setCompany] = useState();
  const [time, setTime] = useState();
  const [answeredCount, setAnsweredCount] = useState(0);
  const { testId, code } = useParams();
  const history = useHistory();
  const [loading,setLoading] = useState(true);
  const [questionCount,setQuestionCount] = useState(0);

  const dispatch = useDispatch();

  // TODO: uncomment for prevent tab Switching, disable right click.
  useEffect(() => {
    getQuestions();
    const handleContextMenu = (event) => {
      event.preventDefault();
    };

    const handleVisibilityChange = () => {
      if (document.hidden) {
        // The page is hidden, warn the user or perform any necessary actions
        alert("Warning: You switched to another tab!");
      }
    };

    // Attach the event listener when the component mounts
    // document.addEventListener('contextmenu', handleContextMenu);
    // document.addEventListener("visibilitychange", handleVisibilityChange);
    // Remove the event listener when the component unmounts
    return () => {
      // document.removeEventListener('contextmenu', handleContextMenu);
      // document.removeEventListener("visibilitychange", handleVisibilityChange);
    };
  }, []);

  const getQuestions = async () => {
    try {
      const api = `${API.test}/${testId}/${code}`
      const data = await getTestById(api);
      setTime(data.test?.duration);
      setCompany(data.test?.company);
      const test = data.test;
      console.log(test)
      if (!test || test?.candidateData?.score !== -1) {
        let reason;
        if(test?.candidateData?.score !== -1) reason = 'Test already taken';
        if(!test) reason = 'Test is Expired / Invalid';
        return history.replace({
          pathname: '/invalid-test',
          state: { reason }
        });
      }
      setQuestionCount(test.totalQuestions);
      setLoading(false);
      let topic = `[${test.topic}]`;
      let address = `${API.skill}/quiz?topic=${topic.toLocaleLowerCase()}&count=${test.totalQuestions}`;
      let quizzes = await getQuestionsWithoutAuth(address, "Quiz score");
      let questions = [];
      for (let quiz of quizzes) {
        let temp = JSON.parse(a2b(quiz));
        temp.question = temp.question.replace(/^[^.]*\. /, '');
        temp.check = null;
        questions.push(temp);
      }
      console.log(questions);
      // save question to redux
      dispatch(addQuestions(questions));
    } catch(error) {
      console.log(error);
    }
  }

  return (
    <div style={{ height: '100vh', display: 'flex', flexDirection: 'column' }}>
      <Loader isLoading={loading} />
      <Modal open={modal} className="flex justify-between items-center" >
        <div className='flex items-center rounded-md' style={{ width: '1000px', height: '500px', backgroundColor: 'white' }}>
          <Instructions start={start} setStart={setStart} setModal={setModal} questionCount={questionCount} />
        </div>
      </Modal>
      <Navbar start={start} company={company} time={time} />
      <hr className="h-px bg-gray-200 border-1 dark:bg-gray-700" />
      <div style={{ flex: 1, display: 'flex', overflowY: 'hidden' }}>
        <Sidebar style={{ flex: '0 0 auto' }} selectque={selectque} setSelectque={setSelectque} />
        <MainWindow style={{ flex: 1 }} selectque={selectque} answeredCount={answeredCount} setAnsweredCount={setAnsweredCount} />
      </div>
      <Footer answeredCount={answeredCount} />
    </div>
  );
}

export default TestLayout;
