import React, { useState, useEffect, useContext } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  Button,
  Grid,
  Paper,
  TextField,
  Modal,
  FormControlLabel,
  MenuItem,
  ListItem,
  ListItemText,
  Collapse,
  TableRow,
  IconButton,
  TableCell,
} from "@material-ui/core";
import { Radio } from "@mui/material";
import { SetPopupContext } from "../home/component/MainRouter";
import {API, fetchData, saveData} from "../../config/apiService"
import {manageInterview, selectCourseItem} from "../../state/action-creators/workspace"
import TextEditor from "../editor/editor-text/TextEditor";
import { ExpandLess, ExpandMore } from "@material-ui/icons";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { loadWorkspace } from "../../utils/datautil/dataUtils";

const descCode= `<p>Provide problem description in detail with some example, input and output format, as below example given.<br><br>Given a non-negative integer num, repeatedly add all its digits until the result has only one digit. </p><p><br></p><p><span style="color: rgb(120, 84, 18);">For example: </span></p><p><span style="color: rgb(120, 84, 18);">Given num = 38, the process is like: 3 + 8 = 11, 1 + 1 = 2. Since 2 has only one digit, return it. </span></p><p><span style="color: rgb(120, 84, 18);">Follow up: Could you do it without any loop/recursion in O(1) runtime?</span></p>`
const descDesign= `<p>Describe your system design in detail </p>`
const CODE = 1;
const DESIGN = 2;
const QUIZE = 3;

const CreateMock = (props)=>{
    const dispatch = useDispatch();
    const setPopup = useContext(SetPopupContext);
    const [template, setTemplate] = useState({title: '', desc: descCode})
    const [select, setSelect] = useState(CODE);

    const createMock = async()=>{
        let data = {
            category: select === 1 ? 'code' : 'design'
        }
        let res = await saveData(API.question, data, "mock", setPopup );
       
        if(res && res.data) {
            console.log('success:', res.data._id)
            props.setMock(false);
            props.refreshData();
            let url = `${window.location.hostname}:${window.location.port}/interview:${btoa(res.data._id)}`
           // window.open(url);
        }
    }

    const styles = {
        modalStyle:{
        paddingTop:'2em'
      }
    }

    return  <Modal open={props.mock} onClose={()=>props.setMock(false)}>
        <Paper
            style={{
                padding: "4%",
                outline: "none",
                minWidth: "30%",
                width:'40%',
                marginLeft:'30%',
                marginTop:'10%',
                height:'auto; overflow'
            }}
        >

        <Grid container direction="column" spacing={2}>
                <Grid item>
                        <h2>Create Mock Test</h2>
                        <hr style={{marginTop:'1em'}}></hr>
                </Grid>
                <Grid item container direction="row" spacing={2} alignItems="center">

                    <Grid item xs={3}>
                        Select Option
                    </Grid>
                    <Grid item>
                    <FormControlLabel
                        control={
                        <Radio
                            name="code"
                            checked={ (select == CODE)}
                            onChange={(event) => {
                                setSelect(CODE);
                                let temp = {...template};
                                temp.title = "";
                                temp.desc = descCode;
                                setTemplate(temp);
                            }}
                        />
                        }
                        label="Code"
                    />
                    </Grid>
                    <Grid item>
                    <FormControlLabel
                        control={
                        <Radio
                            name="code"
                            checked={ (select == DESIGN)}
                            onChange={(event) => {
                                setSelect(DESIGN);
                                let temp = {...template};
                                temp.title = "";
                                temp.desc = descDesign;
                                setTemplate(temp);
                            }}
                        />
                        }
                        label="Design"
                    />
                    </Grid>
                    <Grid item>
                    </Grid>
                </Grid>
                <Grid item>
                    <h3 style={{color:'#5eac1a'}}>Instruction:</h3>
                </Grid>
                <Grid item>
                    <hr></hr>
                </Grid>
                <Grid item>
                    <ul style={{marginLeft:'2em', marginBottom:'2em', color:'#5eac1a'}}>
                        <li>Total number of questions: 1</li>
                        <li>Time allotted: 30 minutes</li>
                        <li>DO NOT refresh the page.</li>
                        <li>All the best!</li>
                    </ul>
                </Grid>
                <Grid container item direction="row" spacing={2} alignItems="center">
                    <Grid item xs={2}>
                        
                    </Grid>
                    <Grid item xs={8}>
                            <Button
                                disabled = {template.title =='' && template.desc ==''}
                                variant="contained"
                                color="primary"
                                style={{background:'#239ce2', padding: "10px 50px", marginLeft:'16px' }}
                                onClick={() =>createMock()}
                            >
                                Start Test
                            </Button>
                    </Grid>
                </Grid>
                
        </Grid>
    </Paper>
  </Modal>
}

export default CreateMock;


