import React, { useContext, useEffect, useState } from "react";
import {
  Button,
  Grid,
  Typography,
  Modal,
  Paper,
  makeStyles,
  TextField,
  MenuItem,
} from "@material-ui/core";
import axios from "axios";
import ChipInput from "material-ui-chip-input";

import { SetPopupContext } from "../home/component/MainRouter"
import apiList, { server } from "../../config/apiList";
import { locations } from "../../data/recruitement/data"
import { jobTMPLT } from "./jobTemplate";
import TextEditor from "../editor/editor-text/TextEditor";



const useStyles = makeStyles((theme) => ({
  body: {
    height: "inherit",
  },
  popupDialog: {
    height: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    // padding: "30px",
  },
}));

const emptyDetails = {
  title: "",
  skillsets: [],
  minexp: 0,
  maxexp: 7,
  location : 'Hybrid',
  company: "",
  template: "TEMPLATE - BASIC",
  summary: "",
  jobdetails:"",
  maxApplicants: 100,
  maxPositions: 30,
  deadline: 30,
  // deadline: new Date(new Date().getTime() + 10 * 24 * 60 * 60 * 1000)
  //   .toISOString()
  //   .substr(0, 16),
  
  jobType: "Full Time",
  //duration: 0,
  salary: 0,
  rating:5
}

const CreateJobs = (props) => {
  const classes = useStyles();
  const setPopup = useContext(SetPopupContext);
  let locs = ["WFH","Hybrid","PAN INDIA",...locations];
  
  const [jd, setJd] = useState();
  const [jobDetails, setJobDetails] = useState(emptyDetails);
  const handleInput = (key, value) => {
    setJobDetails({
      ...jobDetails,
      [key]: value,
    });
  };

  const postJob = () => {
    jobDetails.jobdetails = jd;
    console.log(jobDetails);
    axios
      .post(apiList.jobPosting, jobDetails, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
      .then((response) => {
        setPopup({
          open: true,
          severity: "success",
          message: response.data.message,
        });
        //setJobDetails( emptyDetails);
      })
      .catch((err) => {
        setPopup({
          open: true,
          severity: "error",
          message: err.response.data.message,
        });
        console.log(err.response);
      });
  };

  return (
    <>
      <Grid
        container
        item
        direction="column"
        alignItems="center"
        style={{ padding: "30px", minHeight: "93vh", width: "" }}
      >
        <Grid item>
          <Typography variant="h3">Post a New Job</Typography>
        </Grid>
        <Grid item container xs direction="column" justify="center">
          <Grid item>
            <Paper
              style={{
                padding: "20px",
                outline: "none",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Grid
                container
                direction="column"
                alignItems="stretch"
                spacing={3}
              >
                <Grid item>
                  <TextField
                    label="Title"
                    value={jobDetails.title}
                    onChange={(event) =>
                      handleInput("title", event.target.value)
                    }
                    variant="outlined"
                    fullwidth
                  />
                </Grid>
                <Grid item>
                  <TextField
                    label="Company"
                    value={jobDetails.company}
                    onChange={(event) =>
                      handleInput("company", event.target.value)
                    }
                    variant="outlined"
                    fullwidth
                  />
                </Grid>

                <Grid item>
                  <TextField style={{width:'15em', marginLeft:'0em'}} 
                      select
                      label="Job Location"
                      variant="outlined"
                      className={classes.inputBox}
                      value={jobDetails.location}
                      onChange={(event) => {
                        handleInput("location", event.target.value);
                      }}
                    >
                      {
                        locs.map((key)=>{
                          return <MenuItem id={key} value={key}>{key}</MenuItem>
                      })
                    }
                  </TextField>

                  <TextField style={{width:'9em', marginLeft:'1em'}} 
                    type="number"
                    label="Min Exp. Level"
                    value={jobDetails.minexp}
                    onChange={(event) =>
                      handleInput("minexp", event.target.value)
                    }
                    variant="outlined"
                    InputProps={{ inputProps: { min: 0, max:10 } }}
                  />
                  <TextField style={{width:'9em', marginLeft:'1em'}} 
                    type="number"
                    label="Max Exp. Level"
                    value={jobDetails.maxexp}
                    onChange={(event) =>
                      handleInput("maxexp", event.target.value)
                    }
                    variant="outlined"
                    InputProps={{ inputProps: { min: 0, max:10 } }}
                  />

                </Grid>
                <Grid item>
                  <ChipInput
                    className={classes.inputBox}
                    label="Skills (Press enter to add skill)"
                    variant="outlined"
                    helperText="Press enter to add skill"
                    value={jobDetails.skillsets}
                    onAdd={(chip) =>
                      setJobDetails({
                        ...jobDetails,
                        skillsets: [...jobDetails.skillsets, chip],
                      })
                    }
                    onDelete={(chip, index) => {
                      let skillsets = jobDetails.skillsets;
                      skillsets.splice(index, 1);
                      setJobDetails({
                        ...jobDetails,
                        skillsets: skillsets,
                      });
                    }}
                    fullwidth
                  >
                   
                  </ChipInput>
                </Grid>
                {/* <Grid item>
                  <ChipInput
                    className={classes.inputBox}
                    label="Job Description (Press enter to add description)"
                    variant="outlined"
                    helperText="Press enter to add description"
                    value={jobDetails.jobdesc}
                    onAdd={(chip) =>
                      setJobDetails({
                        ...jobDetails,
                        jobdesc: [...jobDetails.jobdesc, chip],
                      })
                    }
                    onDelete={(chip, index) => {
                      let jobdesc = jobDetails.jobdesc;
                      jobdesc.splice(index, 1);
                      setJobDetails({
                        ...jobDetails,
                        jobdesc: jobdesc,
                      });
                    }}
                    fullwidth
                />
                  <ul style={{marginTop:'1.5em',  marginBottom:'1rem', marginLeft:'1em'}}>
                    { 
                        jobDetails.jobdesc&&jobDetails.jobdesc.map(item => {
                            return <li>{item}</li>
                          }
                        )
                    }
                  </ul>
                </Grid> */}
                <Grid item>
                  <TextField
                    multiline
                    rows={2}
                    maxRows={3}
                    label="Job Summary"
                    value={jobDetails.summary}
                    onChange={(event) =>
                      handleInput("summary", event.target.value)
                    }
                    variant="outlined"
                    fullwidth
                  />
                </Grid>
                <Grid item>
                  <TextField
                    select
                    label="Job Type"
                    variant="outlined"
                    value={jobDetails.jobType}
                    onChange={(event) => {
                      handleInput("jobType", event.target.value);
                    }}
                    fullwidth
                  >
                    <MenuItem value="Full Time">Full Time</MenuItem>
                    <MenuItem value="Part Time">Part Time</MenuItem>
                    <MenuItem value="Work From Home">Work From Home</MenuItem>
                  </TextField>
                </Grid>
                <Grid item>
                  <TextField
                    label="Salary (optional)"
                    type="number"
                    variant="outlined"
                    value={jobDetails.salary}
                    onChange={(event) => {
                      handleInput("salary", event.target.value);
                    }}
                    InputProps = {{ inputProps: { min: 100000 } }}
                    fullwidth
                  />
                </Grid>
                <Grid item>
                  {/* <TextField
                    label="Application Deadline"
                    type="datetime-local"
                    value={jobDetails.deadline}
                    onChange={(event) => {
                      handleInput("deadline", event.target.value);
                    }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    variant="outlined"
                    fullwidth
                  /> */}
                  <TextField
                    select
                    label="Application Deadline"
                    type="number"
                    value={jobDetails.deadline}
                    onChange={(event) => {
                      handleInput("deadline", event.target.value);
                    }}
                    fullwidth>
                    {
                      [...Array(99)].map((_, i)=>
                        <MenuItem id={i} value={i+2}>{i+2} days</MenuItem>
                      )
                    }
                  </TextField>
                </Grid>
                <Grid item>
                  <TextField
                    label="Maximum Number Of Applicants"
                    type="number"
                    variant="outlined"
                    value={jobDetails.maxApplicants}
                    onChange={(event) => {
                      handleInput("maxApplicants", event.target.value);
                    }}
                    InputProps={{ inputProps: { min: 1 } }}
                    fullwidth
                  />
                </Grid>
                <Grid item>
                  <TextField
                    label="Positions Available"
                    type="number"
                    variant="outlined"
                    value={jobDetails.maxPositions}
                    onChange={(event) => {
                      handleInput("maxPositions", event.target.value);
                    }}
                    InputProps={{ inputProps: { min: 1 } }}
                    fullwidth
                  />
                </Grid>
                <Grid item>
                  <TextField
                    select
                    label="Select Job Template and Modify"
                    value={jobDetails.template}
                    onChange={(event) => {
                      handleInput("template", event.target.value);
                      let item = jobTMPLT.filter( (v)=>(v.key == event.target.value))
                      if(item.length > 0) {
                        setJd(item[0].value);
                      }
                    }}
                    
                    fullwidth
                  >
                    {
                      jobTMPLT.map((item, i)=>
                        <MenuItem id = {i} value = {item.key}>{item.key}</MenuItem>
                      )
                    }
                  </TextField>
                </Grid>
                <Grid item>
                  <TextEditor  toolbar = {true} mode = 'edit' content = {jd} contentChanged = {(value)=>{
                      console.log(value); 
                      setJd(value)
                    }}>
                  </TextEditor>
                </Grid>
              </Grid>
              <Button
                variant="contained"
                color="primary"
                style={{ padding: "10px 50px", marginTop: "30px", background:'#1997b5', color:'white'}}
                onClick={() => postJob()}
              >
                Post Job
              </Button>
            </Paper>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default CreateJobs;
