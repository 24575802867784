import axios from "axios";
import apiList from "../../../config/apiList";
import { setLoggedIn } from "../../../state/action-creators/profile";
import isAuth from "./isAuth";
import { toast } from "react-toastify";

 const guestLogin = (dispatch, setPopup)=> {
    const guestDetails = {
        email: "info@joblient.com",
        id:  localStorage.getItem('gdate') ?  btoa(localStorage.getItem('gdate')) : btoa((new Date).getTime())
    }


    axios
      .post(apiList.guest, guestDetails)
      .then((response) => {

            localStorage.setItem("token", response.data.token);
            localStorage.setItem("type", response.data.type);
            localStorage.setItem("name", response.data.name);
            localStorage.setItem("hasProfile",response.data.hasProfile);
            localStorage.setItem("tiny",response.data.tiny);
            localStorage.setItem("time",response.data.time);
            dispatch(setLoggedIn(isAuth()))
            console.log('Guest Logged in success:', response);
            // setPopup && setPopup({
            //     open: true,
            //     severity: "success",
            //     message: "Guest logged in success, please create free signup to access all features!",
            // });
            if (!localStorage.getItem('token')) {
                console.log(`"Guest logged in success, please create free signup to access all features!`)
                toast.info(`"Guest logged in success, please create free signup to access all features!`, {
                position: "top-center",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
              });
            }
      })
      .catch((err) => {
        console.log(`Error:`,err.response.status, err.response);
        if (err?.response?.status===400) {
     
         console.log(`Error:`, err.response);
        return;
        
        } else {
          if (!localStorage.getItem('token')) {
            console.log(`Your guest session of 7 days has expired, please create free signup to access all the system`)
            toast.error(`Your guest session of 7 days has expired, please create free signup to access all the system`, {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }
        }
      })
        
        // setPopup && setPopup({
        //     open: true,
        //     severity: "success",
        //     message: "Your guest session of 7 days has expired, please create free signup!",
        //   });

  }

  export default guestLogin;