import { SymbolPaletteComponent } from "@syncfusion/ej2-react-diagrams";
import { minifyShapes, trCon, trNodes } from "./ShapeMinifiy";
import { black } from "material-ui/styles/colors";

export const interval = [
    1,
    9,
    0.25,
    9.75,
    0.25,
    9.75,
    0.25,
    9.75,
    0.25,
    9.75,
    0.25,
    9.75,
    0.25,
    9.75,
    0.25,
    9.75,
    0.25,
    9.75,
    0.25,
    9.75
];
export const gridlines = {
    lineColor: "#e0e0e0",
    lineIntervals: interval
};

function getPorts(obj) {
    let ports = [
        { id: "port1", shape: "Circle", offset: { x: 0, y: 0.5 } },
        { id: "port2", shape: "Circle", offset: { x: 0.5, y: 1 } },
        { id: "port3", shape: "Circle", offset: { x: 1, y: 0.5 } },
        { id: "port4", shape: "Circle", offset: { x: 0.5, y: 0 } }
    ];
    return ports;
}

export const updateShapes = (diagramInstance)=>{
    let curData = JSON.parse(diagramInstance.saveDiagram());
    return minifyShapes(curData);
    //console.log('save:' , save.connectors)
    let nn = curData.nodes.map( (item, key)=>{ 
        return {
            id: item.id,
            width: item.width,
            height: item.height,
            offsetX: item.offsetX,
            offsetY: item.offsetY,
            shape: item.shape,
            annotations: [{content: item.annotations[0].content}]
    }})
    let cc = curData.connectors.map( (con, key)=>{ 
        // console.log('conn:', conn);
        let temp = {
        id: con.id,
        sourceID: con.sourceID,
        targetID: con.targetID,
        sourcePoint: con.sourcePoint,
        targetPoint: con.targetPoint,
        type: con.type,
        sourcePortID: con.sourcePortID,
        targetPortID: con.targetPortID,
        };
        if (con.annotations.length > 0){
       // console.log('con.annotations[0]:', con.annotations)
        temp['annotations'] = [{content : con.annotations[0].content, style:{color:'red', zIndex:10, backgroundColor:'yellow'}}];
        }
        return temp;
    
    })
    //let cc = [...save.connectors];
    
    let draw = {nodes:nn, connectors:cc};

    return draw;
   // console.log("WSSSSS:", ws);
    //ws && ws.send(JSON.stringify(messageBody));
   // console.log('shape has updated', newMenu);
}
export const nodes = (nodes)=> trNodes(nodes).map(item=>{
        let temp = {...item}; 
        //console.log('type123:', temp);

        if (item.shape.type === 'Text'){
           // temp.style = { fill: "Transparent", color: "Black" };
            temp.style = {
                strokeColor: 'none',
                fill: 'Transparent',
                color: 'Black',
                textAlign: 'Center'
            }
        }

        if (item.shape.shape === 'Terminator'){
            temp.style = { fill: "#b4b4b4", strokeColor: "rgb(22,67,147)" };
        }
        if (item.shape.shape === 'Process'){
            if(item.height === 40) {
                temp.style = { fill: "#5ac8fa", strokeColor: "rgb(22,67,147)" };
            }
            // if(temp.annotations[0].content.indexOf('Auth')>=0) {
            //     temp.style.fill = "rgb(61,182,217)"
            // } else if(temp.annotations[0].content.indexOf('Workers')>=0) {
            //     temp.style.fill = "rgb(118, 137, 134)"
            // }
        }
        if (item.shape.shape==='Decision'){
            temp.style = { fill: "#bb86fc", strokeColor: "rgb(22,67,147)" };
            if(temp.annotations[0].content.indexOf('Load')>=0) {
                   temp.style.fill = "rgb(237,174,115)"
            }
        }
        if (item.shape.shape==='PreDefinedProcess'){
            temp.rotateAngle = 270;
        }
        if (item.shape.shape==='DirectData'){
            temp.style = { fill: "#6bc6d4", strokeColor: "rgb(22,67,147)" };
            if(item.rotateAngle) {
               temp.rotateAngle = item.rotateAngle;
               //temp.style = { fill: "#11a0ff", strokeColor: "rgb(22,67,147)"};
               temp.style = { fill: "#6bc6d4", strokeColor: "Gray"};
            } 
        }
        if (item.shape.shape==='MultiDocument'){
            temp.style = { fill: "rgb(248,215,117)" };
            if(temp.annotations[0].content.indexOf('Cache')>=0) {
                temp.style = { fill: "rgb(245,135,134)", strokeColor: "rgb(22,67,147)" };
            }
        } 
        if ( temp.annotations.length>0) {


            temp.annotations[0].style = {};
            temp.annotations[0].style.color = 'black';
            //console.log('temp.annotations:', temp.annotations);
        }
        //console.log('type:', temp);
        return temp;
    
    }) 


export const connectors= (connectors)=> trCon(connectors).map(item=>{
        let temp = {...item}
        // if(JSON.stringify(temp.sourcePoint) == JSON.stringify(temp.targetPoint)) {
        //     console.log('temp.sourcePoint:', 'equal');
        //     temp.targetDecorator = {};
        //     temp.targetDecorator.shape ='None';
        // }
        temp.style = {
            strokeColor: 'grey'
        }
        if(temp.annotations && temp.annotations.length>0) {
            temp.annotations = [{content : item.annotations[0].content, style:{ color: 'rgb(22,67,147)', Offset:1}}];
        }
        return temp;
    })

export const getNodeDefaults = (node) => {
    let obj = {};
    if (obj.width === undefined) {
        obj.width = 145;
    }
    else {
        let ratio = 100 / obj.width;
        obj.width = 100;
        obj.height *= ratio;
    }
    //obj.style = { fill: "#357BD2", strokeColor: "white" };
    obj.style = { fill: "white", strokeColor: "black" };
    obj.annotations = [
        { style: { color: "black", fill: "transparent" } }
    ];
    //Set ports
    obj.ports = getPorts(node);
    return obj;
}//Sets the default values of a connector

export const getConnectorDefaults = (obj) => {
    if (obj.id.indexOf("connector") !== -1) {
        obj.type = "Orthogonal";
        obj.targetDecorator = {
            shape: "Arrow",
            width: 10,
            height: 10
        };
    }
}
//Sets the Node style for DragEnter element.
export const dragEnter = (args) => {
    //console.log('args:', args)
    let obj = args.element;
    if (obj instanceof Node) {
        let oWidth = obj.width;
        let oHeight = obj.height;
        let ratio = 100 / obj.width;
        obj.width = 100;
        obj.height *= ratio;
        obj.offsetX += (obj.width - oWidth) / 2;
        obj.offsetY += (obj.height - oHeight) / 2;
        obj.style = { fill: "#357BD2", strokeColor: "white" };
    }
}

// export const created=() => {
//     // Sets the zoomFactor
//     //Defines the focusPoint to zoom the Diagram with respect to any point
//     //When you do not set focus point, zooming is performed with reference to the center of current Diagram view.
//     diagramInstance.zoom(0.80, {
//     x: 10,
//     y: 10,
//     });
// }
let connectorSymbols = [
    {
        id: "Link1",
        type: "Orthogonal",
        sourcePoint: { x: 0, y: 0 },
        targetPoint: { x: 60, y: 60 },
        targetDecorator: { shape: "Arrow", style: { strokeColor: '#757575', fill: '#757575' } },
        style: { strokeWidth: 1, strokeColor: '#757575' }
    },
    {
        id: "link3",
        type: "Orthogonal",
        sourcePoint: { x: 0, y: 0 },
        targetPoint: { x: 60, y: 60 },
        style: { strokeWidth: 1, strokeColor: '#757575' },
        targetDecorator: { shape: "None" },
    },
    {
        id: "Link21",
        type: "Straight",
        sourcePoint: { x: 0, y: 0 },
        targetPoint: { x: 60, y: 60 },
        targetDecorator: { shape: "Arrow", style: { strokeColor: '#757575', fill: '#757575' } },
        style: { strokeWidth: 1, strokeColor: '#757575' }
    },
    {
        id: "link23",
        type: "Straight",
        sourcePoint: { x: 0, y: 0 },
        targetPoint: { x: 60, y: 60 },
        style: { strokeWidth: 1, strokeColor: '#757575' },
        targetDecorator: { shape: "None" }
    },
    {
        id: "link33",
        type: "Bezier",
        sourcePoint: { x: 0, y: 0 },
        targetPoint: { x: 60, y: 60 },
        style: { strokeWidth: 1, strokeColor: '#757575' },
        targetDecorator: { shape: "None" }
    }
];

let flowshapes = [
    { id: "Terminator", shape: { type: "Flow", shape: "Terminator" } },
    { id: "Text", shape: {
        type: 'Text',
        content: 'Text'
        },
        style: {
            strokeColor: 'none',
            fill: 'Transparent',
            color: 'Black',
            textAlign: 'Center'
        }

    },
    { id: "Process", shape: { type: "Flow", shape: "Process" } },
    { id: "Decision", shape: { type: "Flow", shape: "Decision" } },
    { id: "Document", shape: { type: "Flow", shape: "Document" } },
    {
        id: "PreDefinedProcess",
        shape: { type: "Flow", shape: "PreDefinedProcess" }
    },
    { id: "PaperTap", shape: { type: "Flow", shape: "PaperTap" } },
    { id: "DirectData", shape: { type: "Flow", shape: "DirectData" } ,
    style: {
        strokeColor: 'none',
        fill: 'none',
        color: 'black',
        textAlign: 'Center',
        transform: 'rotate(180deg)'
    }
},
    { id: "SequentialData", shape: { type: "Flow", shape: "SequentialData" } },
    { id: "Sort", shape: { type: "Flow", shape: "Sort" } },
    { id: "MultiDocument", shape: { type: "Flow", shape: "MultiDocument" } },
    { id: "Collate", shape: { type: "Flow", shape: "Collate" } },
    // { id: "SummingJunction", shape: { type: "Flow", shape: "SummingJunction" } },
    // { id: "Or", shape: { type: "Flow", shape: "Or" } },
    // { id: "InternalStorage", shape: { type: "Flow", shape: "InternalStorage" } },
    // { id: "Extract", shape: { type: "Flow", shape: "Extract" } },
    // { id: "ManualOperation", shape: { type: "Flow", shape: "ManualOperation" } },
    // { id: "Merge", shape: { type: "Flow", shape: "Merge" } },
    // {
    //     id: "OffPageReference",
    //     shape: { type: "Flow", shape: "OffPageReference" }
    // },
    // {
    //     id: "SequentialAccessStorage",
    //     shape: { type: "Flow", shape: "SequentialAccessStorage" }
    // },
    // { id: "Annotation", shape: { type: "Flow", shape: "Annotation" } },
    // { id: "Annotation2", shape: { type: "Flow", shape: "Annotation2" } },
    // { id: "Data", shape: { type: "Flow", shape: "Data" } },
    // { id: "Card", shape: { type: "Flow", shape: "Card" } },
    // { id: "Delay", shape: { type: "Flow", shape: "Delay" } }
];
export const shapePaletters = () => <SymbolPaletteComponent id="symbolpalette" expandMode="Multiple" palettes={[
    {
        id: "flow",
        expanded: true,
        symbols: flowshapes,
        iconCss: "e-diagram-icons1 e-diagram-flow",
        title: "Flow Shapes"
    },
    {
        id: "connectors",
        expanded: true,
        symbols: connectorSymbols,
        iconCss: "e-diagram-icons1 e-diagram-connector",
        title: "Connectors"
    }
]} width={"100%"} height={"700px"} symbolHeight={60} symbolWidth={60} getNodeDefaults={(symbol) => {
    if (symbol.id === "Terminator" ||
        symbol.id === "Process" ||
        symbol.id === "Delay") {
        symbol.width = 80;
        symbol.height = 40;
    }
    else if (symbol.id === "Decision" ||
        symbol.id === "Document" ||
        symbol.id === "PreDefinedProcess" ||
        symbol.id === "PaperTap" ||
        symbol.id === "DirectData" ||
        symbol.id === "MultiDocument" ||
        symbol.id === "Data") {
        symbol.width = 50;
        symbol.height = 40;
    }
    else {
        symbol.width = 50;
        symbol.height = 50;
    }
    symbol.style.strokeColor = '#757575';
}} symbolMargin={{ left: 15, right: 15, top: 15, bottom: 15 }} getSymbolInfo={(symbol) => {
    return { fit: true };
    }}/>