import React, {useState} from 'react'
import { Button, Grid } from '@material-ui/core'


const Instructions = ({start, setStart, setModal, questionCount}) => {

  const startNow = async()=>{
    console.log("Test Started: ");
    setStart('start');
    setModal(false);
}

const stop = ()=>{
    console.log("Test Stop: ");
    setStart('');
}

  return (
    <div className='flex justify-center items-center flex-col'>
      <Grid item xs={11} >
        <div style={{ marginBottom: '0.5em', borderRadius: '10px', padding: '10px', fontSize: '1.2em', border: '0.5px solid lightgray' }}>
          <span style={{ fontWeight: 'bold', color: 'blue', padding: '1em', color: '#5eac1a' }}>Test Instructions:</span>
          <ul style={{ paddingLeft: '2em', marginTop: '1em', color: '#5eac1a' }}>
            <li>{`⭕ No. of questions: ${questionCount}`}</li>
            <li>{`⭕ Test duration: 60 Minutes`}</li>
            <li>{`⭕ Each question carry 4 marks.`}</li>
            <li>{`⭕ There is no negative marking.`}</li>
            <li>{`⭕ Copy/paste of AI contents is prohibited.`}</li>
            <li>{`⭕ Immediately after starting the test, questions will appear`}</li>
            <li>{`⭕ Several unique methods have already been implemented into the system to detect cheating and inappropriate behavior.`}</li>
          </ul>
        </div>
      </Grid>

      <Grid className="md:flex">
        <Grid className="">
          <Button disabled={start} style={{ margin: '1.4em', width: '10em', color: 'white', background: `${start ? 'rgb(173 162 172)' : 'rgb(155 76 145)'}` }} onClick={() => { startNow() }}> Start Test </Button>
        </Grid>
      </Grid>
    </div>
  )
}

export default Instructions