export const catalogue = [
    {
        "id": 1,
        "title": "CHEAT SHEET 65",
        "desc":"CHEAT SHEET",
        "source": 'CHEAT-SHEET',
        "group":4,
        "date": "25/02/23",
        "status":"Added",
        "can_change": "false",
        "type":"content",
        "tag":['#data', '#sql', '#python'],
        "category":'content',
        "content":`
        <span dir="ltr">𝐋𝐢𝐬𝐭 𝐨𝐟 𝐜𝐚𝐫𝐞𝐟𝐮𝐥𝐥𝐲 𝐜𝐮𝐫𝐚𝐭𝐞𝐝 65 𝐜𝐡𝐞𝐚𝐭𝐬𝐡𝐞𝐞𝐭𝐬 𝐲𝐨𝐮 𝐰𝐢𝐥𝐥 𝐞𝐯𝐞𝐫 𝐧𝐞𝐞𝐝 :-<br><br>
        1.  Python : <a href="https://lnkd.in/grD8XUS6" data-attribute-index="3">https://lnkd.in/grD8XUS6</a><br>
        2.  Pandas : <a href="https://lnkd.in/g4yTJ7CP" data-attribute-index="4">https://lnkd.in/g4yTJ7CP</a><br>
        3.  NumPy : <a href="https://lnkd.in/gg9Uw-km" data-attribute-index="5">https://lnkd.in/gg9Uw-km</a><br>
        4.   Matplotlib  <a href="https://lnkd.in/gahrGicD" data-attribute-index="6">https://lnkd.in/gahrGicD</a><br>
        5.  Seaborn  <a href="https://lnkd.in/gcu4UKpw" data-attribute-index="7">https://lnkd.in/gcu4UKpw</a><br>
        6.  Scikit-learn  <a href="https://lnkd.in/gGfkNu5i" data-attribute-index="8">https://lnkd.in/gGfkNu5i</a><br>
        7.  TensorFlow : <a href="https://lnkd.in/g3fw3uRV" data-attribute-index="9">https://lnkd.in/g3fw3uRV</a><br>
        8.  Keras : <a href="https://lnkd.in/gfPTfbgg" data-attribute-index="10">https://lnkd.in/gfPTfbgg</a><br>
        9.  PyTorch <a href="https://bit.ly/3lnsiIz" data-attribute-index="11">https://bit.ly/3lnsiIz</a><br>
        10.  SQL : <a href="https://lnkd.in/gnwe4qcb" data-attribute-index="12">https://lnkd.in/gnwe4qcb</a><br>
        11. R : <a href="https://lnkd.in/gEgJ6A8j" data-attribute-index="13">https://lnkd.in/gEgJ6A8j</a><br>
        12.  Git : <a href="https://lnkd.in/gyzhztvH" data-attribute-index="14">https://lnkd.in/gyzhztvH</a><br>
        13. AWS  <a href="https://bit.ly/3ZQWMS1" data-attribute-index="15">https://bit.ly/3ZQWMS1</a><br>
        14. Azure  <a href="https://bit.ly/42f4N4V" data-attribute-index="16">https://bit.ly/42f4N4V</a><br>
        15.  Google Cloud Platform : <a href="https://bit.ly/3JJADzv" data-attribute-index="17">https://bit.ly/3JJADzv</a><br>
        16.  Docker : <a href="https://bit.ly/3Lt2zJe" data-attribute-index="18">https://bit.ly/3Lt2zJe</a><br>
        17.  Kubernetes  <a href="https://lnkd.in/gjXCT7Mb" data-attribute-index="19">https://lnkd.in/gjXCT7Mb</a><br>
        18.  Linux Command Line : <a href="https://bit.ly/3FtcTgw" data-attribute-index="20">https://bit.ly/3FtcTgw</a><br>
        19.  Jupyter Notebook  <a href="https://lnkd.in/g7cPmgHQ" data-attribute-index="21">https://lnkd.in/g7cPmgHQ</a><br>
        20.  Data Wrangling :<a href="https://bit.ly/3TiMibP" data-attribute-index="22">https://bit.ly/3TiMibP</a><br>
        21.  Data Visualization : <a href="https://lnkd.in/gQ52Jd_J" data-attribute-index="23">https://lnkd.in/gQ52Jd_J</a><br>
        22.  Statistical Inference  <a href="https://lnkd.in/grNXVQh5" data-attribute-index="24">https://lnkd.in/grNXVQh5</a><br>
        23. Probability : <a href="https://lnkd.in/gvnWCphc" data-attribute-index="25">https://lnkd.in/gvnWCphc</a><br>
        24. Linear Algebra : <a href="https://lnkd.in/gty6XpVF" data-attribute-index="26">https://lnkd.in/gty6XpVF</a><br>
        25.  Calculus  <a href="https://lnkd.in/gjhsmsxu" data-attribute-index="27">https://lnkd.in/gjhsmsxu</a><br>
        26. Time Series : <a href="https://bit.ly/3Fvuep4" data-attribute-index="28">https://bit.ly/3Fvuep4</a><br>
        27. NLP: <a href="https://bit.ly/3Fvursm" data-attribute-index="29">https://bit.ly/3Fvursm</a><br>
        28.  Neural Network : <a href="https://lnkd.in/gThs2AAp" data-attribute-index="30">https://lnkd.in/gThs2AAp</a><br>
        29. Deep Learning : <a href="https://lnkd.in/gVbSPae2" data-attribute-index="31">https://lnkd.in/gVbSPae2</a><br>
        30.  Machine Learning : <a href="https://bit.ly/3mZ5Wh3" data-attribute-index="32">https://bit.ly/3mZ5Wh3</a><br>
        31.  Apache Spark : <a href="https://lnkd.in/ge7Rj-Yr" data-attribute-index="33">https://lnkd.in/ge7Rj-Yr</a><br>
        32. Hadoop : <a href="https://bit.ly/3Lq34DR" data-attribute-index="34">https://bit.ly/3Lq34DR</a><br>
        33. Big-O Notation t: <a href="https://lnkd.in/gfYqM8WU" data-attribute-index="35">https://lnkd.in/gfYqM8WU</a><br>
        34.  Regular Expression : <a href="https://lnkd.in/gE9kZTZW" data-attribute-index="36">https://lnkd.in/gE9kZTZW</a><br>
        35.  Unix/Linux Permissions  <a href="https://bit.ly/3ZUfwA8" data-attribute-index="37">https://bit.ly/3ZUfwA8</a><br>
        36.  Python String Formatting <a href="https://lnkd.in/gHi26Uk2" data-attribute-index="38">https://lnkd.in/gHi26Uk2</a><br>
        37.  Flask : <a href="https://lnkd.in/gGzbSTgU" data-attribute-index="39">https://lnkd.in/gGzbSTgU</a><br>
        38.  Django : <a href="https://lnkd.in/grZcWz8y" data-attribute-index="40">https://lnkd.in/grZcWz8y</a><br>
        39.  AWS S3 : <a href="https://bit.ly/3JMYe2A" data-attribute-index="41">https://bit.ly/3JMYe2A</a><br>
        40.  AWS Lambda : <a href="https://bit.ly/3Jq2QtI" data-attribute-index="42">https://bit.ly/3Jq2QtI</a><br>
        41.  AWS EC2 : <a href="https://bit.ly/3JpyuYz" data-attribute-index="43">https://bit.ly/3JpyuYz</a><br>
        42.  AWS CloudFormation : <a href="https://bit.ly/3JHE3D1" data-attribute-index="44">https://bit.ly/3JHE3D1</a><br>
        43.  AWS IAM : <a href="https://bit.ly/3mYW6f5" data-attribute-index="45">https://bit.ly/3mYW6f5</a><br>
        44.  AWS API Gateway : <a href="https://bit.ly/3LvmW8K" data-attribute-index="46">https://bit.ly/3LvmW8K</a><br>
        45.  AWS Route 53 : <a href="https://lnkd.in/gJWK9KqR" data-attribute-index="47">https://lnkd.in/gJWK9KqR</a><br>
        46.  AWS EKS : <a href="https://lnkd.in/gUZYvdvB" data-attribute-index="48">https://lnkd.in/gUZYvdvB</a><br>
        47.  AWS ECS : <a href="https://lnkd.in/geu_Hmu3" data-attribute-index="49">https://lnkd.in/geu_Hmu3</a><br>
        48. AWS RDS : <a href="https://lnkd.in/g-i6kMWB" data-attribute-index="50">https://lnkd.in/g-i6kMWB</a><br>
        49.  AWS Redshift : <a href="https://lnkd.in/gH_fEdU8" data-attribute-index="51">https://lnkd.in/gH_fEdU8</a><br>
        50.  AWS DynamoDB : <a href="https://lnkd.in/ghvfM889" data-attribute-index="52">https://lnkd.in/ghvfM889</a><br>
        51. Git : <a href="https://lnkd.in/gyzhztvH" data-attribute-index="53">https://lnkd.in/gyzhztvH</a><br>
        52.  SQL : <a href="https://lnkd.in/gyierV3f" data-attribute-index="54">https://lnkd.in/gyierV3f</a><br>
        53.   PostgreSQL : <a href="https://lnkd.in/gzfiW7zB" data-attribute-index="55">https://lnkd.in/gzfiW7zB</a><br>
        54.  MySQL: <a href="https://lnkd.in/g4JnPVTe" data-attribute-index="56">https://lnkd.in/g4JnPVTe</a><br>
        55.  MongoDB : <a href="https://lnkd.in/gHc4F4ER" data-attribute-index="57">https://lnkd.in/gHc4F4ER</a><br>
        56.  PySpark RDD : <a href="https://lnkd.in/gTHUYckq" data-attribute-index="58">https://lnkd.in/gTHUYckq</a><br>
        57.  PySpark MLlib : <a href="https://lnkd.in/ggSy4AqJ" data-attribute-index="59">https://lnkd.in/ggSy4AqJ</a><br>
        58.  PySpark GraphX : <a href="https://lnkd.in/gmttid5x" data-attribute-index="60">https://lnkd.in/gmttid5x</a><br>
        59.  PyTorch Lightning : <a href="https://lnkd.in/gNnq5QXz" data-attribute-index="61">https://lnkd.in/gNnq5QXz</a><br>
        60.   TensorFlow Probability Cheat Sheet: <a href="https://lnkd.in/gr3bgDGP" data-attribute-index="62">https://lnkd.in/gr3bgDGP</a><br>
        61. OpenAI GPT-3 Documentation: <a href="https://lnkd.in/gawB_SC9" data-attribute-index="63">https://lnkd.in/gawB_SC9</a><br>
        62. GPT-3 API Reference: <a href="https://lnkd.in/gtCGZvX8" data-attribute-index="64">https://lnkd.in/gtCGZvX8</a><br>
        63. GPT-3 GitHub Repository: <a href="https://lnkd.in/g56cQQPD" data-attribute-index="65">https://lnkd.in/g56cQQPD</a><br>
        64. How to use GPT-3 in Python: <a href="https://lnkd.in/gSx354PM" data-attribute-index="66">https://lnkd.in/gSx354PM</a><br>
        65. Getting started with GPT-3: <a href="https://lnkd.in/g999qJmH" data-attribute-index="67">https://lnkd.in/g999qJmH</a><br>
        `
    },    
]