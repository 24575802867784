export const java_qa_set =[
    {
        "id": 1,
        "title": "Core Java Interview - Advance",
        "tags" :"java,thread",
        "active": true,
        "difficulty": 2,
        "format": "plain",
        
        "sets": [
            {
                "tags": "java,static",
                "Q":`When is static variable loaded? Is it at compile time or runtime? When exactly a static block is loaded in Java?`,
                "A":`Static variable are loaded when classloader brings the class to the JVM. It is not necessary that an object has to be created. Static variables will be allocated memory space when they have been loaded. The code in a static block is loaded/executed only once ie when the class is first initialized. A class can have any number of static blocks. Static block is not member of a class, they do not have a return statement and they cannot be called directly. Cannot contain this or super. They are primarily used to initialize static fields.`
            },
            {
                "tags":"java",
                "Q":`What comes to mind when someone mentions a shallow copy in Java?`,
                "A":`The clone( ) method generates a duplicate copy of the object on which it is called. Only classes that implement the Cloneable interface can be cloned.
                The Cloneable interface defines no members. It is used to indicate that a class allows a bitwise copy of an object (that is, a clone) to be made. If we try to call clone( ) on a class that does not implement Cloneable, a CloneNotSupportedException is thrown. When a clone is made, the constructor for the object being cloned is not called. A clone is simply an exact copy of the original.
                Cloning is a potentially dangerous action, because it can cause unintended side effects. For example, if the object being cloned contains a reference variable called obRef, then when the clone is made, obRef in the clone will refer to the same object as does obRef in the original. If the clone makes a change to the contents of the object referred to by obRef, then it will be changed for the original object, too. Here is another example. If an object opens an I/O stream and is then cloned, two objects will be capable of operating on the same stream. Further, if one of these objects closes the stream, the other object might still attempt to write to it, causing an error.
                Because cloning can cause problems, clone( ) is declared as protected inside Object. This means that it must either be called from within a method defined by the class that implements Cloneable, or it must be explicitly overridden by that class so that it is public.
                Implementing a deep copy of an object can be a learning experience -- you learn that you don't want to do it! If the object in question refers to other complex objects, which in turn refer to others, then this task can be daunting indeed. Traditionally, each class in the object must be individually inspected and edited to implement the Cloneable interface and override its clone() method in order to make a deep copy of itself as well as its contained objects. This article describes a simple technique to use in place of this time-consuming conventional deep copy.`
            },
            {
                "tags": "java,thread",
                "Q":`Once a thread has been started can it be started again?`,
                "A":`No. Only a thread can be started only once in its lifetime. If we try starting a thread which has been already started once, an ‘IllegalThreadStateException’ is thrown, which is a runtime exception. A thread in ‘Runnable state’ or ‘dead state’ thread can’t be restarted.`
            },
            {
                "tags": "java",
                "Q":`When JVM starts up, which thread will be started up first?`,
                "A":`When JVM starts up the thread executing main method is started.`
            },
            {
                "tags": "java",
                "Q":`What are the daemon threads?`,
                "A":`Daemon threads are service provider threads running in the background, these not used to run the application code generally. When all user threads (non-daemon threads) complete their execution, JVM exit the application whatever may be the state of the daemon threads. JVM does not wait for the daemon threads to complete their execution if all user threads have completed their execution.
                To create Daemon thread set the daemon value of Thread using setDaemon(boolean value) method. By default all the threads created by user are user thread. To check whether a thread is a Daemon thread or a user thread use isDaemon() method.
                Example of the Daemon thread is the Garbage Collector run by JVM to reclaim the unused memory by the application. The Garbage collector code runs in a Daemon thread which terminates as all the user threads are done with their execution.`
            },
        ]
    },
    {
        "id": 2,
        "title": "Core Java Interview - Advance",
        "tags" :"java,thread",
        "active": true,
        "difficulty": 2,
        "format": "plain",
        
        "sets": [
            {
                "tags": "java,static",
                "Q":`What are the advantages or usage of threads?`,
                "A":`In sequential programming, updating multiple displays normally requires a big while-loop that performs small parts of each display update. Unfortunately, this loop basically simulates an operating system scheduler. In Java, each view can be assigned a thread to provide continuous updates.
                Programs that need to respond to user-initiated events can set up service routines to handle the events without having to insert code in the main routine to look for these events.`,
            },
            {
                "skip":".",
                "Q":`What is difference between thread and process?`,
                "A":`Differences between threads and processes are:-.
                Threads share the address space of the process that created it; processes have their own address.
                Threads have direct access to the data segment of its process; processes have their own copy of the data segment of the parent process.
                Threads can directly communicate with other threads of its process; processes must use inter-process communication to communicate with sibling processes.
                Threads have almost no overhead; processes have considerable overhead.
                New threads are easily created; new processes require duplication of the parent process.
                Threads can exercise considerable control over threads of the same process; processes can only exercise control over child processes.
                Changes to the main thread (cancellation, priority change, etc) may affect the behavior of the other threads of the process; changes to the parent process do not affect child processes.`
            },
            {
                "skip":".",
                "Q":`Does each thread have its own thread stack?`,
                "A":`Yes each thread has its own call stack. For e g Thread t1 = new Thread();.
                Thread t2 = new Thread();.
                Thread t3 = t1.`
            },
            {
                "Q":`What is a Map?`,
                "A":`A map is an object that stores associations between keys and values (key/value pairs).
                Given a key, you can find its value. Both keys and values are objects.
                The keys must be unique, but the values may be duplicated.
                Some maps can accept a null key and null values, others cannot.`
            },
            {
                "skip":".",
                "Q":`What are the main Implementations of the Map interface?`,
                "A":`The main implementations of the List interface are as follows:.
                HashMap.
                HashTable.
                TreeMap.
                EnumMap.`
            },
        ]
    },
    {
        "id": 3,
        "title": "Core Java Interview - Advance",
        "tags" :"java,jvm",
        "active": true,
        "difficulty": 2,
        "format": "plain",
        
        "sets": [
            {
                "Q":`What is a TreeSet?`,
                "A":`TreeSet is a Set implementation that keeps the elements in sorted order. The elements are sorted according to the natural order of elements or by the comparator provided at creation time.`,
            },
            {
                "Q":`What is the responsibility of Garbage Collector?`,
                "A":`Garbage collector frees the memory occupied by the unreachable objects during the java program by deleting these unreachable objects. It ensures that the available memory will be used efficiently, but does not guarantee that there will be sufficient memory for the program to run.`
            },
            {
                "Q":`Describe, in general, how java's garbage collector works?`,
                "A":`The Java runtime environment deletes objects when it determines that they are no longer being used. This process is known as garbage collection.
                The Java runtime environment supports a garbage collector that periodically frees the memory used by objects that are no longer needed. The Java garbage collector is a mark-sweep garbage collector that scans Java's dynamic memory areas for objects, marking those that are referenced. After all possible paths to objects are investigated, those objects that are not marked (i.e. are not referenced) are known to be garbage and are collected.`
            },
            {
                "Q":`Can a thread hold multiple locks at the same time?`,
                "A":`Yes. A thread can hold multiple locks at the same time. Once a thread acquires a lock and enters into the synchronized method/block, it may call another synchronized method and acquire a lock on another object.`
            },
            {
                "Q":`What is a task's priority and how is it used in scheduling?`,
                "A":`A task's priority is an integer value that identifies the relative order in which it should be executed with respect to other tasks. The scheduler attempts to schedule higher priority tasks before lower priority tasks.`
            },
        ]
    }
]