import { Grid, Checkbox, FormControlLabel } from "@material-ui/core";
import { useContext, useEffect, useRef, useState } from "react";
import SessionClock from "../clock/SessionClock";
import { API, fetchData, saveData } from "../../../config/apiService";
import { useDispatch } from "react-redux";
import { SetPopupContext } from "../../home/component/MainRouter";


const ScoreBox = (props)=>{
    const {selected, mode, time, coins} = props;
    const [config, setConfig] = useState()
    const childRef = useRef();
    const dispatch = useDispatch();
    const setPopup = useContext(SetPopupContext);
    //console.log('coinpanel')
    useEffect( ()=>{
        console.log('config', config)
        if(childRef.current) {
            childRef.current.startClock(time);
        }
        let temp = {...config};
        if(mode=='read'){
            temp.label = ' Min Read';
            temp.status = ' Mark Read';
        } else {
            temp.label = ' Min To Finish';
            temp.status = ' Mark Completed';
        }
        temp.checked = false;
        temp.disabled = false;
        setConfig(temp);
     },[selected])

     const clockNotification = ()=>{
        console.log('Clock has finished the interval');
        let temp = {...config};
        temp.disabled = false;
        setConfig(temp);
        console.log('Clock has finished the interval', temp);
     }

     const onChecked = async()=> {
        let temp = {...config};
        temp.checked = !config.checked;
        setConfig(temp);
        console.log('saving coin collected');
        let data = {title: selected.title, group: selected.group, category: selected.category};
        
        console.log('config to be saved:', data);
        saveData(API.leader, data, "Leader", setPopup);
        //loadWorkspace(dispatch);
        //let res = await fetchData(`${API.leader}?page=2&size=20`, "Leader", null, null, setPopup);

        //console.log('response data:', res);

     }

    return config&&<Grid item container direction="row" alignItems='center' style={{color:'blue'}}>

                <Grid item className="coin" style={{display:'inline-flex', verticalAlign:'middle', marginLeft:'auto', marginRight:20}}>
                        <div style={{}}><strong>{time}</strong>&nbsp;{config.label}</div>
                        <div>
                            <FormControlLabel style={{marginLeft:'2em'}} disabled={config.disabled} control={<Checkbox checked={config.checked} onChange = {()=>onChecked()}/>} label={config.status} />
                        </div>
                        <div>
                            <SessionClock clockNotification={clockNotification} ref={childRef} time={time} reset={"true"}></SessionClock>
                        </div>
                </Grid>
            </Grid>
    
}

export default  ScoreBox;