

import { aws_qa_set } from '../../data/feedback/QA/qa_aws_services';
import { java_qa_set } from '../../data/feedback/QA/qa_java';
import {principal} from '../../data/feedback/pricipal';


export const getInterviewSets = (key)=>{

    if(key=="pricipal") {

        return principal;
    }
    return principal;
}


export const fetchInterviewDetails = (key, id)=>{

    if(key.includes("aws")) {
        console.log('invoked:', id, principal.interviews[id])
        return principal.interviews[id];
    }

    return principal.interviews[0];
}

export const fetchQASets = (key, id)=>{
    if(key.includes("aws")) {
        console.log('aws invoked:', id, aws_qa_set[id])
        return aws_qa_set[id];
    } else if(key.includes("java")) {
        console.log('java invoked:', id, java_qa_set[id])
        return java_qa_set[id];
    }

    return java_qa_set[0];
}