import { Collapse, Grid, ListItem, ListItemText } from "@material-ui/core";
import "./interviews.scss"
import ScheduleInterview from "./ScheduleInterview";
import { useContext, useEffect, useState } from "react";
import {API, fetchData, saveData} from "../../config/apiService"
import {manageInterview, activeInterview} from "../../state/action-creators/workspace"
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { SetPopupContext } from "../home/component/MainRouter";
import { mainProg } from "../../data/practice-code";
import { ExpandLess, ExpandMore } from "@material-ui/icons";
import TextEditor from "../editor/editor-text/TextEditor";
import InterviewTemplate from "./InterviewTemplate";
import CreateMock from "./CreateMock";

const ManageMockInterview = (props)=>{
    const [open, setOpen] = useState(false);
    const [mock, setMock] = useState(false);
    const [expand, setExpand] = useState(false);
    const setPopup = useContext(SetPopupContext);
    const dispatch = useDispatch();
    const history = useHistory();
    //const sessions = useSelector(state=>state.workspace.interview.sessions);
    const [sessions, setSessions] = useState();

    useEffect(() => {
        refreshData();
    }, []);
    
    const handleOpenSession = (item)=>{

        //console.log('item:::', item)

        //let active = {session: item}
        //console.log('item:'. item);
        //dispatch(activeInterview(active))
        //history.push('/interview');
    }

    const sessionFeedback = (item)=>{
       // let active = {session: item}
        //dispatch(activeInterview(active))
        //history.push({pathname: '/interviewreport', state: {session: item}});
    }

    const refreshData = async()=>{
        let result = await fetchData(`${API.interview}?mode=mock`, "Test", dispatch, manageInterview);
        setSessions(result);
        console.log('refresh interview data invoked:', result)
    }

    const sessionDelete = async(session)=> {
        let data = {
            _id : session._id,
            isDeleted: true,
        }
       // console.log('feedback:', data);
        let res = await saveData(API.interview, data, "Interview", setPopup );
        
        if (res) {
            console.log('sessionDelete:', res);
            refreshData();
        }
    }
    const saveLink = ()=>{
        let data = {
            createdBy: 'Interviewer',
            email:'test@jobs.com',
            assignment:'Code',
            //workspace:'ms',
            //sessionLink: link,
            //actions: {text:'Feedback', feedback: ''},
            mode:'interview',
            assessments: [{
                category:'code',
                title:'Code Test',
                desc:"",
                basecode_lang: 'java',
                code_main: mainProg,
            },
            {
                category:'design',
                title:'Design Test',
                desc:"",
                code_main: mainProg,
            }
        ],
           // feedback: {label:'Feedback', status:'', value:'' }
        }

        let success = saveData(API.interview, data, "Interview", setPopup );
        if(success) {
            refreshData();
        }
    }
    const applyStyle = (key)=> {
        let style;
        if(key%2 == 0) {
            style = {
                fontSize:'0.7em',
                background:'#f4f4f1',

            }
        } else {
            style = {
                fontSize:'0.7em',
                //background:'#f4f648'
            }
        }
        return style;
    }

    return <Grid container direction="column" alignItems="center" spacing={2} style={{width:'98%', marginTop:'3em', marginLeft:'0em', paddingRight:'2em', paddingTop:'1em', borderRadius:'10px', border:'1px solid lightgray'}} >
            <Grid item container direction="row"  >
                <Grid item xs={9}>
                    <h3>Manage Test
                    <hr></hr>
                    </h3>
               </Grid>
            </Grid>

            <Grid item container direction="row" justifyContent="center" spacing={2} >
                {mock === true && <CreateMock mock = {mock} setMock = {setMock} refreshData = {refreshData}/>}
            </Grid>
            <Grid item container direction="row" style={{marginTop:'0em'}} >
                <Grid item xs={2}>
                    <h3>Your Test</h3>
                </Grid>
                <Grid item xs={10}>
                    <button type ='button' className="btnLow"  style={{paddingLeft:'1em', paddingRight:'1em', marginLeft:'auto', float:'right'}} onClick={()=>setMock(true)} >Create Test Link</button>
                </Grid>
            </Grid>
            <Grid item container direction="row" alignItems="flex-start" spacing={1} style={{background:'#f4f6f8', marginLeft:'1em',marginTop:'2em',marginBottom:'1em', fontWeight:'bold', fontSize:'0.7em', border:'5px'}} >
                <Grid item xs={1}>Created By</Grid>
                <Grid item xs={1}>Type</Grid>
                <Grid item xs={1}>Status</Grid>
                <Grid item xs={1}>Coins</Grid>
                <Grid item xs={1}>Date Created</Grid>
                <Grid item xs={2}>Problem</Grid>
                
                <Grid item xs={2}>Session Link</Grid>
                <Grid item xs={2}>Actions</Grid>
            </Grid>
            {sessions && sessions.map((_, key)=> sessions[key].assessments ?
            <Grid key={key} item container direction="row" alignItems="flex-start" spacing={1} style={{fontSize:'0.8em', marginLeft:'1em'}}>
                <Grid item xs={1}>{sessions[key].createdBy}</Grid>
                <Grid item xs={1}>{sessions[key].assessments[0].level.toUpperCase()}</Grid>
                <Grid item xs={1} style={{color:`${sessions[key].assessments[0].status === 'Created' ? 'blue':(sessions[key].assessments[0].status === 'Passed' ? 'green': 'red')}`, fontWeight:'bold'}}><div>{sessions[key].assessments[0].status}</div></Grid>
                <Grid item xs={1} style={{fontWeight:'bold'}}><div>{sessions[key].assessments[0].coins}</div></Grid>
                <Grid item xs={1}>{ new Date(sessions[key].createdOn).toLocaleDateString('en-GB', {
                                    day: 'numeric', month: 'short', year: 'numeric'})}</Grid>
                <Grid item xs={2}>{sessions[key].assignment}</Grid>
                <Grid item xs={2}>
                    <div style={{display:'inline-flex', height:'2em', marginLeft:'-2m'}}>
                    {/* <span className="btnLow" style={{height:'2em',marginTop:'0.2em'}} onClick={()=>{handleOpenSession(sessions[key])}}>Open</span> */}
                        <a style={{fontSize:'1em'}} href={`/interview:${btoa(sessions[key]._id)}`} target="_blank">
                            <span className="btnSquare">Open</span>
                        </a>
                        <span className="btnSquare" style={{background:'#eaeef3', color:'black', marginLeft:'0.5em'}}
                            onClick={() => {navigator.clipboard.writeText(`${window.location.hostname}:${window.location.port}/interview:${btoa(sessions[key]._id)}`)}}
                        >Copy</span>
                        {/* <div>{sessions[key].sessionLink}</div> */}
                    </div>
                </Grid>
               
                <Grid item xs={2}>
                    <div style={{display:'inline-flex', height:'2em'}}>

                    <span>
                        <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path fill-rule="evenodd" clipRule="evenodd" d="M8.5 0.625C8.00272 0.625 7.52581 0.822544 7.17417 1.17417C6.82254 1.52581 6.625 2.00272 6.625 2.5V3.277C6.625 4.07265 6.30893 4.83571 5.74632 5.39832C5.36416 5.78048 4.88952 6.04888 4.375 6.18175V5.875C4.375 5.46079 4.03921 5.125 3.625 5.125H1.375C0.960786 5.125 0.625 5.46079 0.625 5.875V12.625C0.625 13.0392 0.960786 13.375 1.375 13.375H3.625C3.99354 13.375 4.3 13.1092 4.3631 12.7588L6.0149 13.2306L6.01501 13.2307C6.34973 13.3263 6.69615 13.3749 7.04427 13.375H7.0445H9.48699C10.4166 13.375 11.3131 13.0297 12.0026 12.4061C12.692 11.7825 13.1253 10.925 13.2182 10C13.2193 9.9897 13.2201 9.97938 13.2207 9.96904L13.3707 7.41904L13.3707 7.41904L13.371 7.41317C13.4064 6.71915 13.1997 6.03438 12.7863 5.4758C12.3729 4.91722 11.7784 4.51948 11.1044 4.35051C11.097 4.34866 11.0896 4.34692 11.0821 4.3453L10.375 4.19075V2.5C10.375 2.00272 10.1775 1.52581 9.82582 1.17417C9.47419 0.822544 8.99728 0.625 8.5 0.625ZM2.875 11.875V7.027V6.625H2.125V11.875H2.875ZM4.375 11.2104C4.45387 11.2275 4.53223 11.2471 4.60996 11.2693L4.60999 11.2693L6.42699 11.7883L6.4271 11.7884C6.62789 11.8458 6.83568 11.8749 7.0445 11.875H7.04473H9.48701C10.0448 11.875 10.5827 11.6678 10.9964 11.2937C11.4065 10.9227 11.6655 10.4139 11.7243 9.86434L11.8731 7.33422C11.8901 6.9881 11.7868 6.64673 11.5806 6.36814C11.376 6.0916 11.0825 5.8939 10.7496 5.80802L10.0344 5.6517L10.014 5.64696C9.68882 5.56632 9.39998 5.37915 9.19355 5.11527C8.98713 4.8514 8.87499 4.52602 8.875 4.191V4.19096V2.5C8.875 2.40054 8.83549 2.30516 8.76517 2.23483C8.69484 2.16451 8.59946 2.125 8.5 2.125C8.40054 2.125 8.30516 2.16451 8.23484 2.23483C8.16451 2.30516 8.125 2.40054 8.125 2.5V3.277C8.125 4.47047 7.65089 5.61507 6.80698 6.45898C6.14196 7.124 5.29023 7.55938 4.375 7.71408V11.2104Z" fill="#1B313D" fillOpacity="0.2"></path>
                        </svg>
                        </span> <span>
                        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path fill-rule="evenodd" clipRule="evenodd" d="M6.5 14.375C6.99728 14.375 7.47419 14.1775 7.82583 13.8258C8.17746 13.4742 8.375 12.9973 8.375 12.5V11.723C8.375 10.9274 8.69107 10.1643 9.25368 9.60168C9.63584 9.21952 10.1105 8.95112 10.625 8.81825V9.125C10.625 9.53921 10.9608 9.875 11.375 9.875H13.625C14.0392 9.875 14.375 9.53921 14.375 9.125V2.375C14.375 1.96079 14.0392 1.625 13.625 1.625H11.375C11.0065 1.625 10.7 1.89082 10.6369 2.24119L8.9851 1.76938L8.98499 1.76934C8.65027 1.67369 8.30385 1.62511 7.95573 1.625H7.9555H5.51301C4.58336 1.62499 3.68685 1.97029 2.9974 2.59391C2.30795 3.21753 1.87472 4.07501 1.78176 5C1.78072 5.0103 1.7799 5.02062 1.77929 5.03096L1.62929 7.58096L1.62927 7.58096L1.62897 7.58683C1.5936 8.28085 1.80026 8.96562 2.21367 9.5242C2.62707 10.0828 3.22157 10.4805 3.89564 10.6495C3.90302 10.6513 3.91043 10.6531 3.91786 10.6547L4.625 10.8093V12.5C4.625 12.9973 4.82254 13.4742 5.17418 13.8258C5.52581 14.1775 6.00272 14.375 6.5 14.375ZM12.125 3.125V7.973V8.375H12.875V3.125H12.125ZM10.625 3.78963C10.5461 3.77253 10.4678 3.75286 10.39 3.73067L10.39 3.73066L8.57301 3.21166L8.5729 3.21163C8.37211 3.15424 8.16432 3.12508 7.9555 3.125H7.95527H5.51299C4.9552 3.12499 4.4173 3.33217 4.00363 3.70635C3.59352 4.07729 3.33451 4.58615 3.27573 5.13566L3.1269 7.66578C3.10986 8.0119 3.21319 8.35327 3.41938 8.63186C3.62404 8.9084 3.91748 9.1061 4.25041 9.19198L4.96564 9.3483L4.986 9.35304C5.31118 9.43368 5.60002 9.62085 5.80645 9.88473C6.01287 10.1486 6.12501 10.474 6.125 10.809V10.809V12.5C6.125 12.5995 6.16451 12.6948 6.23483 12.7652C6.30516 12.8355 6.40054 12.875 6.5 12.875C6.59946 12.875 6.69484 12.8355 6.76516 12.7652C6.83549 12.6948 6.875 12.5995 6.875 12.5V11.723C6.875 10.5295 7.34911 9.38493 8.19302 8.54102C8.85804 7.876 9.70977 7.44062 10.625 7.28592V3.78963Z" fill="#1B313D" fillOpacity="0.2"></path>
                        </svg>
                        </span> 
                        {/* <a href="abc">LinkedIn handle</a> */}
                        <a style={{fontSize:'1em',  display:'inline-flex', textDecoration: 'none'}} href={`/report/interview:${btoa(sessions[key]._id)}`} target="_blank">
                        <span>
                            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <mask id="path-2-inside-1" fill="white">
                                <rect x="3.5" y="2" width="9" height="12" rx="1"></rect>
                                </mask>
                                <rect x="3.5" y="2" width="9" height="12" rx="1" stroke="#032538" strokeWidth="3" mask="url(#path-2-inside-1)"></rect>
                                <rect x="6" y="4.5" width="4" height="1" fill="#032538"></rect>
                                <rect x="6" y="6.5" width="3" height="1" fill="#032538"></rect>
                                <rect x="6" y="8.5" width="3" height="1" fill="#032538"></rect>
                                <rect x="6" y="10.5" width="4" height="1" fill="#032538"></rect>
                            </svg>
                        </span> 
                        <div>View report</div>
                        </a> 
                        
                        <span style={{marginLeft:'1em'}} onClick={()=>{sessionDelete(sessions[key])}}>
                        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path fill-rule="evenodd" clipRule="evenodd" d="M7.625 6.875C7.625 6.46079 7.28921 6.125 6.875 6.125C6.46079 6.125 6.125 6.46079 6.125 6.875V10.625C6.125 11.0392 6.46079 11.375 6.875 11.375C7.28921 11.375 7.625 11.0392 7.625 10.625V6.875ZM9.875 6.875C9.875 6.46079 9.53921 6.125 9.125 6.125C8.71079 6.125 8.375 6.46079 8.375 6.875V10.625C8.375 11.0392 8.71079 11.375 9.125 11.375C9.53921 11.375 9.875 11.0392 9.875 10.625V6.875Z" fill="#D02060"></path>
                            <path d="M2.75 4.25H13.25M11.4325 12.562C11.4169 12.7495 11.3314 12.9244 11.193 13.0518C11.0545 13.1793 10.8732 13.25 10.685 13.25H5.3155C5.12731 13.25 4.94599 13.1793 4.80752 13.0518C4.66905 12.9244 4.58356 12.7495 4.568 12.562L3.875 4.25H12.125L11.4325 12.562ZM9.125 2.75H6.875C6.67609 2.75 6.48532 2.82902 6.34467 2.96967C6.20402 3.11032 6.125 3.30109 6.125 3.5V4.25H9.875V3.5C9.875 3.30109 9.79598 3.11032 9.65533 2.96967C9.51468 2.82902 9.32391 2.75 9.125 2.75Z" stroke="#D02060" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
                        </svg>
                        </span>

                        {/* <div className="btnLow" style={{height:'2em',marginTop:'0.2em'}} onClick={()=>{sessionFeedback(sessions[key])}}>Feedback</div> */}
                        {/* <Delete onClick={()=>{sessionDelete(sessions[key])}}/> */}
                     </div>

                </Grid> 
              </Grid>:<div key={key}></div>
                
            )}
        </Grid>
}

export default ManageMockInterview;