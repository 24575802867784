// import { AccessTime } from '@material-ui/icons';
// import CircularProgress from '@mui/joy/CircularProgress';
import { CircularProgress } from "@material-ui/core";
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import React, { useState, forwardRef, useImperativeHandle, useRef, useEffect } from 'react'
import "./style.scss";
  
const SessionClock = forwardRef((props, ref) => {
    // We need ref in this, because we are dealing
    // with JS setInterval to keep track of it and
    // stop it when needed
    const [timer, setTimer] = useState('00:' + props.time + ':00');
    const [progress, setProgress] = useState(0);
    const Ref = useRef(null);
    const [count, setCount] = useState(props.time);

    // useEffect(()=>{
    //     if (Ref.current) clearInterval(Ref.current);
    //     setTimer('00:' + props.time + ':00');
    // },[])

    useImperativeHandle(ref, () => ({
        startClock(time) {
          clearTimer(getDeadTime(), time);
        },
        stopClock() {
            if (Ref.current) clearInterval(Ref.current);
            setTimer('00:' + props.time + ':00');
        },
        timer
      }));

    // console.log('time:', props.time, props.reset);
    // The state for our timer
    //const [timer, setTimer] = useState('00:00:00');

  
    const getTimeRemaining = (e) => {
        const total = Date.parse(e) - Date.parse(new Date());
        const seconds = Math.floor((total / 1000) % 60);
        const minutes = Math.floor((total / 1000 / 60) % 60);
        const hours = Math.floor((total / 1000 / 60 / 60) % 24);
        return {
            total, hours, minutes, seconds
        };
    }
  
  
    const startTimer = (e) => {
        let { total, hours, minutes, seconds } 
                    = getTimeRemaining(e);
        if (total >= 0) {
            setCount(minutes)
            // update the timer
            // check if less than 10 then we need to 
            // add '0' at the beginning of the variable
            setTimer(
                (hours > 9 ? hours : '0' + hours) + ':' +
                (minutes > 9 ? minutes : '0' + minutes) + ':'
                + (seconds > 9 ? seconds : '0' + seconds)
            )
            let secs = hours*60*60 + minutes*60 + seconds;
            let progPer = (60*props.time - secs) * (100/60*props.time);
            //console.log('total seconds:', secs, 'progress:', progPer, progress);
           
            if( progress <= 100) {
                setProgress(progPer);
            } else {
                setProgress(100);
            }
        } else {
            if (Ref.current) clearInterval(Ref.current);
            props.clockNotification();
        }
    }
  
  
    const clearTimer = (e, time) => {
        setProgress(0);
        // If you adjust it you should also need to
        // adjust the Endtime formula we are about
        // to code next  
        if(time > 0) {
            setTimer('00:' + time + ':00');
        } else {
            setTimer('00:' + props.time + ':00');
        }
  
        // If you try to remove this line the 
        // updating of timer Variable will be
        // after 1000ms or 1sec
        if (Ref.current) clearInterval(Ref.current);
        const id = setInterval(() => {
            startTimer(e);
        }, 1000)
        Ref.current = id;
    }
  
    const getDeadTime = () => {
        let deadline = new Date();
  
        // This is where you need to adjust if 
        // you entend to add more time
        deadline.setSeconds(deadline.getSeconds() + props.time*60);
        return deadline;
    }
  
    // We can use useEffect so that when the component
    // mount the timer will start as soon as possible
  
    // We put empty array to act as componentDid
    // mount only
    useEffect(() => {
        //clearTimer(getDeadTime(), 0);
        //onClickReset();
    }, []);
  
    // Another way to call the clearTimer() to start
    // the countdown is via action event from the
    // button first we create function to be called
    // by the button
    const onClickReset = () => {
        clearTimer(getDeadTime(), 0);
    }

    return ( 
         
        <div className="clock" style={{minWidth:'7em'}}>
            {( (count<=4 && timer.charAt(timer.length-1)%2===0) || count >= 5)&&
                <div style={{display: 'inline-flex'}}>
                    <div>
                        {/* <CircularProgress variant="determinate" value={progress} style={{marginLeft:'-2.85em'}}/> */}
                        <AccessTimeIcon style={{color:'#950f2a'}}/>
                    </div>
                        <div>&nbsp;{timer}</div>
                </div>
            }
        </div>
      
        
    )
})
  
export default SessionClock;