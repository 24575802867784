import Sidebar from "./Sidebar";
import "./Layout.scss";
import Topbar from "./Topbar";
import { Button, CircularProgress, Grid, IconButton } from "@material-ui/core";
import { useContext, useState } from "react";
import { BrowserRouter } from "react-router-dom";
import MainRouter, { SetPopupContext } from "../home/component/MainRouter";
import { RiMenuFill, RiMenuUnfoldFill } from "react-icons/ri";
import JobSearchBar from "../job/JobSearchBar";
import { useDispatch, useSelector } from "react-redux";
import { activeInterview, fetchCourses, selectCourseItem } from "../../state/action-creators/workspace/index"
import { useEffect } from "react";
import { useHistory } from "react-router-dom";
import MainView from "../editor/view-switcher/MainView";
import Report from "../interviewer/InterviewReport";
import InterviewReport from "../interviewer/InterviewReport";
import SessionClock from "../utils/clock/SessionClock";
import { useRef } from "react";
import { API, fetchDataWoToken, saveDataWoToken } from "../../config/apiService";
import { useLocation, } from "react-router-dom";
import { initInterviewSession } from "../../utils/datautil/dataUtils";
import IntLandingPage from "../interviewer/IntLandingPage";
import { connectToServer } from "../../config/wssClient";
import DoneIcon from '@mui/icons-material/Done';
import ClearIcon from '@mui/icons-material/Clear';
const TEST_DEFAULT_TIME = 40;
const INT_DEFAULT_TIME = 60;

const IntLayout = () => {
    const setPopup = useContext(SetPopupContext);
    const dispatch = useDispatch();
    const history = useHistory();
    const items = useSelector(state=>state.workspace.items);
    const [session, setSession] = useState();

    const [expand, setExpand] = useState(true);
    const [open, setOpen] = useState(true);

    const [l, setL] = useState(2);
    const [r, setR] = useState(10);
    const [report, setReport] = useState(false);

    const [assessment, setAssessment] = useState();
    const [time , setTime] = useState(0);
    console.log("ITEMS::::::", items)

    const childRef = useRef();

    let token = "";
    const location = useLocation();
    //console.log('props:', location);
    let urlParams = location.pathname.split(':');
    let _id;

    if(urlParams.length>1) {
    //console.log('urlParams:', urlParams);
        _id= atob(urlParams[1]);
    }

    // if (_id) {
    //     if (!wsRef.current) { // new WebSocket('ws://localhost:7071/ws');
    //       connectToServer().then(ws=>{
    //         wsRef.current = ws;
    //      })
    //     } else {
    //         console.log('stablished socket connection')
    //     }
    // } else {
    //     console.log('no active interview session')
    // }

    const toggleFullSceen = () => {
        if (!document.fullscreenElement) {
          document.documentElement.requestFullscreen();
        } else {
          if (document.exitFullscreen) {
            document.exitFullscreen();
          }
        }
    };

    useEffect(() => {
       
        token = localStorage.getItem("token");
        console.log('token:', token)

        if(token) {
            joinInterview('interviewer')
        }
        if(_id) {
            getData();
        }
      }, []);

    const getData = async() => {
        //dispatch(activeInterview({session: _id, user: 'c'}))
        let res = await fetchDataWoToken(`${API.interview}/test?_id=${_id}`, "Interview Data");
        console.log('res123:', _id, res)
        if (res) {
            setSession(res);
            initInterviewSession(dispatch, res.assessments);
            console.log('interview data:', res);
            let assess = {...res.assessments[0]};
            // if (assess.status === 'Completed') {
            //     assess.coins = 0
            // }
            setAssessment(assess);
            dispatch(selectCourseItem(assess));
            if(res&&res.mode === 'interview') {
                setTime(INT_DEFAULT_TIME);
                if(childRef.current) {
                    childRef.current.startClock(INT_DEFAULT_TIME);
                }
            } else {
                setTime(TEST_DEFAULT_TIME);
                if(childRef.current) {
                    childRef.current.startClock(TEST_DEFAULT_TIME);
                }
            }
        }
    }

    const clockNotification = ()=>{
        //when given time finished
        let assess = {...assessment};
        if (assess.attempt === 1) {
            assess.coins = assess.coins/2;
        }
        assess.attempt = assess.attempt + 1;

        setAssessment(assess);
        dispatch(selectCourseItem(assess));
        setPopup&&setPopup({
            open: true,
            severity: "warning",
            message:  "Alloted time reached",
        });
    }

    const refresh = async()=>{
        let res = await fetchDataWoToken(`${API.interview}/test?_id=${_id}`, "Interview Data");
        if (res) {
            let assess = {...res.assessments[0]};
            setAssessment(assess);
        }
    }

    const handleClick = () => {
      setExpand((prev) => !prev);
      setL(l===2?0:2);
      setR(r===10?12:10);
      // console.log(expand, l,r)
    }

    const handleSessionEnd = ()=>{
        window.close();
        //let active = null;
        //dispatch(activeInterview(active))
       // history.push('/manageinterview');
    }

    const saveSession = ()=>{
        let data = {
            _id : session._id,
            assessments : items,
            status: 'Completed'
        }
        console.log('Interview Saved:', data);
        saveDataWoToken(`${API.interview}/test?_id=${_id}`, data, "Interview", setPopup);
    }

    const joinInterview = (participant)=>{
       
        dispatch(activeInterview({session: _id, user: participant}))
        if(childRef.current) {
            childRef.current.startClock(time);
        }
        setOpen(false);
    }

    return(urlParams.length<=1?<div>Invalid url</div>:assessment&&<BrowserRouter>
        <Grid container direction="column" spacing={0} style={{width:'99%'}}>
                { localStorage.getItem("token") == null ?
                    <Grid item> 
                        <IntLandingPage open={open} joinInterview={joinInterview}/>
                    </Grid>
                : <></>
                }
                <Grid item container direction="row">
                    <Grid item xs={1}>
                            {/* hamburger icon shows the drawer on click */}
                            <IconButton onClick={handleClick} style={{ marginLeft:'20px', marginTop:'0em'}}
                                edge="start"
                                color="inherit"
                                aria-label="expand drawer"
                                sx={{ mr: 2, display: { xs: 'block', sm: 'none',}, }}>   
                                {expand ? <RiMenuFill /> : <RiMenuUnfoldFill />}
                            </IconButton>
                    </Grid>
                    {session.mode === "mock" ? 
                    <Grid item xs={11} container spacing={3} direction="row" style={{marginTop:'0em', paddingLeft:'2em'}}>
                        <Grid item xs={1}></Grid>
                            <Grid item style={{marginLeft:'-0.5em'}}>
                                <span className="ivwlabel">Attempt No : {assessment.attempt}</span>
                            </Grid>
                            <Grid item>
                                { assessment.status ==='Passed'?
                                <span className="ivwlabel">Total Points #: {assessment.coins}</span>
                                :
                                <span className="ivwlabel">Max Point Gain #: {assessment.coins}</span>
                                }
                            </Grid>
                            <Grid item >
                                { assessment.status ==='Passed'?
                                <span className="ivwlabel" style={{color: 'rgb(27 77 56)'}}>Status: {assessment.status}&nbsp;<DoneIcon style={{color:'green'}}/></span>
                                :
                                assessment.status ==='Failed'?
                                <span className="ivwlabel" style={{color: 'rgb(83 18 18)'}}>Status: {assessment.status}&nbsp;&nbsp;<ClearIcon style={{color:'#ff0008'}}/></span>
                                :
                                <span className="ivwlabel">Status: {assessment.status}</span>
                                }
                            </Grid>

                        <Grid item style={{marginTop:'-0.5em'}}>
                            <SessionClock  clockNotification={clockNotification} ref={childRef} time={time} reset={"true"}></SessionClock>
                        </Grid>
                        <Grid item style={{marginTop:'-0.5em', marginLeft: 'auto', float:'right'}}>
                            <Button style={{background:'#239ce2', color:'white'}} onClick={()=>saveSession()}>Save</Button>
                            <Button style={{background:'#FF659D', color:'white', marginLeft:'2em'}} onClick={()=>handleSessionEnd()}>End Session</Button>
                        </Grid>

                    </Grid>
                    :<Grid item xs={11} container spacing={3} direction="row" style={{marginTop:'0em', paddingLeft:'2em'}}>
                        <Grid item xs={1}></Grid>
                        <Grid item style={{marginLeft:'-0.5em'}}>
                            <span className="ivwlabel">Status: {assessment.status}</span>
                        </Grid>
                        <Grid item style={{marginTop:'-0.5em', marginLeft: 'auto', float:'right'}}>
                            <Button style={{background:'#239ce2', color:'white'}} onClick={()=>saveSession()}>Submit</Button>
                            <Button style={{background:'#FF659D', color:'white' , marginLeft:'2em'}} onClick={()=>handleSessionEnd()}>End Session</Button>
                        </Grid>
                    </Grid>}
                    <Grid item xs={3}></Grid>
                </Grid>
                {
                report?            
                <Grid item >
                    <InterviewReport/>
                </Grid>
                :<Grid item container direction="row" >
                { expand?
                        <Grid item xs={l} className="sideBar" style={{maxHeight: '96vh', overflow: 'auto'}}>
                            {!open && <Sidebar expand={expand}/>}
                        </Grid>
                    :   <></>
                }
                <Grid item xs={r} >
                    {!open && <MainView refresh = {refresh}/>}
                </Grid>
                </Grid>
                }

        </Grid>
        </BrowserRouter>
    )
}

export default IntLayout;