import { QSetTopicAlgoLink } from "../../data/interviews/QSetTopicwise/algoDS";
import { algoQuestions } from "../../data/interviews/QSetTopicwise/algoDSQuestions";
import { fullStackQuestions } from "../../data/interviews/QSetTopicwise/fullStackQuestions";
import { QSetTopicFullStackLink } from "../../data/interviews/QSetTopicwise/fullstack";
import { QSetTopicSystemLink } from "../../data/interviews/QSetTopicwise/systemDesign";
import { systemQuestions } from "../../data/interviews/QSetTopicwise/systemQuestions";

export const searchMapper = ()=> {

   let data = {
        category: '',
        ques : '',
        answer: '',

    }

}

export const getSets = (search)=> {
    if (search==null) return [];

    const queries = search.split(' ');

    let setGroup = [];

    let sets = [];

    /* search from fullstack list */
    for ( let i = 0; i < queries.length; i++) {
        let found = QSetTopicFullStackLink.questions.filter( (v)=> v.title.toLowerCase().includes(queries[i]));
        if (found.length > 0){
            sets.push({... found[0], index: 0 });
        }
    }
    if(sets.length>0) {
        setGroup.push({key:'Fullstack', index: 0, ques: sets});
    }
    sets = [];

    /* search from algo list */
    for ( let i = 0; i < queries.length; i++) {
        let found = QSetTopicAlgoLink.questions.filter( (v)=> v.title.toLowerCase().includes(queries[i]));
        if (found.length > 0){
            sets.push({... found[0], index: 1 });
        }
    }
    if(sets.length>0) {
        setGroup.push({key:'Algo-DS',index: 1,  ques: sets});
    }
    sets = [];

    /* search from system */
    for ( let i = 0; i < queries.length; i++) {
        let found = QSetTopicSystemLink.questions.filter( (v)=> v.title.toLowerCase().includes(queries[i]));
        if (found.length > 0){
            sets.push({... found[0], index: 2 });
        }
    }
    if(sets.length>0) {
        setGroup.push({key:'System',index: 2,  ques: sets});
    }
    sets = [];

    return setGroup;

}
function getRandomInt(max) {
    return Math.floor(Math.random() * max);
}
const getRandom = (questions)=>{
    let ques = [];
    let visit = {};

    for(let i = 0; i < questions.length; i++) {
        let ii = getRandomInt(questions.length);

        if(visit[ii] == null){
            visit[ii] = true;
            ques.push({id: i, title: questions[ii].title, answer:null});
            console.log('index: ', ii);
            if(ques.length == SIZE) {
                break;
            }
        }
    }
    console.log('ques:', ques);
    return ques;
}

const SIZE = 5;
export const getRandomQuestions = (name, index)=>{
    let ques = [];

    if (index == 0) {
        ques = getRandom(fullStackQuestions[name]['questions']);
    } else if(index == 1) {
        ques = getRandom(algoQuestions[name]['questions']);
    } else if(index == 2) {
        ques = getRandom(systemQuestions[name]['questions']);
    } 

    return ques;

}