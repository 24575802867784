import { useEffect, useState } from 'react';

var axios = require('axios');

var config = {
  method: 'get',
  url: 'https://www.ziprecruiter.in/jobs/search?d=&l=Bengaluru%2C+India&lat=12.96&long=77.59&page=2&q=Java+Developer',
//   headers: { 
//     'Cookie': '_jobboard_session=cc46816289aa29959074657444c830cf; __cf_bm=OeTGiVNw_ZB6OCAmfrZ5U._D3pKjIjyob.IkzwfQGYU-1686287938-0-Ae75re6ScMmQswEhPSYvaOGzgIy91ihMYYlXwmePVp6uDmBhTjLabuTsYxV5EtTQANLoriSNmQuuE7lq3zNSCB8='
//   }
};
let file = 'https://www.ziprecruiter.in/jobs/search?d=&l=Bengaluru%2C+India&lat=12.96&long=77.59&page=2&q=Java+Developer';


function fetchHtml() {
    // fetch(file)
    // .then((response) => {
    //   return response.text();
    // })
    // .then((html) => {
    //     console.log(html);
    //   document.body.innerHTML = html     
    // });
    var myHeaders = new Headers();
    myHeaders.append("Cookie", "_jobboard_session=cc46816289aa29959074657444c830cf; __cf_bm=ddrIZEm1OZdItul3YaVFzgsvBDO3Y.XuYN6vvgFrJAQ-1686293082-0-Ae+BjaIR3CvuBHlpmq7gy4IBmzcN0qyUIr0CrOb/ek4yovJneAStkd0mfRIvbBwg8It/OqEo5pbt9kqU0NXUd44=");
    
    var requestOptions = {
      method: 'GET',
      headers: myHeaders,
      redirect: 'follow'
    };
    
    fetch("https://www.ziprecruiter.in/jobs/search?d=&l=Bengaluru%2C+India&lat=12.96&long=77.59&page=2&q=Java+Developer", requestOptions)
      .then(response => response.text())
      .then(result => console.log(result))
      .catch(error => console.log('error', error));



  }


const SearchExternal = ()=>{

     useEffect(()=>{
        // console.log('front test')
        // axios(config)
        // .then(function (response) {
        //   console.log(JSON.stringify(response.data));
        // })
        // .catch(function (error) {
        //   console.log(error);
        // });
       fetchHtml();



    },
        
[])



    return <div>
        <iframe width="100%" height="1000vh" allowfullscreen />
    </div>

}

export default SearchExternal;