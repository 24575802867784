import { useContext, useEffect, useRef, useState } from "react";
import './SkillTest.scss';
import QuizTest from "./QuizTest";
import { Button, Grid } from "@material-ui/core";
import SessionClock from "../utils/clock/SessionClock";
import { API, fetchData } from "../../config/apiService";
import { saveAssessNRank } from "../../utils/skillRankUtils";
import { SetPopupContext } from "../home/component/MainRouter";
import { showErrorToast } from "../../utils/toastMsg";

const QuizTestCatalog = (props)=>{
    const setPopup = useContext(SetPopupContext);

    const [topics, setTopics] = useState();
    const [quizzes, setQuizzes] = useState();
    const [selected, setSelected] = useState();
    const [start, setStart] = useState();

    const childRef = useRef();
    const time = 10; //10 minutes
   
    const clockNotification = ()=>{
        //when given time finished
        setDone(0);
    }

    useEffect(()=>{
        loadTabData();
    }, [])

    const loadTabData = async()=>{
        let address = `${API.skill}/quiz?topic=skills`
        let temp = await fetchData(address,"Quiz Catalog" );
        setTopics(temp.quizzes);
    }

    const startNow = async()=>{
        let type = localStorage.getItem("type");
        if (type==='guest') {
          showErrorToast("Please logout and signup your account to start your test", 5000);
          return;
        }

        console.log("Test Started: ", selected);
        let topic = `[${selected.toLowerCase()}]`
        let address = `${API.skill}/quiz?topic=${topic}&count=5`
        let quizzes = await fetchData(address,"Quiz score" );
        setQuizzes(quizzes);
        console.log('quizzes::', quizzes)
        setStart('start');
        if(childRef.current) {
            childRef.current.startClock();
        }
    }

    const stop = ()=>{
        console.log("Test Stop: ", selected);
        setStart();
        if(childRef.current) {
            childRef.current.stopClock();
        }
    }

    const onItemSelect = (skill)=>{
        setStart();
        setSelected(skill);
        if(childRef.current) {
            childRef.current.stopClock();
        }
    }

    const setDone = (score, assess)=>{
        if(score>=1) {
            //save score into database
            console.log("Saving quiz test info", score, assess);
            let data = {};
            data.assess = assess;
            let unqId = [];
            for (let x in assess) {
                unqId.push(x);
             }
            data.title =  selected;
            data.id = selected + "-" + unqId.join('-');

            saveAssessNRank(data, "Quizzes", "quiz", setPopup);

        } else {

        }
        setStart();
        if(childRef.current) {
            childRef.current.stopClock();
        }
    }

    return <div  className="topic-chip-container" style={{padding:'1em', background:'white', marginTop:'40px', marginLeft: '10px', marginBottom:'40px', width:'98%', borderRadius:'10px', border:'0.5px solid lightgray'}}>
        <h1>
            <span className="x_576e2b6f">Quiz Assessment Test</span>
            <hr></hr>
        </h1>
        {!start&&<h3>Select a topic to start test</h3>}
        { topics && topics.map((item, key) =>{
        return <div key={key} className="topic-chip hovered" onClick={()=>onItemSelect(item.skill_name)}>
                <div className="justify-content-center align-self-center">
                    <img className="topic-logo1"/>
                    <span style={{color:'#37465A'}}><b>&nbsp;&nbsp;{item.skill_name}</b></span>
                </div>
            </div>
    
        
        })}
        {   selected ? <>
            <h1 style={{marginTop:'1em', marginBottom:'0.5em'}}>
                    <span className="x_576e2b6f">{selected} : Quiz Test</span>
                    <hr></hr>
            </h1>

            <Grid item xs = {11} >
                <div style={{marginBottom:'0.5em', borderRadius:'10px', padding:'10px',fontSize:'1.2em',  border:'0.5px solid lightgray'}}>
                <span style={{fontWeight:'bold',color:'blue', padding:'1em', color:'#5eac1a'}}>Test Instructions:</span>
                    <ul  style={{paddingLeft:'2em', marginTop:'1em',  color:'#5eac1a'}}>
                        <li>{`No. of questions: 05`}</li>
                        <li>{`Test duration: 10 Minutes`}</li>
                        <li>{`Immediately after starting the test, questions will appear`}</li>
                        <li>{`Several unique methods have already been implemented into the system to detect cheating and inappropriate behavior.`}</li>
                        <li>{`Copy/paste of AI contents is prohibited.`}</li>
                        <li>{`A unique and accurate answer will earn you more points.`}</li>
                        <li>{`Up to 100 points will be assigned to each successful submission.`}</li>
                    </ul>
                </div>
            </Grid>

            <Grid className="md:flex">
                <Grid className=""> 
                    <Button disabled={start} style={{margin:'1.4em',width:'10em', color:'white', background: `${start ? 'rgb(173 162 172)' : 'rgb(155 76 145)'}`}} onClick={()=>{startNow()}}> Start Test </Button>
                    <Button disabled={!start} style={{margin:'1.4em',width:'10em', color:'white', background: `${start ? 'rgb(255, 101, 157)' : 'rgb(173 162 172)'}`}} onClick={()=>{stop()}}> End Test </Button>
                </Grid>
                <Grid className="ml-10">
                    <div className={`${start ? 'mv-rail-sticky-400' : ''}`}>
                        <div id="content_7_btf_wrapper" className="adunitwrapper content_btf_wrapper mv-size-320x50">
                        </div>
                    </div>
                </Grid>
                <Grid className="md:mt-4 ml-6">
                    <SessionClock  clockNotification={clockNotification} ref={childRef} time={time} reset={"true"}></SessionClock>
                </Grid>
            </Grid> 

            {start&&quizzes&&<QuizTest questions = {quizzes} setDone= {setDone}></QuizTest>}
            </> : <></>
        }
        <div style={{height:'10em'}}></div>
        </div>

}

export default QuizTestCatalog;