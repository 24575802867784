import { Checkbox, Grid } from '@material-ui/core';
import React, { Component, useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import apiList from '../../../config/apiList';
import ScoreBox from '../../utils/coins/ScoreBox';
import { loadBlobData } from '../../../utils/datautil/blobloader';


const type = 'pdf'


   const PDFViewer = () => {

//    const { ref, isComponentVisible, setIsComponentVisible } = useMouseScroll(true);

   const [time, setTime] = useState(10);
   const ws = useSelector(state=>state.workspace);
   const selected = ws.selected;
   //console.log('get selected file::', selected);
   //const childRef = useRef();
   const [file, setFile] = useState();


     useEffect( ()=>{
        getData();
        
     },[selected])

     const getData = async()=>{
        let url = '';
   
        if(selected != null){
            const q = 'folder=' + selected.path;
           let url = await loadBlobData(selected.source);
           url = url + '#page=5&toolbar=0&navpanes=0&scrollbar=0&view=fitwidth';
          setFile(url);
        }

       

     }
    return  file && (<Grid container direction='column' alignItems='flex-end'>
            <ScoreBox selected={selected} mode={'read'} time={time}></ScoreBox>
            <Grid style={{width:'100%', height:'100%'}}>
                {/* <embed
                src={file}
                width="52%" height="800" /> */}
                {/* <iframe frameborder="0" width="100%" height="1200vh"  src={file} /> */}
                <iframe scrolling="no" frameBorder="0" allowTransparency="true" width="100%" height="1600vh" src={file} allowFullscreen />
                {/* <object width="80%" height="800" data={file} type="application/pdf"></object> */}
            </Grid>
        </Grid>
    )
	}

    export default PDFViewer;
/*
<div id="embed-pdf" style="display: block;">
<embed width="900" height="715" name="plugin" src="https://www.ciena.com/__data/assets/pdf_file/0031/88294/Ciena_Services_for_Broadband-Access_IB-page1.pdf#toolbar=0&amp;navpanes=0&amp;scrollbar=0" type="application/pdf" internalinstanceid="5"></div>
The following parameters are supported:

Parameter	Value	Description	Support
toolbar	1, 0	Show or hide the toolbar	Chrome
view	FitV, FitH, Fit	Change the zoom level to fit (vertical, horizontal or both)	Chrome
zoom	number	Set a specific zoom level in % (example: 300)	Chrome, FF
page	number	Set initial position to a specific page (example: 2)	Chrome, FF, Safari
nameddest	string	Set initial position to a named destination (like a heading)

*/