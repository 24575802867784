import React, { useEffect, useState } from "react";
import Select from "react-select";
import { customStyles } from "../constants/customStyles";
import { languageOptions } from "../constants/languageOptions";

const LanguagesDropdown = ({ language, onSelectChange }) => {

  // const [lang, setLang] = useState(languageOptions[0]);
  // useEffect(()=>{
  //   setLang(language);

  //   console.log('language===========', language)
  // },[language])

  return (
    <Select 
      placeholder={`Filter By Category`}
      options={languageOptions}
      styles={customStyles}
      value={language}
      defaultValue={languageOptions[0]}
      onChange={(selectedOption) => onSelectChange(selectedOption)}
    />
  );
};

export default LanguagesDropdown;
