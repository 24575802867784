import { Grid, TextField } from "@material-ui/core";
import {wordFormatter} from "./wordUtils";
import DeleteIcon from "@material-ui/icons/Delete";
import AddIcon from "@material-ui/icons/Add";
import { useEffect, useState } from "react";
import {getDefaultWork, editField, initWorkExp, editDescList  } from "./utils"


const SectionWorkExperince =(props)=> {
    const {workexp, edit} = props;
    const [value, setValue] = useState(workexp);

    useEffect ( ()=>{
        setValue(workexp);
    },[workexp])

    //console.log('workexp:', workexp);

const vertLines = ()=> <div key={8} className='-ml-6 md:ml-0 w-20 h-[100%] flex mt-8 scale-x-50 md:scale-100'>
        <div key={1} className="ml-2 md:ml-3 w-1 bg-gray-400 h-auto"></div>
        <div key={2} className="ml-1 w-1 bg-gray-400"></div>
        <div key={3} className="ml-2 w-1 bg-gray-400"></div>
        <div key={4} className="ml-1 w-1 bg-gray-400"></div>
        <div key={5} className="ml-2 w-1 bg-gray-400"></div>
        <div key={6} className="ml-1 w-1 bg-gray-400"></div>

        {/* <Grid key={2} className='ml-1 w-1 bg-green-500'><div className="vline12"/></Grid>
        <Grid key={3} className="ml-1 w-1"><div className="vline13"/></Grid>                                
        <Grid key={4} className="ml-1 w-1"><div className="vline12"/></Grid>
        <Grid key={5} className="ml-1 w-1"><div className="vline13"/></Grid>                                
        <Grid key={6} className="ml-1 w-1"><div className="vline12"/></Grid>
        <Grid key={7} className="ml-1 w-1"><div className="vline13"/></Grid>                                
        <Grid key={8} className="ml-1 w-1"><div className="vline12"/></Grid>  */}
    </div>


    return (
        <Grid className=' ml-1' container direction="column">
            <Grid container direction="row">
            {/* {VERTLINES()} */}
                {/* <Grid key={1} className="ml-1 w-1 bg-red-500 "><div className="vline11"/></Grid>
                <Grid key={2} className='ml-1 w-1 bg-green-500'><div className="vline12"/></Grid>
                <Grid key={3} className="ml-1 w-1"><div className="vline13"/></Grid>                                
                <Grid key={4} className="ml-1 w-1"><div className="vline12"/></Grid>
                <Grid key={5} className="ml-1 w-1"><div className="vline13"/></Grid>                                
                <Grid key={6} className="ml-1 w-1"><div className="vline12"/></Grid>
                <Grid key={7} className="ml-1 w-1"><div className="vline13"/></Grid>                                
                <Grid key={8} className="ml-1 w-1"><div className="vline12"/></Grid>  */}
               

                <Grid className="">
                    {
                        workexp.works.map((_, i)=> {
                           
                            return <div key={i} className="flex">
                                    <div>{vertLines()}</div>

                                <div className="list -ml-12 md:ml-0">
                                
                                <div style={{display:'inline-flex'}}>

                                    <div className='duration -ml-24 md:-ml-36 scale-50 md:scale-100 text-[9px]'>
                                        <div style={{marginLeft:'10px', marginTop:'0.8em'}}>{edit ? editField(workexp, setValue,i,'start' , 's') :  workexp.works[i].start}</div>
                                        <div style={{marginLeft:'28px'}}>-</div>
                                        <div style={{marginLeft:'10px'}}>{edit ? editField(workexp, setValue,i,'end' , 's') : workexp.works[i].end}</div>
                                    </div>
                                
                                    <div className="company">
                                        { edit ? <DeleteIcon style={{display:'inline-block', marginLeft:'-23px', marginTop:'-20px'}} onClick={()=>{
                                            let temp = {...workexp}
                                            temp.works.splice(i,1);
                                            setValue(temp);
                                        }}></DeleteIcon>:<></>}
                                        <div className="-ml-8 md:ml-0">{edit ? editField(workexp, setValue,i,'post') : workexp.works[i].post}</div>
                                        <div className="-ml-8 md:ml-0 font-bold">{edit ? editField(workexp, setValue,i,'company') : workexp.works[i].company}</div>
                                    </div>
                                </div>

                                <div className="sub -mt-4 md:mt-0"><h6></h6><div className="headingBold">Technologies:-&nbsp;</div></div>
                                {
                                    edit?<TextField style={{width:'100%'}} multiline value={workexp.works[i].tech} onChange={(event) => {
                                        let temp = {...workexp}
                                        temp.works[i].tech = event.target.value
                                        setValue(temp);
                                    }}>

                                    </TextField>:
                                    wordFormatter(workexp.works[i].tech)
                                }
                                <div style={{marginBottom:'0.6em'}}/>  
                                {
                                    workexp.works[i].desc.map((_, j)=> {
                                        return <div key={j} style={{textAlign: "left"}}>
                                                
                                                {/* {edit?<TextField style={{width:'100%'}} multiline value={workexp.works[i].desc[j]}></TextField>:
                                                    wordFormatter(workexp.works[i].desc[j])
                                                } */}
                                                {edit?editDescList(workexp,setValue,i, j, workexp.works[i].desc.length):
                                                    <div className="sub">
                                                        <h6></h6><div className="headingBold">&nbsp;</div>
                                                        {wordFormatter(workexp.works[i].desc[j])}
                                                    </div>
                                                }
                                        </div>
                                    })
    
                                } 
                                </div>
                            </div>
                            }
                            )
                        }
                        { workexp.works.length===0 ? initWorkExp(workexp, setValue) : <></>}
                        { workexp.works.length>0 ? [...Array(2)].map((_, k)=>  <h3 key={k}>&nbsp;</h3>) : <></>}
                        { edit ? <AddIcon style={{display:'inline-block', marginTop:'20px', marginLeft:'0px'}} onClick={()=> {
                                        let temp = {...value}
                                        temp.works.push(getDefaultWork())
                                        setValue(temp);
                                }}
                                ></AddIcon>:<></>
                        }

                </Grid>
            </Grid>
        </Grid>
    )
}

export default SectionWorkExperince;