import { useContext, useEffect, useRef, useState } from "react";
import './SkillTest.scss';
import QuizTest from "./QuizTest";
import { Button, Grid, Typography } from "@material-ui/core";
import SessionClock from "../utils/clock/SessionClock";
import { API, fetchData } from "../../config/apiService";
import { saveAssessNRank } from "../../utils/skillRankUtils";
import { SetPopupContext } from "../home/component/MainRouter";
import BasicTabs from "../interviewPrep/TabQGroup";
import SkillTest from "./SkillTest";
import { setBk, setBrd } from "../../utils/colorUtils";
import { isMobile } from "../../utils/mobileUtil";

const TopSkills = (props)=>{
    const setPopup = useContext(SetPopupContext);

    const [topics, setTopics] = useState({});
    const [questions, setQuestions] = useState();
    const [selected, setSelected] = useState();
    const [tabIndex, setTabIndex] = useState(0);

    useEffect(()=>{
        loadData('fullstack', 0);
    }, [])

    const loadData = async(skill, index)=>{

        if(!topics[index]) {
            let address = `${API.skill}/skill?topic=${skill}`
            let temp = await fetchData(address,"Skill Catalog" );
            console.log('skill data:', temp.questions);
            let tt = {...topics};
            tt[index] = temp.questions;
            setTopics(tt);
        }
    }

    function removeWhiteSpaces(str) {
        return str
          ?.toString()
       
          ?.replace(/[\r\n|.|' ']/gm, "");
    }


    const startNow = async(title)=>{
        console.log("Test Started: ", title);
        let topic = removeWhiteSpaces(title).toLowerCase();
        let address = `${API.skill}/skill?topic=${topic}`
        console.log('address', address);

        let out = await fetchData(address,"Skill Fetch" );
        if(out) {
            setQuestions(out.questions);
            console.log('out.questions:', out.questions)
        }
    }

    const onItemSelect = (skill)=>{
        console.log('topic:', skill);
        setSelected(skill);
        startNow(skill.title)
    }

    const loadTabData = (index)=>{
        console.log("tab:", index)
        if(index === 0) {
            loadData('fullstack', index);
        } else if(index === 1) {
            loadData('algods', index);
        } else {
            loadData('design', index);
        }

    }

    const onTabChanged = (index)=>{
        setTabIndex(index);
        loadTabData(index);
    }

    return <div className="pt-8 pl-2 pr-2 topic-chip-container">

        <Typography style={{display:'flex', justifyContent:'left', marginBottom:'0.4em'}} variant={'h5'}>Top Interview Questions</Typography>
        <hr></hr>

        <Grid style={{background: '#cadce9', borderRadius: '12px 12px 0 0', width:'100%'}}>
            <BasicTabs setTabIndex= {onTabChanged} isMobile={isMobile}></BasicTabs>
        </Grid>
        
        <Grid style={{background: 'rgb(244 251 252)', borderRadius: '0 0 12px 12px', userSelect:'none' }}>
                <div style={{marginTop:'0vh', padding:'10px'}} className="topic-chip-container">

            {topics[tabIndex] && topics[tabIndex].map((item, key)=>{
                let linkTitle = `${item.title} Interview Questions`;
                let flatLink = item.title.replace('.','').split(' ').join('-');
                let href = '#'; //`/interview-questions/${flatLink.toLowerCase()}`
                let altTitle = `Top ${item.qaCount} ${item.title} Interview Questions`;
                let imgTitle = `Top ${item.qaCount} ${item.title}`;

                return <div key={key} className="topic-chip hovered" onClick={()=>onItemSelect(item)}>
                        <div className="justify-content-center align-self-center">
                            <img className="topic-logo1" src={item.img} alt={altTitle} title={imgTitle}/>
                            <span style={{color:'#37465A'}}><b>&nbsp;&nbsp;{item.title}</b></span><span className="small text-muted">&nbsp;&nbsp;{item.qaCount}&nbsp;&nbsp;</span>
                        </div>
                    </div>
                })
            }
            </div>
        </Grid>

        <Grid item xs={11} >
        
            <h1 style={{marginTop:'1em', marginBottom:'1em'}}>
                {selected && questions&& <span className="x_576e2b6f">{selected.title} Top {questions.length} Interview Questions</span>}
                <hr></hr>
            </h1>
           
            <Grid>
                { questions && questions.map((que, key)=>{
                    return <div key={key} style = {{display:'flex', alignItems: 'center', marginBottom:'2em'}}>
                    <div className="num" style = {setBk(key)} > <span className="text-xl md:text-2xl"> { key < 9 ? `0${key+1}` : `${key+1}` }</span></div>
                        <div className="context" style ={ setBk(key) }>
                            <p className="note" style = {setBrd(key)}>
                                <span className="text-xl md:text-2xl">{que.title}</span>
                            </p>
                        </div>
                    </div>
                   })}
            <div style={{height:'5em'}}></div>
            </Grid>
            </Grid>
        </div>

}

export default TopSkills;